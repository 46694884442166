/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';

const URL_RESOURCE_SUBSCRIPTIONS = '/api/consumption/v0/subscriptions';

export function getLocations() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/consumption/v0/locations?activeOnly=true`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

/**
 * - Get a single or list of subscriptions
 * An empty list is returned if no subscriptions are found.
 */
export function getSubscriptions(/* subscriptionId */) {
  /*
  if (subscriptionId) {
    return RestHelper.get(
      `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${urlSubscriptions}/${subscriptionId}`,
    );
  }
  */

  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${URL_RESOURCE_SUBSCRIPTIONS}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

/**
 * - Creates a new subscription
 * ️️️️️The locations available for creating a new load shape
 * subscription can be obtained using the "getLocations()" API.
 */
export function createSubscription(data) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${URL_RESOURCE_SUBSCRIPTIONS}`,
    data,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

/**
 * - Edit an existing subscription
 */
export function editSubscription(subscriptionId, data) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${URL_RESOURCE_SUBSCRIPTIONS}/${subscriptionId}`,
    data,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

/**
 * - Delete an existing subscription
 * The subscription to delete is identified by the target URI (subscriptionId).
 */
export function deleteSubscription(subscriptionId) {
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${URL_RESOURCE_SUBSCRIPTIONS}/${subscriptionId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}
