import { MeterReadingNotesTypes, PossibleReadingNotesTypes } from '../MeterReadingTypes';

export const getTextFromProps = (label: string, readingNotes: MeterReadingNotesTypes): string => {
  const labelCode: string = label.split('.').pop() || '';
  if (labelCode !== '') {
    const readingNotesObjects = Object.entries(readingNotes);
    const searchedReadingNote = readingNotesObjects.find((readingNote) => readingNote[0].replace(/_/g, '').toLowerCase() === labelCode.toLowerCase());
    if (searchedReadingNote) {
      return searchedReadingNote[1];
    }
  }
  return '';
};

export const getDropOptions = (reasons: PossibleReadingNotesTypes[], readingNotes: MeterReadingNotesTypes, firstDropdownOptionText: string) => {
  if (reasons) {
    const dropOptions = [
      {
        content: firstDropdownOptionText,
        value: 'first_option',
        disabled: true,
      },
    ];
    reasons.map((readingNote) =>
      dropOptions.push({
        content: getTextFromProps(`${readingNote.messageCode}`, readingNotes),
        value: readingNote.number,
        disabled: false,
      })
    );
    return dropOptions;
  }
  return [];
};
