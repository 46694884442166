/* eslint-disable max-len */
import React from 'react';

const DeviceIcon = (): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36'
    height='36'
    viewBox='0 0 36 36'
  >
    <path
      fill='#767676'
      fillRule='evenodd'
      d='M32 22.5a.5.5 0 0 1-.5.5H31v3a.5.5 0 0 1-1 0v-3h-2.5a.503.503 0 0 1-.426-.237.502.502 0 0 1-.021-.487l2-4a.5.5 0 0 1 .894.448L28.309 22H30v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 .5.5zm-16.357-6.577C16.014 14.772 17.04 14 18.196 14c.959 0 1.729.505 2.094 1.351.411.951.207 2.1-.506 2.858L17.157 21h2.811a.5.5 0 0 1 0 1H16a.5.5 0 0 1-.364-.843l3.42-3.634a1.666 1.666 0 0 0 .316-1.776c-.121-.279-.429-.747-1.14-.747h-.001c-.756 0-1.4.495-1.637 1.229a.5.5 0 1 1-.951-.306zM9 19.75C9 20.99 7.869 22 6.479 22c-1.186 0-2.223-.751-2.465-1.786a.499.499 0 0 1 .372-.601.494.494 0 0 1 .6.372c.139.588.767 1.015 1.493 1.015C7.318 21 8 20.439 8 19.75s-.682-1.25-1.521-1.25a.5.5 0 0 1 0-1C7.318 17.5 8 16.939 8 16.25S7.318 15 6.479 15c-.726 0-1.354.426-1.493 1.014a.499.499 0 1 1-.972-.228C4.256 14.751 5.293 14 6.479 14 7.869 14 9 15.009 9 16.25c0 .705-.372 1.327-.942 1.74l.002.01-.002.01c.57.413.942 1.035.942 1.74zM24 28h11V16H24v12zM1 28h10.875V8H1v20zm11.875 0H23V8H12.875v20zM35 15H24V8h6.848c-.245.393-.764.667-1.369.667a.5.5 0 1 0 0 1c.839 0 1.521.523 1.521 1.166 0 .644-.682 1.167-1.521 1.167-.729 0-1.357-.401-1.494-.953a.493.493 0 0 0-.605-.366.499.499 0 0 0-.365.605c.245.993 1.281 1.714 2.464 1.714 1.39 0 2.521-.972 2.521-2.167 0-.673-.367-1.269-.93-1.666.418-.296.726-.7.856-1.167H35v7zM0 29h36V7H0v22z'
    />
  </svg>
);

export default DeviceIcon;
