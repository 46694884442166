import { Dispatch } from 'react';
import { ConsumptionTableTypes } from '../../components/ConsumptionTable/ConsumptionTableTypes';
import { getContractConsumptions } from '../../../api-factory/contractApi';

export enum ContractConsumptionsActionType {
  LOAD_ALL_CONTRACT_CONSUMPTIONS = 'api/reducer/LOAD_ALL_CONTRACT_CONSUMPTIONS',
  LOADING = 'api/reducer/LOADING',
}

export type ContractConsumptionsActionTypes = {
  type: ContractConsumptionsActionType;
  content: ConsumptionTableTypes[];
};

const loadAllContractConsumptions = (dispatch: Dispatch<Partial<ContractConsumptionsActionTypes>>, contractId: string) => {
  dispatch({
    type: ContractConsumptionsActionType.LOADING,
  });
  getContractConsumptions(contractId).then((response) => {
    dispatch({
      type: ContractConsumptionsActionType.LOAD_ALL_CONTRACT_CONSUMPTIONS,
      content: response.data.content,
    });
  });
};

export { loadAllContractConsumptions };
