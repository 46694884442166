var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import * as React from 'react';
var BASE_CLASS = 'button-v2';
export var Button = function (_a) {
    var _b = _a.children, children = _b === void 0 ? 'Button' : _b, disabled = _a.disabled, id = _a.id, qaId = _a.qaId, name = _a.name, _c = _a.version, version = _c === void 0 ? 'v1' : _c, iconLeft = _a.iconLeft, iconRight = _a.iconRight, className = _a.className, _d = _a.type, type = _d === void 0 ? 'button' : _d, handleClick = _a.onClick, _e = _a.autoFocus, autoFocus = _e === void 0 ? false : _e;
    var classes = classNames("".concat(BASE_CLASS), "".concat(BASE_CLASS, "--").concat(version), disabled && "".concat(BASE_CLASS, "--disabled"), iconLeft && "".concat(BASE_CLASS, "--icon-left"), iconRight && "".concat(BASE_CLASS, "--icon-right"), className);
    var optionalProps = autoFocus ? { autoFocus: autoFocus } : {};
    return (React.createElement("button", __assign({ disabled: disabled, id: id, "data-qa": qaId, name: name, className: classes, type: type, onClick: handleClick }, optionalProps),
        !!iconLeft && React.createElement("i", null, iconLeft),
        React.createElement("span", null, children),
        !!iconRight && React.createElement("i", null, iconRight)));
};
Button.displayName = 'ButtonComponent';
export default Button;
