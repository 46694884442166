import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { DatePicker, InputState } from '@eon-funke/react-shared-ui-next';
import { FormikProps } from 'formik';
import { DeviceDataTypes, MeterReadingDateInputLabels } from '../../MeterReadingTypes';
import { calculateReadingPeriod, prepareDateForDisplay } from './meterReadingDateInputHelper';
import { DATE_FORMAT } from '../../../../../utils/GermanDateHelpers';

export type MeterReadDateInputTypes = {
  formikProps: FormikProps<DeviceDataTypes>;
  loggedIn: boolean;
  name: string;
};

export type MeterReadDateInputProps = MeterReadDateInputTypes & MeterReadingDateInputLabels;

const MeterReadDateInput = React.memo((props: MeterReadDateInputProps) => {
  const { newDateLabel, datePlaceHolder, lastReadingDateLabel, loggedIn, name, formikProps } = props;
  const { values, setFieldValue, getFieldMeta, errors } = formikProps;

  if (!values?.readingPeriod) {
    return null;
  }

  const [readingDatePicker, setReadingDatePicker] = useState(false);

  const readingPeriod = useMemo(() => calculateReadingPeriod(values.readingPeriod), [values.readingPeriod]);

  const onChange = (value) => {
    const newDateObj = {
      ...value,
      start: moment(value.text, DATE_FORMAT).toDate(),
    };
    const fieldName = 'readingDate';

    if (!value.text) {
      setFieldValue(fieldName, ' ');
    } else {
      setFieldValue(fieldName, newDateObj);
    }

    setReadingDatePicker(false);
  };

  const isDatePickerDisabled = useMemo(() => {
    if (readingPeriod.startDate.toDateString() === readingPeriod.endDate.toDateString()) {
      return true;
    }
    return false;
  }, [readingPeriod]);

  return (
    <div className='form-group'>
      <div className='ano-meter-form__input-group'>
        <h5 className='ano-meter-form__header ano-meter-form__header'>{newDateLabel}</h5>
        <DatePicker
          id={name}
          locale='de'
          daysLongLocalized={moment.weekdays()}
          daysShortLocalized={moment.weekdaysMin()}
          monthsLocalized={moment.months()}
          initialShownMonth={readingPeriod.endDate}
          name={name}
          value={values.readingDate}
          qaId='qaId'
          label={datePlaceHolder}
          showOverlay={!isDatePickerDisabled && readingDatePicker}
          onFocus={() => setReadingDatePicker(true)}
          onClick={() => setReadingDatePicker(true)}
          onBlur={() => setReadingDatePicker(false)}
          onChange={onChange}
          inputState={getFieldMeta('readingDate').error ? InputState.ERROR : InputState.VALID}
          disabled={isDatePickerDisabled}
          errorMessage={errors.readingDate}
          disabledDays={{
            before: readingPeriod.startDate,
            after: readingPeriod.endDate,
          }}
          minDate={readingPeriod.startDate}
          maxDate={readingPeriod.endDate}
        />

        {loggedIn ? (
          <strong className={loggedIn ? 'loggedin-meter-form__last-values' : 'ano-meter-form__last-values'}>
            {`
            ${lastReadingDateLabel}
            ${prepareDateForDisplay(values.registers)}
            `}
          </strong>
        ) : null}
      </div>
    </div>
  );
});

export default MeterReadDateInput;
