import React, { useEffect } from 'react';
var MTCaptcha = function (_a) {
    var setFieldValue = _a.setFieldValue, siteKey = _a.siteKey, _b = _a.action, action = _b === void 0 ? '' : _b;
    window.verified = function (e) {
        setFieldValue('captcha', e.verifiedToken);
    };
    window.verifyExpired = function () {
        setFieldValue('captcha', '');
    };
    useEffect(function () {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "var mtcaptchaConfig = {\n        \"sitekey\": \"".concat(siteKey, "\",\n        \"verified-callback\": verified,\n        \"verifyexpired-callback\": verifyExpired,\n        \"action\": \"").concat(action, "\",\n        \"lang\": \"de\",\n        \"customLangText\": {\n          \"de\": {\n                \"inputPrompt\": \"Geben Sie den Text ein.\"\n               }\n         }\n    };");
        document.body.appendChild(s);
        var mt_service = document.createElement('script');
        mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
        mt_service.async = true;
        document.body.appendChild(mt_service);
        var mt_service2 = document.createElement('script');
        mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';
        mt_service2.async = true;
        document.body.appendChild(mt_service2);
    }, []);
    return React.createElement("div", { className: "mtcaptcha" });
};
export default MTCaptcha;
