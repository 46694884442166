import { getContracts } from '../../api-factory/contractApi';
import Router from '../../utils/Router';
import SessionHandler from '../utils/SessionHandler';

// Actions
const LOAD_CURRENT_CONTRACTS = 'api/reducer/GET_CONTRACTS';
export const SET_SELECTED_CONTRACT = 'api/reducer/SET_SELECTED_CONTRACT';
const SET_CONTRACT_WITHOUT_HASH = 'api/reducer/SET_CONTRACT_WITHOUT_HASH';

// Reducer
export default function reducer(state = {}, action) {
  const getPreselectedContract = (contracts) => {
    const id = Router.getParameter('contractAccId');
    if (id) {
      SessionHandler.setPermissions(action.data.innerData, id);
      return id;
    }
    if (contracts[0]) {
      SessionHandler.setPermissions(action.data.innerData, contracts[0].contractAccountId);
      return contracts[0].contractAccountId;
    }
    return null;
  };
  switch (action.type) {
    case LOAD_CURRENT_CONTRACTS:
      return {
        ...state,
        data: action.data.innerData,
        contractId: getPreselectedContract(action.data.innerData),
      };
    case SET_SELECTED_CONTRACT:
      SessionHandler.setPermissions(state.data, action.data.contractId);
      Router.setParameter({ contractAccId: action.data.contractId });
      return {
        ...state,
        contractId: action.data.contractId,
      };
    case SET_CONTRACT_WITHOUT_HASH:
      SessionHandler.setPermissions(state.data, action.data.contractId);
      return {
        ...state,
        contractId: action.data.contractId,
      };
    default:
      return state;
  }
}

// Action Creators
// TODO: Add error handling
export function loadCurrent(dispatch) {
  const resp = getContracts();
  return resp
    .then((reponse) => {
      dispatch({
        type: LOAD_CURRENT_CONTRACTS,
        data: {
          innerData: reponse.data.content,
        },
      });
    })
    .catch(() => {});
}

export function setSelectedContract(dispatch, contractId) {
  SessionHandler.resetPermissions();
  dispatch({
    type: SET_SELECTED_CONTRACT,
    data: {
      contractId,
    },
  });
}
export function setSelectedContractWithoutHash(dispatch, contractId) {
  SessionHandler.resetPermissions();
  dispatch({
    type: SET_CONTRACT_WITHOUT_HASH,
    data: {
      contractId,
    },
  });
}
