import { getContactGroups, saveContactDetails } from '../api/contactLoggedInApi';

const INIT_STATE = {
  response: {},
  groups: {},
  subjects: [],
  isRequestPending: false,
};

// Actions
const GET_CONTACT_GROUPS = 'api/reducer/GET_CONTACT_GROUPS';
const SAVE_CONTACT_DETAILS = 'api/reducer/SAVE_CONTACT_DETAILS';
const RESET_CONTACT_STATE = 'api/reducer/DELETE_CONTACT_GROUPS';

const createSubjectsFromContactGroups = (data, reasonData) => {
  const reasonDetails = [];
  Object.entries(data)
    .filter((group, index) => group[index] === 'groups')
    .map((groupItem) => {
      Object.keys(groupItem[1]).map((subjectItem) => {
        const subjectData = reasonData[subjectItem];
        reasonDetails.push({ content: subjectData, value: subjectItem });
        return reasonDetails;
      });
      return reasonDetails;
    });
  const sortedData = reasonDetails.sort((a, b) => a.value - b.value);
  return sortedData;
};

// Reducer
export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_CONTACT_GROUPS:
      return {
        ...state,
        response: action.data,
        groups: action.data.groups,
        subjects: createSubjectsFromContactGroups(action.data, action.payload),
      };
    case SAVE_CONTACT_DETAILS:
      return state;
    case RESET_CONTACT_STATE:
      return INIT_STATE;
    default:
      return state;
  }
}

// Action Creators
export const loadContactGroups = (dispatch, contractId, reasonData) => {
  const resp = getContactGroups(contractId);
  return resp.then((response) => {
    dispatch({
      type: GET_CONTACT_GROUPS,
      data: response.data,
      payload: reasonData,
    });
  });
};

export const saveContactForm = (dispatch, payload) => {
  const resp = saveContactDetails(payload);
  return resp.then((response) => {
    dispatch({
      type: SAVE_CONTACT_DETAILS,
      data: response,
    });
  });
};

export const cleanContactGroups = (dispatch) => {
  dispatch({
    type: RESET_CONTACT_STATE,
  });
};
