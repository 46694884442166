import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';
import SessionHandler, { loginTypes } from '../../utils/SessionHandler';
import { MeterReadingFormValueTypes } from '../components/MeterRead/MeterReadingTypes';

const API_CONSTANT = '/api/meterreading/v0';

const isLoggedIn = () => SessionHandler.getLoginType() === loginTypes.loggedIn;

export function getMeterReadingData() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/meters?filterBy=openReadingOrders&withInfoMessage=true`,
    {},
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}

export function saveMeterReadingData(meterData: MeterReadingFormValueTypes) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/readingresults`,
    meterData,
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}

export function saveAllMeterReadings(meterData) {
  const response = RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/readings`,
    meterData,
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
  return response;
}

export function sendEmailData(emailData) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/readingresultconfirmation`,
    emailData,
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}

export function activateOko(okoData) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/communication/v0/anonymous/subscription`,
    okoData,
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}

export function getAllMeters() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/meters`,
    {},
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}

export function getMeterReadDate(contractAccountId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/futurereadings/${contractAccountId}`,
    {},
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  ); // needs to add root source
}

export function getAllMeterPins() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}${API_CONSTANT}/meterpins`,
    {},
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}

export function getMeterPinDetails(serialNumber) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}${API_CONSTANT}/meterpins/${serialNumber}`,
    {},
    RestHelper.setXClientHeader({}, isLoggedIn() ? CONFIG.CHANNEL_CONFIG.portal : CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}
