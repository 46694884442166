import CSC_CONFIG from './csc/config/config';
import CIC_CONFIG from './cic/config/config';
import { getIdDataset, getInputValueIfExists } from './utils/DomHelper';

export const isCICPage =
  (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('csc') === -1) || getIdDataset('cscn-config', 'isCicPage') === 'true';
export const isCICContentTree =
  (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('csc') === -1) || getIdDataset('cscn-config', 'isCicContentTree') === 'true';
window.isCICPage = isCICPage;
window.isCicContentTree = isCICContentTree;
const CONFIG = isCICContentTree ? CIC_CONFIG : CSC_CONFIG;
export const isAccount = !!getInputValueIfExists('is-account-config');
export default CONFIG;
export const getConfig = () => {
  return CONFIG;
};
