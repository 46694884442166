import React from 'react';

export type MeterCounterTypes = {
  index: number;
  amount: number;
  hasError: boolean;
  meterCounterLabel: string;
};

type MeterCounterProps = MeterCounterTypes;

const MeterCounter = (props: MeterCounterProps) => {
  const classes = props.hasError ? 'text-center ano-meter-counter__text__error' : 'text-center ano-meter-counter__text';
  const pathDescription =
    'M18 2C9.201 2 2 9.201 2 18s7.201 16 16 16 ' +
    '16-7.201 16-16S26.799 2 18 2zm0 30c-7.7 0-14-6.3-14-14S10.3 4 18 ' +
    '4s14 6.3 14 14-6.3 14-14 14z';
  return (
    <div className='ano-meter-counter row'>
      {props.hasError && (
        <div className='svg-bckgrd'>
          <svg
            className='ano-meter-error-container__error'
            xmlns='http://www.w3.org/2000/svg'
            width='36'
            height='36'
            viewBox='0 0 36 36'
          >
            <g
              fill='#F3463F'
              fillRule='evenodd'
            >
              <path d={pathDescription} />
              <path d='M17 24h2v2h-2zM18.6 22h-1.2l-.4-6.857V10h2v5.143L18.6 22' />
            </g>
          </svg>
        </div>
      )}
      <h4 className={classes}>{`${props.meterCounterLabel} ${props.index} von ${props.amount}`}</h4>
    </div>
  );
};

export default MeterCounter;
