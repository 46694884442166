import { AxiosResponse } from 'axios';
import moment from 'moment';
import { DATE_FORMAT_NATIVE } from '../../utils/GermanDateHelpers';

import { AppointmentOrder, ContractAccountAssOrderId, MyAppointmentWish } from '../../cic/types/myAppointmentTypes';
import RestHelper, { MWDomains } from '../../utils/RestHelper';

type RestHateoas<T> = {
  data: T;
};

type RestArrayHateoas<T> = {
  data: {
    content: T;
  };
};

export type AppointmentGap = {
  gapId: string;
  isGreen: boolean;
  appointment: {
    from: string;
    to: string;
  };
  transientAppointmentDate?: string;
};

export type AppointmentGapList = {
  assOrderId: string;
  gaps: AppointmentGap[];
};

/*
GET     contractaccounts/{contractAccountId}/appointments/{assOrderId}
Response:
{
  "isDatePlannable": false,
  "isDateCancellable": false,
  "isDateRequestable": true,
  "appointment": {
    "from": "2022-01-11T06:30:00.000Z",
    "to": "2022-01-11T10:15:00.000Z"
  },
  "meterInstallationServiceProvider": {
    "name": "SupInfo AN",
    "phone": "0815"
  },
  "blocked": [
    {
      "from": "2021-11-15T00:00:00.000Z",
      "to": "2022-01-04T00:00:00.000Z"
    }
  ]
}
*/
export const getNextAppointment = (contractAccountId: string, appointmentOrderId?: string): Promise<RestHateoas<AppointmentOrder>> => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments/${appointmentOrderId}`
  );
};

/*
GET     /appointments
Response:
{
    content:[
        {
            "contractAccountId": "21655756747",
            "assOrderId":[ "000815","121333" ]
        },
        {
            "contractAccountId": "216557534343",
            "assOrderId":[ "000815","44444" ]
        }
    ]
}
*/
export const findAllAppointmentIds = (): Promise<RestArrayHateoas<ContractAccountAssOrderId[]>> => {
  return RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/appointments`);
};

/*
GET     contractaccounts/{contractAccountId}/appointments
Response:
{
  "contractAccountId": "21655756747",
  "assOrderIds": [
    "000815",
    "121333"
  ]
}
*/
export const getAppointmentId = (contractAccountId: string): Promise<RestHateoas<ContractAccountAssOrderId>> => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments`
  );
};

export const deleteAppointment = (contractAccountId: string, assOrderId: string): Promise<AxiosResponse> => {
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments/${assOrderId}`
  );
};

/*
Terminvorschläge für ein Vertragskonto, Auftragsnummer und Zeitraum
request:

GET /api/partner/v0/contractaccounts/{contractAccountId}/appointments/{assOrderId}/gaps?from=2022-01-11&to=2022-01-13
response:

{
    "assOrderId": "000815",
    "gaps":[
        {
            "gapId":"17",
            "isGreen":false,
            "appointment" : {
                "from":"2022-01-11T06:15:00.000Z",
                "to":"2022-01-11T09:15:00.000Z"
            }
        },
        {
            "gapId":"18",
            "isGreen":true,
            "appointment" : {
                "from":"2022-01-11T08:15:00.000Z",
                "to":"2022-01-11T011:15:00.000Z"
            }
        },
        {
            "gapId":"20",
            "isGreen":false,
            "appointment" : {
                "from":"2022-01-11T012:15:00.000Z",
                "to":"2022-01-11T15:15:00.000Z"
            }
        }
    ]
}
*/
export const getAppointmentGapList = (
  contractAccountId: string,
  assOrderId: string,
  from: Date,
  to: Date
): Promise<RestHateoas<AppointmentGapList>> => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments/${assOrderId}/gaps?from=${moment(from).format(DATE_FORMAT_NATIVE)}&to=${moment(to).format(DATE_FORMAT_NATIVE)}`
  );
};

/*
Terminvorschlag für ein Vertragskonto und Auftragsnummer setzen
request:

PUT /api/partner/v0/contractaccounts/{contractAccountId}/appointments/{assOrderId}?gap={gapId}
response: 201
*/
export function saveAppointment(contractAccountId: string, assOrderId: string, gapId: string) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments/${assOrderId}?gapId=${gapId}`,
    {},
    {}
  );
}

/*
Terminstatus für ein Vertragskonto, Auftragsnummer und Terminstatusabrufen
request:

GET /api/partner/v0/contractaccounts/{contractAccountId}/appointments/{assOrderId}/gaps/{gapId}
response:

{
	"assOrderId": "000815",
	"gapId":"17",
	"available":true
}
*/
export const loadAppointmentGaps = (contractAccountId: string, assOrderId: string, gapId: string): Promise<RestHateoas<AppointmentGapList>> => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments/${assOrderId}/gaps/${gapId}`
  );
};

/*
Wunschtermin festlegen für ein Vertragskonto und Auftragsnummer
request:

POST /api/partner/v0/contractaccounts/{contractAccountId}/appointments/{assOrderId}
request body:

{
    "earliestDate": "2020-01-01",
    "proposals": [
        {
            "weekday": "MONDAY|TUESDAY|WEDNESDAY|THURSDAY|FRIDAY",
            "from": "hh:mm:ss",
            "to": "hh:mm:ss"
        }
    ]
}
response: 201
*/
export const saveAppointmentWish = (contractAccountId: string, assOrderId: string, paylod: MyAppointmentWish): Promise<AxiosResponse> => {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/appointments/${assOrderId}`,
    paylod
  );
};
