var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useState } from 'react';
import ExpandableView from './ExpandableView';
export var Expandable = function (_a) {
    var id = _a.id, qaId = _a.qaId, title = _a.title, _b = _a.isOpened, isOpened = _b === void 0 ? false : _b, props = __rest(_a, ["id", "qaId", "title", "isOpened"]);
    var _c = useState(false), isExpanded = _c[0], setExpanded = _c[1];
    useEffect(function () {
        setExpanded(isOpened);
    }, []);
    var toggleExpanded = useCallback(function () {
        setExpanded(function (prev) { return !prev; });
    }, []);
    return (React.createElement(ExpandableView, __assign({ id: id, qaId: qaId, title: title, isExpanded: isExpanded, toggleContent: toggleExpanded }, props)));
};
Expandable.displayName = 'Expandable';
export default Expandable;
