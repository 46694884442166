import classNames from 'classnames';
import React from 'react';
var BASE_CLASS = 'alert';
export var Alert = function (_a) {
    var children = _a.children, _b = _a.type, type = _b === void 0 ? 'info' : _b, className = _a.className, iconRight = _a.iconRight, iconLeft = _a.iconLeft, id = _a.id, qaId = _a.qaId;
    var classes = classNames("".concat(BASE_CLASS), "".concat(BASE_CLASS, "--").concat(type), className);
    return (React.createElement("div", { className: classes, id: id, "data-qa": qaId },
        !!iconLeft && (React.createElement("span", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon--left") }, iconLeft)),
        typeof children === 'string' ? (React.createElement("p", { className: "".concat(BASE_CLASS, "__content") }, children)) : (children),
        !!iconRight && (React.createElement("span", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon--right") }, iconRight))));
};
Alert.displayName = 'AlertComponent';
export default Alert;
