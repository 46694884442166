import RestHelper, { MWDomains } from '../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';

export const validatePhoneNumber = (phoneNumber) => {
  const pattern = new RegExp('^(\\+[1-9]|[0-9])[\\d\\s\\-]+$');

  if (!pattern.test(phoneNumber)) {
    const data = {
      countryCode: null,
      formattedNumber: null,
      nationalNumber: null,
      number: phoneNumber,
      region: null,
      type: null,
      valid: false,
    };

    return Promise.resolve({ data });
  }

  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/util/phones`,
    { number: phoneNumber },
    {},
    true,
    false
  );
};
export const refreshToken = () => RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/token`);

export function getPhonenIdFromPhoneObject(phoneNumberObj) {
  return phoneNumberObj?._links?.self?.href?.split('/').pop();
}

export function getMyData(contractId) {
  return RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}`);
}

export function getPersonalData(contractId) {
  return RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}`);
}

export function getBankDetails(iban) {
  return RestHelper.post(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/util/iban`, iban);
}

export function addBankingData(bankDetailsObj, contractId) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/bankaccounts`,
    bankDetailsObj
  );
}

export function saveMyData(emailData, contractId) {
  return RestHelper.put(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}`, emailData);
}
export function getBusinessPartner() {
  return RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/businesspartners`);
}

export function addPhoneNumber(phoneNumberObj, contractId) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/phonenumbers`,
    phoneNumberObj
  );
}

export function editPhoneNumbers(phoneNumberObj, contractId) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/phonenumbers/${phoneNumberObj.order}`,
    phoneNumberObj
  );
}

export function getPhoneNumbers(contractId) {
  return RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/phonenumbers`)
    .then((response) => {
      return {
        data: {
          content: response.data.content.map((item) => {
            const splitLink = item._links.self.href.split('/');
            return {
              phone: item.phone,
              order: splitLink[splitLink.length - 1],
              default: item.default,
            };
          }),
        },
      };
    })
    .catch((error) => {
      throw error;
    });
}

export function deletePhoneNumber(phoneNumberObject, contractId) {
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/phonenumbers/${phoneNumberObject.order}`
  );
}
export function getTaxVatNumbers(contractId) {
  return RestHelper.get(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/taxnumbers`);
}

export const saveTaxVatNumber = (contractId, data) =>
  RestHelper.patch(`${RestHelper.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/partner/v0/mydata/${contractId}/taxnumbers`, data)
    .then((response) => {
      CacheHandler.saveCache({ taxVatData: { ...response } });
      return response;
    })
    .catch((error) => {
      throw error.response;
    });

export default {
  saveMyData,
  addPhoneNumber,
  getPhoneNumbers,
  editPhoneNumbers,
  deletePhoneNumber,
  getTaxVatNumbers,
  saveTaxVatNumber,
  getBusinessPartner,
  getPhonenIdFromPhoneObject,
  getMyData,
  getPersonalData,
  refreshToken,
  validatePhoneNumber,
  getBankDetails,
  addBankingData,
};
