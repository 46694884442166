import RestHelper, { MWDomains } from '../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';
import CONFIG from '../../mainConfig';
import EnvironmentHelper from '../../utils/EnvironmentHelper';

export const getInstanceAttr = () => {
  let stage = {};
  if (EnvironmentHelper.getAemInstanceKey() === 'PQA') {
    stage = { aemInstanceKey: 'PQA' };
  } else if (EnvironmentHelper.getAemInstanceKey() !== 'PROD') {
    stage = { aemInstanceKey: null };
  }
  return stage;
};

const addBusinessPartner = (partnerData) => {
  return RestHelper.post(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/useraccount/v0/businesspartners`, partnerData, {}, true, true);
};

const registerUser = (dataObj, config) =>
  RestHelper.post(`${RestHelper.getBaseUrl(MWDomains.cscAccount)}/public-api/v2/accounts`, dataObj, config, true, false);

const deleteApp = (payload) => {
  const promise = RestHelper.post(`${RestHelper.getBaseUrl(MWDomains.cscAccount)}/api/v1/accounts/delete`, {
    data: {
      id: CacheHandler.getCurrentCacheByToken('accountData').id,
      type: 'AccountDeactivationResource',
      attributes: {
        dso: CONFIG.AEM_CONFIG.siteVariable,
        ...payload,
      },
    },
  });
  promise.then(() => {
    if (payload.applicationKeys) {
      const oldCache = CacheHandler.getCurrentCacheByToken('accountData');
      const apps = oldCache.apps.filter((app) => !payload.applicationKeys.find((deletedApp) => deletedApp === app));
      const current = { ...oldCache, ...{ apps } };
      CacheHandler.saveCache({ accountData: current });
    }
  });

  return promise;
};
// v2 is not supported for pw reset. Hence v1 is used.
const resetPasswordInitiate = (emailDataObj, config) =>
  RestHelper.post(`${RestHelper.getBaseUrl(MWDomains.cscAccount)}/public-api/v1/accounts/resetpassword`, emailDataObj, config, true, false);

export const deactivate2FAAuthentication = async () => {
  const egcid = CacheHandler.getCurrentCacheByToken('accountData')?.id;
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cscAccount)}/api/common/v1/accounts/deletemfa/${egcid}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
};

const saveAccountData = (dataObj) =>
  RestHelper.patch(`${RestHelper.getBaseUrl(MWDomains.cscAccount)}/api/v1/accounts`, {
    data: {
      id: CacheHandler.getCurrentCacheByToken('accountData').id,
      type: 'AccountResource',
      attributes: {
        dso: CONFIG.AEM_CONFIG.siteVariable,
        ...dataObj.attributes,
        applicationKey: 'CSCN',
        ...getInstanceAttr(),
      },
    },
  })
    .then(() => {
      const oldData = CacheHandler.getCurrentCacheByToken('accountData');
      let emailData = {};
      if (dataObj.attributes.email) {
        if (oldData.oldEmail === dataObj.attributes.email) {
          emailData = {
            emailConfirmed: true,
            oldEmail: null,
          };
        } else if (!oldData.emailConfirmed) {
          emailData = {
            emailConfirmed: false,
            email: dataObj.attributes.email,
          };
        } else {
          emailData = {
            emailConfirmed: false,
            oldEmail: oldData.email,
          };
        }
      }
      const current = { ...oldData, ...dataObj.attributes, ...emailData };
      CacheHandler.saveCache({ accountData: current });
      return {
        data: {
          ...current,
        },
      };
    })
    .catch((error) => {
      throw error.response;
    });

export default { deactivate2FAAuthentication, addBusinessPartner, registerUser, deleteApp, resetPasswordInitiate, saveAccountData };
