var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import { InputState } from '../../constants';
import { Icon } from '../Icon/Icon';
var KEY_CODES = {
    ARROW_DOWN: 40,
    ARROW_UP: 38,
    ENTER: 13,
    SPACE: 32,
};
var BASE_CLASS = 'dropdown-component';
var DropdownComponent = (function (_super) {
    __extends(DropdownComponent, _super);
    function DropdownComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.selectToggleIcon = (React.createElement("div", { className: "".concat(BASE_CLASS, "__select-icon") },
            React.createElement(Icon, { id: "".concat(_this.props.id, "__toggle-icon"), qaId: "".concat(_this.props.id, "__toggle-icon"), glyph: "icon_arrow-down_54x54", size: 12 })));
        _this.selectValidIcon = (React.createElement("div", { className: "".concat(BASE_CLASS, "__select-valid-icon") },
            React.createElement(Icon, { id: "".concat(_this.props.id, "__valid-icon"), qaId: "".concat(_this.props.id, "__valid-icon"), glyph: "icon_check_54x54", size: 19 })));
        _this.selectErrorIcon = (React.createElement("div", { className: "".concat(BASE_CLASS, "__select-error-icon") },
            React.createElement(Icon, { id: "".concat(_this.props.id, "__error-icon"), qaId: "".concat(_this.props.id, "__error-icon"), glyph: "icon_cross_54x54", size: 14 })));
        _this.renderDropdown = function () {
            var _a = _this.props, _b = _a.inputState, inputState = _b === void 0 ? InputState.NONE : _b, disabled = _a.disabled, className = _a.className, id = _a.id, qaId = _a.qaId, isMobile = _a.isMobile;
            var showError = inputState === InputState.ERROR;
            var dropdownClasses = classNames(BASE_CLASS, showError && "".concat(BASE_CLASS, "--error"), disabled && "".concat(BASE_CLASS, "--disabled"), className || '');
            return (React.createElement("div", { ref: function (elem) {
                    _this.dropdownRef = elem;
                }, id: id, "data-qa": qaId, className: dropdownClasses },
                _this.renderSelect(false),
                !isMobile && _this.renderDialog()));
        };
        _this.renderSelect = function (isDialogSelect) {
            var _a = _this.props, _b = _a.inputState, inputState = _b === void 0 ? InputState.NONE : _b, value = _a.value, required = _a.required, isOpen = _a.isOpen, disabled = _a.disabled, tabIndex = _a.tabIndex, isMobile = _a.isMobile, onClick = _a.onClick, label = _a.label, errorMessage = _a.errorMessage;
            var showError = inputState === InputState.ERROR;
            var showValid = inputState === InputState.VALID && !!value;
            var isOpeningRightNow = isOpen && !_this.state.isCurrentlyOpen;
            var isClosingRightNow = !isOpen && _this.state.isCurrentlyOpen;
            var validationIcon = (showError && _this.selectErrorIcon) || (showValid && _this.selectValidIcon);
            var content = _this.getSelectContent();
            var selectLabelClasses = classNames("".concat(BASE_CLASS, "__select-label"), (!!content || showError) && "".concat(BASE_CLASS, "__select-label--up"), !!required && "".concat(BASE_CLASS, "__select-label--required"));
            var selectFocusBarClasses = classNames("".concat(BASE_CLASS, "__select-focus-bar"), _this.state.hasFocus &&
                (isClosingRightNow || isOpeningRightNow) &&
                "".concat(BASE_CLASS, "__select-focus-bar--activated"));
            return (React.createElement("div", null,
                React.createElement("div", { className: "".concat(BASE_CLASS, "__select"), tabIndex: !disabled ? tabIndex || 0 : undefined, onFocus: !disabled && !_this.state.hasFocus ? _this.onFocusHandler : undefined, onBlur: !disabled && _this.state.hasFocus ? _this.onBlurHandler : undefined, onClick: !disabled && !isMobile && onClick ? onClick : undefined, onKeyDown: _this.onKeyDownHandler(isDialogSelect), ref: function (elem) {
                        return isDialogSelect ? (_this.dialogSelectRef = elem) : (_this.closedSelectRef = elem);
                    } },
                    React.createElement("div", { className: "".concat(BASE_CLASS, "__select-content") },
                        validationIcon,
                        content),
                    React.createElement("div", { className: selectLabelClasses }, label),
                    isMobile && _this.renderMobileSelect(),
                    _this.selectToggleIcon),
                React.createElement("span", { className: selectFocusBarClasses }),
                showError && React.createElement("div", { className: "".concat(BASE_CLASS, "--error-message") }, errorMessage)));
        };
        _this.getSelectContent = function () {
            var _a = _this.props, value = _a.value, options = _a.options, placeholder = _a.placeholder;
            var dropdownOption = value && options.filter(function (option) { return option.value === value; });
            var content;
            if (dropdownOption && dropdownOption[0] && dropdownOption[0].content) {
                content = dropdownOption[0].content;
            }
            else if (placeholder && !value) {
                content = placeholder;
            }
            return content;
        };
        _this.onFocusHandler = function (event) {
            if (_this.props.onFocus) {
                _this.props.onFocus(event);
            }
            _this.setState({
                hasFocus: true,
            });
        };
        _this.onKeyDownHandler = function (isDialogSelect) { return function (event) {
            if (_this.props.onKeyDown) {
                _this.props.onKeyDown(event);
            }
            if (isDialogSelect) {
                _this.handleArrowKeys(event);
            }
        }; };
        _this.onBlurHandler = function (event) {
            var newFocusedElem = event.relatedTarget || document.activeElement;
            if (_this.dropdownRef && !_this.dropdownRef.contains(newFocusedElem)) {
                if (_this.props.onBlur) {
                    _this.props.onBlur(event);
                }
                _this.setState({
                    hasFocus: false,
                });
            }
        };
        _this.handleChange = function (e) { return !!_this.props.onChange && _this.props.onChange(e.target.value); };
        _this.handleArrowKeys = function (event) {
            var keyCode = event.which || event.keyCode || event.charCode;
            if (keyCode === KEY_CODES.ARROW_UP) {
                event.preventDefault();
                if (_this.state.focusedOptionElem >= 0) {
                    _this.setState({
                        focusedOptionElem: _this.state.focusedOptionElem - 1,
                    });
                }
            }
            if (keyCode === KEY_CODES.ARROW_DOWN) {
                event.preventDefault();
                var selectableOptions = _this.props.options.filter(function (element) { return !element.disabled; }).length;
                if (_this.state.focusedOptionElem < selectableOptions - 1) {
                    _this.setState({
                        focusedOptionElem: _this.state.focusedOptionElem + 1,
                    });
                }
            }
            if ((keyCode === KEY_CODES.ENTER || keyCode === KEY_CODES.SPACE) &&
                _this.state.focusedOptionElem >= 0) {
                event.preventDefault();
                var focusedOptionElem_1 = _this.state.focusedOptionElem;
                if (_this.dialogOptionRefs[focusedOptionElem_1]) {
                    _this.setState({
                        focusedOptionElem: -1,
                    }, function () {
                        _this.dialogOptionRefs[focusedOptionElem_1].click();
                    });
                }
            }
        };
        _this.renderDialog = function () {
            var _a = _this.props, disabled = _a.disabled, isOpen = _a.isOpen, options = _a.options;
            var isClosingRightNow = !isOpen && !_this.state.isCurrentlyOpen;
            var dialogClasses = classNames("".concat(BASE_CLASS, "__dialog"), !disabled && isOpen && "".concat(BASE_CLASS, "__dialog--open"), (disabled || isClosingRightNow) && "".concat(BASE_CLASS, "__dialog--close"));
            var onTransitionEndHandler = function (event) {
                if (!isOpen && event.propertyName === 'opacity') {
                    _this.setState({
                        focusedOptionElem: -1,
                        isCurrentlyOpen: false,
                    });
                }
            };
            _this.dialogOptionRefs = [];
            return (React.createElement("div", { className: dialogClasses, onTransitionEnd: onTransitionEndHandler },
                _this.renderSelect(true),
                React.createElement("div", { className: "".concat(BASE_CLASS, "__option-container") }, options.map(_this.renderOptions))));
        };
        _this.renderOptions = function (option) {
            var _a = _this.props, value = _a.value, tabIndex = _a.tabIndex, options = _a.options, onChange = _a.onChange;
            var dropdownOptionClasses = classNames("".concat(BASE_CLASS, "__option"), !!option.disabled && "".concat(BASE_CLASS, "__option--disabled"), option.value === value && "".concat(BASE_CLASS, "__option--active"));
            var getTabIndex = function () {
                if (tabIndex) {
                    return tabIndex + options.indexOf(option) + 1;
                }
                return options.indexOf(option) + 1;
            };
            return (React.createElement("div", { ref: function (element) {
                    if (element && !option.disabled) {
                        _this.dialogOptionRefs.push(element);
                    }
                }, key: option.value, className: dropdownOptionClasses, tabIndex: getTabIndex(), onBlur: _this.onBlurHandler, onKeyDown: _this.handleArrowKeys, onClick: !option.disabled && onChange ? function () { return onChange(option.value); } : undefined }, option.content));
        };
        _this.renderMobileSelect = function () {
            var _a = _this.props, className = _a.className, disabled = _a.disabled, id = _a.id, multiple = _a.multiple, required = _a.required, size = _a.size, value = _a.value, options = _a.options, onClick = _a.onClick, isMobile = _a.isMobile;
            var selectProps = {
                className: className,
                disabled: disabled,
                id: id,
                multiple: multiple,
                required: required,
                size: size,
                value: value,
            };
            return (React.createElement("select", __assign({}, selectProps, { onChange: _this.handleChange, className: "".concat(BASE_CLASS, "__mobile-dialog"), onClick: !!onClick && onClick, onBlur: _this.onBlurHandler }),
                React.createElement("option", { disabled: true, value: "" }),
                isMobile && options.map(_this.renderMobileOptions)));
        };
        _this.renderMobileOptions = function (option) {
            var content = option.content, optionProps = __rest(option, ["content"]);
            return (React.createElement("option", __assign({ key: option.value }, optionProps, { onBlur: _this.onBlurHandler }), content));
        };
        _this.state = {
            focusedOptionElem: -1,
            hasFocus: false,
            isCurrentlyOpen: props.isOpen || false,
        };
        return _this;
    }
    DropdownComponent.prototype.componentDidUpdate = function () {
        if (this.props.isOpen && !this.state.isCurrentlyOpen) {
            this.setState({
                isCurrentlyOpen: true,
            });
        }
        if (!this.state.hasFocus) {
            return;
        }
        if (this.state.focusedOptionElem >= 0) {
            if (this.dialogOptionRefs[this.state.focusedOptionElem]) {
                this.dialogOptionRefs[this.state.focusedOptionElem].focus();
                return;
            }
        }
        if (!!this.props.isOpen || this.state.isCurrentlyOpen) {
            if (this.dialogSelectRef) {
                this.dialogSelectRef.focus();
            }
        }
        else if (this.closedSelectRef) {
            this.closedSelectRef.focus();
        }
    };
    DropdownComponent.prototype.render = function () {
        return this.renderDropdown();
    };
    return DropdownComponent;
}(React.Component));
DropdownComponent.displayName = 'DropdownComponent';
export default DropdownComponent;
