var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import * as React from 'react';
import { InputState } from "../../constants";
import { genericIcon } from '../../utils/helper';
import InputFeedback from '../InputFeedback/InputFeedback';
import Label from '../Label/Label';
var BASE_CLASS = 'form-input-v2';
export var TextInput = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'text' : _b, id = _a.id, _c = _a.label, label = _c === void 0 ? 'InputLabel' : _c, errorMessage = _a.errorMessage, _d = _a.inputState, inputState = _d === void 0 ? InputState.NONE : _d, value = _a.value, required = _a.required, qaId = _a.qaId, _e = _a.name, name = _e === void 0 ? 'InputName' : _e, disabled = _a.disabled, iconLeft = _a.iconLeft, iconRight = _a.iconRight, className = _a.className, feedbackClassName = _a.feedbackClassName, labelClassName = _a.labelClassName, onClick = _a.onClick, onFocus = _a.onFocus, handleChange = _a.onChange, handleBlur = _a.onBlur, inputRef = _a.inputRef, readOnly = _a.readOnly, _f = _a.autoFocus, autoFocus = _f === void 0 ? false : _f, inputPassThroughProps = _a.inputPassThroughProps;
    var isValid = inputState === InputState.VALID;
    var hasError = inputState === InputState.ERROR;
    var isPending = inputState === InputState.PENDING;
    var placeholder = inputPassThroughProps && inputPassThroughProps.placeholder;
    var isFilled = value || value === 0 || placeholder;
    var wrapperClasses = classNames("".concat(BASE_CLASS), required && "".concat(BASE_CLASS, "--required"), hasError && "".concat(BASE_CLASS, "--error"), isValid && "".concat(BASE_CLASS, "--valid"), isFilled && "".concat(BASE_CLASS, "--filled"), disabled && "".concat(BASE_CLASS, "--disabled"), isPending && "".concat(BASE_CLASS, "--pending"), className);
    var inputClasses = classNames("".concat(BASE_CLASS, "__field"), hasError && "".concat(BASE_CLASS, "__field--error"), isValid && "".concat(BASE_CLASS, "__field--valid"), isFilled && "".concat(BASE_CLASS, "__field--filled"), disabled && "".concat(BASE_CLASS, "__field--disabled"), isPending && "".concat(BASE_CLASS, "__field--pending"), (iconLeft || hasError || isValid || isPending) && "".concat(BASE_CLASS, "__field--icon-left"), iconRight && "".concat(BASE_CLASS, "__field--icon-right"));
    var pendingIcon = function () { return (React.createElement("div", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon-left--pending"), id: "".concat(id, "__pending-icon") },
        React.createElement("span", { className: "icon__wrapper", style: { display: 'block' } },
            React.createElement("svg", { className: "spinner-circular", viewBox: "30 20 50 50", transform: "scale(2)" },
                React.createElement("circle", { className: "loader__circle loader__circle loader__circle loader__circle--visible", cx: "50", cy: "50", r: "10", fill: "none", strokeWidth: "2", strokeMiterlimit: "0" }))))); };
    return (React.createElement("div", { id: "__".concat(id) },
        React.createElement("div", { className: "".concat(wrapperClasses) },
            React.createElement("input", __assign({ id: id, "data-qa": qaId, className: inputClasses, type: type, value: value, name: name, disabled: disabled, onChange: handleChange, onBlur: handleBlur, onFocus: onFocus, onClick: onClick, ref: inputRef, readOnly: readOnly, autoFocus: autoFocus }, inputPassThroughProps)),
            isPending && pendingIcon(),
            hasError && genericIcon(BASE_CLASS, id, 'error'),
            isValid && genericIcon(BASE_CLASS, id, 'valid'),
            iconLeft && (React.createElement("div", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon-left") }, iconLeft)),
            iconRight && (React.createElement("div", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon-right") }, iconRight)),
            React.createElement(Label, { htmlFor: id, required: required, labelClassName: labelClassName }, label),
            React.createElement("span", { className: "form-input__bar" })),
        errorMessage instanceof Array ? (errorMessage.map(function (error, index) { return (React.createElement(InputFeedback, { key: index, hasError: hasError, errorMessage: error, inputState: inputState, feedbackClassName: feedbackClassName })); })) : (React.createElement(InputFeedback, { hasError: hasError, errorMessage: errorMessage, inputState: inputState, feedbackClassName: feedbackClassName }))));
};
TextInput.displayName = 'TextInputComponent';
export default TextInput;
