var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { getBrowser, getDevice, isFallbackBrowser, isPhone, isTablet } from '../../utils/ua';
export var WithEnvInformation = function (_a) {
    var _b = _a.getBrowserInfo, getBrowserInfo = _b === void 0 ? false : _b, _c = _a.getDeviceInfo, getDeviceInfo = _c === void 0 ? false : _c, browserBlacklist = _a.browserBlacklist;
    return function (Component) { var _a; return _a = (function (_super) {
            __extends(WithEnvInformationComponent, _super);
            function WithEnvInformationComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.getBrowserInformation = function () {
                    return _this.setState(function () {
                        var currentBrowser = getBrowser();
                        return {
                            browser: currentBrowser,
                        };
                    });
                };
                _this.getDeviceInformation = function () {
                    return _this.setState(function () {
                        var currentDevice = getDevice();
                        return {
                            device: currentDevice,
                        };
                    });
                };
                return _this;
            }
            WithEnvInformationComponent.prototype.UNSAFE_componentWillMount = function () {
                if (getBrowserInfo) {
                    this.getBrowserInformation();
                    this.setState({
                        isFallbackBrowser: isFallbackBrowser(getBrowser(), !!browserBlacklist && browserBlacklist),
                    });
                }
                if (getDeviceInfo) {
                    this.getDeviceInformation();
                    this.setState({
                        isMobile: isPhone(getDevice()),
                    });
                    this.setState({
                        isTablet: isTablet(getDevice()),
                    });
                }
            };
            WithEnvInformationComponent.prototype.render = function () {
                return React.createElement(Component, __assign({}, this.props, this.state));
            };
            return WithEnvInformationComponent;
        }(React.Component)),
        _a.displayName = "WithEnvInformation(".concat(Component.displayName, ")"),
        _a; };
};
