var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
var contextTypes = {
    toggle: PropTypes.func,
};
var DropdownItem = (function (_super) {
    __extends(DropdownItem, _super);
    function DropdownItem(props) {
        var _this = _super.call(this, props) || this;
        _this.onClick = _this.onClick.bind(_this);
        return _this;
    }
    DropdownItem.prototype.onClick = function (e) {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }
        if (this.props.onClick) {
            this.props.onClick(e);
        }
        this.context.toggle();
    };
    DropdownItem.prototype.getTabIndex = function () {
        if (this.props.disabled) {
            return '-1';
        }
        return '0';
    };
    DropdownItem.prototype.render = function () {
        var tabIndex = this.getTabIndex();
        var _a = this.props, className = _a.className, _b = _a.tag, Tag = _b === void 0 ? 'div' : _b, props = __rest(_a, ["className", "tag"]);
        var classes = classNames(className, {
            disabled: props.disabled,
        });
        return React.createElement(Tag, __assign({}, props, { tabIndex: tabIndex, className: classes, onClick: this.onClick }));
    };
    return DropdownItem;
}(React.Component));
DropdownItem.contextTypes = contextTypes;
DropdownItem.displayName = 'DropdownItem';
export default DropdownItem;
