import anOkoSubscr, { anOkoUnsub, prefillOko } from '../api/okoApi';
import doubleOptInSubscr, { doubleOptInUnsub } from '../api/doubleOptInApi';
// Actions
const COMMUNICATION_SUBSC_SAVE_SUCCESS = 'api/reducer/COMMUNICATION_SUBSC_SAVE_SUCCESS';
const COMMUNICATION_SUBSC_SAVE_FAIL = 'api/reducer/COMMUNICATION_SUBSC_SAVE_FAIL';
const COMMUNICATION_SUBSC_REMOVE_ERROR = 'api/reducer/COMMUNICATION_SUBSC_REMOVE_ERROR';
const COMMUNICATION_PREFILL = 'api/reducer/COMMUNICATION_PREFILL';

export const stages = {
  FirstStep: 'FirstStep',
  SuccesStep: 'SuccesStep',
};

// Reducer
const communicationReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMUNICATION_SUBSC_SAVE_SUCCESS:
      return {
        ...state,
        step: stages.SuccesStep,
      };
    case COMMUNICATION_SUBSC_SAVE_FAIL:
      return {
        ...state,
        apiErrors: action.data.errorCode,
      };
    case COMMUNICATION_SUBSC_REMOVE_ERROR: {
      const noErr = {};
      noErr[action.data.errorName] = false;
      return {
        ...state,
        apiErrors: {
          ...state.apiErrors,
          ...noErr,
        },
      };
    }

    case COMMUNICATION_PREFILL:
      return {
        ...state,
        prefillData: { ...action.data, invitationId: action.param },
      };

    default:
      return state;
  }
};

// Action Creators
export const subscribe = (dispatch, payload) => {
  const { isOko, ...rest } = payload;
  const request = isOko ? anOkoSubscr(rest) : doubleOptInSubscr(rest);
  request
    .then((response) => {
      const loadedData = response && response.data;
      dispatch({
        type: COMMUNICATION_SUBSC_SAVE_SUCCESS,
        data: {
          innerData: loadedData,
        },
      });
    })
    .catch((response) => {
      const errorCode =
        response && response.response.data.errors && response.response.data.errors[0] && response.response.data.errors[0].code.split('.').pop();
      const errs = {};
      if (errorCode === 'invalidInput') {
        errs.policyCheckFailed = true;
        errs.policyCheckFailedVk = true;
        errs.policyCheckFailedGp = true;
      } else {
        errs[errorCode] = true;
      }
      dispatch({
        type: COMMUNICATION_SUBSC_SAVE_FAIL,
        data: {
          errorCode: errs,
        },
      });
    });
  return request;
};

export const unSubscribe = (dispatch, payload) => {
  const { isOko, ...rest } = payload;
  const request = isOko ? anOkoUnsub(rest) : doubleOptInUnsub(rest);
  request
    .then((response) => {
      const loadedData = response && response.data;
      dispatch({
        type: COMMUNICATION_SUBSC_SAVE_SUCCESS,
        data: {
          innerData: loadedData,
        },
      });
    })
    .catch((response) => {
      const errorCode =
        response && response.response.data.errors && response.response.data.errors[0] && response.response.data.errors[0].code.split('.').pop();
      const errs = {};
      if (errorCode === 'invalidInput') {
        errs.policyCheckFailed = true;
        errs.policyCheckFailedGp = true;
      } else if (errorCode) {
        errs[errorCode] = true;
      } else {
        errs.unknownError = true;
      }
      dispatch({
        type: COMMUNICATION_SUBSC_SAVE_FAIL,
        data: {
          errorCode: errs,
        },
      });
    });
  return request;
};

export const removeAPiError = (dispatch, errorName) => {
  dispatch({
    type: COMMUNICATION_SUBSC_REMOVE_ERROR,
    data: {
      errorName,
    },
  });
};

export const loadPrefill = (dispatch, payload) => {
  const request = prefillOko(payload);
  request
    .then((response) => {
      const loadedData = response && response.data;
      dispatch({
        type: COMMUNICATION_PREFILL,
        data: loadedData,
        param: payload.subscriptionInvitationId,
      });
    })
    .catch(() => {
      dispatch({
        type: COMMUNICATION_PREFILL,
        data: null,
      });
    });
  return request;
};

export default communicationReducer;
