import axios from 'axios';
import RestHelper from '../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';

export function getContracts(token) {
  const config = token ? { headers: { 'X-Ciam-Token': token } } : {};
  const auth = !token;
  return CacheHandler.requestCache(
    () =>
      RestHelper.get(
        `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/contractaccounts?projection=withBusinessPartner`,
        {},
        config,
        true,
        auth
      ),
    'contractaccounts'
  );
}
export function getContractConsumptions(contractAccountId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/consumption/v0/contractconsumptions?contractAccountId=${contractAccountId}`
  );
}
export function getContractConsumptionDetails(contractAccountId, contractId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/readingresults?contractAccountId=${contractAccountId}&contractId=${contractId}`
  );
}

export function getContractAdditionalDetails(contractAccountId) {
  const response = CacheHandler.requestCache(() => {
    const baseURL = RestHelper.getBaseUrl();
    return RestHelper.get(`${baseURL}/cscn-customer-srv/api/partner/v0/contracts?contractAccountId=${contractAccountId}`, {}, {});
  }, `additionalDetails_${contractAccountId}`);
  return {
    request: new Promise((resolve, reject) => {
      response
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          const error = err;
          if (axios.isCancel(error)) {
            error.type = 'cancel';
          }
          reject(error);
        });
    }),
  };
}

export function getHanAccessDetails(contractAccountId, meterSerialNumber) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/contractaccounts/${contractAccountId}/gatewayaccount/${meterSerialNumber}`
  );
}

export default { getContracts, getHanAccessDetails, getContractAdditionalDetails, getContractConsumptionDetails, getContractConsumptions };
