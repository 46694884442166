import UAParser from 'ua-parser-js';
import { BLACKLIST_BROWSER } from '../constants';
var parser = new UAParser();
export var getBrowser = function () { return parser && parser.getBrowser(); };
export var getDevice = function () { return parser && parser.getDevice(); };
export var isFallbackBrowser = function (browser, componentBrowserBlacklist) {
    var usedBlacklist = componentBrowserBlacklist || BLACKLIST_BROWSER;
    return (usedBlacklist.filter(function (obj) { return obj.name === browser.name && obj.major === browser.major; }).length >
        0);
};
export var isPhone = function (device) { return device.type === 'mobile'; };
export var isTablet = function (device) { return device.type === 'tablet'; };
