var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import DayPicker from 'react-day-picker';
import Icon from '../Icon/Icon';
import TextInput from '../TextInput/TextInput';
moment.locale('de');
export var merge = function (t, u) { return (__assign(__assign({}, t), u)); };
var DatePickerComponent = (function (_super) {
    __extends(DatePickerComponent, _super);
    function DatePickerComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleTextChange = function (e) {
            _this.props.onChange({
                date: moment(e.currentTarget.value).isValid()
                    ? moment(e.currentTarget.value).toDate()
                    : moment(e.currentTarget.value, 'DD.MM.YYYY').toDate(),
                text: e.currentTarget.value,
            });
        };
        _this.handleDateChange = function (date) { return function (e) {
            e.preventDefault();
            _this.setState({ dateShown: date });
        }; };
        _this.handleFocus = function (e) {
            _this.DatePickerInput.focus();
            _this.props.onFocus(e);
        };
        _this.handleBlur = function (value, dateFormats) { return function (e) {
            if (value) {
                if (!_this.changeIsTriggered) {
                    _this.handleDayPickerChange(moment(value.text, dateFormats).toDate());
                }
                else {
                    _this.changeIsTriggered = false;
                }
            }
            _this.props.onBlur(e);
        }; };
        _this.handleMouseDown = function (e) { return e.preventDefault(); };
        _this.renderNavbarElement = function () { return React.createElement("div", { className: "DayPicker-Navbar" }); };
        _this.setRef = function (input) { return (_this.DatePickerInput = input); };
        _this.renderCaptionElement = function (date, dateShown) {
            var _a = _this.props, qaId = _a.qaId, monthsLocalized = _a.monthsLocalized, fromMonth = _a.fromMonth, toMonth = _a.toMonth;
            var handleLowerNavValue = function (dateToCheck, monthStart) {
                return monthStart && monthStart > dateToCheck;
            };
            var handleUpperNavValue = function (dateToCheck, monthEnd) {
                return monthEnd && monthEnd < dateToCheck;
            };
            var DayPickerCaptionButtonDownClasses = classNames('DayPicker-Caption__button DayPicker-Caption__button--down', handleLowerNavValue(date, fromMonth) && 'DayPicker-Caption__button--hidden');
            var DayPickerCaptionButtonUpClasses = classNames('DayPicker-Caption__button DayPicker-Caption__button--up', handleUpperNavValue(date, toMonth) && 'DayPicker-Caption__button--hidden');
            return (React.createElement("div", { className: "DayPicker-Caption" },
                React.createElement("div", { className: "DayPicker-Caption__wrapper" },
                    React.createElement("div", { className: "DayPicker-Caption__year" },
                        React.createElement("div", { role: "button", "data-qa": "".concat(qaId, "-year-down"), className: DayPickerCaptionButtonDownClasses, onClick: _this.handleDateChange(moment(dateShown).subtract(1, 'y').toDate()) }),
                        React.createElement("span", { className: "DayPicker-Caption__year-string" }, date.getFullYear()),
                        React.createElement("div", { role: "button", "data-qa": "".concat(qaId, "-year-up"), className: DayPickerCaptionButtonUpClasses, onClick: _this.handleDateChange(moment(dateShown).add(1, 'y').toDate()) })),
                    React.createElement("div", { className: "DayPicker-Caption__month" },
                        React.createElement("div", { role: "button", "data-qa": "".concat(qaId, "-month-down"), className: DayPickerCaptionButtonDownClasses, onClick: _this.handleDateChange(moment(dateShown).subtract(1, 'M').toDate()) }),
                        React.createElement("span", { className: "DayPicker-Caption__month-string" }, monthsLocalized[date.getMonth()]),
                        React.createElement("div", { role: "button", "data-qa": "".concat(qaId, "-month-up"), className: DayPickerCaptionButtonUpClasses, onClick: _this.handleDateChange(moment(dateShown).add(1, 'M').toDate()) })))));
        };
        _this.state = { dateShown: props.initialShownMonth || new Date() };
        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }
    DatePickerComponent.dayMatchesModifier = function (day, modifier) {
        return DayPicker.ModifiersUtils.dayMatchesModifier(day, modifier);
    };
    DatePickerComponent.prototype.componentDidUpdate = function (prevProps) {
        var showOverlay = this.props.showOverlay;
        var wasShowingOverlay = prevProps.showOverlay;
        if (wasShowingOverlay === false && showOverlay) {
            document.addEventListener('mousedown', this.handleClick, false);
        }
        else {
            document.removeEventListener('mousedown', this.handleClick, false);
        }
    };
    DatePickerComponent.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClick, false);
    };
    DatePickerComponent.prototype.handleDayPickerChange = function (date) {
        if (!isNaN(date.getTime())) {
            this.props.onChange({ text: moment(date).format(this.props.dateFormats[0]), date: date });
            this.handleDateChange(date);
        }
    };
    DatePickerComponent.prototype.handleClick = function (e) {
        if (this.overlay && this.overlay.contains(e.target)) {
            return;
        }
        this.props.onBlur(e);
    };
    DatePickerComponent.prototype.buildInputText = function (value) {
        var isDateValid = moment(value.start).isValid();
        if (value) {
            if (typeof value.start === 'undefined') {
                return '';
            }
            switch (true) {
                case !!(value.text || !isDateValid):
                    return value.text;
                case !!(value.end && value.start):
                    var startString = moment(value.start).format(this.props.dateFormats[0]);
                    var endString = moment(value.end).format(this.props.dateFormats[0]);
                    return "".concat(startString, " - ").concat(endString);
                case !!value.start:
                    return moment(value.start).format(this.props.dateFormats[0]);
            }
        }
    };
    DatePickerComponent.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, id = _b.id, qaId = _b.qaId, name = _b.name, value = _b.value, required = _b.required, inputState = _b.inputState, disabled = _b.disabled, onClick = _b.onClick, dateFormats = _b.dateFormats, showOverlay = _b.showOverlay, label = _b.label, numberOfMonths = _b.numberOfMonths, monthsLocalized = _b.monthsLocalized, disabledDays = _b.disabledDays, daysShortLocalized = _b.daysShortLocalized, daysLongLocalized = _b.daysLongLocalized, errorMessage = _b.errorMessage, className = _b.className, locale = _b.locale, onDayTouchEnd = _b.onDayTouchEnd, onDayTouchStart = _b.onDayTouchStart, fromMonth = _b.fromMonth, toMonth = _b.toMonth, disabledTextEdit = _b.disabledTextEdit, useNative = _b.useNative, customModifiers = _b.customModifiers;
        var dateShown = this.state.dateShown;
        var startDate;
        var endDate;
        if (value) {
            startDate = value.start;
            endDate = value.end ? value.end : value.start;
        }
        if (startDate > endDate) {
            _a = [endDate, startDate], startDate = _a[0], endDate = _a[1];
        }
        var wrapperClassNames = classNames('date-picker', className);
        var defaultModifiers = {
            end: endDate,
            start: startDate,
        };
        var calendarModifiers = customModifiers
            ? merge(defaultModifiers, customModifiers)
            : defaultModifiers;
        return (React.createElement("div", { id: "__".concat(id), className: wrapperClassNames },
            React.createElement(TextInput, { inputRef: this.setRef, id: "".concat(id, "_text-input"), qaId: qaId, label: label, inputState: inputState, errorMessage: errorMessage, required: required, disabled: disabled, readOnly: disabledTextEdit, iconRight: (React.createElement(Icon, { id: "".concat(id, "-icon"), qaId: "".concat(qaId, "-icon"), size: "18", glyph: "icon_kalender_18x18", style: { fill: 'currentColor' }, onClick: this.handleFocus })), name: name && "".concat(name, "_text-input"), type: useNative ? 'date' : 'text', value: this.buildInputText(value), onChange: this.handleTextChange, onFocus: this.handleFocus, onClick: onClick, onBlur: this.handleBlur(value, dateFormats) }),
            React.createElement("div", { id: "".concat(id, "_daypicker"), ref: function (ref) { return (_this.overlay = ref); } }, showOverlay && !useNative && (React.createElement("label", { onMouseDown: this.handleMouseDown, className: "date-picker__overlay", htmlFor: "".concat(id, "_text-input") },
                React.createElement(DayPicker, { numberOfMonths: numberOfMonths, firstDayOfWeek: 1, onDayClick: function (day, _a) {
                        var disabled = _a.disabled;
                        if (!disabled) {
                            _this.handleDayPickerChange(day);
                            _this.changeIsTriggered = true;
                        }
                    }, selectedDays: value ? [value.start, { from: value.start, to: value.end }] : undefined, months: monthsLocalized, month: dateShown, weekdaysLong: daysLongLocalized, weekdaysShort: daysShortLocalized, disabledDays: disabledDays, modifiers: calendarModifiers, navbarElement: this.renderNavbarElement, captionElement: function (captionElement) {
                        return _this.renderCaptionElement(captionElement.date, dateShown);
                    }, locale: locale, fromMonth: fromMonth, toMonth: toMonth, onDayTouchEnd: onDayTouchEnd, onDayTouchStart: onDayTouchStart }))))));
    };
    DatePickerComponent.defaultProps = {
        dateFormats: ['DD.MM.YYYY', 'DD.MM.YY', 'DD.MM', 'DD/MM/YYYY', 'DD/MM/YY', 'DD/MM'],
        daysLongLocalized: moment.weekdays(),
        daysShortLocalized: moment.weekdaysMin(),
        disabledTextEdit: false,
        locale: 'de',
        monthsLocalized: moment.months(),
        onBlur: function () { return null; },
        onFocus: function () { return null; },
    };
    return DatePickerComponent;
}(React.Component));
DatePickerComponent.displayName = 'DatePickerComponent';
export default DatePickerComponent;
