import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';

export function getMyData(contractId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function getPersonalData(contractId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function saveMyData(contractId, emailData) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}`,
    emailData,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}
export function getBusinessPartner() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/businesspartners`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export const getTaxVatNumbers = (contractAccountId: string) => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractAccountId}/taxnumbers`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  )
    .then((response) => {
      return {
        data: {
          taxin: response.data.taxin ? response.data.taxin : '',
          vatin: response.data.vatin ? response.data.vatin : 'DE',
        },
      };
    })
    .catch((error) => {
      throw error;
    });
};

export const saveTaxVatNumber = (contractAccountId: string, data) => {
  return RestHelper.patch(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractAccountId}/taxnumbers`,
    data,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
};

// @TODO find out if it exists
const refreshToken = () =>
  RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/token`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );

export function getPhonenIdFromPhoneObject(phoneNumberObj) {
  return phoneNumberObj?.order;
}

export default {
  saveMyData,
  getTaxVatNumbers,
  saveTaxVatNumber,
  getBusinessPartner,
  getMyData,
  getPersonalData,
  refreshToken,
  getPhonenIdFromPhoneObject,
};
