export default function decodeEntities(givenStr: string): string {
  let str: string = givenStr;
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  if (str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    element.innerHTML = str;
    str = element.textContent ? element.textContent : '';
    element.textContent = '';
  }

  return str;
}

export function b64DecodeUnicode(str: string): string {
  const newStr = str.replace(/\s/g, '');
  return decodeURIComponent(escape(atob(newStr)));
}

export function convertThousandsSeparator(amount: string): string {
  const floatPart = amount.split(',')[1];
  const convertedFloatPart = floatPart && floatPart.length === 1 ? `${floatPart}0` : floatPart;
  const displayFloatPart = floatPart ? convertedFloatPart : '00';
  const displayIntPart = amount ? amount.split(',')[0] : '0';

  if (parseFloat(amount) < 1000) {
    return `${displayIntPart},${displayFloatPart}`;
  }
  const intPart = amount.split(',')[0];
  const intPartLength = intPart.length;
  const convertedIntPart: string[] = [];
  let firstPartPosition;

  for (let i = intPartLength - 3; i > 0; i -= 3) {
    const endPosition = i + 3;
    const slicedString = intPart.slice(i, endPosition);
    convertedIntPart.unshift(slicedString);
    firstPartPosition = i;
  }
  const splitStringFirstPart = intPart.slice(0, firstPartPosition);
  convertedIntPart.unshift(splitStringFirstPart);
  return `${convertedIntPart.join('.')},${displayFloatPart}`;
}

export function convertFloatToGerman(number: number, fractionPlaces: number = 2): string | 0 {
  const addSeparator = (part) => {
    if (part.length > 3) {
      return `${addSeparator(part.slice(0, -3))}.${part.slice(-3)}`;
    }
    return part;
  };
  let returnVal;
  if (!number) {
    return 0;
  }
  if (number.toString) {
    returnVal = number.toString();
  }
  const parts = returnVal.split('.');
  const numberOfZeros = '0'.repeat(fractionPlaces);
  const addFracPlaces = (frac) => (fractionPlaces !== 0 && frac.length < fractionPlaces ? `${frac}${numberOfZeros}`.slice(0, fractionPlaces) : frac);
  const second = parts[1] ? `,${addFracPlaces(parts[1])}` : `,${numberOfZeros}`;
  return `${addSeparator(parts[0])}${second}`;
}

export function convertGermanToFloat(number: string): number {
  return parseFloat(number.replace(' ', '').replace('.', '').replace(',', '.'));
}

export function arrayToIsoDate(array: string[]): string {
  const prefix = (string) => `0${string}`.slice(-2);
  const year = array[0];
  const month = prefix(array[1]);
  const day = prefix(array[2]);
  return `${year}-${month}-${day}`;
}
