import { getAllMeters } from '../api/meterReadingApi';

// Actions
const LOAD_ALL_METERS = 'api/reducer/ALL_METERS';
const MARK_METER_SAVED = 'reduce/MARK_METER_SAVED';

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_ALL_METERS:
      return {
        ...state,
        action: action.data.innerData,
      };
    case MARK_METER_SAVED:
      return {
        ...state,
        meterSaved: [...(state.meterSaved || []), action.serialNo],
      };
    default:
      return state;
  }
}

// Action Creators
export function loadAllMeters(dispatch) {
  const resp = getAllMeters();
  return resp
    .then((response) => {
      dispatch({
        type: LOAD_ALL_METERS,
        data: {
          innerData: response.data.content,
        },
      });
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
    });
}

export function markMeterSaved(dispatch, serialNo) {
  return dispatch({
    type: MARK_METER_SAVED,
    serialNo,
  });
}
