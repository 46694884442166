import { getAllMeters } from '../../api/meterReadingApi';
import { DeviceDataTypes, MeterReadingErrorObjectType } from '../../components/MeterRead/MeterReadingTypes';

enum MeterReadingActions {
  LOAD_ALL_METERS = 'api/reducer/LOGGEDIN_ALL_METERS',
  LOADING = 'api/reducer/LOGGEDIN_ALL_METERS_LOADING',
  MARK_METER_SAVED = 'reduce/LOGGEDIN_MARK_METER_SAVED',
  OPEN_METER = 'reduce/LOGGEDIN_OPEN_METER',
  SET_API_ERROR = 'api/reducer/LOGGEDIN_SET_API_ERROR',
}

export type loggedinMeterReadingReducerActionTypes = {
  type: MeterReadingActions;
  loading: boolean;
  apiError: boolean;
  category: string;
  serialNumber: string;
  data: {
    devices: DeviceDataTypes[];
    errorObjects: MeterReadingErrorObjectType[];
  };
};

function isLoading(dispatch, loading) {
  dispatch({
    type: MeterReadingActions.LOADING,
    data: {
      loading,
    },
  });
}

function loadAllMeters(dispatch) {
  isLoading(dispatch, true);
  getAllMeters()
    .then((response) => {
      dispatch({
        type: MeterReadingActions.LOAD_ALL_METERS,
        data: {
          devices: response.data.content,
        },
      });
      isLoading(dispatch, false);
    })
    .catch((error) => {
      dispatch({
        type: MeterReadingActions.SET_API_ERROR,
        data: {
          error,
        },
        apiError: true,
        loading: false,
      });
    });
}

function markMeterSaved(dispatch, serialNumber) {
  return dispatch({
    type: MeterReadingActions.MARK_METER_SAVED,
    serialNumber,
  });
}

function setOpenMeter(dispatch, serialNumber) {
  return dispatch({
    type: MeterReadingActions.OPEN_METER,
    serialNumber,
  });
}

export { MeterReadingActions, loadAllMeters, markMeterSaved, setOpenMeter, isLoading };
