export default class EvngDomManipulator {
  static redirectorPath = '/de/cic_service/redirector.html#destination=contact';

  private static removeAllElem(list: NodeListOf<Element>) {
    if (list && list.length) {
      list.forEach((elem) => {
        elem.remove();
      });
    }
  }

  static initDomManipulations() {
    EvngDomManipulator.removeFooterContent();
    EvngDomManipulator.makeContactButtonStatic();
    document.addEventListener('DOMContentLoaded', () => {
      EvngDomManipulator.removeFooterContent();
      EvngDomManipulator.makeContactButtonStatic();
    });
  }

  private static removeFooterContent() {
    EvngDomManipulator.removeAllElem(document.querySelectorAll('.footer-cta'));
    EvngDomManipulator.removeAllElem(document.querySelectorAll('.footer-navigation-wrapper'));
  }

  private static makeContactButtonStatic() {
    document.querySelector('.contact-link')?.addEventListener('click', () => {
      location.href = EvngDomManipulator.redirectorPath;
    });
  }
}
