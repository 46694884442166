var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
var BASE_CLASS = 'tooltip';
export var Tooltip = function (_a) {
    var targetElement = _a.targetElement, content = _a.content, _b = _a.place, place = _b === void 0 ? 'top' : _b, id = _a.id, disable = _a.disable, qaId = _a.qaId, html = _a.html, _c = _a.version, version = _c === void 0 ? 'v1' : _c, props = __rest(_a, ["targetElement", "content", "place", "id", "disable", "qaId", "html", "version"]);
    return (React.createElement("div", { className: "".concat(BASE_CLASS, "__wrapper ").concat(BASE_CLASS, "__wrapper--").concat(version), "data-qa": qaId },
        React.createElement("a", { "data-tip": content, "data-tooltip-id": id, "data-html": html, "data-place": place, "data-tip-disable": disable, className: "".concat(BASE_CLASS, "__target-element") }, targetElement),
        React.createElement(ReactTooltip, __assign({ id: id, type: "light", effect: "solid", className: "".concat(BASE_CLASS) }, props), typeof content === 'string' ? (React.createElement("span", { className: "".concat(BASE_CLASS, "__content") }, content)) : (content.props.children))));
};
Tooltip.displayName = 'TooltipComponent';
export default Tooltip;
