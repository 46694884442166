import { InferProps } from 'prop-types';
import React, { useState } from 'react';
import { Button, InputState, TextInput, Checkbox, Loader } from '@eon-funke/react-shared-ui-next';
import CacheHandler from '../../../../../utils/CacheHandler';
import { EMAIL_REGEX } from '../../../../../utils/RegExUtils';
import { activateOko } from '../../../../../api-factory/meterReadingApi';
import { meterReadingRegisterLabelPropTypes } from './MeterReadingRegisterPropTypes';

type MeterReadingRegisterTypes = {
  emailValidationError: string;
  emailAddressLabel: string;
  serverErrorMessage: string;
  onNextStep: (registerPage: boolean) => void;
  onOkoSuccess: (okoEmail: string) => void;
};

type MeterReadingRegisterState = {
  email: string;
  inputStatus: any;
  serverError: boolean;
  loading: boolean;
};

export type MeterReadingRegisterProps = MeterReadingRegisterTypes & InferProps<typeof meterReadingRegisterLabelPropTypes>;

const MeterReadingRegister: React.FC<MeterReadingRegisterProps> = (props) => {
  const [dataProtectionAccepted, setDataProtectionAccepted] = useState<boolean>(false);

  const [state, setState] = useState<MeterReadingRegisterState>({
    email: '',
    inputStatus: InputState.NONE,
    serverError: false,
    loading: false,
  });

  const checkEmail = (value) => {
    const isEmailValid = value ? EMAIL_REGEX.test(String(value)) : false;
    setState({
      ...state,
      email: value,
      inputStatus: isEmailValid ? InputState.VALID : InputState.ERROR,
      serverError: false,
    });
    return isEmailValid;
  };

  const okoSubscription = () => {
    setState({
      ...state,
      loading: true,
    });

    const okoObj = {
      mailAddress: state.email,
      privacyPolicyAccepted: dataProtectionAccepted,
    };

    const result = activateOko(okoObj);
    result.then(() => {
      setState({
        ...state,
        loading: false,
        serverError: false,
      });
      props.onOkoSuccess(state.email);
    });
    result.catch(() => {
      setState({
        ...state,
        loading: false,
        serverError: true,
        inputStatus: InputState.ERROR,
      });
    });
  };

  const defaultEmail = CacheHandler.getCurrentCache().accountData?.email;
  const errorMessage = state.serverError ? props.serverErrorMessage : props.emailValidationError;
  const dataProtectionText = (
    <div>
      {props.dataProtectionText1}{' '}
      <a
        href={props.dataProtectionPageUrl}
        className='meter-reading-register__data-protection-link'
      >
        {' '}
        {props.dataProtectionLabel}
      </a>{' '}
      {props.dataProtectionText2}
    </div>
  );

  if (state.loading) {
    return (
      <Loader
        loading
        fallbackGifSrc={sessionStorage.fallbackLoader}
      />
    );
  }

  const okoClassContainer = !props.onlyGuestPortal ? 'meter-reading-register__oko-container' : 'meter-reading-register__oko-container-full-width';
  const okoBorderContainer = !props.onlyGuestPortal
    ? 'meter-reading-register__oko-border-container'
    : 'meter-reading-register__oko-border-container-full-width';

  return (
    <>
      {!props.onlyGuestPortal && (
        <div className='meter-reading-register__without-register-container'>
          <div className='meter-reading-register__without-register-text-container'>
            <h5 className='meter-reading-register__without-register-text'>{props.withoutRegisterText}</h5>
          </div>
          <div className='meter-reading-register__without-register-button-container'>
            <Button
              className='meter-reading-register__button__without-register'
              version={props.withoutRegisterButton?.version}
              id='meter-reading-register-without-register-button'
              qaId='meter-reading-register-without-register-button'
              onClick={() => props.onNextStep(false)}
              autoFocus={false}
            >
              {' '}
              {props.withoutRegisterButton?.label}
            </Button>
          </div>
        </div>
      )}
      <div className='meter-reading-register__container'>
        <div className={okoClassContainer}>
          <div className={okoBorderContainer}>
            <h4 className='meter-reading-register__headline'>{props.readingCardHeadline}</h4>
            <p className='meter-reading-register__description'>{props.readingCardSubline}</p>
            <div className='meter-reading-register__form-input'>
              <TextInput
                onBlur={(e) => {
                  checkEmail(e.target.value);
                }}
                onChange={(e) => {
                  checkEmail(e.target.value);
                }}
                id='meter-reading-register-input'
                qaId='meter-reading-register-input'
                errorMessage={state.inputStatus === InputState.ERROR ? errorMessage : ''}
                type='text'
                name='email'
                value={state.email}
                defaultValue={defaultEmail}
                label={props.emailAddressLabel}
                inputState={state.inputStatus}
              />
            </div>
            <div className='meter-reading-register__data-protection-container'>
              <Checkbox
                name='data-protection'
                id='meter-reading-register-checkbox-data-protection'
                qaId='meter-reading-register-checkbox-data-protection'
                label={dataProtectionText}
                checked={dataProtectionAccepted}
                onChange={() => setDataProtectionAccepted(!dataProtectionAccepted)}
              />
            </div>
          </div>
          <div className='meter-reading-register__button-container'>
            <Button
              className='meter-reading-register__button__oko-activate'
              version={props.activateButton?.version}
              id='meter-reading-register-oko-activate-button'
              qaId='meter-reading-register-oko-activate-button'
              onClick={() => okoSubscription()}
              autoFocus={false}
              disabled={state.inputStatus !== InputState.VALID || !dataProtectionAccepted}
            >
              {' '}
              {props.activateButton?.label}
            </Button>
          </div>
        </div>
        {!props.onlyGuestPortal && (
          <div className='meter-reading-register__register-container'>
            <h4 className='meter-reading-register__headline'>{props.registerForPortalHeadline}</h4>
            <p className='meter-reading-register__description'>{props.registerForPortalSubline}</p>
            <h5 className='meter-reading-register__benefits-text'>{props.yourBenefitsText}</h5>
            <div className='meter-reading-register__benefits-container'>
              <div className='meter-reading-register__benefits-container__arrow' />
              {props.yourBenefits.paperlessCommunication}
            </div>
            <div className='meter-reading-register__benefits-container'>
              <div className='meter-reading-register__benefits-container__arrow' />
              {props.yourBenefits.meterShow}
            </div>
            <div className='meter-reading-register__benefits-container'>
              <div className='meter-reading-register__benefits-container__arrow' />
              {props.yourBenefits.meterRemind}
            </div>
            <div className='meter-reading-register__benefits-container'>
              <div className='meter-reading-register__benefits-container__arrow' />
              {props.yourBenefits.consumptionHistory}
            </div>
            <div className='meter-reading-register__benefits-container'>
              <div className='meter-reading-register__benefits-container__arrow' />
              {props.yourBenefits.addressChangeOnline}
            </div>
            <div className='meter-reading-register__button-container'>
              <Button
                className='meter-reading-register__button__with-register'
                version={props.registerButton?.version}
                id='meter-reading-register-with-register-button'
                qaId='meter-reading-register-with-register-button'
                onClick={() => props.onNextStep(true)}
                autoFocus={false}
              >
                {' '}
                {props.registerButton?.label}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

MeterReadingRegister.propTypes = meterReadingRegisterLabelPropTypes;
export default MeterReadingRegister;
