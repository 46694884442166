import { validatePhoneNumber } from '../api/myDataApi';

const VALIDATE_PHONENUMBER = 'api/reducer/validation/CHECK_PHONENUMBER';
const VALIDATE_PHONENUMBER_PENDING = 'api/reducer/validation/CHECK_PHONENUMBER_PENDING';

function checkForError(form) {
  const errorValidation = form.validations.find((x) => x.hasError === true);
  return errorValidation ? errorValidation.hasError : false;
}

const mergeNewValidation = (formName, inputState, prevState) => {
  const newState = JSON.parse(JSON.stringify(prevState));
  if (newState[formName] == null) {
    newState[formName] = {
      formHasError: false,
      validations: [],
      defaultValidations: [],
      resetValidation: false,
    };
  }
  let found = false;
  newState[formName].validations.forEach((elem, i) => {
    if (elem.inputName === inputState.name) {
      newState[formName].validations.splice(i, 1);
      found = true;
    }
  });
  const currentValidationState = {
    inputName: inputState.name,
    hasError: inputState.hasError,
    value: inputState.value ? inputState.value : '',
    id: inputState.id ? inputState.id : '',
    resetValidation: false,
  };
  if (!found) {
    newState[formName].defaultValidations.push({
      ...currentValidationState,
      resetValidation: true,
    });
  }
  newState[formName].validations.push(currentValidationState);

  newState[formName].formHasError = checkForError(newState[formName]);
  return newState;
};

function isPhoneNumberValid(data) {
  return data.valid && (data.type === 'MOBILE' || data.type === 'FIXED_LINE_OR_MOBILE') && data.region === 'DE';
}

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case 'validation/reducer/new_validation':
      return mergeNewValidation(action.data.formName, action.data.inputState, state);
    case 'validation/reducer/clear': {
      const defaultData = state[action.data.formName].defaultValidations;
      return {
        ...state,
        [action.data.formName]: {
          validations: defaultData,
          formHasError: checkForError(state[action.data.formName]),
          defaultValidations: defaultData,
        },
      };
    }
    case 'validation/reducer/remove_validation': {
      const newValidations = state[action.data.formName].validations.filter((validation) => validation.inputName !== action.data.inputName);
      return {
        ...state,
        [action.data.formName]: {
          validations: newValidations,
          formHasError: checkForError(state[action.data.formName]),
          defaultValidations: state[action.data.formName].defaultValidations,
          resetValidation: true,
        },
      };
    }
    case VALIDATE_PHONENUMBER_PENDING: {
      return {
        ...state,
        [action.data.formName]: {
          phoneNumberValidation: {
            isRequestPending: true,
          },
        },
      };
    }
    case VALIDATE_PHONENUMBER: {
      return {
        ...state,
        [action.data.formName]: {
          phoneNumberValidation: {
            isRequestPending: false,
            isValid: isPhoneNumberValid(action.data.result.data),
            apiError: action.data.error ? action.data.error : false,
            result: action.data.result.data,
          },
        },
      };
    }
    default:
      return state;
  }
}

export function updateValidationState(dispatch, state, formName) {
  dispatch({
    type: 'validation/reducer/new_validation',
    data: {
      formName,
      inputState: state,
    },
  });
}

export function resetValidationState(dispatch, formName) {
  dispatch({
    type: 'validation/reducer/clear',
    data: { formName },
  });
}

export function removeValidation(dispatch, formName, inputName) {
  dispatch({
    type: 'validation/reducer/remove_validation',
    data: { formName, inputName },
  });
}

export function onPhoneNumberValidation(dispatch, number, formName) {
  dispatch({
    type: VALIDATE_PHONENUMBER_PENDING,
    data: {
      formName,
    },
  });
  validatePhoneNumber(number)
    .then((result) => {
      dispatch({
        type: VALIDATE_PHONENUMBER,
        data: { formName, result },
      });
    })
    .catch((error) => {
      dispatch({
        type: VALIDATE_PHONENUMBER,
        data: { formName, error },
      });
    });
}
