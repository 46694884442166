import RestHelper from '../utils/RestHelper';
import { filenameEncoding } from '../../common/features/PostBox/postboxTableBodyHelper';
import { PostboxApiResponseTypes } from '../../common/features/PostBox/types/PostBoxTypes';

export function getPostboxDocuments(): Promise<PostboxApiResponseTypes> {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/documents`);
}

function initDownloadPostboxDocument(documentId, cb): void {
  const url = `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/documents/${documentId}?filenameEncoding=${filenameEncoding()}`;
  RestHelper.downloadContentWithPost(documentId, url, [], cb);
}

export default { initDownloadPostboxDocument, getPostboxDocuments };
