import RestHelper, { MWDomains } from '../../utils/RestHelper';
import { getPersonalData } from './myDataApi';
import CONFIG from '../config/config';
import { UnsubscribePayload } from '../components/Communication/communicationTypes';

export function subscribeEmail(payload: UnsubscribePayload) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/communication/v0/anonymous/subscription`,
    payload,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal),
    false,
    false
  );
}

export function unsubscribeEmail(payload: UnsubscribePayload) {
  const { captcha } = payload;
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/communication/v0/anonymous/subscription`,
    payload,
    RestHelper.setXClientHeader({ headers: { captcha } }, CONFIG.CHANNEL_CONFIG.portal),
    false,
    false
  );
}

export function confirmConfirmationCode(payload: UnsubscribePayload) {
  return RestHelper.patch(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/communication/v0/public/subscription?companyCode=${payload.companyCode}&confirmationCode=${payload.confirmationCode}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.anonymMR),
    false,
    false
  );
}

export function unsubscribeConfirmationCode(payload: UnsubscribePayload) {
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/communication/v0/public/subscription`,
    payload,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.anonymMR),
    false,
    false
  );
}

export const changeCommunicationTypeForMultipleAccounts = () => {
  RestHelper.patch(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/notificationtypes`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal),
    false,
    true
  );
};

export const updatePopupHasBeenShown = () => {
  RestHelper.patch(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/session?hasShownOnlineCommunicationPopup=true`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal),
    false,
    true
  );
};

export const changeCommunicationType = (contractAccountId, type = 'EMAIL') => {
  getPersonalData(contractAccountId).then((response) => {
    const personalData = response.data;
    personalData.notificationTypeKSA = type;
    personalData.notificationTypeBill = type;
    RestHelper.put(
      `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractAccountId}`,
      personalData,
      RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
    );
  });
};

export function prefillOko({ companyCode, subscriptionInvitationId }) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/communication/v0/public/subscriptionInvitation?companyCode=${companyCode}&subscriptionInvitationId=${subscriptionInvitationId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.anonymMR)
  );
}
