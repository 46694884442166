class Unauthenticated extends Error {
  toRedirect: boolean;

  loginType: string;

  constructor(message?: string) {
    super(message);
    this.name = 'FunkeUnauthenticated';
    this.toRedirect = true;
    this.loginType = '';
  }
}
export default Unauthenticated;
