import { getDevices, getHanDevices, getDeviceData } from '../api/deviceApi';
import { fixMonth } from '../components/ConsumptionVisSharedComponents/VisualizationDateRange/ReadingDateRange';
import { isDateBefore } from '../components/ConsumptionVisSharedComponents/VisualizationDateRange/date-validation';
import SessionHandler from '../utils/SessionHandler';
import Router from '../../utils/Router';
import { getContractSpecificItems } from './rlm';
import CacheHandler from '../../utils/CacheHandler';

// Actions
const LOAD_CURRENT_DEVICES = 'api/reducer/GET_DEVICES';
const SET_SELECTED_DEVICE = 'api/reducer/DEVICE';
const LOAD_CURRENT_DEVICE_DATA = 'api/reducer/GET_DEVICE_DATA';
const LOAD_CURRENT_DEVICE_DATA_STARTED = 'api/reducer/LOAD_CURRENT_DEVICE_DATA_STARTED';
const LOAD_CURRENT_DEVICE_DATA_FAILURE = 'api/reducer/LOAD_CURRENT_DEVICE_DATA_FAILURE';
const SET_DEVICE_DATE = 'api/reducer/DEVICE_DATE';
const SET_START_TIME = 'api/reducer/START_TIME';

export const getDeviceId = (device) => `${device.serialNumber}-${device.contractAccount}-${device.obis}`;
export const getDeviceIndex = (devices, deviceId) => (devices && devices.find ? devices.findIndex((elem) => getDeviceId(elem) === deviceId) : null);
export const getDevice = (devices, deviceId) => (devices && devices.find ? devices.find((elem) => getDeviceId(elem) === deviceId) : null);

export const isNotValid = (devices) =>
  Array.from(
    devices.map(
      (device) =>
        device.dateRanges &&
        device.dateRanges.length > 0 &&
        isDateBefore(
          fixMonth(device.dateRanges[device.dateRanges.length - 1].valid && device.dateRanges[device.dateRanges.length - 1].valid.to),
          new Date()
        )
    )
  );

function setPermissionByDevice(contractId) {
  const dataCachedContracts = CacheHandler.getCurrentCacheByToken('contractaccounts');
  if (dataCachedContracts && dataCachedContracts.data) {
    SessionHandler.setPermissions(dataCachedContracts.data.content, contractId);
  }
}

// Reducer
export default function reducer(state = {}, action) {
  const getPreselectedDevice = (devices) => {
    const id = Router.getParameter('deviceId');
    if (id) {
      return id;
    }
    if (devices[0]) {
      Router.setParameter({ deviceId: getDeviceId(devices[0]) });
      return getDeviceId(devices[0]);
    }
    return null;
  };
  switch (action.type) {
    case LOAD_CURRENT_DEVICES:
      // set permission depending on device, avoid wrong default contract mismatching to the device
      if (action.data.innerData && action.data.innerData[0]) {
        setPermissionByDevice(action.data.innerData[0].contractAccount);
      }
      return {
        ...state,
        devicesList: action.data.innerData,
        deviceValidStatus: isNotValid(action.data.innerData),
        deviceId: getPreselectedDevice(action.data.innerData),
      };
    case SET_SELECTED_DEVICE:
      Router.setParameter({ deviceId: action.data.deviceId });
      return {
        ...state,
        deviceId: action.data.deviceId,
      };
    case LOAD_CURRENT_DEVICE_DATA:
      return {
        ...state,
        deviceData: [action.data.innerData],
      };
    case SET_DEVICE_DATE:
      return {
        ...state,
        selectedStartDate: action.data.dateFrom,
        selectedEndDate: action.data.dateTo,
      };
    case SET_START_TIME:
      return {
        ...state,
        measurementData: {
          interval: state.deviceData[0].intervalSec * 1000,
          standardInterval: state.deviceData[0].intervalSec * 1000,
          unit: state.deviceData[0].unit,
          contractSpecificValues: getContractSpecificItems(state.deviceData, 'smartmeter'),
        },
        deviceDataProcceding: false,
        apiErrorCode: null,
      };
    case LOAD_CURRENT_DEVICE_DATA_FAILURE:
      return {
        ...state,
        deviceDataProcceding: false,
        apiErrorCode: action.data.error,
      };
    case LOAD_CURRENT_DEVICE_DATA_STARTED:
      return {
        ...state,
        deviceDataProcceding: true,
      };
    default:
      return state;
  }
}

// Action Creators
export function loadCurrent(dispatch, disabledHanDeviceOnly) {
  const resp = disabledHanDeviceOnly ? getDevices() : getHanDevices();

  return resp
    .then((reponse) => {
      dispatch({
        type: LOAD_CURRENT_DEVICES,
        data: {
          innerData: reponse.data ? reponse.data.content : {},
        },
      });
    })
    .catch((err) => {
      let errorCode;
      if (err?.response?.data?.errors[0] && err.response.data.errors[0].code) {
        errorCode = err.response.data.errors[0].code.split('.').pop();
      }
      dispatch({
        type: LOAD_CURRENT_DEVICE_DATA_FAILURE,
        data: {
          error: errorCode,
        },
      });
    });
}

export function setSelectedDevice(dispatch, deviceId, contractId) {
  SessionHandler.resetPermissions();

  if (contractId) {
    setPermissionByDevice(contractId);
  }

  dispatch({
    type: SET_SELECTED_DEVICE,
    data: {
      deviceId,
    },
  });
}

export function loadDeviceData(dispatch, serialNumber, obises, dateFrom, dateTo) {
  const resp = getDeviceData(serialNumber, obises, dateFrom, dateTo);
  dispatch({
    type: LOAD_CURRENT_DEVICE_DATA_STARTED,
  });
  return resp
    .then((response) => {
      dispatch({
        type: LOAD_CURRENT_DEVICE_DATA,
        data: {
          innerData: response.data,
        },
      });
    })
    .catch((err) => {
      let errorCode;
      if (err.response.data.errors[0] && err.response.data.errors[0].code) {
        errorCode = err.response.data.errors[0].code.split('.').pop();
      }
      dispatch({
        type: LOAD_CURRENT_DEVICE_DATA_FAILURE,
        data: {
          error: errorCode,
        },
      });
    });
}

export function setDeviceDate(dispatch, dateFrom, dateTo) {
  dispatch({
    type: SET_DEVICE_DATE,
    data: {
      dateTo,
      dateFrom,
    },
  });
}

export function setMeasurementTimeList(dispatch, time) {
  dispatch({
    type: SET_START_TIME,
    data: {
      time,
    },
  });
}
