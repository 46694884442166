var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
var VALID_FORMATS = ['DD.MM.YYYY', 'DD.MM.YY', 'DDMMYY', 'DDMMYYYY'];
var getDisplayedElement = function (row, selector) {
    var array = row.getElementsByTagName(selector);
    var newList = [];
    for (var i in array) {
        if (array[i] && array[i] instanceof Element) {
            var style = document.defaultView.getComputedStyle(array[i], null);
            if (style.display !== 'none') {
                newList.push(array[i]);
            }
        }
    }
    return newList;
};
var addRemoveClass = function (thList, col, dir) {
    for (var i = 0; i < thList.length; i++) {
        if (thList[i].classList.contains('sort-down')) {
            thList[i].classList.remove('sort-down');
        }
        if (thList[i].classList.contains('sort-up')) {
            thList[i].classList.remove('sort-up');
        }
        if (col === i) {
            thList[i].classList.add(dir ? 'sort-down' : 'sort-up');
        }
    }
};
var getColspanSum = function (th) {
    var colSpan = 0;
    for (var i in th) {
        if (th[i] && th[i] instanceof Element) {
            colSpan += th[i].colSpan;
        }
    }
    return colSpan;
};
var checkDataIsDate = function (value) {
    moment.locale('de');
    var result = value;
    var tempVal = value.split(' - ');
    if (moment(tempVal[0], __spreadArray(__spreadArray([], VALID_FORMATS, true), ['MM/YYYY', 'M/YYYY'], false), true).isValid()) {
        result = moment(tempVal[0], ['DD.MM.YYYY', 'MM/YYYY', 'M/YYYY']).unix().toString();
    }
    return result;
};
var compareValues = function (a, b) {
    var aVal = checkDataIsDate(a);
    var bVal = checkDataIsDate(b);
    if (/^\d+(?:[,.]\d+)?$/.exec(aVal) && /^\d+(?:[,.]\d+)?$/.exec(bVal)) {
        aVal = aVal.replace(/\./g, '').replace(/,/g, '.');
        bVal = bVal.replace(/\./g, '').replace(/,/g, '.');
        return parseFloat(aVal) - parseFloat(bVal);
    }
    if (aVal < bVal) {
        return -1;
    }
    else if (aVal > bVal) {
        return 1;
    }
    return 0;
};
var sortTable = function (col, reverse, tableId) {
    var table = document.getElementById(tableId);
    var i;
    var tempReverse = reverse;
    tempReverse = -(+tempReverse || -1);
    if (table.classList.contains('expandableRowTable') && table.tBodies.length > 1) {
        var tBodies = Array.prototype.slice.call(table.tBodies);
        tBodies.sort(function (a, b) {
            var aVal = getDisplayedElement(a.rows[0], 'td')[col];
            var bVal = getDisplayedElement(b.rows[0], 'td')[col];
            if (!(aVal instanceof Element) || !(bVal instanceof Element)) {
                return 0;
            }
            return (tempReverse *
                compareValues(aVal.innerText !== '' ? aVal.innerText.trim() : aVal.innerHTML.trim(), bVal.innerText !== '' ? bVal.innerText.trim() : aVal.innerHTML.trim()));
        });
        for (i = 0; i < tBodies.length; ++i) {
            table.appendChild(tBodies[i]);
        }
    }
    else {
        var tb = table.tBodies[0];
        var tr = Array.prototype.slice.call(tb.rows, 0);
        tr = tr.sort(function (a, b) {
            var aVal = getDisplayedElement(a, 'td')[col];
            var bVal = getDisplayedElement(b, 'td')[col];
            if (!(aVal instanceof Element) || !(bVal instanceof Element)) {
                return 0;
            }
            return (tempReverse *
                compareValues(aVal.innerText !== '' ? aVal.innerText.trim() : aVal.innerHTML.trim(), bVal.innerText !== '' ? bVal.innerText.trim() : aVal.innerHTML.trim()));
        });
        for (i = 0; i < tr.length; ++i) {
            tb.appendChild(tr[i]);
        }
    }
};
var Table = (function (_super) {
    __extends(Table, _super);
    function Table() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Table.prototype.componentDidMount = function () {
        if (this.props.sorted) {
            this.makeSortable();
        }
    };
    Table.prototype.componentDidUpdate = function () {
        if (this.props.sorted) {
            this.makeSortable();
        }
    };
    Table.prototype.makeSortable = function () {
        var table = document.getElementById(this.props.id);
        if (!table) {
            return;
        }
        var th = table.tHead;
        var i;
        var tableId = this.props.id;
        th = th && (th = th.rows[0]) && (th = th.cells);
        if (th) {
            i = th.length;
        }
        else {
            return;
        }
        var colSpanTotal = getColspanSum(th);
        function runSort(j, id, thL) {
            if (th[i] && th[i] instanceof Element && th[i].innerText !== '') {
                var dir_1 = 1;
                var colSpan = th[i + 1] ? th[i + 1].colSpan : 1;
                var calculatedIndex_1 = i + (colSpanTotal - th.length - (colSpan - 1));
                if (th[i].getElementsByClassName('sort-icon').length === 0) {
                    var newSpan = document.createElement('span');
                    newSpan.classList.add('sort-icon');
                    th[i].appendChild(newSpan);
                }
                if (!th[i].classList.contains('sortable')) {
                    thL[i].classList.add('sortable');
                }
                th[i].addEventListener('click', function () {
                    addRemoveClass(thL, j, dir_1);
                    sortTable(calculatedIndex_1, (dir_1 = 1 - dir_1), id);
                });
            }
            if (--i >= 0) {
                runSort(i, tableId, th);
            }
        }
        runSort(i, tableId, th);
    };
    Table.prototype.render = function () {
        var _a = this.props, className = _a.className, size = _a.size, bordered = _a.bordered, striped = _a.striped, inverse = _a.inverse, hover = _a.hover, reflow = _a.reflow, responsive = _a.responsive, _b = _a.tag, Tag = _b === void 0 ? 'table' : _b, _c = _a.responsiveTag, ResponsiveTag = _c === void 0 ? 'div' : _c, _d = _a.sorted, sorted = _d === void 0 ? false : _d, attributes = __rest(_a, ["className", "size", "bordered", "striped", "inverse", "hover", "reflow", "responsive", "tag", "responsiveTag", "sorted"]);
        var classes = classNames(className, 'table', size ? 'table-' + size : false, bordered ? 'table-bordered' : false, striped ? 'table-striped' : false, inverse ? 'table-inverse' : false, hover ? 'table-hover' : false, reflow ? 'table-reflow' : false, sorted ? 'table-sorted' : false);
        var table = React.createElement(Tag, __assign({}, attributes, { className: classes }));
        if (responsive) {
            return React.createElement(ResponsiveTag, { className: "table-responsive" }, table);
        }
        return table;
    };
    return Table;
}(React.Component));
Table.displayName = 'Table';
export default Table;
