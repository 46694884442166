import React, { useEffect, useState } from 'react';
import { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { Button, Loader } from '@eon-funke/react-shared-ui-next';
import { ErrorHintArgsTypes, MeterReadingErrorObjectType, MeterRegisterLabelComponentLabels } from '../../components/MeterRead/MeterReadingTypes';
import { checkMeterLockedByImplausibleReadingResult, MeterReadingReducerStateTypes } from '../../redux/meterReading';
import MeterReadingForm from '../../components/MeterRead/Anonym/MeterReadingForm/MeterReadingForm';
import MeterCounter from '../../components/MeterRead/Anonym/MeterReadingGeneric/MeterCounter';
import MeterInformationBox from '../../components/MeterRead/Anonym/MeterReadingGeneric/MeterInformationBox';
import MeterReadingGeneric from '../../components/MeterRead/Anonym/MeterReadingGeneric/MeterReadingGeneric';
import NoRegisterError from '../../components/MeterRead/Anonym/NoRegisterError/NoRegisterError';
import MeterReadThanks from '../../components/MeterRead/Anonym/MeterReadThanks/MeterReadThanks';
import MeterReadingInfoMessageDisplay from '../../components/MeterRead/Core/MeterReadInfoMessageDisplay/MeterReadingInfoMessageDisplay';
import {
  allMetersLockedByImplausibleReadingResults,
  buildButtonControl,
  isMeterLocked,
} from '../../components/MeterRead/Anonym/MeterReadingForm/meterReadingFormHelper';
import { meterReadingPageLabelPropTypes } from '../../components/MeterRead/MeterReadingPropTypes';
import SessionHandler, { loginTypes } from '../../../utils/SessionHandler';
import { loadCurrent, meterReadError, paginate, setCurrentStep } from '../../actions/meterReading/meterReadingMainActions';
import StepComponent from '../../components/StepComponent/StepComponent';
import MeterReadingEmailFeedback, {
  EmailFeedbackStatusEnum,
} from '../../components/MeterRead/Anonym/MeterReadingEmailFeedback/MeterReadingEmailFeedback';
import MeterReadingRegister from '../../components/MeterRead/Anonym/MeterReadingRegister/MeterReadingRegister';

export const MeterReadingWizardStepEnum = {
  READING: 'reading',
  EMAIL_FEEDBACK: 'email-feedback',
  REGISTER: 'register',
  FINISHED: 'finished',
};

export type MeterReadingPageTypes = {
  loadMeterReadingData: (currentPage: number) => void;
  paginate: (pageId: number) => void;
  setCurrentStep: (currentStep: string) => void;
  meterReadError: (errorObjects: MeterReadingErrorObjectType[]) => void;
};

type MeterReadingPageState = {
  feedbackEmail: string;
  emailFeedbackStatus: string;
  okoEmail: string;
};

export type MeterReadingPageProps = MeterReadingPageTypes &
  MeterReadingReducerStateTypes &
  MeterRegisterLabelComponentLabels &
  InferProps<typeof meterReadingPageLabelPropTypes>;

const MeterReadingPage = (props: MeterReadingPageProps) => {
  const [lockedMeterCount, setLockedMeterCount] = useState<number>(0);

  const [state, setState] = useState<MeterReadingPageState>({
    feedbackEmail: '',
    emailFeedbackStatus: EmailFeedbackStatusEnum.PENDING,
    okoEmail: '',
  });

  const stringConverter = (str) => {
    if (str === 'true') return true;
    return false;
  };

  const canSeeRegister = stringConverter(sessionStorage.getItem('anonymousOkoAllowed'));

  const paginateWithCurrentPageId = () => {
    props.paginate(props.currentPageId);
  };

  useEffect(() => {
    SessionHandler.userMustBeLoggedIn(loginTypes.anonym);
    props.loadMeterReadingData(0);
  }, []);

  const generateStepLabels = () => {
    const stepLabelsArray = [
      {
        label: props.stepComponent.meterValueLabel,
        step: MeterReadingWizardStepEnum.READING,
      },
      {
        label: props.stepComponent.emailConfirm,
        step: MeterReadingWizardStepEnum.EMAIL_FEEDBACK,
      },
      {
        label: props.stepComponent.register,
        step: MeterReadingWizardStepEnum.REGISTER,
      },
      {
        label: props.stepComponent.done,
        step: MeterReadingWizardStepEnum.FINISHED,
      },
    ];

    if (!canSeeRegister) stepLabelsArray.splice(2, 1);

    return stepLabelsArray;
  };

  if (props.loading) {
    return (
      <div
        className='row'
        key='0'
      >
        <div
          className='col-md-12 col-xs-12 col-sm-12 form-wrapper form-wrapper--has-shadow ano-meter-reading'
          key='0'
        >
          <Loader
            loading
            fallbackGifSrc={sessionStorage.fallbackLoader}
          />
        </div>
      </div>
    );
  }

  if (props.errorHint) {
    const errorArgs: ErrorHintArgsTypes = props.errorHint.args;
    if (errorArgs && errorArgs.infoMessages && errorArgs.infoMessages.length) {
      return (
        <MeterReadingInfoMessageDisplay
          {...props}
          errorArgs={errorArgs}
        />
      );
    }
  }

  const allMetersLocked: boolean = allMetersLockedByImplausibleReadingResults(props.devices);

  const buildMeterView = (device, deviceIndex) => {
    const meterIsLocked = checkMeterLockedByImplausibleReadingResult(device);

    if (meterIsLocked) SessionHandler.destroySession(false);

    const isLastMeter = () => {
      return props.devices.length - 1 === Math.abs(props.currentPageId);
    };

    const submitButtonLabel: string = buildButtonControl(
      props.continueButtonText,
      props.submitButtonText,
      props.saveAndContinueButtonText,
      isLastMeter(),
      isMeterLocked(props.devices[props.currentPageId])
    );

    const meterCounter = (
      <MeterCounter
        key={`counter, ${deviceIndex}`}
        meterCounterLabel={props.meterCounterLabel ? props.meterCounterLabel : ''}
        index={deviceIndex + 1}
        amount={props.devices?.length}
        hasError={props.errorObjects && props.errorObjects.length > 0 && !props.errorObjects.find((x) => x.serialNumber === device.serialNumber)}
      />
    );

    return (
      <div className='col-md-12 col-xs-12 col-sm-12'>
        {props.currentStep === MeterReadingWizardStepEnum.READING && (
          <>
            {isMeterLocked(device) ? (
              <div className='ano-meter-reading__hint-text'>
                {!allMetersLocked ? (
                  <>
                    {meterCounter}
                    <MeterInformationBox
                      energyTypeLabel={props.energyTypeLabel ? props.energyTypeLabel : ''}
                      deviceNumberLabel={props.deviceNumberLabel ? props.deviceNumberLabel : ''}
                      lastReadingDateLabel={props.lastReadingDateLabel ? props.lastReadingDateLabel : ''}
                      newMeterReadingDate=''
                      editButton={false}
                      deviceData={device}
                      paginate={paginateWithCurrentPageId}
                    />
                    <p className='ano-meter-reading__locked-text'>{props.currentMeterLockedText}</p>
                    <Button
                      onClick={() => {
                        if (isLastMeter()) {
                          setLockedMeterCount(lockedMeterCount + 1);
                          props.setCurrentStep(MeterReadingWizardStepEnum.EMAIL_FEEDBACK);
                        } else {
                          setLockedMeterCount(lockedMeterCount + 1);
                          props.paginate(props.currentPageId + 1);
                        }
                      }}
                      version={props.versions.button}
                      qaId='meter-reading-button-next'
                      className='meter-reading-button-next'
                    >
                      {submitButtonLabel}
                    </Button>
                  </>
                ) : (
                  <>
                    <MeterReadingInfoMessageDisplay
                      {...props}
                      allMetersLocked={allMetersLocked}
                      allMetersLockedText={props.allMetersLockedText}
                    />
                  </>
                )}
              </div>
            ) : (
              <div>
                {meterCounter}
                <MeterInformationBox
                  energyTypeLabel={props.energyTypeLabel ? props.energyTypeLabel : ''}
                  deviceNumberLabel={props.deviceNumberLabel ? props.deviceNumberLabel : ''}
                  lastReadingDateLabel={props.lastReadingDateLabel ? props.lastReadingDateLabel : ''}
                  newMeterReadingDate=''
                  editButton={false}
                  deviceData={device}
                  paginate={paginateWithCurrentPageId}
                />
                <MeterReadingForm
                  {...props}
                  buttonLabel={submitButtonLabel}
                  currentPageId={props.currentPageId}
                  paginate={props.paginate}
                  loading={props.loading}
                  loggedIn={false}
                  deviceData={device}
                  lastMeter={isLastMeter()}
                  setCurrentStep={props.setCurrentStep}
                  nextStep={MeterReadingWizardStepEnum.EMAIL_FEEDBACK}
                />
              </div>
            )}
          </>
        )}

        {props.currentStep === MeterReadingWizardStepEnum.EMAIL_FEEDBACK && (
          <div className='col-md-12 form-wrapper ano-meter-reading'>
            <MeterReadingEmailFeedback
              {...props.emailFeedback}
              emailValidationError={props.emailValidationError}
              emailAddressLabel={props.emailAddressLabel}
              serverErrorMessage={props.serverErrorMessage}
              registerCount={props.devices.length}
              missedMeter={lockedMeterCount}
              amount={props.devices.length}
              onSuccess={(popState, email) => {
                if (canSeeRegister) {
                  props.setCurrentStep(MeterReadingWizardStepEnum.REGISTER);
                } else {
                  props.setCurrentStep(MeterReadingWizardStepEnum.FINISHED);
                }
                setState({
                  ...state,
                  emailFeedbackStatus: popState,
                  feedbackEmail: email,
                });
              }}
            />
          </div>
        )}

        {props.currentStep === MeterReadingWizardStepEnum.REGISTER && (
          <div className='col-md-12 form-wrapper ano-meter-reading'>
            <MeterReadingRegister
              {...props}
              {...props.register}
              emailValidationError={props.emailValidationError}
              serverErrorMessage={props.serverErrorMessage}
              emailAddressLabel={props.emailAddressLabel}
              onNextStep={(registerPage: boolean) => {
                if (registerPage) {
                  window.open(props.registrationButtonLink, '_blank');
                }
                props.setCurrentStep(MeterReadingWizardStepEnum.FINISHED);
              }}
              onOkoSuccess={(okoEmail: string) => {
                setState({
                  ...state,
                  okoEmail,
                });
                props.setCurrentStep(MeterReadingWizardStepEnum.FINISHED);
              }}
            />
          </div>
        )}

        {props.currentStep === MeterReadingWizardStepEnum.FINISHED && (
          <div className='col-md-12 form-wrapper ano-meter-reading'>
            <MeterReadThanks
              {...props.thanksMsg}
              amount={props.devices.length}
              missedMeter={lockedMeterCount}
              emailFeedbackState={state.emailFeedbackStatus}
              feedbackEmail={state.feedbackEmail}
              okoEmail={state.okoEmail}
              returnUrl={props.anonymousMeterReadingLoginPage}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {props.devices && props.devices.length !== 0 ? (
        <div className='col-md-12 col-xs-12 col-sm-12'>
          <div className='row'>
            <div className={!allMetersLocked ? 'col-md-12 col-xs-12 col-sm-12 form-wrapper form-wrapper--has-shadow ano-meter-reading' : ''}>
              {!allMetersLocked && (
                <>
                  <MeterReadingGeneric
                    genericTitle={props.genericTitle ? props.genericTitle : ''}
                    contractIdLabel={props.contractIdLabel ? props.contractIdLabel : ''}
                    addressLabel={props.addressLabel ? props.addressLabel : ''}
                    deviceData={props.devices[props.currentPageId]}
                  />
                  <StepComponent
                    {...props.stepComponent}
                    currentStep={props.currentStep}
                    stepLabels={generateStepLabels()}
                  />
                </>
              )}

              {buildMeterView(props.devices[props.currentPageId], props.currentPageId)}
            </div>
          </div>
        </div>
      ) : (
        <div className='col-md-12 form-wrapper form-wrapper--has-shadow ano-meter-reading'>
          <MeterReadingGeneric
            genericTitle={props.genericTitle ? props.genericTitle : ''}
            contractIdLabel={props.contractIdLabel ? props.contractIdLabel : ''}
            addressLabel={props.addressLabel ? props.addressLabel : ''}
          />
          <NoRegisterError
            {...props}
            isSmallText={false}
          />
        </div>
      )}
    </>
  );
};

type MeterReadingReducerMapStateTypes = {
  meterReading: MeterReadingReducerStateTypes;
};

function mapStateToProps(state: MeterReadingReducerMapStateTypes): Partial<MeterReadingPageProps> {
  return {
    devices: state.meterReading.devices,
    currentPageId: state.meterReading.currentPageId,
    currentStep: state.meterReading.currentStep,
    errorObjects: state.meterReading.errorObjects,
    errorHint: state.meterReading.errorHint,
    loading: state.meterReading.loading,
  };
}

function mapDispatchToProps(dispatch): Partial<MeterReadingPageProps> {
  return {
    loadMeterReadingData: (currentPage) => loadCurrent(dispatch, currentPage),
    paginate: (currentPage) => paginate(dispatch, currentPage),
    setCurrentStep: (currentStep) => setCurrentStep(dispatch, currentStep),
    meterReadError: (errorObj) => meterReadError(dispatch, errorObj),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterReadingPage);
