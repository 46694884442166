import { PhoneNumberType } from '../../pages/PhoneNumberPage/PhoneNumberTypes';
import { addPhoneNumber, deletePhoneNumber, editPhoneNumbers, getPhoneNumbers } from '../../../api-factory/phoneNumbersApi';

export enum PhoneNumberChangeEvent {
  ADD = 'add',
  EDIT = 'edit',
  REMOVE = 'remove',
  LOAD = 'load',
  NONE = 'none',
}

export enum PhoneNumberActions {
  HAS_API_ERROR = 'HAS_API_ERROR',
  LOAD_PHONE_NUMBERS = 'LOAD_PHONE_NUMBERS',
  IS_LOADING = 'IS_LOADING',
  IS_EDITING = 'IS_EDITING',
  FINISHED_EDITING = 'FINISHED_EDITING',
  RESET_API_ERROR = 'RESET_API_ERROR',
  RESET_CHANGE_EVENT_TYPE = 'RESET_CHANGE_EVENT_TYPE',
}

export type PhoneNumberActionTypes = {
  type: PhoneNumberActions;
  phoneNumbers?: PhoneNumberType[];
  processedPhoneNumber?: PhoneNumberType;
  changeEvent?: PhoneNumberChangeEvent;
  apiErrorMessage?: string;
};

export function getPhoneNumbersAction(dispatch, contractId: string) {
  setLoading(dispatch, PhoneNumberChangeEvent.LOAD);
  getAllPhoneNumbersOfContractId(dispatch, contractId);
}

export function addPhoneNumberAction(dispatch, phoneNumberObject: PhoneNumberType, contractId: string) {
  setLoading(dispatch, PhoneNumberChangeEvent.ADD);
  return new Promise((resolve, reject) => {
    addPhoneNumber(phoneNumberObject, contractId)
      .then((response) => {
        handleResponse(dispatch, response, contractId);
        resolve(response);
      })
      .catch((error) => {
        setHasApiError(dispatch, error.message);
        reject(error);
      });
  });
}

export function editPhoneNumberAction(dispatch, phoneNumberObject: PhoneNumberType, contractId: string) {
  setLoading(dispatch, PhoneNumberChangeEvent.EDIT);
  return new Promise((resolve, reject) => {
    editPhoneNumbers(phoneNumberObject, contractId)
      .then((response) => {
        handleResponse(dispatch, response, contractId);
        resolve(response);
      })
      .catch((error) => {
        setHasApiError(dispatch, error.message);
        reject(error);
      });
  });
}

export function removePhoneNumberAction(dispatch, phoneNumberObject: PhoneNumberType, contractId: string) {
  setLoading(dispatch, PhoneNumberChangeEvent.REMOVE);
  deletePhoneNumber(phoneNumberObject, contractId)
    .then((response) => {
      handleResponse(dispatch, response, contractId);
    })
    .catch((error) => {
      setHasApiError(dispatch, error.message);
    });
}

export function resetApiErrorAction(dispatch) {
  dispatch({
    type: PhoneNumberActions.RESET_API_ERROR,
  });
}

function getAllPhoneNumbersOfContractId(dispatch, contractId: string) {
  dispatch({
    type: PhoneNumberActions.IS_LOADING,
  });
  getPhoneNumbers(contractId)
    .then((response) => {
      dispatch({
        type: PhoneNumberActions.LOAD_PHONE_NUMBERS,
        phoneNumbers: response.data.content,
      });
    })
    .catch((error) => {
      setHasApiError(dispatch, error.message);
    });
}

function setLoading(dispatch, changeEvent: PhoneNumberChangeEvent) {
  dispatch({
    type: PhoneNumberActions.IS_EDITING,
    changeEvent,
  });
}

function setHasApiError(dispatch, apiErrorMessage: string) {
  dispatch({
    type: PhoneNumberActions.HAS_API_ERROR,
    apiErrorMessage,
  });
}

function handleResponse(dispatch, response: any, contractId: string) {
  dispatch({
    type: PhoneNumberActions.FINISHED_EDITING,
    processedPhoneNumber: response.data,
  });
  getAllPhoneNumbersOfContractId(dispatch, contractId);
}
