import classNames from 'classnames';
import * as React from 'react';
import { WithEnvInformation } from '../EnvInformation/EnvInformation';
var BASE_CLASS = 'loader-v2';
export var Loader = function (_a) {
    var children = _a.children, loading = _a.loading, fallbackGifSrc = _a.fallbackGifSrc, isFallbackBrowser = _a.isFallbackBrowser, qaId = _a.qaId;
    var containerClasses = classNames("".concat(BASE_CLASS, "-container"), loading ? "".concat(BASE_CLASS, "-container--loading") : "".concat(BASE_CLASS, "-container--loaded"));
    var loaderClasses = classNames("".concat(BASE_CLASS, "__cover"), loading ? "".concat(BASE_CLASS, "__cover--visible") : "".concat(BASE_CLASS, "__cover--hidden"));
    var renderFallback = function () { return (React.createElement("img", { src: fallbackGifSrc, className: "loader__fallback-img", alt: "Loader" })); };
    var renderDefaultLoader = function () { return (React.createElement("svg", { className: "spinner-circular", viewBox: "25 25 50 50" },
        React.createElement("circle", { className: "loader__circle loader__circle loader__circle loader__circle--visible", cx: "50", cy: "50", r: "10", fill: "none", strokeWidth: "2", strokeMiterlimit: "0" }))); };
    return (React.createElement("div", { className: containerClasses, "data-qa": qaId },
        React.createElement("div", { className: loaderClasses },
            React.createElement("div", { className: BASE_CLASS }, !isFallbackBrowser ? renderDefaultLoader() : renderFallback())),
        children));
};
Loader.displayName = 'Loader';
export default Loader;
export var LoaderWithEnv = WithEnvInformation({
    getBrowserInfo: true,
    getDeviceInfo: true,
})(Loader);
