import { isCICContentTree } from '../mainConfig';
import {
  getMeterReadDate as getMeterReadDateCIC,
  sendEmailData as sendEmailDataCIC,
  activateOko as activateOkoCIC,
} from '../cic/api/meterReadingApi';
import { getMeterReadDate as getMeterReadDateCSCN, sendEmailData as sendEmailDataCSCN } from '../csc/api/meterReadingApi';

interface ApiSet {
  getMeterReadDate;
  sendEmailData;
  activateOko?;
}

const currentApiSet: ApiSet = isCICContentTree
  ? { getMeterReadDate: getMeterReadDateCIC, sendEmailData: sendEmailDataCIC, activateOko: activateOkoCIC }
  : { getMeterReadDate: getMeterReadDateCSCN, sendEmailData: sendEmailDataCSCN };
export const { getMeterReadDate, sendEmailData, activateOko } = currentApiSet;

export default currentApiSet;
