import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';

const API_CONSTANT = '/api/partner/v0/invoices';

const getInvoices = (contractId) => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}?contractAccountId=${contractId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
};

function getInvoicePdf(invoiceId, contractAccountId) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${API_CONSTANT}/${invoiceId}?contractAccountId=${contractAccountId}`,
    {},
    RestHelper.setXClientHeader({ responseType: 'blob' }, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export default { getInvoices, getInvoicePdf };
