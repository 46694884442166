import React from 'react';
import { Alert } from '@eon-funke/react-shared-ui-next';
import GlobalErrorHandler from '../../utils/GlobalErrorHandler';

// NOTE: real err should be logged somewhere
export default function ErrorFallBackComponent() {
  return (
    <Alert type='error' id='cscn-general-error' qaId='cscn-general-error'>
      {GlobalErrorHandler.getErrorMessage('serverError')}
    </Alert>
  );
}
