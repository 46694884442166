import qs from 'qs';

function parseParams(str: string): any {
  return str
    .replace('#', '')
    .split('&')
    .reduce((params, param) => {
      const copypar = params;
      const paramSplit = param.split('=').map((value) => decodeURIComponent(value.replace('+', ' ')));
      const param1 = paramSplit[1];
      copypar[paramSplit[0]] = param1;
      return copypar;
    }, {});
}
export function getQParameterByName(name: string, url: string = window.location.href): string | null {
  const regex = new RegExp(`[?&]${name.replace(/[[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
class Router {
  static redirect(pageName: string): void {
    location.href = pageName;
  }

  static setParamters(params: any): void {
    const newHash = `#${qs.stringify({ ...this.getAllParameters(), ...params })}`;
    if (history.pushState) {
      history.pushState(null, null, newHash);
    } else {
      location.hash = newHash;
    }
  }

  static setParameter(param: any): boolean {
    const params = this.getAllParameters();

    if (params === '') {
      this.setParamters(param);
    } else {
      params[Object.keys(param)[0]] = param[Object.keys(param)[0]];
      this.setParamters(params);
    }
    return true;
  }

  static getParameter(name: string): any {
    const params = window.location.hash ? parseParams(window.location.hash) : {};
    return params[name];
  }

  static getAllParameters(): any {
    const params = window.location.hash !== '' ? qs.parse(window.location.hash.split('#')[1]) : '';
    return params;
  }

  static createUrl(uri: string, params?: any): string {
    if (params && Object.keys(params).length > 0) {
      const url = uri || '';
      return `${url}#${qs.stringify(params)}`;
    }
    return uri;
  }

  static encodeDataUrl(data: string): string {
    return Object.keys(data)
      .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
      .join('&');
  }

  static parseQueryParam(paramString: string): {} {
    const returnObject = {};
    paramString.split('&').forEach((elem) => {
      const fragments = elem.split('=');
      const fragment1 = fragments[1];
      returnObject[fragments[0]] = fragment1;
    });
    return returnObject;
  }

  static getUrlParameter(sParam: string): string {
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');
    let parameter = '';
    for (let i = 0; i < sURLVariables.length; i += 1) {
      const sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        const param1 = sParameterName[1];
        parameter = param1;
        return decodeURIComponent(sParameterName[1]);
      }
    }
    return parameter;
  }
}
export default Router;
