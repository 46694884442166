import { getInvoices } from '../api/invoiceApi';

// Actions
const LOAD_ALL_INVOICES = 'api/reducer/ALL_INVOICES';
const CURRENT_REQUEST = 'api/reducer/CURRENT_REQUEST';

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_ALL_INVOICES:
      return {
        ...state,
        data: action.data.innerData,
      };
    case CURRENT_REQUEST:
      return {
        ...state,
        currentRequest: {
          cancel: action.data.source?.cancel,
          token: {
            reason: 'New request was sent',
          },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const loadAllInvoices = (dispatch, contractId) => {
  const resp = getInvoices(contractId);
  dispatch({
    type: CURRENT_REQUEST,
    data: {
      source: resp?.source,
    },
  });
  return resp
    .then((response) => {
      dispatch({
        type: LOAD_ALL_INVOICES,
        data: {
          innerData: response.data,
        },
      });
    })
    .catch((error) => {
      if (error.type !== 'cancel') {
        dispatch({
          type: LOAD_ALL_INVOICES,
          data: {
            innerData: null,
          },
        });
      } else {
        throw error;
      }
    });
};
