import axios from 'axios';
import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';
import CONFIG from '../config/config';

export function getContracts(token) {
  const config = token ? { headers: { 'X-Ciam-Token': token } } : {};
  const auth = !token;
  return CacheHandler.requestCache(
    () =>
      RestHelper.get(
        `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/contractaccounts?projection=withBusinessPartner`,
        {},
        RestHelper.setXClientHeader(config, CONFIG.CHANNEL_CONFIG.portal),
        true,
        auth
      ),
    'contractaccounts'
  );
}

export function getContractConsumptions(contractAccountId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/consumption/v0/contractconsumptions?contractAccountId=${contractAccountId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function getContractConsumptionDetails(contractAccountId, contractId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/meterreading/v0/readingresults?contractAccountId=${contractAccountId}&contractId=${contractId}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function getContractAdditionalDetails(contractAccountId) {
  const response = CacheHandler.requestCache(() => {
    return RestHelper.get(
      `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/contracts?contractAccountId=${contractAccountId}`,
      {},
      RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
    );
  }, `additionalDetails_${contractAccountId}`);
  return {
    request: new Promise((resolve, reject) => {
      response
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((err) => {
          const error = err;
          if (axios.isCancel(error)) {
            error.type = 'cancel';
          }
          reject(error);
        });
    }),
  };
}

export function getHanAccessDetails(contractAccountId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}/api/smartmeter/v0/contractaccounts/${contractAccountId}/gatewayaccount`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export default { getContracts, getHanAccessDetails, getContractAdditionalDetails, getContractConsumptionDetails, getContractConsumptions };
