import RestHelper from '../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';

export function getCurrentBudgetBillingPlan(contractAccountId) {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/budgetbillingplans`);
}

export function getBudgetBillingPlan(contractAccountId) {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/budgetbillingplans`);
}

export function getAdjustmentDetails(contractAccountId, billingPlanItemId) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/budgetbillingplans/${billingPlanItemId}`
  );
}

export const saveNewBudgetBillingPlan = (contractAccountId, billingPlanItemId, data) =>
  RestHelper.patch(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/contractaccounts/${contractAccountId}/budgetbillingplans/${billingPlanItemId}`,
    data
  )
    .then((response) => {
      CacheHandler.saveCache({ bbpSaveStatus: response.status });
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export default {
  getCurrentBudgetBillingPlan,
};
