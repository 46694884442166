import RestHelper from '../utils/RestHelper';
import config from '../config/config';
import SessionHandler, { loginTypes } from '../utils/SessionHandler';

const getChannelHeaderIfAnonym = () =>
  SessionHandler.getLoginType() === loginTypes.loggedIn
    ? {}
    : {
        headers: { 'X-Client': config.CHANNEL_CONFIG.anonymMR },
      };

export { getChannelHeaderIfAnonym };

export function getMeterReadingData() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/meters?filterBy=openReadingOrders&withInfoMessage=true`,
    {},
    getChannelHeaderIfAnonym()
  );
}

export function saveMeterReadingData(meterData) {
  return RestHelper.post(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/readingresults`, meterData, getChannelHeaderIfAnonym());
}

export function sendEmailData(emailData) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/readingresultconfirmation`,
    emailData,
    getChannelHeaderIfAnonym()
  );
}

export function getAllMeters() {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/meters`);
}

export function getMeterReadDate(contractAccountId) {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/futurereadings/${contractAccountId}`); // needs to add root source
}

export function getAllMeterPins() {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/meterpins`);
}

export function getMeterPinDetails(serialNumber) {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/meterreading/v0/meterpins/${serialNumber}`);
}
