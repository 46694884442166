var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
var Form = (function (_super) {
    __extends(Form, _super);
    function Form() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Form.prototype.findRequiredChild = function (attributes) {
        var _this = this;
        var arr = React.Children.map(attributes.children, function (child) {
            if (!!child && !!child.props && child.props.children) {
                return _this.findRequiredChild({ children: child.props.children });
            }
            return !!child && !!child.props && child.props.required;
        }).includes(true);
        return arr;
    };
    Form.prototype.renderRequiredHint = function (msg, requiredClassName) {
        return (React.createElement("p", { className: "form-message__mandatory ".concat(requiredClassName) },
            React.createElement("span", { className: "form-message__mandatory--asterisk" }, "*\u00A0"),
            React.createElement("span", { className: "form-message__mandatory--required" }, msg)));
    };
    Form.prototype.render = function () {
        var _a = this.props, className = _a.className, getRef = _a.getRef, hintMsg = _a.hintMsg, inline = _a.inline, _b = _a.requiredClassName, requiredClassName = _b === void 0 ? '' : _b, _c = _a.requiredMsg, requiredMsg = _c === void 0 ? 'Pflichtfelder' : _c, _d = _a.tag, Tag = _d === void 0 ? 'form' : _d, attributes = __rest(_a, ["className", "getRef", "hintMsg", "inline", "requiredClassName", "requiredMsg", "tag"]);
        var classes = classNames(className, inline ? 'form-inline' : false);
        var hasRequiredChild = !!attributes && this.findRequiredChild(attributes);
        return (React.createElement("div", null,
            React.createElement(Tag, __assign({}, attributes, { ref: getRef, className: classes })),
            !!hasRequiredChild && this.renderRequiredHint(requiredMsg, requiredClassName),
            !!hintMsg && React.createElement("span", { className: "form-message__hint text-micro" }, hintMsg)));
    };
    return Form;
}(React.Component));
export { Form };
Form.displayName = 'Form';
export default Form;
