import React from 'react';
var StandardLink = function (props) {
    var disabled = props.disabled, _a = props.className, className = _a === void 0 ? '' : _a, href = props.href, target = props.target, title = props.title, iconLeft = props.iconLeft, iconRight = props.iconRight, onClick = props.onClick, linkText = props.linkText;
    var handleOnClick = function (e) {
        if (disabled) {
            e.preventDefault();
            return;
        }
        if (onClick) {
            e.preventDefault();
            onClick(e);
        }
    };
    return (React.createElement("a", { title: title, className: "standard-link-v2 ".concat(className), target: target, href: href, rel: target === '_blank' ? 'noopener noreferrer' : '', onClick: handleOnClick },
        iconLeft && (React.createElement("span", { className: "standard-link-v2__icon standard-link-v2__icon-left" }, iconLeft)),
        React.createElement("span", { className: "standard-link-v2__text" }, linkText),
        iconRight && (React.createElement("span", { className: "standard-link-v2__icon standard-link-v2__icon-right" }, iconRight))));
};
StandardLink.displayName = 'StandardLink';
export default StandardLink;
