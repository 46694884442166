import axios from 'axios';
import CacheHandler from '../../utils/CacheHandler';
import RestHelper from '../utils/RestHelper';

export const getInvoices = (contractId) => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const config = { cancelToken: source.token };

  const response = CacheHandler.requestCache(() => {
    const baseURL = RestHelper.getBaseUrl();
    return RestHelper.get(`${baseURL}/cscn-customer-srv/api/partner/v0/invoices?contractAccountId=${contractId}`, {}, config);
  }, `invoices_${contractId}`);
  return new Promise((resolve, reject) => {
    response
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        const error = err;
        if (axios.isCancel(error)) {
          error.type = 'cancel';
        }
        reject(error);
      });
  });
};

export function getInvoicePdf(invoiceId, contractAccountId) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/invoices/${invoiceId}?contractAccountId=${contractAccountId}`,
    {},
    {
      responseType: 'blob',
    }
  );
}

export default {
  getInvoices,
  getInvoicePdf,
};
