// eslint-disable-next-line import/no-unresolved
import RestHelper from '../utils/RestHelper';

export default function doubleOptInSubscr(payload) {
  return RestHelper.post(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/advertisement`, payload, {}, false, false);
}

export function doubleOptInConfirm(payload) {
  return RestHelper.patch(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/advertisement?${new URLSearchParams(Object.entries(payload)).toString()}`,
    null,
    {},
    false,
    false
  );
}
export function doubleOptInUnsub(payload) {
  return RestHelper.delete(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/advertisement`, payload, {}, false, false);
}
