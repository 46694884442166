import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';
import { UserData, UserDataObject } from './types/accountDataApiTypes';
import EnvironmentHelper from '../../utils/EnvironmentHelper';
import CONFIG from '../config/config';

export const cicGASApiString = '/api/cic/v1/accounts';

export const getInstanceAttr = () => {
  let stage = {};
  if (EnvironmentHelper.getAemInstanceKey() === 'PQA') {
    stage = { aemInstanceKey: 'PQA' };
  } else if (EnvironmentHelper.getAemInstanceKey() !== 'PROD') {
    stage = { aemInstanceKey: null };
  }
  return stage;
};

const addBusinessPartner = (partnerData) => {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/useraccount/v0/businesspartners`,
    partnerData,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal),
    true,
    true
  );
};

const registerUser = (userDataObject: UserDataObject, config) =>
  RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}/public-api/cic/v2/accounts`,
    {
      data: {
        ...userDataObject.data,
        type: 'CicAccountRegistrationResource',
      },
    },
    RestHelper.setXClientHeader(config, CONFIG.CHANNEL_CONFIG.portal),
    true,
    false
  );

// TODO: move the response handling in the function how call this deleteAccount and rename it to deleteAccount. Change the payload to UserDataObject

const deleteApp = (payload) => {
  const promise = RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}${cicGASApiString}/delete`,
    {
      data: {
        id: CacheHandler.getCurrentCacheByToken('accountData').id,
        type: 'AccountDeletionResource',
        attributes: {
          dso: CONFIG.AEM_CONFIG.siteVariable,
          ...payload,
        },
      },
    },
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
  promise.then(() => {
    if (payload.applicationKeys) {
      const oldCache = CacheHandler.getCurrentCacheByToken('accountData');
      const apps = oldCache.apps.filter((app) => !payload.applicationKeys.find((deletedApp) => deletedApp === app));
      const current = { ...oldCache, ...{ apps } };
      CacheHandler.saveCache({ accountData: current });
    }
  });
  return promise;
};
// v2 is not supported for pw reset. Hence v1 is used.
const resetPasswordInitiate = (userDataObject: UserDataObject, config) =>
  RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}/public-api/cic/v1/accounts/resetpassword`,
    {
      data: {
        ...userDataObject.data,
        type: 'AccountResetPasswordResource',
      },
    },
    RestHelper.setXClientHeader(config, CONFIG.CHANNEL_CONFIG.portal),
    true,
    false
  );

// TODO: move the response handling in the function how call this saveAccountData and rename it to updateAccount. Change the payload to UserDataObject
const saveAccountData = (userData: UserData) =>
  RestHelper.patch(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}${cicGASApiString}`,
    {
      data: {
        id: CacheHandler.getCurrentCacheByToken('accountData').id,
        type: 'AccountUpdateResource',
        attributes: {
          ...userData.attributes,
          ...getInstanceAttr(),
          dso: CONFIG.AEM_CONFIG.siteVariable,
          applicationKey: 'CIC',
        },
      },
    },
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  )
    .then(() => {
      const oldData = CacheHandler.getCurrentCacheByToken('accountData');
      let emailData = {};
      if (userData.attributes.email) {
        if (oldData.oldEmail === userData.attributes.email) {
          emailData = {
            emailConfirmed: true,
            oldEmail: null,
          };
        } else if (!oldData.emailConfirmed) {
          emailData = {
            emailConfirmed: false,
            email: userData.attributes.email,
          };
        } else {
          emailData = {
            emailConfirmed: false,
            oldEmail: oldData.email,
          };
        }
      }
      const current = { ...oldData, ...userData.attributes, ...emailData };
      CacheHandler.saveCache({ accountData: current });
      return {
        data: {
          ...current,
        },
      };
    })
    .catch((error) => {
      throw error.response;
    });

export const deactivate2FAAuthentication = async (): Promise<any> => {
  const egcid = CacheHandler.getCurrentCacheByToken('accountData')?.id;
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}/api/common/v1/accounts/deletemfa/${egcid}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
};

export default { addBusinessPartner, registerUser, deleteApp, resetPasswordInitiate, saveAccountData, deactivate2FAAuthentication };
