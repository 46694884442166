import { ContractConsumptionsActionTypes, ContractConsumptionsActionType } from '../actions/contractConsumptions/contractConsumptionsActions';
import { ConsumptionTableTypes } from '../components/ConsumptionTable/ConsumptionTableTypes';

export type ContractConsumptionsReducerStateTypes = {
  data: ConsumptionTableTypes[];
  loading: boolean;
};

const initialState: ContractConsumptionsReducerStateTypes = {
  data: [],
  loading: false,
};

export default function reducer(state: ContractConsumptionsReducerStateTypes = initialState, action: ContractConsumptionsActionTypes) {
  switch (action.type) {
    case ContractConsumptionsActionType.LOAD_ALL_CONTRACT_CONSUMPTIONS:
      return {
        ...state,
        loading: false,
        data: action.content,
      };
    case ContractConsumptionsActionType.LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
