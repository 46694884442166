import { InvoiceActionTypes, InvoiceActionType } from '../actions/Invoice/invoiceActions';
import { InvoiceTypes } from '../components/Invoice/InvoiceTypes';

export type InvoiceReducerStateTypes = {
  invoices: InvoiceTypes[];
  loading: boolean;
  hasApiError: boolean;
  apiErrorMessage: string;
};

const initialState: InvoiceReducerStateTypes = {
  invoices: [],
  loading: false,
  hasApiError: false,
  apiErrorMessage: '',
};

export default function reducer(state: InvoiceReducerStateTypes = initialState, action: InvoiceActionTypes) {
  switch (action.type) {
    case InvoiceActionType.LOAD_ALL_INVOICES:
      return {
        ...state,
        loading: false,
        invoices: action.invoices,
      };
    case InvoiceActionType.LOADING:
      return {
        ...state,
        loading: true,
      };
    case InvoiceActionType.HAS_API_ERROR:
      return {
        ...state,
        loading: false,
        hasApiError: true,
        invoices: [],
        apiErrorMessage: action.apiErrorMessage,
      };

    default:
      return state;
  }
}
