import classNames from 'classnames';
import * as React from 'react';
import { InputState } from "../../constants";
import InputFeedback from '../InputFeedback/InputFeedback';
export var RadioButton = function (_a) {
    var id = _a.id, qaId = _a.qaId, label = _a.label, _b = _a.inputState, inputState = _b === void 0 ? InputState.NONE : _b, errorMessage = _a.errorMessage, value = _a.value, name = _a.name, checked = _a.checked, disabled = _a.disabled, className = _a.className, feedbackClassName = _a.feedbackClassName, handleChange = _a.onChange;
    var showError = inputState === InputState.ERROR;
    var wrapperClasses = classNames('form-radio form-radio--label', {
        'form-radio--error': showError,
    }, {
        'radio-state--active': checked,
    }, {
        'radio-state--disabled': disabled,
    }, className);
    var inputClasses = classNames('form-radio__radio', {
        'form-radio__field--error': showError,
    });
    var controlClasses = classNames('form-radio__control', {
        'form-radio__control--checked': checked,
    }, {
        'form-radio__control--disabled': disabled,
    });
    var labelClasses = classNames('form-radio__label', {
        'active-label': checked,
    });
    return (React.createElement("div", { id: "".concat(name, "__").concat(id) },
        React.createElement("div", { className: wrapperClasses },
            React.createElement("label", { className: labelClasses, htmlFor: id },
                React.createElement("input", { type: "radio", name: name, id: id, "data-qa": qaId, value: value, className: inputClasses, defaultChecked: checked, onClick: handleChange, disabled: disabled }),
                React.createElement("span", null, label),
                React.createElement("div", { className: controlClasses })),
            errorMessage && (React.createElement(InputFeedback, { hasError: showError, errorMessage: errorMessage, feedbackClassName: feedbackClassName })))));
};
RadioButton.displayName = 'RadioButtonComponent';
export default RadioButton;
