import { getIdDataset, getInputValueIfExists } from '../../utils/DomHelper';
import decodeEntities from '../../utils/StringHelpers';

// const baseApiURLs = 'https://i-api.eon.de/cscn-customer-srv';
const data = getIdDataset('cscn-config', 'props');
const cscConfig = data ? JSON.parse(decodeEntities(getIdDataset('cscn-config', 'props'))) : null;
const cicDomains = getIdDataset('KUNDENPORTAL_MW_URL', 'cicMiddlewareDomains') || '{}';
const cscnCustomerDomain = getIdDataset('KUNDENPORTAL_MW_URL', 'cscnMiddlewareCustomerDomain');
const cscnAccountDomain = getIdDataset('KUNDENPORTAL_MW_URL', 'cscnMiddlewareAccountDomain');
export const isDoiDSO = (DSO) => DSO;
const CONFIG = {
  GA_TRACKING_ID: 'XXXXXXXXX',
  GA_TRACKING_DEBUG: false,
  GA_ENABLED: false,
  ACTION_TYPES: {},
  CHANNEL_CONFIG: {
    anonymMR: 'ANONYMOUSMETERREADING',
    portal: 'PORTAL',
    loggedInCsc: 'CSC',
    postalAuth: 'ANONYMOUSINTERIMMETERREADING',
  },
  AEM_CONFIG: {
    cscConfigAdded: !!cscConfig,
    ...cscConfig,
    offlineModeIsEnabled: getInputValueIfExists('offline-portal-enabled-flag') === 'true',
    isDkePage: getInputValueIfExists('dke-page') === 'true',
    isAuthorMode: getInputValueIfExists('author_mode') !== null,
    middlewareDomains: { ...JSON.parse(cicDomains), cscnCustomerDomain, cscnAccountDomain },
  },
};

export default CONFIG;
