import { getMyData, saveMyData, getBusinessPartner, getTaxVatNumbers, saveTaxVatNumber } from '../../api-factory/myDataApi';
import { addPhoneNumber, deletePhoneNumber, editPhoneNumbers, getPhoneNumbers } from '../../api-factory/phoneNumbersApi';

// Actions
const LOAD_CURRENT_PERSONAL_DATA = 'api/reducer/GET_PERSONAL_DATA';
const SAVE_MY_DATA = 'api/reducer/SAVE_MY_DATA';
const LOAD_BUSINESS_PARTNER = 'api/reducer/LOAD_BUSINESS_PARTNER';
const ADD_NEW_PHONE_NUMBER = 'api/reducer/ADD_NEW_PHONE_NUMBER';
const EDIT_PHONE_NUMBER = 'api/reducer/EDIT_PHONE_NUMBER';
const LOAD_PHONE_NUMBERS = 'api/reducer/LOAD_PHONE_NUMBERS';
const REMOVE_PHONE_NUMBER = 'api/reducer/REMOVE_PHONE_NUMBERS';
const CHANGE_PHONENUMBER_STANDARD_STATE = 'reducer/CHANGE_PHONENUMBER_STANDARD_STATE';
const RESET_PASSWORD_INITIATE = 'api/reducer/RESET_PASSWORD_INITIATE';
const RESET_PASSWORD_VALIDATE = 'api/reducer/RESET_PASSWORD_VALIDATE';
const RESET_PASSWORD_SET = 'api/reducer/RESET_PASSWORD_SET';
const LOAD_TAX_VAT_NUMBERS = 'api/reducer/LOAD_TAX_VAT_NUMBERS';
const SAVE_TAX_DATA = 'api/reducer/SAVE_TAX_DATA';

// Reducer
export default function reducer(state = {}, action) {
  const standardState = state.phoneNumberStandardState ? { ...state.phoneNumberStandardState } : {};
  switch (action.type) {
    case LOAD_CURRENT_PERSONAL_DATA:
      return {
        ...state,
        data: action.data.innerData,
      };
    case SAVE_MY_DATA:
      return {
        ...state,
        data: action.data.innerData,
      };
    case LOAD_BUSINESS_PARTNER:
      return {
        ...state,
        businessPartner: action.businessPartner,
      };
    case LOAD_PHONE_NUMBERS:
      return {
        ...state,
        phoneNumbers: action.phoneNumbers,
      };
    case ADD_NEW_PHONE_NUMBER:
      return {
        ...state,
        phoneNumbers: action.phoneNumbers,
      };
    case EDIT_PHONE_NUMBER:
      return {
        ...state,
        phoneNumbers: action.phoneNumbers,
      };
    case REMOVE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumbers: action.phoneNumbers,
      };
    case CHANGE_PHONENUMBER_STANDARD_STATE:
      return {
        ...state,
        phoneNumberStandardState: { ...standardState, ...action.value },
      };
    case RESET_PASSWORD_INITIATE:
      return {
        ...state,
        data: action.data.innerData,
      };
    case RESET_PASSWORD_VALIDATE:
      return {
        ...state,
        data: action.data.innerData,
      };
    case RESET_PASSWORD_SET:
      return {
        ...state,
        data: action.data.innerData,
      };
    case LOAD_TAX_VAT_NUMBERS:
      return {
        ...state,
        taxNumbers: action.taxNumbers,
      };
    case SAVE_TAX_DATA:
      return {
        ...state,
        taxNumbers: action.taxNumbers,
      };
    default:
      return state;
  }
}

// Action Creators
export function changePhoneNumberStandardState(dispatch, inputName, isStandard) {
  return dispatch({
    type: CHANGE_PHONENUMBER_STANDARD_STATE,
    value: {
      [inputName]: isStandard,
    },
  });
}

export function loadCurrent(dispatch, contractId) {
  const resp = getMyData(contractId);
  return resp.then((reponse) => {
    dispatch({
      type: LOAD_CURRENT_PERSONAL_DATA,
      data: {
        innerData: reponse.data,
      },
    });
  });
}

export function savePersonalData(dispatch, contractId, myData) {
  const resp = saveMyData(myData, contractId);
  return resp.then((response) => {
    dispatch({
      type: SAVE_MY_DATA,
      data: {
        innerData: response.data,
      },
    });
  });
}

export function loadBusinessPartner(dispatch) {
  const resp = getBusinessPartner();
  return resp.then((response) => {
    dispatch({
      type: LOAD_BUSINESS_PARTNER,
      businessPartner: response.data,
    });
  });
}

export function loadPhoneNumbers(dispatch, contractId) {
  const resp = getPhoneNumbers(contractId);
  return resp.then((response) => {
    dispatch({
      type: LOAD_PHONE_NUMBERS,
      phoneNumbers: response.data.content,
    });
  });
}

export function addNewPhoneNumber(dispatch, phoneNumberObject, contractId) {
  const resp = addPhoneNumber(phoneNumberObject, contractId);
  return resp
    .then((response) => {
      dispatch({
        type: ADD_NEW_PHONE_NUMBER,
        phoneNumbers: response.data.content,
      });
    })
    .then(() => {
      loadCurrent(dispatch, contractId);
      loadPhoneNumbers(dispatch, contractId);
    });
}

export function editPhoneNumber(dispatch, phoneNumberObject, contractId, index) {
  const resp = editPhoneNumbers(phoneNumberObject, index, contractId);
  return resp
    .then((response) => {
      dispatch({
        type: EDIT_PHONE_NUMBER,
        phoneNumbers: response.data.content,
      });
    })
    .then(() => {
      loadCurrent(dispatch, contractId);
      loadPhoneNumbers(dispatch, contractId);
    });
}

export function removePhoneNumber(dispatch, contractId, index) {
  const resp = deletePhoneNumber(contractId, index);
  return resp
    .then((response) => {
      dispatch({
        type: REMOVE_PHONE_NUMBER,
        phoneNumbers: response.data.content,
      });
    })
    .then(() => {
      loadCurrent(dispatch, contractId);
      loadPhoneNumbers(dispatch, contractId);
    });
}

export function loadTaxVatNumbers(dispatch, contractId) {
  const resp = getTaxVatNumbers(contractId);
  return resp.then((response) => {
    dispatch({
      type: LOAD_TAX_VAT_NUMBERS,
      taxNumbers: response.data,
    });
  });
}

export const saveNewTaxVatData = (dispatch, id, data) =>
  saveTaxVatNumber(id, data)
    .then((response) => {
      dispatch({
        type: SAVE_TAX_DATA,
        taxNumbers: response.data,
      });
    })
    .catch((err) => {
      const error = err && err.data && err.data.errors;
      throw error;
    });
