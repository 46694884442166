// eslint-disable-next-line import/no-unresolved
import RestHelper from '../utils/RestHelper';
import { getMyData } from '../../api-factory/myDataApi';

export default function anOkoSubscr(payload) {
  return RestHelper.post(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/subscription`, payload, {}, false, false);
}

export async function changeCommunicationTypeByContractAccount(notificationTypesNotFiltered, type = 'EMAIL') {
  try {
    const contractAccountCommunicationChanges = notificationTypesNotFiltered.map(async (notificationItem) => {
      const { contractAccountId } = notificationItem;
      let personalData = await getMyData(contractAccountId);

      personalData = personalData.data;

      personalData.notificationTypeBill = personalData.notificationTypeBill ? type : null;
      personalData.notificationTypeKSA = personalData.ksaNotification ? type : null;
      const putPersonalData = await RestHelper.put(
        `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/mydata/${contractAccountId}`,
        personalData
      );
      return putPersonalData;
    });

    return await Promise.all(contractAccountCommunicationChanges);
  } catch (error) {
    return false;
  }
}

export function anOkoConfirm(payload) {
  return RestHelper.patch(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/subscription?${new URLSearchParams(Object.entries(payload)).toString()}`,
    null,
    {},
    false,
    false
  );
}

export function anOkoUnsub(payload) {
  return RestHelper.delete(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/subscription`, payload, {}, false, false);
}

export function prefillOko({ companyCode, subscriptionInvitationId }) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/communication/v0/public/subscriptionInvitation?companyCode=${companyCode}&subscriptionInvitationId=${subscriptionInvitationId}`
  );
}

export async function changeCommunicationTypeForMultipleAccounts(type = 'EMAIL') {
  const payload = {
    notificationType: {
      correspondence: type,
      selfReadingByCustomer: type,
    },
  };

  return RestHelper.patch(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/mydata/notificationtypes`, payload, {}, false, false);
}

export async function changeCommunicationType(contractAccountId, type = 'EMAIL') {
  try {
    let personalData = await getMyData(contractAccountId);
    personalData = personalData.data;

    personalData.notificationTypeBill = personalData.notificationTypeBill ? type : null;
    personalData.notificationTypeKSA = personalData.ksaNotification ? type : null;
    return RestHelper.put(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/partner/v0/mydata/${contractAccountId}`, personalData);
  } catch (error) {
    return false;
  }
}
