import { MyDataTypes } from './types/personalDataPropTypes';
import { PersonalDataActions, PersonalDataActionTypes } from '../actions/personalData/personalDataActions';

export type PersonalDataReducerStateTypes = {
  myData: MyDataTypes;
  isLoading: boolean;
  hasApiError: boolean;
  apiErrorMessage: string;
};

const initialState: PersonalDataReducerStateTypes = {
  myData: {} as MyDataTypes,
  isLoading: true,
  hasApiError: false,
  apiErrorMessage: '',
};

export default function reducer(state: PersonalDataReducerStateTypes = initialState, action: PersonalDataActionTypes) {
  switch (action.type) {
    case PersonalDataActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoadingPersonalData,
      };
    case PersonalDataActions.LOAD_CURRENT_PERSONAL_DATA:
    case PersonalDataActions.SAVE_MY_DATA:
      return {
        ...state,
        myData: action.myData,
        isLoading: false,
      };
    case PersonalDataActions.LOAD_BUSINESS_PARTNER:
      return {
        ...state,
        businessPartner: action.businessPartner,
        isLoading: false,
      };
    case PersonalDataActions.RESET_PASSWORD_INITIATE:
    case PersonalDataActions.RESET_PASSWORD_VALIDATE:
    case PersonalDataActions.RESET_PASSWORD_SET:
      return {
        ...state,
        myData: action.myData,
        isLoading: false,
      };
    case PersonalDataActions.SET_API_ERROR:
      return {
        ...state,
        isLoading: false,
        hasApiError: true,
        apiErrorMessage: action.apiErrorMessage,
      };
    case PersonalDataActions.CLEAR_API_ERROR:
      return {
        ...state,
        hasApiError: false,
        apiErrorMessage: '',
      };
    default:
      return state;
  }
}
