import PropTypes from 'prop-types';

export const meterReadingMailboxInfoPopupPropTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  closeButton: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
};

export const meterReadingEmailNotificationPropTypes = {
  fromToMetersRegisteredText: PropTypes.string.isRequired,
  metersRegisteredText: PropTypes.string.isRequired,
  headLine: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  acceptButton: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  declineButton: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  mailboxPopup: PropTypes.shape(meterReadingMailboxInfoPopupPropTypes).isRequired,
};
