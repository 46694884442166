import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';

export const getTaxVatNumbers = (contractAccountId: string) => {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractAccountId}/taxnumbers`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  )
    .then((response) => {
      return {
        data: {
          taxin: response.data.taxin ? response.data.taxin : '',
          vatin: response.data.vatin ? response.data.vatin : 'DE',
        },
      };
    })
    .catch((error) => {
      throw error;
    });
};

export const saveTaxVatNumber = (contractAccountId: string, data) => {
  return RestHelper.patch(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractAccountId}/taxnumbers`,
    data,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
};

export default {
  getTaxVatNumbers,
  saveTaxVatNumber,
};
