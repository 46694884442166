import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_NATIVE = 'YYYY-MM-DD';
let THRESHOLD = 366;

export const setThreshold = (limit) => {
  THRESHOLD = limit;
};
/**
 * strict date format check
 * @param {string} date - the date to check
 * @returns {boolean} - true/false if format is valid
 */
export const dateFormatValid = (date?: string, props?: any): boolean =>
  !props || (!props.isMobile && !props.isTablet) ? moment(date, DATE_FORMAT, true).isValid() : moment(date, DATE_FORMAT_NATIVE, true).isValid();
/**
 * checks if a given date is before another reference date
 * @param {date} currentDate - date selected
 * @param {date} refDate - date reference to check against
 * @returns {boolean}
 */
export const isDateBefore = (currentDate: Date | undefined, refDate: Date): boolean => moment(currentDate).isBefore(refDate, 'day');
/**
 * checks if a given date is after another reference date
 * @param {date} currentDate - date selected
 * @param {date} refDate - date reference to check against
 * @returns {boolean}
 */
export const isDateAfter = (currentDate: Date, refDate: Date): boolean => moment(currentDate).isAfter(refDate, 'day');
/**
 * checks if range in days is greater then a given threshold
 * @param {date} fromDate - start date
 * @param {date} toDate - end date
 * @returns {boolean}
 */
export const isDateRangeGreaterThenThreshold = (fromDate: Date, toDate: Date): boolean => moment(toDate).diff(moment(fromDate), 'days') >= THRESHOLD;

/*
 * format date for usage in date-picker
 * @param {string} property - property to look at. returns undefined if not found
 * @param {object} obj - object with property
 */
export const fixMonth = (property) => {
  if (Array.isArray(property)) {
    const clone = Array.isArray(property) && [...property];
    clone[1] -= 1;
    return clone;
  }
  return undefined;
};
