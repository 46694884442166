import { Dispatch } from 'react';
import { UnsubscribeApiErrorTypes, UnsubscribePayload } from '../../components/Communication/communicationTypes';
import { confirmConfirmationCode, subscribeEmail, unsubscribeConfirmationCode, unsubscribeEmail } from '../../api/communicationApi';

export enum CommunicationActionType {
  LOAD_COMMUNICATION_DATA = 'api/reducer/LOAD_COMMUNICATION_DATA',
  SUBSCRIBE_SAVE_SUCCESS = 'api/reducer/COMMUNICATION_SUBSCRIBE_SAVE_SUCCESS',
  SUBSCRIBE_SAVE_FAIL = 'api/reducer/COMMUNICATION_SUBSC_SAVE_FAIL',
}

export type CommunicationReducerActionTypes = {
  type: string;
  apiError: UnsubscribeApiErrorTypes;
};

const loadCommunicationData = (dispatch: Dispatch<Partial<CommunicationReducerActionTypes>>) => {
  dispatch({
    type: CommunicationActionType.LOAD_COMMUNICATION_DATA,
  });
};

const subscribeWithEmail = (dispatch: Dispatch<Partial<CommunicationReducerActionTypes>>, payload: UnsubscribePayload) => {
  loadCommunicationData(dispatch);
  const request = subscribeEmail(payload);
  request
    .then(() => {
      dispatch({
        type: CommunicationActionType.SUBSCRIBE_SAVE_SUCCESS,
      });
    })
    .catch((error) => {
      handleOkoErrorResponse(error, dispatch);
    });
  return request;
};

const unSubscribeWithEmail = (dispatch: Dispatch<Partial<CommunicationReducerActionTypes>>, payload: UnsubscribePayload) => {
  loadCommunicationData(dispatch);
  const request = unsubscribeEmail(payload);
  request
    .then(() => {
      dispatch({
        type: CommunicationActionType.SUBSCRIBE_SAVE_SUCCESS,
      });
    })
    .catch((error) => {
      handleOkoErrorResponse(error, dispatch);
    });
  return request;
};

const confirmWithConfirmationCode = (dispatch: Dispatch<Partial<CommunicationReducerActionTypes>>, payload: UnsubscribePayload) => {
  loadCommunicationData(dispatch);
  confirmConfirmationCode(payload)
    .then(() => {
      dispatch({
        type: CommunicationActionType.SUBSCRIBE_SAVE_SUCCESS,
      });
    })
    .catch((response) => {
      handleOkoErrorResponse(response, dispatch);
    });
};

const unsubscribeWithConfirmationCode = (dispatch: Dispatch<Partial<CommunicationReducerActionTypes>>, payload: UnsubscribePayload) => {
  loadCommunicationData(dispatch);
  unsubscribeConfirmationCode(payload)
    .then(() => {
      dispatch({
        type: CommunicationActionType.SUBSCRIBE_SAVE_SUCCESS,
      });
    })
    .catch((error) => {
      handleOkoErrorResponse(error, dispatch);
    });
};

function handleOkoErrorResponse(error, dispatch: Dispatch<Partial<CommunicationReducerActionTypes>>) {
  const errors: UnsubscribeApiErrorTypes = {
    policyCheckFailed: false,
    unknownError: false,
    serverError: false,
    policyCheckFailedGp: false,
    policyCheckFailedVk: false,
    activationLinkExpired: false,
  };
  const errorList = error?.response?.data?.errors;
  const errorCode = errorList ? errorList?.[0]?.code?.split('.')?.pop() : 'serverError';
  if (errorCode === 'invalidInput') {
    errors.policyCheckFailed = true;
    errors.policyCheckFailedVk = true;
    errors.policyCheckFailedGp = true;
  } else {
    errors[errorCode] = true;
  }
  dispatch({
    type: CommunicationActionType.SUBSCRIBE_SAVE_FAIL,
    apiError: errors,
  });
}

export { subscribeWithEmail, unSubscribeWithEmail, confirmWithConfirmationCode, unsubscribeWithConfirmationCode };
