import { getIdDataset, getInputValueIfExists } from '../../utils/DomHelper';
import decodeEntities, { b64DecodeUnicode } from '../../utils/StringHelpers';

// const baseApiURLs = 'https://i-api.eon.de/cscn-customer-srv';
const data = getIdDataset('cscn-config', 'props');
const cscConfig = data ? JSON.parse(decodeEntities(getIdDataset('cscn-config', 'props'))) : null;
const cicDomains = getIdDataset('KUNDENPORTAL_MW_URL', 'cicMiddlewareDomains') || '{}';
const cscnCustomerDomain = getIdDataset('KUNDENPORTAL_MW_URL', 'cscnMiddlewareCustomerDomain');
const cscnAccountDomain = getIdDataset('KUNDENPORTAL_MW_URL', 'cscnMiddlewareAccountDomain');
const noDoiDSO = ['ENB', 'EVP', 'AHN', 'ENS', 'NNNG', 'ELBE'];
// The functionality is disabled for now
// export const isDoiDSO = (DSO) => !noDoiDSO.includes(DSO);

const CONFIG = {
  GA_TRACKING_ID: 'XXXXXXXXX',
  GA_TRACKING_DEBUG: false,
  GA_ENABLED: false,
  ACTION_TYPES: {},
  CHANNEL_CONFIG: {
    anonymMR: 'ANONYMOUSMETERREADING',
    loggedInCsc: 'CSC',
    portal: 'PORTAL',
    postalAuth: 'ANONYMOUSINTERIMMETERREADING',
  },
  AEM_CONFIG: {
    cscConfigAdded: !!cscConfig,
    ...cscConfig,
    isDkePage: getInputValueIfExists('dke-page') === 'true',
    isAuthorMode: getInputValueIfExists('author_mode') !== null,
    middlewareDomains: { ...JSON.parse(cicDomains), cscnCustomerDomain, cscnAccountDomain },
  },
};
export default CONFIG;
