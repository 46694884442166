import React from 'react';
import { Icon } from '@eon-funke/react-shared-ui-next';
import MeterNumberValidationHelpers from '../../../../../utils/MeterNumberValidationHelpers';
import DateIcon from './DateIcon';
import { DeviceDataTypes } from '../../MeterReadingTypes';
import { prepareEnergyTypeForDisplay } from '../MeterReadingForm/meterReadingFormHelper';
import { prepareDateForDisplay } from '../../Core/MeterReadDateInput/meterReadingDateInputHelper';
import EditIcon from './EditIcon';
import DeviceIcon from './DeviceIcon';

export type MeterReadingInformationBoxTypes = {
  deviceData: DeviceDataTypes;
  energyTypeLabel: string;
  deviceNumberLabel: string;
  lastReadingDateLabel: string;
  editButton: boolean;
  newMeterReadingDate: string;
  paginate: () => void;
};

type MeterInformationBoxProps = MeterReadingInformationBoxTypes;

const MeterInformationBox = (props: MeterInformationBoxProps) => {
  let serialNumber;
  let typeDisplay;
  let dateDisplay;
  if (props.deviceData) {
    serialNumber = props.deviceData.obfuscationActive ? props.deviceData.serialNumberObfuscated : props.deviceData.serialNumber;
    serialNumber = MeterNumberValidationHelpers.removeRedundantZeros(serialNumber);
    dateDisplay = prepareDateForDisplay(props.deviceData.registers);
    typeDisplay = prepareEnergyTypeForDisplay(props.deviceData);
  }
  const classNameStart = 'text-center ano-meter-informationbox__value ano-meter-informationbox__value__';

  return (
    <div className='ano-meter-informationbox row Rectangle'>
      <div className='col-md-4 col-sm-6 col-xs-6'>
        <span className={`text-center ano-meter-informationbox__value ${typeDisplay.energyTypeClass}`}>
          <Icon
            glyph={typeDisplay.iconType}
            size='36'
            style={{ fill: '#767676' }}
          />
        </span>
        <h5 className='text-center ano-meter-informationbox__icon'>{typeDisplay.energyTypeValue}</h5>
        <h5 className='text-center ano-meter-informationbox__text'>{props.energyTypeLabel}</h5>
      </div>
      <div className='col-md-4 col-sm-6 col-xs-6'>
        <span className={`${classNameStart}serial-number`}>
          <DeviceIcon />
        </span>
        <h5 className='text-center ano-meter-informationbox__icon'>{serialNumber}</h5>
        <h5 className='text-center ano-meter-informationbox__text'>{props.deviceNumberLabel}</h5>
      </div>
      <div className='col-md-4 col-sm-12 col-xs-12 ano-meter-informationbox__last-read-date'>
        <span className={`${classNameStart}last-reading-date`}>
          <DateIcon />
        </span>
        <h5 className='text-center ano-meter-informationbox__icon'>
          {`${props.editButton ? props.newMeterReadingDate : dateDisplay}`}
          {props.editButton && '   '}
          {props.editButton && <EditIcon paginate={props.paginate} />}
        </h5>
        <h5 className='text-center ano-meter-informationbox__text'>{props.lastReadingDateLabel}</h5>
      </div>
    </div>
  );
};

export default MeterInformationBox;
