import { FormikErrors } from 'formik';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/GermanDateHelpers';
import { DeviceDataTypes } from '../MeterReadingTypes';
import GlobalErrorHandler from '../../../../utils/GlobalErrorHandler';
import { pickRegisterFromDeviceByRegisterNumber } from '../Anonym/MeterReadingForm/meterReadingFormHelper';
import { calculateReadingPeriod } from '../Core/MeterReadDateInput/meterReadingDateInputHelper';

const hasFormatError = (inputValue: string): boolean => {
  if (inputValue) {
    if (inputValue.search(/\B[^0-9]/g) !== -1) {
      return false;
    }

    const positionOfFirstComma = inputValue.indexOf(',');
    const positionOfFirstDot = inputValue.indexOf('.');

    if (positionOfFirstDot !== -1 && positionOfFirstComma !== -1) {
      return true;
    }

    const dotSplitValues: string[] = inputValue.split('.');
    const commaSplitValues: string[] = inputValue.split(',');

    if (positionOfFirstDot !== -1) {
      for (let i = 0; i < dotSplitValues.length; i += 1) {
        const valueLength = dotSplitValues[i].length;
        if (valueLength > 3 || valueLength < 1) {
          return false;
        }
        if (i !== 0 && dotSplitValues[i].length < 3) {
          return false;
        }
      }
    }

    if (positionOfFirstComma !== -1) {
      if (commaSplitValues.length - 1 > 1) {
        return false;
      }
      if (commaSplitValues.length - 1 === 1) {
        if (positionOfFirstDot !== -1) {
          let lastDotPosition = 0;
          const positionOfDots = dotSplitValues.map((value) => {
            lastDotPosition = value.length + lastDotPosition + 1;
            return lastDotPosition;
          });
          if (positionOfDots.some((item) => item > positionOfFirstComma)) {
            return false;
          }
        }
      }
    }
    return true;
  }
  return false;
};

const hasCommaBeforePeriod = (inputValue: string): boolean => {
  if (inputValue) {
    const dotIndex: number = inputValue.indexOf('.');
    const commaIndex: number = inputValue.indexOf(',');
    return dotIndex === -1 || commaIndex === -1 || dotIndex < commaIndex;
  }
  return false;
};

const hasIncorrectDecimalLength = (numberOfDecimalPlaces: number, inputValue: string): boolean => {
  if (inputValue && inputValue.indexOf(',') !== -1) {
    const decimalPlaces = inputValue.split(',')[1];
    if (decimalPlaces) {
      return decimalPlaces.length <= numberOfDecimalPlaces;
    }
  }
  return true;
};

const hasIncorrectPreDecimalLength = (numberOfPreDecimalPlaces: number, inputValue: string): boolean => {
  if (inputValue) {
    const preDecimalPlaces = inputValue.replace(/\./g, '').split(',')[0];
    if (preDecimalPlaces) {
      return preDecimalPlaces.length <= numberOfPreDecimalPlaces;
    }
  }
  return false;
};

const validate = (values: DeviceDataTypes, props) => {
  const readingPeriod = calculateReadingPeriod(values.readingPeriod);
  const readingDate = values?.readingDate?.text;

  const errors: FormikErrors<DeviceDataTypes> = {
    readingDate: undefined,
    registers: [],
  };

  if (!readingDate || !moment(readingDate, DATE_FORMAT, true).isValid()) {
    errors.readingDate = GlobalErrorHandler.getErrorMessage('required').toString();
  }

  if (moment(readingDate, DATE_FORMAT, true).isBefore(readingPeriod.startDate)) {
    errors.readingDate = props.dateRangeErrorMsg;
  }

  if (moment(readingDate, DATE_FORMAT, true).isAfter(readingPeriod.endDate)) {
    errors.readingDate = props.futureDateErrorMessage;
  }

  values.registers.forEach((register, index) => {
    if (!register.readingResult || register.readingResult === '') {
      errors.registers[index] = {
        readingResult: GlobalErrorHandler.getErrorMessage('required').toString(),
      };
      return;
    }
    if (!hasFormatError(register.readingResult ? register.readingResult.toString() : '')) {
      errors.registers[index] = { readingResult: props.meterFormatErrorMsg };
      return;
    }
    const registerData = pickRegisterFromDeviceByRegisterNumber(register.registerNumber, props.deviceData.registers);
    if (registerData) {
      if (!hasCommaBeforePeriod(register.readingResult ? register.readingResult.toString() : '')) {
        errors.registers[index] = {
          readingResult: props.dontUseCommaOrDotError,
        };
        return;
      }
      if (!hasIncorrectDecimalLength(registerData.numberOfDecimalPlaces, register.readingResult ? register.readingResult.toString() : '')) {
        errors.registers[index] = {
          readingResult: props.decimalLengthError.replace('%%MAXDECIMALLENGTH%%', (registerData.numberOfDecimalPlaces || '').toString()),
        };
        return;
      }
      if (!hasIncorrectPreDecimalLength(registerData.lengthIntegerPart, register.readingResult ? register.readingResult.toString() : '')) {
        errors.registers[index] = {
          readingResult: props.maximumPreDecimalPlaces.replace('%%MAXINTEGERLENGTH%%', (registerData.lengthIntegerPart || '').toString()),
        };
      }
    }
  });

  if (errors?.registers?.length === 0 && errors?.readingDate === undefined) {
    return undefined;
  }

  return errors;
};

export default validate;
