import { getBudgetBillingPlan, saveNewBudgetBillingPlan } from '../api/budgetBillingPlanApi';

// Actions
const LOAD_BILLING_PLAN = 'api/reducer/GET_BILLING_PLAN';
const SAVE_BILLING_PLAN = 'api/reducer/SAVE_BILLING_PLAN';

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_BILLING_PLAN:
      return {
        ...state,
        data: action.data.innerData,
      };
    case SAVE_BILLING_PLAN:
      return {
        ...state,
        data: action.data.innerData,
      };
    default:
      return state;
  }
}

// Action Creators
export function loadBillingPlan(dispatch, contractId) {
  const resp = getBudgetBillingPlan(contractId);
  return resp.then((reponse) => {
    dispatch({
      type: LOAD_BILLING_PLAN,
      data: {
        innerData: reponse.data.content,
      },
    });
  });
}

export function saveBillingPlan(dispatch, contractId, billPlanItemId, billingItemData) {
  const resp = saveNewBudgetBillingPlan(contractId, billPlanItemId, billingItemData);
  return resp.then((response) => {
    dispatch({
      type: SAVE_BILLING_PLAN,
      data: {
        innerData: response,
      },
    });
  });
}
