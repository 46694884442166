import React from 'react';
import { Icon } from '@eon-funke/react-shared-ui-next';

type EditIconTypes = {
  paginate: () => void;
};

type EditIconProps = EditIconTypes;

const EditIcon = (props: EditIconProps): JSX.Element => (
  <Icon
    glyph='icon_bearbeiten_18x18'
    size='18'
    style={{ fill: 'rgba(0, 145, 187, 1)', cursor: 'pointer' }}
    qaId='ano-meter-form__edit-icon'
    onClick={() => {
      sessionStorage.setItem('usePrefill', '1');
      props.paginate();
    }}
  />
);

export default EditIcon;
