var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var BASE_CLASS = 'form-switch';
var ToggleSwitch = (function (_super) {
    __extends(ToggleSwitch, _super);
    function ToggleSwitch(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            checked: !!props.checked,
        };
        return _this;
    }
    ToggleSwitch.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var _this = this;
        if (this.state.checked !== !!nextProps.checked) {
            this.setState({
                checked: !!nextProps.checked,
            }, function () {
                if (nextProps.onChange) {
                    nextProps.onChange(_this.state.checked);
                }
            });
        }
    };
    ToggleSwitch.prototype.displayLabel = function (checked, label, altLabel, id) {
        if (!label) {
            return null;
        }
        var labelText = label;
        if (checked && altLabel) {
            labelText = altLabel;
        }
        return (React.createElement("div", { className: "".concat(BASE_CLASS, "__label") },
            React.createElement("label", { htmlFor: id, className: "".concat(BASE_CLASS, "__label--text") }, labelText)));
    };
    ToggleSwitch.prototype.render = function () {
        var _this = this;
        var _a = this.props, label = _a.label, altLabel = _a.altLabel, name = _a.name, id = _a.id, required = _a.required, disabled = _a.disabled, readOnly = _a.readOnly, inputState = _a.inputState, qaId = _a.qaId;
        var onClickHandler = function () {
            if (!_this.props.disabled && !_this.props.readOnly) {
                _this.setState({ checked: !_this.state.checked }, function () {
                    if (_this.props.onChange) {
                        _this.props.onChange(_this.state.checked);
                    }
                    if (_this.props.onClick) {
                        _this.props.onClick(_this.state.checked);
                    }
                });
            }
        };
        return (React.createElement("div", { className: "".concat(BASE_CLASS, " ").concat(disabled ? 'switch-disabled' : '', " ").concat(required ? 'switch-required' : '', " ").concat(altLabel ? 'switch-options' : '', " ").concat(inputState || '') },
            React.createElement("div", { className: "".concat(BASE_CLASS, "-toggle__label switch") },
                React.createElement("input", { type: "checkbox", className: "switch__checkbox", id: id, name: name, checked: this.state.checked, required: required, disabled: disabled, readOnly: readOnly, onChange: onClickHandler, "data-qa": qaId }),
                React.createElement("div", { className: "switch__slider switch__slider--round" })),
            this.displayLabel(this.state.checked, label, altLabel, id)));
    };
    return ToggleSwitch;
}(React.Component));
ToggleSwitch.displayName = 'ToggleSwitch';
export default ToggleSwitch;
