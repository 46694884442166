import PropTypes, { InferProps } from 'prop-types';

export const appointmentOrderPropType = {
  isDatePlannable: PropTypes.bool.isRequired,
  isDateCancellable: PropTypes.bool.isRequired,
  isDateRequestable: PropTypes.bool.isRequired,
  appointment: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  meterInstallationServiceProvider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  blocked: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
};

export type AppointmentOrder = InferProps<typeof appointmentOrderPropType>;

export type ContractAccountAssOrderId = {
  contractAccountId: string;
  assOrderIds: string[];
};

/**
 * Data of UI and API call
 */
export const appointmentGapPropType = {
  gapId: PropTypes.string.isRequired,
  isGreen: PropTypes.bool.isRequired,
  appointment: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};

export const appointmentGapList = {
  assOrderId: PropTypes.string.isRequired,
  gaps: PropTypes.arrayOf(PropTypes.shape(appointmentGapPropType)),
};

/**
 * Data of api call
 */
export const myAppointmentWishEntry = {
  weekday: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export const myAppointmentWish = {
  earliestDate: PropTypes.string.isRequired,
  proposals: PropTypes.arrayOf(PropTypes.shape(myAppointmentWishEntry)),
};

export type MyAppointmentProposalData = InferProps<typeof myAppointmentWishEntry>;
export type MyAppointmentWish = InferProps<typeof myAppointmentWish>;
