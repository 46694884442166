var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import React from 'react';
import Icon from '../Icon/Icon';
import CustomTooltip from '../Tooltip/Tooltip';
var ExpandableView = function (_a) {
    var id = _a.id, qaId = _a.qaId, openLabel = _a.openLabel, closeLabel = _a.closeLabel, deleteLabel = _a.deleteLabel, onDelete = _a.onDelete, children = _a.children, title = _a.title, _b = _a.titleMessage, titleMessage = _b === void 0 ? function () { return ''; } : _b, _c = _a.subTitleMessage, subTitleMessage = _c === void 0 ? function () { return ''; } : _c, subtitle = _a.subtitle, _d = _a.expandedTitle, expandedTitle = _d === void 0 ? title : _d, expandedSubtitle = _a.expandedSubtitle, hideSubtitleWhenExpanded = _a.hideSubtitleWhenExpanded, altDesign = _a.altDesign, expandableForm = _a.expandableForm, expandableIcon = _a.expandableIcon, expandableIconBeforeTitle = _a.expandableIconBeforeTitle, tooltip = _a.tooltip, isExpanded = _a.isExpanded, toggleContent = _a.toggleContent, _e = _a.headerTypeMain, headerTypeMain = _e === void 0 ? "h3" : _e, _f = _a.headerTypeAlt, headerTypeAlt = _f === void 0 ? "h5" : _f, _g = _a.expandableAriaLabel, expandableAriaLabel = _g === void 0 ? "Auf- oder Zusammenklappen des dazugehoerigen Abschnitts (toggle)" : _g;
    var handleToggle = function (e) { return toggleContent(e); };
    var handleDelete = function (e) { return onDelete(e); };
    var svgSize = '18';
    var BASE_CLASS = 'expandable-v2';
    var HeaderTypeMain = headerTypeMain;
    var HeaderTypeAlt = headerTypeAlt;
    var getTrigger = function (isDelete) {
        var wrapperClassName = classNames(isExpanded && !isDelete
            ? "".concat(BASE_CLASS, "__trigger-down")
            : isDelete
                ? "".concat(BASE_CLASS, "__trigger-delete")
                : "".concat(BASE_CLASS, "__trigger-up"));
        var iconClassName = classNames(isExpanded && !isDelete
            ? "".concat(BASE_CLASS, "__trigger-down-icon")
            : isDelete
                ? "".concat(BASE_CLASS, "__trigger-delete-icon")
                : "".concat(BASE_CLASS, "__trigger-up-icon"));
        var labelClassName = classNames(isExpanded && !isDelete
            ? "".concat(BASE_CLASS, "__trigger-down-label")
            : isDelete
                ? "".concat(BASE_CLASS, "__trigger-delete-label")
                : "".concat(BASE_CLASS, "__trigger-up-label"));
        var icon = isDelete ? "icon_loeschen_".concat(svgSize, "x").concat(svgSize) : "icon_arrow-down_36x36";
        return (React.createElement("div", { className: wrapperClassName },
            React.createElement("div", __assign({ className: iconClassName, role: "button", "aria-label": expandableAriaLabel, onClick: isDelete ? handleDelete : handleToggle }, (qaId ? { 'data-qa': "".concat(qaId, "_").concat(iconClassName) } : {})),
                React.createElement(Icon, { size: svgSize, glyph: icon })),
            !altDesign && (React.createElement("span", __assign({ className: labelClassName, role: "button", "aria-label": expandableAriaLabel, onClick: isDelete ? handleDelete : handleToggle }, (qaId ? { 'data-qa': "".concat(qaId, "_").concat(labelClassName) } : {})), isExpanded && !isDelete ? closeLabel : isDelete ? deleteLabel : openLabel))));
    };
    var renderedExpandableIcon = expandableIcon ? (React.createElement("div", { className: expandableIconBeforeTitle ? "".concat(BASE_CLASS, "-icon-left") : "".concat(BASE_CLASS, "-icon") }, expandableIcon)) : null;
    var renderedTooltip = tooltip ? (React.createElement("div", { className: "".concat(BASE_CLASS, "-icon ").concat(!isExpanded && 'collapsed-icon', " ").concat(BASE_CLASS, "-tooltip") },
        React.createElement(CustomTooltip, { qaId: "".concat(id, "Tool"), id: "".concat(id, "Tool"), targetElement: React.createElement(Icon, { size: "30", glyph: "icon_info_18x18" }), content: tooltip }))) : null;
    var subtitleText = (subtitle || expandedSubtitle) && isExpanded
        ? !hideSubtitleWhenExpanded && (expandedSubtitle || subtitle)
        : subtitle;
    var getPrimaryTitle = function (primaryTitle, expandedPrimaryTitle, expanded) {
        var hasTitleMessage = titleMessage() !== '';
        var hasSubTitleMessage = subTitleMessage() !== '';
        return !hasTitleMessage && !hasSubTitleMessage ? (React.createElement(HeaderTypeAlt, null, !expanded ? primaryTitle : expandedPrimaryTitle)) : (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(HeaderTypeAlt, { className: hasTitleMessage ? "".concat(BASE_CLASS, "__title-with-message") : null },
                    !expanded ? primaryTitle : expandedPrimaryTitle,
                    hasTitleMessage && !expanded && React.createElement("span", null, titleMessage()))),
            hasSubTitleMessage && !expanded && React.createElement("span", null, subTitleMessage())));
    };
    var getAltTitle = function (altTitle, expandedAltTitle, expanded) { return (React.createElement("div", { className: tooltip || renderedExpandableIcon ? "".concat(BASE_CLASS, "__tooltip-h3") : null }, expandableIconBeforeTitle ? (React.createElement(React.Fragment, null,
        React.createElement(HeaderTypeMain, null,
            renderedExpandableIcon,
            React.createElement("span", null, !expanded ? altTitle : expandedAltTitle),
            tooltip && React.createElement("span", { className: "".concat(BASE_CLASS, "__tooltip-span") }, renderedTooltip)))) : (React.createElement(React.Fragment, null,
        React.createElement(HeaderTypeMain, null,
            React.createElement("span", null, !expanded ? altTitle : expandedAltTitle),
            React.createElement("span", null, renderedExpandableIcon),
            tooltip && React.createElement("span", { className: "".concat(BASE_CLASS, "__tooltip-span") }, renderedTooltip)))))); };
    var wrapperClass = altDesign ? "".concat(BASE_CLASS, " alt-design") : "".concat(BASE_CLASS);
    return (React.createElement("div", __assign({ id: id, className: wrapperClass }, (qaId ? { 'data-qa': qaId } : {})),
        React.createElement("div", { className: "\n            ".concat(BASE_CLASS, "__trigger\n            ").concat(isExpanded ? 'expanded' : 'collapsed', "\n          ") },
            React.createElement("div", { className: "".concat(BASE_CLASS, "__trigger-label ").concat(BASE_CLASS, "__top") },
                React.createElement("div", __assign({ className: "".concat(BASE_CLASS, "__trigger-label-text") }, (qaId ? { 'data-qa': "".concat(qaId, "_").concat(BASE_CLASS, "__trigger-label-text") } : {})),
                    altDesign
                        ? getAltTitle(title, expandedTitle, isExpanded)
                        : getPrimaryTitle(title, expandedTitle, isExpanded),
                    subtitleText && React.createElement("p", { className: "".concat(BASE_CLASS, "__subtitle") }, subtitleText)),
                React.createElement("div", { className: "".concat(BASE_CLASS, "__trigger-label-button") },
                    getTrigger(),
                    (!isExpanded || !expandableForm) && onDelete && getTrigger(true))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-xs-12" },
                    React.createElement("div", { className: "".concat(BASE_CLASS, "__panel-").concat(isExpanded ? 'show' : 'hide') },
                        React.createElement("div", { className: "".concat(BASE_CLASS, "__content") }, children),
                        expandableForm && (React.createElement("div", null,
                            React.createElement("hr", { className: "".concat(BASE_CLASS, "__content-hr") }),
                            React.createElement("div", { className: "".concat(BASE_CLASS, "__footer") },
                                React.createElement("div", null, expandableForm),
                                React.createElement("div", null, onDelete && getTrigger(true)))))))))));
};
export default ExpandableView;
