import { InferProps } from 'prop-types';
import React, { useState } from 'react';
import { Button, InputState, Loader, TextInput } from '@eon-funke/react-shared-ui-next';
import { EMAIL_REGEX } from '../../../../../utils/RegExUtils';
import MeterReadingMailboxInfoPopup from './MeterReadingMailboxInfoPopup';
import { sendEmailData } from '../../../../../api-factory/meterReadingApi';
import { meterReadingEmailNotificationPropTypes } from './MeterReadingEmailFeedbackPropTypes';

export enum EmailFeedbackStatusEnum {
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  PENDING = 'pending',
}

const CHECKED_SVG_PATH = [
  'M18 32c7.732 0 14-6.268 14-14S25.732 4 18 4 4 10.268 4 18s6.268 ' +
    '14 14 14zm0 2C9.163 34 2 26.837 2 18S9.163 2 18 2s16 7.163 16 16-7.163 16-16 16z',
  'M16.016 25.872l-8.174-8.196 1.246-1.244 6.931 6.948 10.754-10.754 1.244 1.245-12 12',
];

type MeterReadingEmailFeedbackTypes = {
  onSuccess: (emailFeedbackStatus: string, email: string) => void;
  registerCount: number;
  missedMeter: number;
  amount: number;
  emailValidationError: string;
  emailAddressLabel: string;
  serverErrorMessage: string;
};

type MeterReadingEmailFeedbackState = {
  email: string;
  inputStatus: InputState;
  serverError: boolean;
  loading: boolean;
  showMailboxInfoPopup: boolean;
};

export type MeterReadingEmailFeedbackProps = InferProps<typeof meterReadingEmailNotificationPropTypes> & MeterReadingEmailFeedbackTypes;

const MeterReadingEmailFeedback: React.FC<MeterReadingEmailFeedbackProps> = (props) => {
  const [state, setState] = useState<MeterReadingEmailFeedbackState>({
    email: '',
    inputStatus: InputState.NONE,
    loading: false,
    serverError: false,
    showMailboxInfoPopup: false,
  });

  const buildAcknowledgeText = () => {
    let str = '';
    if (props.missedMeter === 0) {
      str = `${props.amount} ${props.metersRegisteredText}`;
    } else if (props.fromToMetersRegisteredText) {
      str = props.fromToMetersRegisteredText
        .replace('%%SUCCESS%%', (props.amount - props.missedMeter).toString())
        .replace('%%TOTAL%%', props.amount.toString());
    }
    return str;
  };

  const checkEmail = (value) => {
    const isEmailValid = value ? EMAIL_REGEX.test(String(value)) : false;
    setState({
      ...state,
      email: value,
      serverError: false,
      inputStatus: isEmailValid ? InputState.VALID : InputState.ERROR,
    });
    return isEmailValid;
  };

  const sendEmail = () => {
    const emailObj = JSON.parse(window.sessionStorage.getItem('emailObject') || '{}');
    if (emailObj) {
      emailObj.email = state.email;
      setState({
        ...state,
        loading: true,
      });

      const result = sendEmailData(emailObj);
      result.then(() => {
        window.sessionStorage.removeItem('emailObject');
        setState({
          ...state,
          loading: false,
          serverError: false,
          showMailboxInfoPopup: true,
        });
      });
      result.catch(() => {
        setState({
          ...state,
          loading: false,
          serverError: true,
          inputStatus: InputState.ERROR,
        });
      });
    }
  };

  const errorMessage = state.serverError ? props.serverErrorMessage : props.emailValidationError;
  const acknowledgeTextData = buildAcknowledgeText();

  if (state.loading) {
    return (
      <Loader
        loading
        fallbackGifSrc={sessionStorage.fallbackLoader}
      />
    );
  }

  return (
    <div className='row col-md-12 '>
      <div className='meter-reading-email-feedback__success-message'>
        <svg
          className='meter-reading-email-feedback__success-message__checked-svg'
          xmlns='http://www.w3.org/2000/svg'
          width='36'
          height='36'
          viewBox='0 0 36 36'
        >
          <g
            fill='#39CB2C'
            fillRule='evenodd'
          >
            <path
              fillRule='nonzero'
              d={CHECKED_SVG_PATH[0]}
            />
            <path d={CHECKED_SVG_PATH[1]} />
          </g>
        </svg>
        <h5 className='meter-reading-email-feedback__success-message'>{acknowledgeTextData}</h5>
      </div>
      <div className='col-xs-12 col-sm-8 col-md-8 col-sm-offset-2 col-md-offset-2 meter-reading-email-feedback__form-container'>
        <h4 className='meter-reading-email-feedback__headline'>{props.headLine}</h4>
        <p className='meter-reading-email-feedback__description'>{props.description}</p>
        <div className='meter-reading-email-feedback__form-input'>
          <TextInput
            onBlur={(e) => {
              checkEmail(e.target.value);
            }}
            onChange={(e) => {
              checkEmail(e.target.value);
            }}
            id='meter-reading-email-feedback-input'
            qaId='meter-reading-email-feedback-input'
            errorMessage={state.inputStatus === InputState.ERROR ? errorMessage : ''}
            type='text'
            name='email'
            value={state.email}
            label={props.emailAddressLabel}
            inputState={state.inputStatus}
          />
        </div>
        <div className='meter-reading-email-feedback__buttons'>
          <div>
            <Button
              className='meter-reading-email-feedback__buttons__decline'
              version={props.declineButton?.version}
              id='meter-reading-email-feedback-decline-button'
              qaId='meter-reading-email-feedback-decline-button'
              onClick={() => {
                props.onSuccess(EmailFeedbackStatusEnum.REFUSED, state.email);
              }}
              autoFocus={false}
            >
              {' '}
              {props.declineButton?.label}
            </Button>
          </div>
          <div>
            <Button
              id='meter-reading-email-feedback-accept-button'
              qaId='meter-reading-email-feedback-accept-button'
              version={props.acceptButton?.version}
              disabled={state.inputStatus !== InputState.VALID}
              onClick={() => {
                sendEmail();
              }}
            >
              {' '}
              {props.acceptButton?.label}
            </Button>
          </div>
        </div>
      </div>
      <MeterReadingMailboxInfoPopup
        {...props.mailboxPopup}
        showPopup={state.showMailboxInfoPopup}
        email={state.email}
        onClose={() => {
          setState({
            ...state,
            showMailboxInfoPopup: false,
          });
          props.onSuccess(EmailFeedbackStatusEnum.ACCEPTED, state.email);
        }}
      />
    </div>
  );
};

MeterReadingEmailFeedback.propTypes = meterReadingEmailNotificationPropTypes;
export default MeterReadingEmailFeedback;
