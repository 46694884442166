import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_NATIVE } from './date-validation';

moment.locale('de');

/*
 * format date for usage in date-picker
 * @param {string} property - property to look at. returns undefined if not found
 * @param {object} obj - object with property
 */
export const fixMonth = (property: number[]) => {
  if (Array.isArray(property)) {
    const clone = [...property];
    clone[1] -= 1;
    return clone;
  }
  return [1, 1, 2000];
};

/**
 * format date for usage in date-picker
 * @param {string} fixedDate - result from fixedMonth
 * @returns {Date} - date to use
 */
export const formatDate = (fixedDate) => moment(fixedDate).toDate();

/**
 * replace placeholders with dynamic values
 * @param {string} orgString - string with placeholder
 * @param {array} values - list of values to replace placeholder
 * @returns {string} newString - returns orgstring with values
 */
export const replaceStringPlaceholder = (orgString, values) => {
  const placeholder = '%%placeholder%%';
  const newString =
    values.length > 1
      ? values.map((item) => {
          values.shift();
          return replaceStringPlaceholder(orgString.replace(placeholder, item), values);
        })[0]
      : orgString.replace(placeholder, values);

  return newString;
};

export const getDatesBetween = (startDate, endDate) => {
  const dates: Date[] = [];
  // Strip hours minutes seconds etc.
  let currentDate = moment(startDate).toDate();

  while (currentDate < endDate) {
    dates.push(currentDate);

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1 // Will increase month if over range
    );
  }
  dates.shift();
  return dates;
};

/**
 * createMeasurementsList - returns data start date
 * @param {object} selectedDeviceByDateRange - deviceData for date range
 * @returns {date} - date as UTC in ms
 */
export const createMeasurementsList = (selectedDeviceByDateRange) => {
  const data =
    selectedDeviceByDateRange.length > 1
      ? selectedDeviceByDateRange.map((x) => moment(fixMonth(x.startTime)).valueOf())
      : moment(fixMonth(selectedDeviceByDateRange[0] && selectedDeviceByDateRange[0].startTime)).valueOf();
  return data;
};

export const getToDateLimit = (getTo) => {
  const invalidDate = moment(formatDate(fixMonth(getTo))).toDate();
  return invalidDate > new Date() ? new Date() : invalidDate;
};

export const getMeterDateRange = (ranges, getFrom) => {
  let meterRanges;

  if (ranges && ranges.length > 1) {
    const results = [];
    meterRanges = ranges.map((item, index) => {
      const dateRange = [];
      results.push(item);

      if (index === 0) {
        dateRange.push({
          before: formatDate(fixMonth(getFrom)),
          after: getToDateLimit(),
        });
      } else {
        dateRange.push(
          getDatesBetween(
            formatDate(fixMonth(results[index - 1].valid && results[index - 1].valid.to)),
            formatDate(fixMonth(results[index].valid && results[index].valid.from))
          )
        );
      }
      return dateRange;
    });
  } else {
    meterRanges = {
      before: formatDate(fixMonth(getFrom)),
      after: getToDateLimit(),
    };
  }
  return Array.isArray(meterRanges) ? meterRanges.flat(Infinity) : meterRanges;
};

export const getDefaultFromTo = (props, apiTo?) => {
  const nowObj = moment();
  const apiToObj = !apiTo ? nowObj : moment(fixMonth(apiTo));
  const toDate = apiToObj.valueOf() < nowObj.valueOf() ? apiToObj : nowObj.subtract(1, 'days');
  const fromDate = moment(toDate).subtract(1, 'month');
  const isDevice = props && (props.isMobile || props.isTablet);
  return {
    fromDate: {
      text: !isDevice ? fromDate.format(DATE_FORMAT) : fromDate.format(DATE_FORMAT_NATIVE),
      date: fromDate.toDate(),
      start: fromDate.toDate(),
      api: fromDate.format(DATE_FORMAT_NATIVE),
    },
    toDate: {
      text: !isDevice ? toDate.format(DATE_FORMAT) : toDate.format(DATE_FORMAT_NATIVE),
      date: toDate.toDate(),
      start: toDate.toDate(),
      api: toDate.format(DATE_FORMAT_NATIVE),
    },
  };
};
