import { getMeterReadingData } from '../api/meterReadingApi';

// Actions
const LOAD_CURRENT = 'api/reducer/METER_READ';
const PAGINATE = 'reducer/METER_READ/PAGINATE';
export const ERROR_LIST = 'reducer/METER_READ/ERROR_LIST';
const BLUR_STATUS = 'reducer/METER_READ/BLUR_STATUS';
const CLICK_STATUS = 'reducer/METER_READ/CLICK_STATUS';
const ERROR_HINT = 'reducer/METER_READ/ERROR_HINT';

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_CURRENT:
      return {
        state,
        action: action.data.innerData,
        currentPageId: action.data.currentPageId,
      };
    case PAGINATE:
      return {
        ...state,
        currentPageId: action.data.currentPageId,
      };
    case ERROR_LIST:
      return {
        ...state,
        errorObj: action.data.errorObj,
      };
    case BLUR_STATUS:
      return {
        ...state,
        blurStatus: action.data.blurStatus,
      };
    case CLICK_STATUS:
      return {
        ...state,
        clickStatus: action.data.clickStatus,
      };
    case ERROR_HINT:
      return {
        ...state,
        errorHint: {
          code: action.data.code,
          args: action.data.args,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export function loadCurrent(dispatch, currentPage) {
  const resp = getMeterReadingData();
  return resp
    .then((reponse) => {
      dispatch({
        type: LOAD_CURRENT,
        data: {
          currentPageId: currentPage,
          innerData: reponse.data.content,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR_HINT,
        data: {
          code: err?.response?.data?.errors[0]?.code,
          args: err?.response?.data?.errors[0]?.args,
        },
      });
    });
}

export function paginate(dispatch, currentPage) {
  dispatch({
    type: PAGINATE,
    data: {
      currentPageId: currentPage,
    },
  });
}

export function meterReadError(dispatch, errorObj) {
  dispatch({
    type: ERROR_LIST,
    data: {
      errorObj,
    },
  });
}

export function blurStatusForNextFocus(dispatch, blurStatus) {
  dispatch({
    type: BLUR_STATUS,
    data: {
      blurStatus,
    },
  });
}

export function onDropDownClick(dispatch, clickStatus) {
  dispatch({
    type: CLICK_STATUS,
    data: {
      clickStatus,
    },
  });
}
