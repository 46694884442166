import { Dispatch } from 'react';
import { TaxNumberChangeEvents, TaxNumberChangeEventType, TaxNumberTypes } from '../../pages/TaxVatDetailsPage/taxVatinPageTypes';
import { getTaxVatNumbers, saveTaxVatNumber } from '../../../api-factory/taxNumberApi';

enum TaxNumbersActions {
  IS_LOADING = 'IS_LOADING',
  LOAD_TAX_VAT_NUMBERS = 'LOAD_TAX_VAT_NUMBERS',
  SAVE_TAX_DATA = 'SAVE_TAX_DATA',
  SET_API_ERROR = 'SET_API_ERROR',
  SET_TAX_VAT_NUMBERS_CHANGE_EVENTS = 'SET_TAX_VAT_NUMBERS_CHANGE_EVENTS',
}

type TaxNumbersActionsTypes = {
  type: TaxNumbersActions;
  taxNumbersChangeEvent: TaxNumberChangeEventType;
  taxNumbers: TaxNumberTypes;
  apiErrorMessage: string;
  isLoading: boolean;
};

const loadTaxVatNumbers = (dispatch: Dispatch<Partial<TaxNumbersActionsTypes>>, contractAccountId: string) => {
  setLoader(dispatch);
  getTaxVatNumbers(contractAccountId)
    .then((response) => {
      dispatch({
        type: TaxNumbersActions.LOAD_TAX_VAT_NUMBERS,
        taxNumbers: response.data,
      });
    })
    .catch((error) => {
      setApiError(dispatch, true, error);
    });
};
const saveNewTaxVatData = (dispatch: any, contractAccountId: string, data: TaxNumberTypes) => {
  setLoader(dispatch);
  return saveTaxVatNumber(contractAccountId, data)
    .then((response) => {
      dispatch({
        type: TaxNumbersActions.SAVE_TAX_DATA,
        taxNumbers: response.data,
      });
    })
    .catch((error) => {
      setApiError(dispatch, true, error);
    });
};

const getEvent = (oldValue: string, newValue: string, defaultValue: string) => {
  if (oldValue === defaultValue && newValue !== defaultValue) {
    return TaxNumberChangeEvents.SAVE;
  }
  if (oldValue !== newValue) {
    return TaxNumberChangeEvents.UPDATE;
  }
  return TaxNumberChangeEvents.NONE;
};
function setTaxVatNumbersChangeEvents(dispatch, oldValues: TaxNumberTypes, newValues: TaxNumberTypes) {
  const taxinEvent = newValues?.taxin ? getEvent(oldValues.taxin, newValues.taxin, '') : TaxNumberChangeEvents.NONE;

  const vatinEvent = newValues?.vatin ? getEvent(oldValues.vatin, newValues.vatin, 'DE') : TaxNumberChangeEvents.NONE;

  dispatch({
    type: TaxNumbersActions.SET_TAX_VAT_NUMBERS_CHANGE_EVENTS,
    taxNumbersChangeEvent: {
      taxin: taxinEvent,
      vatin: vatinEvent,
    },
  });
}

function setLoader(dispatch, isLoading: boolean = true) {
  dispatch({
    type: TaxNumbersActions.IS_LOADING,
    isLoading,
  });
}

function setApiError(dispatch, hasApiError: boolean = false, error = { message: '' }) {
  dispatch({
    type: TaxNumbersActions.SET_API_ERROR,
    hasApiError,
    apiErrorMessage: error.message,
  });
}

export { TaxNumbersActions, TaxNumbersActionsTypes, loadTaxVatNumbers, saveNewTaxVatData, setTaxVatNumbersChangeEvents, setLoader, setApiError };
