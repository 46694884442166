export var InputState;
(function (InputState) {
    InputState[InputState["NONE"] = 1] = "NONE";
    InputState[InputState["VALID"] = 2] = "VALID";
    InputState[InputState["ERROR"] = 3] = "ERROR";
    InputState[InputState["PENDING"] = 4] = "PENDING";
})(InputState || (InputState = {}));
export var BLACKLIST_BROWSER = [
    { name: 'IE', major: '11' },
];
