import meterReading from './meterReading';
import registration from '../../common/features/Registration/registrationReducer';
import accountData from '../../common/features/AccountData/redux/accountData';
import contact from './contactLoggedIn';
import contracts from './contracts';
import search from '../../csc/redux/search';
import loggedInMeterReading from './loggedinMeterReading';
import invoice from './invoice';
import meterPins from './meterPins';
import postbox from '../../common/features/PostBox/redux/postboxReducer';
import myData from './personalData';
import budgetbillingplans from './budgetBillingPlan';
import consumptionSubscription from './consumptionSubscription';
import contractConsumptions from './contractConsumptions';
import rlm from './rlm';
import devices from './devices';
import phone from './phone';
import myAppointment from './myAppointment';
import communication from './communication';
import taxNumbers from './taxNumbers';
import intermediateReading from '../../common/features/IntermediateReading/intermediateReadingReducer';

const reducers = {
  accountData,
  budgetbillingplans,
  consumptionSubscription,
  devices,
  invoice,
  loggedInMeterReading,
  communication,
  contractConsumptions,
  contact,
  contracts,
  meterPins,
  meterReading,
  myAppointment,
  myData,
  phone,
  postbox,
  registration,
  rlm,
  search,
  taxNumbers,
  intermediateReading,
};

export default reducers;
