import { InferProps } from 'prop-types';
import React from 'react';
import { Button, PopUp } from '@eon-funke/react-shared-ui-next';
import { meterReadingMailboxInfoPopupPropTypes } from './MeterReadingEmailFeedbackPropTypes';

export type MeterReadingMailboxInfoPopupProps = InferProps<typeof meterReadingMailboxInfoPopupPropTypes> & {
  onClose: () => void;
  showPopup?: boolean;
  email: string;
};

const MeterReadingMailboxInfoPopup: React.FC<MeterReadingMailboxInfoPopupProps> = (props) => {
  return (
    <PopUp
      size='custom-size'
      userOnClose={() => {
        props.onClose();
      }}
      overlayOnClose={false}
      show={props.showPopup}
    >
      <div className='row meter-reading-mailbox-info__container'>
        <div className='col-xs-offset-1 col-xs-10'>
          <h4 className='meter-reading-mailbox-info__headline'>{props.headline}</h4>
          <p className='meter-reading-mailbox-info__subline'>
            {props.subline}
            <span className='meter-reading-mailbox-info__email'>{props.email}</span>
          </p>
          <p className='meter-reading-mailbox-info__description'>{props.description}</p>
          <div className='meter-reading-mailbox-info__buttons'>
            {' '}
            <Button
              version={props.closeButton?.version}
              id='meter-reading-mailbox-info_close-button'
              qaId='meter-reading-mailbox-info_close-button'
              onClick={() => {
                document.getElementsByTagName('html')[0].classList.remove('prevent-scroll');
                document.getElementsByTagName('html')[0].removeAttribute('style');
                props.onClose();
              }}
            >
              {props.closeButton?.label}
            </Button>
          </div>
        </div>
      </div>
    </PopUp>
  );
};

MeterReadingMailboxInfoPopup.propTypes = meterReadingMailboxInfoPopupPropTypes;
export default MeterReadingMailboxInfoPopup;
