import { AxiosResponse } from 'axios';
import { PhoneNumberType } from '../pages/PhoneNumberPage/PhoneNumberTypes';
import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';

function getPhoneNumbers(contractId): Promise<AxiosResponse<PhoneNumberType[]>> {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}/phonenumbers`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

function addPhoneNumber(phoneNumberObj, contractId) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}/phonenumbers`,
    phoneNumberObj,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}
function editPhoneNumbers(phoneNumberObject: PhoneNumberType, contractId: string) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}/phonenumbers/${phoneNumberObject.order}`,
    phoneNumberObject,
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

function deletePhoneNumber(phoneNumberObject: PhoneNumberType, contractId: string) {
  return RestHelper.delete(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/mydata/${contractId}/phonenumbers/${phoneNumberObject.order}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export default {
  getPhoneNumbers,
  addPhoneNumber,
  editPhoneNumbers,
  deletePhoneNumber,
};
