/* eslint-disable max-len */
import React from 'react';

const DateIcon = (): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36'
    height='36'
    viewBox='0 0 36 36'
  >
    <path
      fill='#767676'
      fillRule='evenodd'
      d='M11 29.5a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m0-6a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m22 6a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m-11-6a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m0-6a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m0 12a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m11-6a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m0-6a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5M34.5 35h-33a.501.501 0 0 1-.5-.5V9h34v25.5c0 .276-.225.5-.5.5M1.5 1h33a.5.5 0 0 1 .5.5V8H1V1.5a.5.5 0 0 1 .5-.5m33-1h-33C.673 0 0 .675 0 1.5v33c0 .828.673 1.5 1.5 1.5h33c.827 0 1.5-.672 1.5-1.5v-33c0-.826-.673-1.5-1.5-1.5'
    />
  </svg>
);

export default DateIcon;
