import contractCscApi from '../csc/api/contractApi';
import contractCicApi from '../cic/api/contractApi';

import { isCICContentTree } from '../mainConfig';

const currentApiSet = isCICContentTree ? contractCicApi : contractCscApi;
export const { getContracts, getHanAccessDetails, getContractAdditionalDetails, getContractConsumptionDetails, getContractConsumptions } =
  currentApiSet;

export default currentApiSet;
