import classNames from 'classnames';
import dompurify from 'dompurify';
import * as React from 'react';
export var RichText = function (_a) {
    var children = _a.children, id = _a.id, qaId = _a.qaId, className = _a.className, _b = _a.size, size = _b === void 0 ? 'default' : _b;
    var BASE_CLASS = 'rich-text';
    var classes = classNames("".concat(BASE_CLASS), size && "".concat(BASE_CLASS, "-").concat(size), className);
    var sanitizerConfig = { ADD_ATTR: ['target'] };
    var sanitizer = dompurify.sanitize;
    return (React.createElement("span", { id: id, "data-qa": qaId, className: classes, dangerouslySetInnerHTML: { __html: sanitizer(children, sanitizerConfig) } }));
};
RichText.displayName = 'RichTextComponent';
export default RichText;
