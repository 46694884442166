import { Dispatch } from 'react';
import { getInstallations } from '../../../../csc/api/companiesInTroubleApi';
import SessionHandler from '../../../../csc/utils/SessionHandler';
import Router from '../../../../utils/Router';
import { ContractProps } from '../types/CompaniesInTroubleTypes';

// Actions
enum InstallationsActions {
  LOAD_CURRENT_INSTALLATIONS = 'api/reducer/GET_INSTALLATIONS',
  SET_SELECTED_INSTALLATIONS = 'api/reducer/SET_SELECTED_INSTALLATIONS',
}

type InstallationsActionsTypes = {
  type: InstallationsActions;
  data: {
    innerData?: ContractProps[];
    harnesId?: string;
    pos?: number;
    contract?: ContractProps;
  };
};

export type InstallationsReducerStateTypes = {
  harnesId: string;
  contract: ContractProps | {};
  contractList: ContractProps[];
  selectedContract: string;
  elemPos: string;
};

const initialState: InstallationsReducerStateTypes = {
  harnesId: '',
  contract: {},
  contractList: [],
  selectedContract: '',
  elemPos: '',
};

export const getHarnesId = (contract: ContractProps | undefined) => contract?.harnesId;
export const getContract = (contracts: any, harnesId: string) =>
  contracts && contracts.find ? contracts.find((elem) => getHarnesId(elem) === harnesId) : null;

// Reducer
export default function reducer(state: InstallationsReducerStateTypes = initialState, action: InstallationsActionsTypes) {
  const getPreselectedContract = (contracts: any) => {
    const id = Router.getParameter('harnesId');

    if (id && contracts) {
      return id;
    }

    if (contracts) {
      if (contracts[0]) {
        const hId = getHarnesId(contracts[0]) || null;
        Router.setParameter({ harnesId: hId, pos: 0 });
        return hId;
      }
    }

    return null;
  };

  const getElemPosition = (contracts: any, harnesId: string) => {
    const id = Router.getParameter('pos');
    if (id) {
      return id;
    }
    const pos = contracts?.indexOf(getContract(contracts, harnesId));
    Router.setParameter({ pos });
    return pos;
  };

  switch (action.type) {
    case InstallationsActions.LOAD_CURRENT_INSTALLATIONS:
      return {
        ...state,
        contractsList: action.data.innerData,
        harnesId: getPreselectedContract(action.data.innerData),
        contract: getContract(action.data.innerData, getPreselectedContract(action.data.innerData)),
        elemPos: getElemPosition(action.data.innerData, getPreselectedContract(action.data.innerData)),
      };
    case InstallationsActions.SET_SELECTED_INSTALLATIONS:
      Router.setParameter({
        harnesId: action.data.harnesId,
        pos: action.data.pos,
      });
      Router.setParameter({ pos: action.data.pos });
      return {
        ...state,
        harnesId: action.data.harnesId,
        contract: action.data.contract,
        elemPos: action.data.pos,
      };

    default:
      return state;
  }
}

// Action Creators
export function loadCurrentInstallations(dispatch: Dispatch<InstallationsActionsTypes>) {
  const resp = getInstallations();
  return resp.then((response) => {
    dispatch({
      type: InstallationsActions.LOAD_CURRENT_INSTALLATIONS,
      data: {
        innerData: response.data ? response.data.content : {},
      },
    });
  });
}

export function setSelectedInstallation(dispatch: Dispatch<InstallationsActionsTypes>, harnesId: string, pos: number, contract: ContractProps) {
  SessionHandler.resetPermissions();
  dispatch({
    type: InstallationsActions.SET_SELECTED_INSTALLATIONS,
    data: {
      harnesId,
      pos,
      contract,
    },
  });
}
