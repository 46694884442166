import RestHelper, { MWDomains } from '../../../utils/RestHelper';
import CONFIG from '../../../mainConfig';

const apiPath = {
  intermediateReading: '/api/meterreading/v0/meters?filterBy=openReadingOrders&withInfoMessage=true',
};
function getIntermediateReadingMeters() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}${apiPath.intermediateReading}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.postalAuth)
  );
}

export { getIntermediateReadingMeters, apiPath };
