import RestHelper from '../utils/RestHelper';

export function getInstallationsInfo() {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/feedin/v0/installations`);
}
export function getInstallationDetails(selected) {
  // eslint-disable-next-line no-underscore-dangle
  return RestHelper.get(selected._links.self.href);
}
export default { getInstallationsInfo };
