import React from 'react';
import PropTypes from 'prop-types';
import { Loader, DatePicker, InputState, WithEnvInformation } from '@eon-funke/react-shared-ui-next';
import { Formik, Form } from 'formik';
import moment from 'moment';
import 'core-js/features/array/flat';
import SessionHandler, { loginTypes } from '../../../utils/SessionHandler';
import { dateFormatValid, isDateBefore, isDateAfter, isDateRangeGreaterThenThreshold, DATE_FORMAT, DATE_FORMAT_NATIVE } from './date-validation';

moment.locale('de');

const BASE_CLASS = 'ConsumptionVis-DateRange';

/*
 * format date for usage in date-picker
 * @param {string} property - property to look at. returns undefined if not found
 * @param {object} obj - object with property
 */
export const fixMonth = (property) => {
  if (Array.isArray(property)) {
    const clone = Array.isArray(property) && [...property];
    clone[1] -= 1;
    return clone;
  }
  return false;
};

/**
 * format date for usage in date-picker
 * @param {string} fixedDate - result from fixedMonth
 * @returns {Date} - date to use
 */
export const formatDate = (fixedDate) => moment(fixedDate).toDate();

/**
 * createMeasurementsList - returns data start date
 * @param {object} selectedDeviceByDateRange - deviceData for date range
 * @returns {date} - date as UTC in ms
 */
const createMeasurementsList = (selectedDeviceByDateRange) => {
  const data =
    selectedDeviceByDateRange.length > 1
      ? selectedDeviceByDateRange.map((x) => moment(fixMonth(x.startTime)).valueOf())
      : moment(fixMonth(selectedDeviceByDateRange[0] && selectedDeviceByDateRange[0].startTime)).valueOf();
  return data;
};

/**
 * checks current state of date field
 * @param {boolean} error - error flag for field
 * @param {boolean} touched - control flag if field already touched
 * @returns {enum} InputState - ERROR or default NONE
 */
export const checkInput = (error, touched) => {
  if (touched && error) {
    return InputState.ERROR;
  }
  return InputState.NONE;
};

/**
 * replace placeholders with dynamic values
 * @param {string} orgString - string with placeholder
 * @param {array} values - list of values to replace placeholder
 * @returns {string} newString - returns orgstring with values
 */
export const replaceStringPlaceholder = (orgString, values) => {
  const placeholder = '%%placeholder%%';
  const newString =
    values.length > 1
      ? values.map((item) => {
          values.shift();
          return replaceStringPlaceholder(orgString.replace(placeholder, item), values);
        })[0]
      : orgString.replace(placeholder, values);

  return newString;
};

export const getDatesBetween = (startDate, endDate) => {
  const dates = [];
  // Strip hours minutes seconds etc.
  let currentDate = moment(startDate).toDate();

  while (currentDate < endDate) {
    dates.push(currentDate);

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1 // Will increase month if over range
    );
  }
  dates.shift();
  return dates;
};

/* eslint-disable react/prop-types */
// not possible because propTypes not recognized after assignment
export const DateRangeForm = (props) => (
  <Form>
    <div className='col-md-6 col-sm-6 col-xs-12' style={{ position: 'relative' }}>
      <DatePicker
        disabled={props.orgProps.deviceDataProcceding}
        id={props.orgProps.dateFrom.id}
        locale='de'
        daysLongLocalized={moment.weekdays()}
        daysShortLocalized={moment.weekdaysMin()}
        monthsLocalized={moment.months()}
        name={props.orgProps.dateFrom.id}
        qaId={`qa-${props.orgProps.dateFrom.id}`}
        label={props.orgProps.dateFrom.label}
        disabledDays={props.getMeterDateRange()}
        initialShownMonth={props.formikProps.values.fromDate.date}
        showOverlay={props.orgState.dateFromIsOpen && !props.orgProps.deviceDataProcceding}
        onBlur={() => props.handleBlur('from')}
        onFocus={() => props.setState({ dateFromIsOpen: true })}
        onClick={() => props.setState({ dateFromIsOpen: true })}
        onChange={(date) => props.handleChange(date, 'from', props.formikProps)}
        value={props.formikProps.values.fromDate}
        useNative={props.orgProps.isMobile || props.orgProps.isTablet}
        inputState={checkInput(props.formikProps.errors.fromDate, props.formikProps.touched.fromDate)}
        errorMessage={
          checkInput(props.formikProps.errors.fromDate, props.formikProps.touched.fromDate) === InputState.ERROR && props.formikProps.errors.fromDate
        }
      />
    </div>
    <div className='col-md-6 col-sm-6 col-xs-12' style={{ position: 'relative' }}>
      <DatePicker
        disabled={props.orgProps.deviceDataProcceding}
        id={props.orgProps.dateTo.id}
        locale='de'
        daysLongLocalized={moment.weekdays()}
        daysShortLocalized={moment.weekdaysMin()}
        monthsLocalized={moment.months()}
        name={props.orgProps.dateTo.id}
        qaId={`qa-${props.orgProps.dateTo.id}`}
        label={props.orgProps.dateTo.label}
        disabledDays={props.getMeterDateRange()}
        initialShownMonth={props.formikProps.values.toDate.date}
        showOverlay={props.orgState.dateToIsOpen && !props.orgProps.deviceDataProcceding}
        onBlur={() => props.handleBlur('to')}
        onFocus={() => props.setState({ dateToIsOpen: true })}
        onClick={() => props.setState({ dateToIsOpen: true })}
        onChange={(date) => props.handleChange(date, 'to', props.formikProps)}
        value={props.formikProps.values.toDate}
        useNative={props.orgProps.isMobile || props.orgProps.isTablet}
        inputState={checkInput(props.formikProps.errors.toDate, props.formikProps.touched.toDate)}
        errorMessage={
          checkInput(props.formikProps.errors.toDate, props.formikProps.touched.toDate) === InputState.ERROR && props.formikProps.errors.toDate
        }
      />
    </div>
  </Form>
);

const getDefaultFromTo = (props, apiTo, apiFrom) => {
  const nowObj = moment();
  const apiToObj = !apiTo ? nowObj : moment(fixMonth(apiTo));

  const toDate = apiToObj.valueOf() < nowObj.valueOf() ? apiToObj : nowObj.subtract(1, 'days');
  let fromDate = moment(toDate).subtract(1, 'month');
  if (apiFrom) {
    const apiFromObj = moment(fixMonth(apiFrom));
    if (fromDate.isBefore(apiFromObj)) {
      fromDate = apiFromObj;
    }
  }
  const isDevice = props && (props.isMobile || props.isTablet);
  return {
    fromDate: {
      text: !isDevice ? fromDate.format(DATE_FORMAT) : fromDate.format(DATE_FORMAT_NATIVE),
      date: fromDate.toDate(),
      start: fromDate.toDate(),
      api: fromDate.format(DATE_FORMAT_NATIVE),
    },
    toDate: {
      text: !isDevice ? toDate.format(DATE_FORMAT) : toDate.format(DATE_FORMAT_NATIVE),
      date: toDate.toDate(),
      start: toDate.toDate(),
      api: toDate.format(DATE_FORMAT_NATIVE),
    },
  };
};

/* eslint-enable react/prop-types */

/**
 * @module ContractNavigation
 */

class ReadingDateRange extends React.Component {
  constructor() {
    super();
    this.selectedDevice = {};
    this.state = {
      dateFromIsOpen: false,
      dateToIsOpen: false,
      initialDate: false,
    };
  }

  UNSAFE_componentWillMount() {
    SessionHandler.userMustBeLoggedIn(loginTypes.loggedIn, loginTypes.callCenter);
    this.setState(getDefaultFromTo(this.props));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.devices && nextProps.selectedDeviceId) {
      this.selectedDevice = nextProps.devices.find((elem) => this.props.getDeviceId(elem) === nextProps.selectedDeviceId);

      const defaultDateForDevice = this.props.getBoundaries
        ? this.props.getBoundaries(
            getDefaultFromTo(this.props, this.props.getTo(this.selectedDevice), this.props.getFrom(this.selectedDevice)),
            this.selectedDevice
          )
        : getDefaultFromTo(this.props, this.props.getTo(this.selectedDevice), this.props.getFrom(this.selectedDevice));
      // device change
      if (this.props.selectedDeviceId !== nextProps.selectedDeviceId) {
        this.setState({
          initialDate: true,
        });

        this.props.setSelectedDate(defaultDateForDevice.fromDate.api, defaultDateForDevice.toDate.api);
        if (this.formikState) {
          this.formikState.resetForm({ values: { ...defaultDateForDevice } });
        }
        this.props.getDeviceData(this.selectedDevice, {
          selectedStartDate: defaultDateForDevice.fromDate.api,
          selectedEndDate: defaultDateForDevice.toDate.api,
        });
      }

      // date change
      if (
        this.props.selectedStartDate !== nextProps.selectedStartDate ||
        this.props.selectedEndDate !== nextProps.selectedEndDate ||
        this.state.initialDate
      ) {
        if (this.formikState && this.state.initialDate) {
          this.formikState.resetForm({ values: { ...defaultDateForDevice } });
        }

        this.setState((prevState) => ({
          initialDate: false,
        }));

        this.props.getDeviceData(this.selectedDevice, nextProps);
      }

      // device data change
      if (this.props.deviceData !== nextProps.deviceData) {
        this.props.setStartTime(createMeasurementsList(nextProps.deviceData));
      }
    }
  }

  getMeterDateRange() {
    let meterRanges;
    const ranges = this.selectedDevice.dateRanges;

    if (ranges && ranges.length > 1) {
      const results = [];
      meterRanges = ranges.map((item, index) => {
        const dateRange = [];
        results.push(item);

        if (index === 0) {
          dateRange.push({
            before: formatDate(fixMonth(this.props.getFrom(this.selectedDevice))),
            after: this.getToDateLimit(),
          });
        } else {
          dateRange.push(
            getDatesBetween(
              formatDate(fixMonth(results[index - 1].valid && results[index - 1].valid.to)),
              formatDate(fixMonth(results[index].valid && results[index].valid.from))
            )
          );
        }
        return dateRange;
      });
    } else {
      meterRanges = {
        before: formatDate(fixMonth(this.props.getFrom(this.selectedDevice))),
        after: this.getToDateLimit(),
      };
    }
    return Array.isArray(meterRanges) ? meterRanges.flat(Infinity) : meterRanges;
  }

  getToDateLimit() {
    const invalidDate = moment(formatDate(fixMonth(this.props.getTo(this.selectedDevice)))).toDate();
    return invalidDate * 1 > new Date() * 1 ? new Date() : invalidDate;
  }

  handleBlur(ref) {
    if (ref === 'from') {
      this.setState({ dateFromIsOpen: false });
    } else {
      this.setState({ dateToIsOpen: false });
    }
  }

  async handleChange(dateObj, ref, formikBag) {
    const newDateObj = { ...dateObj, start: dateObj.date };
    this.setState({
      initialDate: false,
    });
    if (ref === 'from') {
      formikBag.setFieldValue('fromDate', newDateObj);
      formikBag.setFieldTouched('fromDate');

      this.setState({
        initialDate: false,
      });

      await Promise.resolve();

      formikBag.submitForm();

      this.setState({
        dateFromIsOpen: false,
      });
    } else {
      formikBag.setFieldValue('toDate', newDateObj);
      formikBag.setFieldTouched('toDate');
      this.setState({
        initialDate: false,
      });
      await Promise.resolve();

      formikBag.submitForm();

      this.setState({
        dateToIsOpen: false,
      });
    }
  }

  render() {
    if (this.props.devices && this.props.selectedDeviceId) {
      this.selectedDevice = this.props.devices.find((elem) => this.props.getDeviceId(elem) === this.props.selectedDeviceId);
    }
    return (
      <div className={`${BASE_CLASS}`}>
        {this.selectedDevice && this.props.getFrom(this.selectedDevice) ? (
          <Formik
            initialValues={{
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }}
            validate={(values) => {
              const errors = {};

              const meterRange = this.getMeterDateRange();

              const meterDateRangeMin = meterRange.length ? meterRange[0].before : meterRange.before;

              const meterDateRangeMax = meterRange.length ? meterRange[0].after : meterRange.after;
              // errors for fromDate field
              if (!dateFormatValid(values.fromDate.text, this.props)) {
                // is date format valid
                errors.fromDate = this.props.errorMessages.general;
              } else if (isDateBefore(values.fromDate.date, meterDateRangeMin)) {
                // is entered date before validFrom
                errors.fromDate = replaceStringPlaceholder(this.props.errorMessages.fromDateNotInRange, [
                  moment(formatDate(fixMonth(this.props.getFrom(this.selectedDevice))))
                    .subtract(1, 'days')
                    .format(DATE_FORMAT),
                ]);
              } else if (isDateAfter(values.fromDate.date, meterDateRangeMax)) {
                // is entered date after today
                errors.fromDate = replaceStringPlaceholder(this.props.errorMessages.toDateNotInRange, [
                  moment(this.getToDateLimit()).add(1, 'days').format(DATE_FORMAT),
                ]);
              } else if (
                dateFormatValid(values.fromDate.text, this.props) &&
                dateFormatValid(values.toDate.text, this.props) &&
                isDateAfter(values.fromDate.date, values.toDate.date)
              ) {
                // is fromDate after toDate
                errors.fromDate = this.props.errorMessages.dateBackwardsInTime;
              }

              // errors for toDate
              if (!dateFormatValid(values.toDate.text, this.props)) {
                // is date format valid
                errors.toDate = this.props.errorMessages.general;
              } else if (isDateBefore(values.toDate.date, meterDateRangeMin)) {
                // is entered date before validFrom
                errors.toDate = replaceStringPlaceholder(this.props.errorMessages.fromDateNotInRange, [
                  moment(formatDate(fixMonth(this.props.getFrom(this.selectedDevice))))
                    .subtract(1, 'days')
                    .format(DATE_FORMAT),
                ]);
              } else if (isDateAfter(values.toDate.date, meterDateRangeMax)) {
                // is entered date after today
                errors.toDate = replaceStringPlaceholder(this.props.errorMessages.toDateNotInRange, [
                  moment(this.getToDateLimit()).add(1, 'days').format(DATE_FORMAT),
                ]);
              } else if (
                dateFormatValid(values.fromDate.text, this.props) &&
                dateFormatValid(values.toDate.text, this.props) &&
                isDateBefore(values.toDate.date, values.fromDate.date)
              ) {
                // is fromDate after toDate
                errors.toDate = this.props.errorMessages.dateBackwardsInTime;
              }

              if (
                this.props.oneYearLimit &&
                dateFormatValid(values.fromDate.text, this.props) &&
                dateFormatValid(values.toDate.text, this.props) &&
                isDateRangeGreaterThenThreshold(values.fromDate.date, values.toDate.date)
              ) {
                // is date range selected larger then allowed (1 year)
                errors.fromDate = this.props.errorMessages.dateRangeBiggerThenAllowed;
                errors.toDate = this.props.errorMessages.dateRangeBiggerThenAllowed;
              }

              return errors;
            }}
            onSubmit={(values) => {
              this.props.setSelectedDate(
                moment(values.fromDate.date).format(DATE_FORMAT_NATIVE),
                moment(values.toDate.date).format(DATE_FORMAT_NATIVE)
              );
            }}
            render={(formikProps) => {
              this.formikState = formikProps;
              return (
                <div className='row consumption-visualization-date-range'>
                  <DateRangeForm
                    formikProps={formikProps}
                    orgProps={this.props}
                    orgState={this.state}
                    setState={(obj) => this.setState(obj)}
                    getMeterDateRange={() => this.getMeterDateRange()}
                    handleBlur={(e) => this.handleBlur(e)}
                    handleChange={(dateObj, ref, formikBag) => this.handleChange(dateObj, ref, formikBag)}
                  />
                </div>
              );
            }}
          />
        ) : (
          <div className='contract-navigation-loader-wrapper form-wrapper form-wrapper--has-shadow'>
            <Loader loading={!this.selectedDevice} fallbackGifSrc={sessionStorage.fallbackLoader} />
          </div>
        )}
      </div>
    );
  }
}
ReadingDateRange.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  devices: PropTypes.array,
  deviceData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedDeviceId: PropTypes.string,
  selectedStartDate: PropTypes.string,
  selectedEndDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
  setStartTime: PropTypes.func,
  getDeviceData: PropTypes.func,
  errorMessages: PropTypes.shape({
    general: PropTypes.string,
    fromDateNotInRange: PropTypes.string,
    toDateNotInRange: PropTypes.string,
    dateBackwardsInTime: PropTypes.string,
    dateRangeBiggerThenAllowed: PropTypes.string,
  }),
  getDeviceId: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  dateFrom: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dateTo: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  getFrom: PropTypes.func,
  getTo: PropTypes.func,
  oneYearLimit: PropTypes.bool,
  getBoundaries: PropTypes.func,
};

export default WithEnvInformation({
  getBrowserInfo: true,
  getDeviceInfo: true,
})(ReadingDateRange);
