import { CommunicationActionType, CommunicationReducerActionTypes } from '../actions/communications/communicationActions';
import { UnsubscribeApiErrorTypes } from '../components/Communication/communicationTypes';

export enum CommunicationStages {
  FirstStep = 'FirstStep',
  SuccesStep = 'SuccesStep',
}

export type CommunicationReducerStateTypes = {
  step: CommunicationStages;
  isLoading: boolean;
  apiError: UnsubscribeApiErrorTypes;
};

const initialState: CommunicationReducerStateTypes = {
  step: CommunicationStages.FirstStep,
  isLoading: false,
  apiError: {
    activationLinkExpired: false,
    policyCheckFailed: false,
    unknownError: false,
    policyCheckFailedGp: false,
    policyCheckFailedVk: false,
  },
};

const communicationReducer = (
  state: CommunicationReducerStateTypes = initialState,
  action: CommunicationReducerActionTypes
): CommunicationReducerStateTypes => {
  switch (action.type) {
    case CommunicationActionType.SUBSCRIBE_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiError: {} as UnsubscribeApiErrorTypes,
        step: CommunicationStages.SuccesStep,
      };
    case CommunicationActionType.LOAD_COMMUNICATION_DATA:
      return {
        ...state,
        isLoading: true,
        apiError: {
          activationLinkExpired: false,
          policyCheckFailed: false,
          unknownError: false,
          policyCheckFailedGp: false,
          policyCheckFailedVk: false,
        },
      };
    case CommunicationActionType.SUBSCRIBE_SAVE_FAIL:
      return {
        ...state,
        isLoading: false,
        apiError: action.apiError,
      };
    default:
      return state;
  }
};

export default communicationReducer;
