import { getAllMeterPins } from '../api/meterReadingApi';

// Actions
const LOAD_METER_PINS = 'api/reducer/LOAD_METER_PINS';
const SET_SELECTED_METER = 'api/reducer/SET_SELECTED_METER';

// Reducer
export default function reducer(state = {}, action) {
  const getPreselectedMeterNumber = (meters) => {
    if (meters[0]) {
      return meters[0].serialNumber;
    }
    return null;
  };
  switch (action.type) {
    case LOAD_METER_PINS:
      return {
        ...state,
        data: action.data.innerData,
        meterSerialNumber: getPreselectedMeterNumber(action.data.innerData),
      };
    case SET_SELECTED_METER:
      return {
        ...state,
        meterSerialNumber: action.data.serialNumber,
      };
    default:
      return state;
  }
}

// Action Creators
export function loadMeters(dispatch) {
  const resp = getAllMeterPins();
  return resp.then((reponse) => {
    dispatch({
      type: LOAD_METER_PINS,
      data: {
        innerData: reponse.data.content,
      },
    });
  });
}

export function setSelectedMeter(dispatch, serialNumber) {
  dispatch({
    type: SET_SELECTED_METER,
    data: {
      serialNumber,
    },
  });
}
