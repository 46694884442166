import * as React from 'react';
var popUpId;
var findParent = function (node, id) {
    return node.id === id || (node.parentNode && findParent(node.parentNode, id));
};
function focusBack(e) {
    if (!findParent(e.target, popUpId)) {
        e.preventDefault();
        var closeButton = document.getElementById('md-close-btn');
        closeButton === null || closeButton === void 0 ? void 0 : closeButton.focus();
    }
}
export { focusBack, findParent };
var PopUp = function (_a) {
    var userOnClose = _a.userOnClose, show = _a.show, children = _a.children, id = _a.id, _b = _a.size, size = _b === void 0 ? '' : _b, hideOverlay = _a.hideOverlay, _c = _a.overlayOnClose, overlayOnClose = _c === void 0 ? true : _c, openInParent = _a.openInParent, className = _a.className, title = _a.title, textBox = _a.textBox;
    popUpId = id;
    var html = document.querySelector('html');
    var disablePreventScroll = function () {
        var marginTop = parseInt(html.style.marginTop, 10);
        html.classList.remove('prevent-scroll');
        if (Math.abs(marginTop) > 0) {
            html.style.marginTop = '0px';
            window.scrollTo(0, -marginTop);
        }
    };
    var enablePreventScroll = function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        html.classList.add('prevent-scroll');
        html.style.marginTop = "".concat(-scrollTop, "px");
    };
    var closeModal = function () {
        document.body.removeEventListener('focusin', focusBack);
        disablePreventScroll();
    };
    var onClose = function () {
        closeModal();
        if (userOnClose) {
            userOnClose();
        }
    };
    var openModal = function () {
        document.body.addEventListener('focusin', focusBack);
        if (!openInParent) {
            enablePreventScroll();
        }
    };
    if (!show) {
        closeModal();
    }
    else {
        openModal();
    }
    var modalShow = show ? 'md md-show' : 'md';
    var parentClass = openInParent ? 'md-in-parent' : '';
    var modalClassName = "".concat(modalShow, " ").concat(parentClass, " ").concat(size, " ").concat(className || '');
    return (React.createElement("div", { id: id, className: "md-react-popup" },
        React.createElement("div", { className: modalClassName },
            React.createElement("div", { className: "md-content" },
                title && React.createElement("h4", null, title),
                textBox && React.createElement("p", null, textBox),
                children),
            React.createElement("button", { id: "md-close-btn", className: "md-close", onClick: onClose },
                React.createElement("span", { className: "icon-close" }))),
        !hideOverlay && React.createElement("div", { className: "md-overlay", onClick: overlayOnClose ? onClose : null })));
};
export default PopUp;
