import { getAccountData, saveAccountData, resetPasswordInitiate } from '../../../../api-factory/accountDataApi';
import decodeEntities from '../../../../utils/StringHelpers';
import CacheHandler from '../../../../utils/CacheHandler';

// Actions
const LOAD_ACCOUNT_DATA = 'api/reducer/GET_ACCOUNT_DATA';
const SAVE_ACCOUNT_DATA = 'api/reducer/SAVE_ACCOUNT_DATA';
const INITIATE_PASSWORD_RESET = 'api/reducer/INITIATE_PASSWORD_RESET';
const INITIATE_PASSWORD_RESET_DONE = 'api/reducer/INITIATE_PASSWORD_RESET_DONE';
const INITIATE_PASSWORD_RESET_ERROR = 'api/reducer/INITIATE_PASSWORD_RESET_ERROR';

// Helper
const getDecodedValueIfString = (data) => (acc, key) => ({
  ...acc,
  [key]: typeof data[key] === 'string' ? decodeEntities(data[key]) : data[key],
});

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_ACCOUNT_DATA:
      return {
        ...state,
        data: Object.keys(action.data.innerData).reduce(getDecodedValueIfString(action.data.innerData), {}),
      };
    case SAVE_ACCOUNT_DATA:
      return {
        ...state,
        data: Object.keys(action.data.innerData).reduce(getDecodedValueIfString(action.data.innerData), {}),
      };
    case INITIATE_PASSWORD_RESET:
      return {
        ...state,
        isRequestPending: true,
      };
    case INITIATE_PASSWORD_RESET_DONE:
      return {
        ...state,
        isRequestPending: false,
        resetData: action.data,
      };
    case INITIATE_PASSWORD_RESET_ERROR: {
      // Only one errorcode is possible
      // When FIELD_CONTENT error only captcha could be wrong
      const err = action.data.error.response.data.errors.pop().code;
      const errorType = err === 'FIELD_CONTENT' ? `${err}_captcha` : err;
      return {
        ...state,
        isRequestPending: false,
        error: errorType,
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const initiatePasswordReset = (dispatch, payload, config) => {
  dispatch({
    type: INITIATE_PASSWORD_RESET,
  });
  const rsp = resetPasswordInitiate({ ...payload }, config);

  rsp
    .then((response) => {
      if (response.status === 201) {
        dispatch({
          type: INITIATE_PASSWORD_RESET_DONE,
          data: {
            success: true,
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: INITIATE_PASSWORD_RESET_ERROR,
        data: {
          success: false,
          error: err,
        },
      });
    });
  return rsp;
};

export const loadCurrentAccountData = (dispatch) =>
  getAccountData().then((response) => {
    const loadedData = response && response.data;
    dispatch({
      type: LOAD_ACCOUNT_DATA,
      data: {
        innerData: loadedData,
      },
    });
  });

export const saveCurrentAccountData = (dispatch, data) =>
  saveAccountData(data)
    .then(() => {
      dispatch({
        type: SAVE_ACCOUNT_DATA,
        data: {
          innerData: CacheHandler.getCurrentCacheByToken('accountData'),
        },
      });
    })
    .catch((err) => {
      const error = err && err.data && err.data.errors;
      throw error;
    });
