import PropTypes, { InferProps, shape } from 'prop-types';

import { getAppointmentGapList } from '../../../csc/api/myAppointmentApi';
import { AppointmentWeek } from '../../components/MyAppointment/MyAppointmentGapTable/MyAppointmentGapWeekUtil';

export const SET_APPOINTMENT_LOADING = 'api/reducer/APPOINTMENT_LOADING';
export const THROW_APPOINTMENT_ERROR = 'api/reducer/APPOINTMENT_ERROR';
export const LOAD_APPOINTMENT_GAPS = 'api/reducer/LOAD_APPOINTMENT_GAPS';

const myAppointmentLoadGapsAction = {
  data: shape({
    innerData: PropTypes.array.isRequired,
    week: PropTypes.object.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export type MyAppointmentLoadGapsAction = InferProps<typeof myAppointmentLoadGapsAction>;

export const loadAppointmentGapList = (dispatch: Function, contractAccountId: string, assOrderId: string, weeks: AppointmentWeek[]) => {
  if (contractAccountId && assOrderId && weeks.length > 0) {
    dispatch({ type: SET_APPOINTMENT_LOADING });

    getAppointmentGapList(contractAccountId, assOrderId, weeks[0].from, weeks[0].to)
      .then((responseGapList) => {
        if (responseGapList.data?.gaps?.length === 0 && weeks.length > 1) {
          loadAppointmentGapList(dispatch, contractAccountId, assOrderId, weeks.slice(1));
        }
        dispatch({
          type: LOAD_APPOINTMENT_GAPS,
          data: {
            innerData: responseGapList.data.gaps,
            week: weeks[0],
          },
        });
      })
      .catch((errorResponse) => {
        if (errorResponse?.response?.status === 404) {
          if (weeks?.length > 1) {
            loadAppointmentGapList(dispatch, contractAccountId, assOrderId, weeks.slice(1));
          } else {
            dispatch({
              type: LOAD_APPOINTMENT_GAPS,
              data: {
                innerData: [],
                week: weeks.length === 1 ? weeks[0] : null,
              },
            });
          }
        } else {
          dispatch({
            type: LOAD_APPOINTMENT_GAPS,
            data: {
              innerData: null,
            },
          });
        }
      });
  }
};
