import React from 'react';
import { Icon } from '@eon-funke/react-shared-ui-next';

export type EnergyTypeHelperTypes = {
  text: string;
  className: string;
};

export const getPowerUnitLabel = (unit: string): string => {
  switch (unit) {
    // Kilovoltampere
    case 'KVA':
      return 'kVA';
    // Kilowatt
    case 'KW':
      return 'kW';
    // Kilowattstunde
    case 'KWH':
      return 'kWh';
    // Megawatt
    case 'MGW':
      return 'MW';
    // Milliwatt
    case 'MW':
      return 'mW';
    // Kubikmeter
    case 'M3':
      return 'm³';
    default:
      return unit;
  }
};

export function elaboratedTypes(division, text) {
  const divisionText = text[division];
  let icon;
  switch (division) {
    case 'CONSUMPTION_GAS':
      icon = <div className='energy-type__gas' />;
      break;
    case 'CONSUMPTION_POWER':
      icon = <div className='energy-type__power' />;
      break;
    case 'FEEDIN_POWER_WATER':
      icon = (
        <Icon
          size='18'
          glyph='icon_einspeiser-wasser_18x18'
          className='asasd'
        />
      );
      break;
    case 'FEEDIN_GAS':
      icon = (
        <Icon
          size='18'
          glyph='icon_einspeiser-gas_18x18'
        />
      );
      break;
    case 'FEEDIN_POWER':
      icon = (
        <Icon
          size='18'
          glyph='icon_einspeiser-strom_18x18'
        />
      );
      break;
    case 'FEEDIN_POWER_CHP_ACT':
      icon = (
        <Icon
          size='18'
          glyph='icon_einspeiser-kwk_18x18'
        />
      );
      break;
    case 'FEEDIN_POWER_BIOMASS':
      icon = (
        <Icon
          size='18'
          glyph='icon_bio-gas_18x18'
        />
      );
      break;
    case 'FEEDIN_POWER_WIND':
      icon = (
        <Icon
          size='18'
          glyph='icon_einspeisemanagement_18x18'
        />
      );
      break;
    case 'FEEDIN_POWER_PHOTOELECTRIC':
      icon = (
        <Icon
          size='18'
          glyph='icon_solar_18x18'
        />
      );
      break;
    case 'FEEDIN_POWER_GEOTHERMAL':
      icon = (
        <Icon
          size='18'
          glyph='icon_waerme_18x18'
        />
      );
      break;
    default:
      icon = <span />;
  }
  return { text: divisionText, icon };
}

export type DivisionType = {
  gasText: string;
  powerText: string;
  convergenceText: string;
};

export default (division: string, divisionTextList: DivisionType): EnergyTypeHelperTypes => {
  let divisionText;
  let className;
  switch (division) {
    case 'GAS':
      divisionText = divisionTextList.gasText;
      className = 'loggedin-meter-energy-type__gas';
      break;
    case 'POWER':
      divisionText = divisionTextList.powerText;
      className = 'loggedin-meter-energy-type__power';
      break;
    case 'CONVERGENCE':
      divisionText = divisionTextList.convergenceText;
      className = 'loggedin-meter-energy-type__convergence';
      break;
    default:
      divisionText = '';
      className = '';
  }
  return { text: divisionText, className };
};
