import { Dispatch } from 'react';
import { getContracts } from '../../../api-factory/contractApi';
import SessionHandler from '../../../utils/SessionHandler';
import { ContractProps } from '../../components/Dashboardiii/propTypes/contractPropTypes';

enum ContractsActions {
  LOAD_CURRENT_CONTRACTS = 'api/reducer/GET_CONTRACTS',
  SET_SELECTED_CONTRACT = 'api/reducer/SET_SELECTED_CONTRACT',
  SET_CONTRACT_WITHOUT_HASH = 'api/reducer/SET_CONTRACT_WITHOUT_HASH',
}

type ContractsActionsTypes = {
  type: ContractsActions;
  data: {
    innerData?: ContractProps[];
    contractId?: string;
  };
};

function loadCurrent(dispatch: Dispatch<ContractsActionsTypes>) {
  const contractResponse = getContracts();
  return contractResponse
    .then((response) => {
      dispatch({
        type: ContractsActions.LOAD_CURRENT_CONTRACTS,
        data: {
          innerData: response.data.content,
        },
      });
    })
    .catch((error) => {
      throw error.response;
    });
}

function setSelectedContract(dispatch: Dispatch<ContractsActionsTypes>, contractId: string) {
  SessionHandler.resetPermissions();
  dispatch({
    type: ContractsActions.SET_SELECTED_CONTRACT,
    data: {
      contractId,
    },
  });
}

function setSelectedContractWithoutHash(dispatch: Dispatch<ContractsActionsTypes>, contractId: string) {
  SessionHandler.resetPermissions();
  dispatch({
    type: ContractsActions.SET_CONTRACT_WITHOUT_HASH,
    data: {
      contractId,
    },
  });
}

export { ContractsActions, loadCurrent, setSelectedContract, setSelectedContractWithoutHash, ContractsActionsTypes };
