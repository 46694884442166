var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
export var FormGroup = function (_a) {
    var className = _a.className, row = _a.row, disabled = _a.disabled, color = _a.color, check = _a.check, _b = _a.tag, Tag = _b === void 0 ? 'div' : _b, attributes = __rest(_a, ["className", "row", "disabled", "color", "check", "tag"]);
    var classes = classNames(className, color ? "has-".concat(color) : false, row ? 'row' : false, check ? 'form-check-v2' : 'form-group-v2', check && disabled ? 'disabled' : false);
    return React.createElement(Tag, __assign({}, attributes, { className: classes }));
};
FormGroup.displayName = 'FormGroupComponent';
export default FormGroup;
