import AppManager from '@eon-funke/react-aem-vendor-libs';
import { setSession, accountComponents, accountData } from '@eon-funke/ciam-account';

// TODO what's the difference between this main.less and the one in indexAEM.js

import RestHelper from './utils/RestHelper';
import Router from './utils/Router';
import CacheHandler from './utils/CacheHandler';
import GlobalErrorHandler from './utils/GlobalErrorHandler';
import SessionHandler, { loginTypes } from './utils/SessionHandler';
import ActivityWatcher, { tokenTypes } from './utils/ActivityWatcher';
import CICSessionHandlerEngine from './utils/SessionHandlerCic';
import CICreducers from './cic/redux/declaration';
import CSCreducers from './csc/redux/declaration';
import CONFIG, { isCICContentTree } from './mainConfig';
import EvngDomManipulator from './utils/EvngDomManipulator';

let reducers;
if (isCICContentTree) {
  reducers = CICreducers;
} else {
  reducers = CSCreducers;
}

// colors for development

if (CONFIG.AEM_CONFIG.isAuthorMode || process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log(
    'Are you wondering why you are not seeing your component being loaded? Check the readme section "React component startup troubleshooting"'
  );
}
if (CONFIG.AEM_CONFIG.siteVariable === 'EVNG') {
  EvngDomManipulator.initDomManipulations();
}

export { isCICContentTree as isCIC };

export const startAccountApp = (routes) => {
  const dkeComponents = routes.filter(
    (route) => route?.routeName === 'FlyoutMenu' || route.routeName === 'FlyoutButton' || route.routeName === 'SearchAutocomplete'
  );
  setSession();
  AppManager.startApp([...accountComponents, ...dkeComponents], {
    accountData,
  });
};

const startCicApp = async (routes) => {
  SessionHandler.setEngine(new CICSessionHandlerEngine());

  SessionHandler.startSession();
  if (SessionHandler.isUserLoggedIn(loginTypes.loggedIn)) {
    ActivityWatcher.initActivityObserving(
      540,
      () => (sessionStorage?.mockMode === '1' ? Promise.resolve() : RestHelper.refreshCiamToken()),
      () => RestHelper.refreshMiddlewareToken(),
      () => SessionHandler.destroySession()
    );
  }
  if (SessionHandler.isUserLoggedIn(loginTypes.callCenter)) {
    ActivityWatcher.initActivityObserving(
      540,
      () => {},
      () => RestHelper.refreshMiddlewareToken(),
      () => SessionHandler.destroySession()
    );
  }
  // adding mock service, if it is a holding site...
  if (!CONFIG.AEM_CONFIG.cscConfigAdded) {
    AppManager.startApp(
      [
        {
          routeName: 'FlyoutMenu',
          load: (container, callback) => {
            callback();
          },
        },
        {
          routeName: 'FlyoutButton',
          load: (container, callback) => {
            callback();
          },
        },
      ],
      {}
    );
    return;
  }
  sessionStorage.cscRedirectLock = '';
  sessionStorage.fallbackLoader = CONFIG.AEM_CONFIG.fallbackGifLoader;
  GlobalErrorHandler.errorPage500Path = CONFIG.AEM_CONFIG.errorPageFor500;
  GlobalErrorHandler.representativeInfoPage = CONFIG.AEM_CONFIG.representativeInfoPage;
  GlobalErrorHandler.redirectOn500 = CONFIG.AEM_CONFIG.globalErrorFlag === 'true';
  GlobalErrorHandler.addGlobalErrorMsgs(CONFIG.AEM_CONFIG.globalErrorMessages);
  if (CONFIG.AEM_CONFIG.isDkePage && !SessionHandler.isUserLoggedIn(loginTypes.loggedIn)) {
    const sharedComps = [];
    routes.forEach((route) => {
      if (route.routeName === 'FlyoutMenu') {
        sharedComps.push(route);
      } else if (route.routeName === 'FlyoutButton') {
        sharedComps.push(route);
      }
    });
    AppManager.startApp(sharedComps, {});
    return;
  }
  // Contact Form href link update for logged in and non-logged in
  // @TODO this should be moved the a separeted component
  const contactHrefElem = document.querySelector(`a[title=${CONFIG.AEM_CONFIG.contactForm.ContactLinkTitle}]`);
  const contactChannelHrelElem = document.querySelector(`a.contacts-channel__link[title=${CONFIG.AEM_CONFIG.contactForm.ContactChannelLinkTitle}]`);
  if (contactHrefElem !== null) {
    if (SessionHandler.isUserLoggedIn(loginTypes.loggedIn) || SessionHandler.isUserLoggedIn(loginTypes.callCenter)) {
      contactHrefElem.setAttribute('href', CONFIG.AEM_CONFIG.contactForm.LoggedInRedirectPage);
    } else {
      contactHrefElem.setAttribute('href', CONFIG.AEM_CONFIG.contactForm.NonLoggedInRedirectPage);
    }
  }
  if (contactChannelHrelElem !== null) {
    if (SessionHandler.isUserLoggedIn(loginTypes.loggedIn)) {
      contactChannelHrelElem.setAttribute('href', CONFIG.AEM_CONFIG.contactForm.LoggedInRedirectPage);
    } else {
      contactChannelHrelElem.setAttribute('href', CONFIG.AEM_CONFIG.contactForm.NonLoggedInRedirectPage);
    }
  }
  RestHelper.initBaseURLs(
    sessionStorage.mockMode && sessionStorage.mockMode === '1' ? JSON.parse(sessionStorage.mockUrls) : CONFIG.AEM_CONFIG.middlewareDomains
  );

  const jwtParameter = Router.getParameter('jwt');
  if (jwtParameter && !!Router.getParameter('isCallCenterMode')) {
    SessionHandler.destroySession(false);
    CacheHandler.clearAllCache();
    SessionHandler.initSession('Bearer', jwtParameter, loginTypes.callCenter);
    ActivityWatcher.registerNewActivity();
    ActivityWatcher.registerTokenRefreshing(tokenTypes.csc);
    ActivityWatcher.initActivityObserving(
      540,
      () => () => {},
      () => RestHelper.refreshMiddlewareToken(),
      () => SessionHandler.destroySession()
    );
    AppManager.startApp(routes, reducers);
  } else if (RestHelper.isCiamLogin()) {
    const promise = RestHelper.initCiamTokenExchange();
    promise.then(() => {
      AppManager.startApp(routes, reducers);
    });
  } else {
    AppManager.startApp(routes, reducers);
  }
};

export default startCicApp;
