import React from 'react';
import SessionHandler, { loginTypes, appTypes } from '../../../utils/SessionHandler';
import CONFIG, { isCICContentTree } from '../../../mainConfig';
import CacheHandler from '../../../utils/CacheHandler';
import { getContracts } from '../../../api-factory/contractApi';

/**
 * @TODO check if OK or if we need another behaviour
 */

class LoginBridge extends React.Component {
  static saveAccountObj() {
    const payload = SessionHandler.parseJwt(SessionHandler.getToken('ciam'));
    const covertCiamIdsToGridIds = (apps) => {
      return apps.split(',').map((elem) => {
        const internalAppid = elem.substring(3);
        return Object.keys(appTypes)
          .find((appType) => {
            return internalAppid.indexOf(appTypes[appType]) !== -1;
          })
          ?.toUpperCase();
      });
    };
    CacheHandler.saveCache({
      accountData: {
        id: payload.custom_attributes.egcid,
        firstName: payload.custom_attributes.firstname,
        mobilNumber: payload.custom_attributes.phone,
        email: payload.custom_attributes.newemail || payload.custom_attributes.email,
        oldEmail: payload.custom_attributes.newemail && payload.custom_attributes.email,
        salutation: payload.custom_attributes.salutation?.toLowerCase() === 'firma' ? 'COMPANY' : '',
        lastName: payload.custom_attributes.lastname,
        emailConfirmed: !payload.custom_attributes.newemail,
        has2FactorAuthEnabled: payload.custom_attributes.has2FactorAuthEnabled === 'true',
        apps: covertCiamIdsToGridIds(payload.custom_attributes.apps),
      },
    });
  }

  componentDidMount() {
    sessionStorage.cscRedirectLock = 1;
    const isLoggedIn = SessionHandler.isUserLoggedIn(loginTypes.loggedIn);
    const isAnonym = SessionHandler.isUserLoggedIn(loginTypes.anonym);
    const isCallCenterAgent = SessionHandler.isUserLoggedIn(loginTypes.callCenter);
    if (isLoggedIn || isAnonym) {
      LoginBridge.saveAccountObj();
      if (
        (!SessionHandler.isUserAssignedToApp(appTypes.cscn) && !isCICContentTree) ||
        (!SessionHandler.isUserAssignedToApp(appTypes.cic) && isCICContentTree)
      ) {
        alert(this.props.wrongAppIdMsg);
        SessionHandler.destroySession(true);
        return;
      }
      getContracts(SessionHandler.getToken('ciam'))
        .then((response) => {
          if (response.data.content.length !== 0) {
            location.replace(CONFIG.AEM_CONFIG.SSO.loginSuccessUrl);
          } else {
            SessionHandler.destroySession(true, CONFIG.AEM_CONFIG.inactiveAccountPageURL);
          }
        })
        .catch((errResp) => {
          const errList = errResp?.response?.data?.errors;
          if (Array.isArray(errList) && errList.find((elem) => elem.code === 'global.error.activationFailed')) {
            SessionHandler.destroySession(true, CONFIG.AEM_CONFIG.loginSapFailureUrl);
          }
        });
    } else if (isCallCenterAgent) {
      getContracts().then((response) => {
        if (response.data.content.length !== 0) {
          location.replace(decodeURIComponent(CONFIG.AEM_CONFIG.callCenterLandingPage));
        } else {
          SessionHandler.destroySession(true, CONFIG.AEM_CONFIG.inactiveAccountPageURL);
        }
      });
    } else if (!CONFIG.AEM_CONFIG.isAuthorMode) {
      const redirectUrl = CONFIG.AEM_CONFIG.SSO.loginFailureUrl;
      location.replace(redirectUrl);
    }
  }

  render() {
    return null;
  }
}

export default LoginBridge;
