import { DeviceDataTypes, ErrorHintArgsTypes, MeterReadingErrorObjectType } from '../../components/MeterRead/MeterReadingTypes';
import { getMeterReadingData } from '../../api/meterReadingApi';

export const LOAD_CURRENT = 'api/reducer/METER_READ';
export const LOADING = 'api/reducer/METER_READ/LOADING';
export const PAGINATE = 'reducer/METER_READ/PAGINATE';
export const CURRENT_STEP = 'reducer/METER_READ/CURRENT_STEP';
export const ERROR_LIST = 'reducer/METER_READ/ERROR_LIST';
export const ERROR_HINT = 'reducer/METER_READ/ERROR_HINT';

export type MeterReadingReducerActionTypes = {
  type: string;
  validReading: boolean;
  data: {
    devices: DeviceDataTypes[];
    currentPageId: number;
    currentStep: string;
    validReadings: boolean;
    errorObjects: MeterReadingErrorObjectType[];
    blurStatus: boolean;
    clickStatus: boolean;
    code: string;
    args: ErrorHintArgsTypes;
    loading: boolean;
  };
};

export function loadCurrent(dispatch, currentPage: number) {
  dispatch({
    type: LOADING,
    data: {
      loading: true,
    },
  });
  getMeterReadingData()
    .then((response) => {
      dispatch({
        type: LOAD_CURRENT,
        data: {
          devices: response.data.content,
          currentPageId: currentPage,
        },
      });
      dispatch({
        type: LOADING,
        data: {
          loading: false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_HINT,
        data: {
          code: error.response.data.errors[0].code,
          args: error.response.data.errors[0].args,
        },
      });
      dispatch({
        type: LOADING,
        data: {
          loading: false,
        },
      });
    });
}

export function setLoadingStatus(dispatch, loading: boolean) {
  dispatch({
    type: LOADING,
    data: {
      loading,
    },
  });
}

export function paginate(dispatch, currentPage) {
  dispatch({
    type: PAGINATE,
    data: {
      currentPageId: currentPage,
    },
  });
}

export function setCurrentStep(dispatch, step) {
  dispatch({
    type: CURRENT_STEP,
    data: {
      currentStep: step,
    },
  });
}

export function meterReadError(dispatch, errorObjects) {
  dispatch({
    type: ERROR_LIST,
    data: {
      errorObjects,
    },
  });
}
