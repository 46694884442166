(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("moment"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define("@eon-funke/react-shared-ui-next", ["moment", "react"], factory);
	else if(typeof exports === 'object')
		exports["@eon-funke/react-shared-ui-next"] = factory(require("moment"), require("react"));
	else
		root["@eon-funke/react-shared-ui-next"] = factory(root["Moment"], root["React"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_moment__, __WEBPACK_EXTERNAL_MODULE_react__) => {
return 