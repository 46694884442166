import React from 'react';
import { StandardLink } from '@eon-funke/react-shared-ui-next';
import SessionHandler from '../../../../../utils/SessionHandler';

export type NoRegisterErrorLabels = {
  registrationButtonTxt: string;
  logoutAndCloseButtonTxt: string;
  registrationButtonLink: string;
  logoutAndCloseButtonLink: string;
  logoutButtonTxt: string;
  anonymousMeterReadingLoginPage: string;
};

export type NoRegisterErrorTypes = {
  noRegisterError: string;
  isSmallText: boolean;
};

export type NoRegisterErrorProps = NoRegisterErrorLabels & NoRegisterErrorTypes;

const NoRegisterError = (props: NoRegisterErrorProps): JSX.Element => {
  SessionHandler.destroySession(false);
  const arrowLeftIcon = (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>E9B900B7-E313-43AA-B8A9-8918D6852A02</title>
      <g
        fill='none'
        fillRule='evenodd'
      >
        <path d='M6.874 15L6 14.126 11.126 9 6 3.874 6.874 3l6 6-6 6' />
      </g>
    </svg>
  );

  const showRegistrationAndLogoutButtons =
    props.registrationButtonTxt && props.registrationButtonLink && props.logoutAndCloseButtonTxt && props.logoutAndCloseButtonLink;

  const pathDescription =
    'M27 14.003c-1.103 0-2 .897-2 2s.897 ' +
    '2 2 2 2-.897 2-2-.897-2-2-2zm0 5c-1.654 0-3-1.346-3-3s1.346-3 ' +
    '3-3 3 1.346 3 3-1.346 3-3 3zm-2 23h4v-19h-4v19zm4.5 1h-5a.5.5 0 ' +
    '0 1-.5-.5v-20a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v20a.5.5 0 0 ' +
    '1-.5.5zm-2.5-42c-14.337 0-26 11.664-26 26 0 14.337 11.663 26 26 ' +
    '26s26-11.663 26-26c0-14.336-11.663-26-26-26zm0 53c-14.888 ' +
    '0-27-12.112-27-27s12.112-27 27-27 27 12.112 27 27-12.112 27-27 27z';

  return (
    <div className='no-meter-error-container row'>
      <div className='svg-bckgrd'>
        <svg
          className='no-meter-error-container__exclamation'
          width='54'
          height='55'
          viewBox='0 0 54 55'
          xmlns='http://www.w3.org/2000/svg'
        >
          <title>6758B6DD-5E7D-456D-B810-9212FE77B926</title>
          <path
            d={pathDescription}
            fill='#000'
            fillRule='evenodd'
          />
        </svg>
      </div>
      <div className='no-meter-error-container__flex-center-vertically'>
        {props.isSmallText ? (
          <p className='no-meter-error-container__label'>{props.noRegisterError}</p>
        ) : (
          <h4 className='no-meter-error-container__label'>{props.noRegisterError}</h4>
        )}

        {showRegistrationAndLogoutButtons && (
          <div className='button-group centered'>
            <StandardLink
              className='button button--v0'
              href={props.registrationButtonLink}
              onClick={() => SessionHandler.destroySession(true, props.registrationButtonLink, () => {})}
              linkText={props.registrationButtonTxt}
            />

            <StandardLink
              className='button button--v0'
              href={props.logoutAndCloseButtonLink}
              onClick={() => SessionHandler.destroySession(true, props.logoutAndCloseButtonLink, () => {})}
              linkText={props.logoutAndCloseButtonTxt}
            />
          </div>
        )}

        {props.anonymousMeterReadingLoginPage && props.logoutButtonTxt && !showRegistrationAndLogoutButtons && (
          <StandardLink
            className='no-meter-error-container__enter-another-meter-reading'
            iconLeft={arrowLeftIcon}
            href={props.anonymousMeterReadingLoginPage}
            onClick={() => SessionHandler.destroySession(true, props.anonymousMeterReadingLoginPage, () => {})}
            linkText={props.logoutButtonTxt}
          />
        )}
      </div>
    </div>
  );
};

export default NoRegisterError;
