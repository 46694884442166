import RestHelper from '../utils/RestHelper';

export function getInstallations() {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/feedin/v0/installations?filterBy=eegSubsidyPayments`);
}
export const getInstallationsInfo = (harnesId: string, contractAccountId: string) =>
  RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/feedin/v0/installations/${harnesId}/${contractAccountId}/eegSubsidyPaymentInfo`);

export const saveInstallationsInfo = (harnesId: string, contractAccountId: string, dataObj) =>
  RestHelper.put(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/feedin/v0/installations/${harnesId}/${contractAccountId}/eegSubsidyPaymentInfo`,
    dataObj
  );

export default { getInstallations, getInstallationsInfo, saveInstallationsInfo };
