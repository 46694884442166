import moment from 'moment';
import GermanDateHelpers from '../../../../../utils/GermanDateHelpers';
import { DeviceDataTypes, MeterReadingRegisterTypes } from '../../MeterReadingTypes';
import GlobalErrorHandler from '../../../../../utils/GlobalErrorHandler';

export type ReadingPeriodDateType = {
  startDate: Date;
  endDate: Date;
};

type ReadingPeriodType = {
  from: number[];
  to: number[];
};

type DateValidationErrorTypes = {
  dateValidationError: string;
  dateRangeErrorMsg: string;
  futureDateErrorMessage: string;
};

export const formatToIso = (gDate: string) => gDate?.split('.').reverse().join('-') || '';

export const validateInputDate = (value: string, readingPeriod: ReadingPeriodDateType, dateValidationErrors: DateValidationErrorTypes): string => {
  if (!value) {
    return GlobalErrorHandler.getErrorMessage('required').toString();
  }
  if (!GermanDateHelpers.isValidGermanDateFormat(value)) {
    return dateValidationErrors.dateValidationError;
  }
  if (moment(readingPeriod.startDate).unix() > moment(formatToIso(value)).unix()) {
    return dateValidationErrors.dateRangeErrorMsg;
  }
  if (moment(readingPeriod.endDate).unix() < moment(formatToIso(value)).unix()) {
    return dateValidationErrors.futureDateErrorMessage;
  }
  return '';
};

export const calculateReadingPeriod = (readingPeriod: ReadingPeriodType): ReadingPeriodDateType => {
  const startDate = readingPeriod.from;
  const endDate = readingPeriod.to;

  return {
    startDate: new Date(startDate[0]!, startDate[1]! - 1, startDate[2]!),
    endDate: new Date(endDate[0]!, endDate[1]! - 1, endDate[2]!),
  };
};

export const prepareDateForDisplay = (registers: MeterReadingRegisterTypes[]): string => {
  const dates = registers.map((register) => {
    const year = register.lastReadingDate[0];
    let month: number | string = register.lastReadingDate[1];
    if (month.toString().length === 1) {
      month = `0${month}`;
    }
    let day: number | string = register.lastReadingDate[2];
    if (day.toString().length === 1) {
      day = `0${day}`;
    }

    const newDate: string = `${month}/${day}/${year}`;

    return new Date(newDate);
  });

  // sort dates to most actual
  dates.sort((x, y) => x.getDate() - y.getDate());

  return GermanDateHelpers.dateToGermanString(dates[0]);
};

export const returnDateAsString = (dateAsArray: number[]): string => {
  const year = dateAsArray[0];
  let month: number | string = dateAsArray[1];
  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  let day: number | string = dateAsArray[2];
  if (day.toString().length === 1) {
    day = `0${day}`;
  }

  const dateAsString = `${day}.${month}.${year}`;

  return dateAsString;
};

export const returnDateAsArray = (dateAsString): number[] => {
  const str = dateAsString.split('.');

  const year = parseInt(str[2], 10);
  const month = parseInt(str[1], 10);
  const day = parseInt(str[0], 10);

  const dateAsArray = [year, month, day];

  return dateAsArray;
};

export const initialValues = (deviceData: DeviceDataTypes): DeviceDataTypes => {
  const readingDate = returnDateAsString(deviceData?.readingPeriod?.to);
  const readingDateValue = {
    start: moment(readingDate, 'DD/MM/YYYY').toDate(),
    text: readingDate,
  };
  return {
    ...deviceData,
    readingDate: readingDateValue,
  };
};
