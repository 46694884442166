export function getInputValueIfExists(name: string): string | null {
  const input = document.getElementsByName(name);
  if (input && input[0]) {
    return input[0].value;
  }
  return null;
}

export function getIdDataset(name: string, dataSetName: string): string | null | undefined {
  const elem = document.getElementById(name);
  if (elem && elem.dataset) {
    return elem.dataset[dataSetName];
  }
  return null;
}
export function findPos(obj) {
  let curtop = 0;
  let myObj = obj;
  if (obj.offsetParent) {
    do {
      curtop += myObj.offsetTop;
      myObj = myObj.offsetParent;
    } while (myObj.offsetParent);
  }
  return [curtop - window.innerHeight / 2];
}
