import { PostboxDocumentTypes } from '../types/PostBoxTypes';
import { PostBoxActionTypes, PostboxErrorCodes, PostboxReducerActionTypes } from './postBoxActions';

type PostboxReducerStateTypes = {
  documents: PostboxDocumentTypes[];
  hasError: boolean;
  loading: boolean;
  errorStatus: number;
  errorCode: PostboxErrorCodes;
};

function reducer(
  state: PostboxReducerStateTypes = { loading: true } as PostboxReducerStateTypes,
  action: PostboxReducerActionTypes
): PostboxReducerStateTypes {
  switch (action.type) {
    case PostBoxActionTypes.LOAD_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
        loading: false,
      };
    case PostBoxActionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case PostBoxActionTypes.HAS_ERROR:
      return {
        ...state,
        loading: false,
        hasError: true,
        errorStatus: action.errorStatus,
        errorCode: action.errorCode,
      };
    default:
      return state;
  }
}

export default reducer;
export type { PostboxReducerStateTypes };
