import { InferProps } from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from '@eon-funke/react-shared-ui-next';
import SessionHandler from '../../../../../utils/SessionHandler';
import { EmailFeedbackStatusEnum } from '../MeterReadingEmailFeedback/MeterReadingEmailFeedback';
import meterReadThanksLabelPropTypes from './MeterReadThanksPropTypes';

export type MeterReadThanksTypes = {
  amount: number;
  missedMeter: number;
  emailFeedbackState: string;
  feedbackEmail: string;
  okoEmail: string;
};

type MeterReadThanksProps = MeterReadThanksTypes & InferProps<typeof meterReadThanksLabelPropTypes>;

const MeterReadThanks: React.FC<MeterReadThanksProps> = (props) => {
  const buildAcknowledgeText = () => {
    if (props.missedMeter === 0) {
      return {
        counterText: props.metersRegisteredText.replace('%%TOTAL%%', props.amount.toString()),
        hint: null,
      };
    }
    if (props.fromToMetersRegisteredText) {
      return {
        counterText: props.fromToMetersRegisteredText
          .replace('%%SUCCESS%%', (props.amount - props.missedMeter).toString())
          .replace('%%TOTAL%%', props.amount.toString()),
        hint: props.revisitHint,
      };
    }
    return null;
  };

  const acknowledgeTextData = buildAcknowledgeText();

  useEffect(() => {
    SessionHandler.destroySession(false);
  });

  return (
    <>
      <div className='meter-read-thanks__flex-center-vertically'>
        <h2 className='meter-read-thanks__headline'>{props.thankYouText}</h2>
        <p className='meter-read-thanks__description'>{`${props.description} ${acknowledgeTextData?.hint ? acknowledgeTextData.hint : ''}`}</p>
        {acknowledgeTextData?.counterText && (
          <div className='meter-read-thanks__container'>
            <div className='meter-read-thanks__container__arrow' />
            <div className='meter-read-thanks__container__text'>{acknowledgeTextData.counterText}</div>
          </div>
        )}
        {props.emailFeedbackState === EmailFeedbackStatusEnum.ACCEPTED && props.feedbackEmail && (
          <div className='meter-read-thanks__container'>
            <div className='meter-read-thanks__container__arrow' />
            <div className='meter-read-thanks__container__text'>
              {props.emailConfirmationText}
              <span className='meter-read-thanks__container__email'>{props.feedbackEmail}</span>
            </div>
          </div>
        )}
        {props.okoEmail && (
          <div className='meter-read-thanks__container'>
            <div className='meter-read-thanks__container__arrow' />
            <div className='meter-read-thanks__container__text'>
              {props.okoConfirmationText1}
              <span className='meter-read-thanks__container__email'>{props.okoEmail}</span>
              {props.okoConfirmationText2}
            </div>
          </div>
        )}
        <div className='meter-read-thanks__button-container'>
          <Link
            data-qa='logOutLink'
            isButton
            version={props.backButton?.version}
            href={props.returnUrl}
          >
            {' '}
            {props.backButton?.label}
          </Link>
        </div>
      </div>
    </>
  );
};

MeterReadThanks.propTypes = meterReadThanksLabelPropTypes;
export default MeterReadThanks;
