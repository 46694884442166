export const tokenTypes = {
  ciam: 'ciam',
  mw: 'mw',
};

class ActivityWatcher {
  private static activityObserver?: ReturnType<typeof setInterval>;

  private static timoeoutInterval: number;

  private static refreshCiamToken: () => Promise<any>;

  private static refreshMiddlewareToken: () => Promise<any>;

  static getCurrentTime() {
    return (new Date().getTime() / 1000) * 1;
  }

  static getLastActivtyTimestamp() {
    return sessionStorage.lastActivityTimestamp;
  }

  static registerNewActivity() {
    sessionStorage.lastActivityTimestamp = this.getCurrentTime();
  }

  static registerTokenRefreshing(...types) {
    types.forEach((type) => {
      sessionStorage[`${type}TokenRefresh`] = this.getCurrentTime();
      sessionStorage[`${type}TokenRefreshIsGoingOn`] = '';
    });
  }

  static cleanupLocalData() {
    Object.values(tokenTypes).forEach((type) => {
      sessionStorage[`${type}TokenRefresh`] = '';
      sessionStorage[`${type}TokenRefreshIsGoingOn`] = '';
    });
    sessionStorage.lastActivityTimestamp = '';
  }

  private static calculateLastActivityInterval() {
    return this.getCurrentTime() - parseInt(this.getLastActivtyTimestamp(), 10);
  }

  private static registerActivityListeners() {
    const eventCalculator = () => {
      if (this.calculateLastActivityInterval() > 5) {
        this.registerNewActivity();
        this.refreshTokens();
      }
    };
    window.addEventListener('mousemove', eventCalculator);
    window.addEventListener('touchend', eventCalculator);
    window.addEventListener('touchstart', eventCalculator);
    window.addEventListener('touchmove', eventCalculator);
    window.addEventListener('scroll', eventCalculator);
    window.addEventListener('keypress', eventCalculator);
  }

  private static itHasValidLocks(type) {
    const tokenTimer = type === tokenTypes.ciam ? sessionStorage.ciamTokenRefreshIsGoingOn : sessionStorage.mwTokenRefreshIsGoingOn;
    return tokenTimer && this.getCurrentTime() - tokenTimer < 60;
  }

  private static refreshTokens() {
    const lastRefreshCiam = sessionStorage.ciamTokenRefresh || 0;
    const lastRefreshMw = sessionStorage.mwTokenRefresh || 0;
    const timeToUpdateToken = (lastTokenRefresh) => this.getCurrentTime() - lastTokenRefresh > this.timoeoutInterval / 2;
    if (timeToUpdateToken(lastRefreshCiam) && !this.itHasValidLocks(tokenTypes.ciam)) {
      sessionStorage.ciamTokenRefreshIsGoingOn = this.getCurrentTime();
      // @TODO In CC-mode the refreshCiamToken is undefined, this is a temporary solution to avoid the error
      this.refreshCiamToken()
        ?.then(() => {
          this.registerTokenRefreshing(tokenTypes.ciam);
        })
        .catch(() => {
          sessionStorage.ciamTokenRefreshIsGoingOn = '';
        });
    }
    if (timeToUpdateToken(lastRefreshMw) && !this.itHasValidLocks(tokenTypes.mw)) {
      sessionStorage.mwTokenRefreshIsGoingOn = this.getCurrentTime();
      this.refreshMiddlewareToken()
        .then(() => {
          this.registerTokenRefreshing(tokenTypes.mw);
        })
        .catch(() => {
          sessionStorage.mwTokenRefreshIsGoingOn = '';
        });
    }
  }

  static initActivityObserving(
    timoeoutInterval = 540,
    refreshCiamToken: () => Promise<any>,
    refreshMiddlewareToken: () => Promise<any>,
    sessionTerminator: () => void
  ) {
    if (this.activityObserver) {
      return;
    }
    this.refreshCiamToken = refreshCiamToken;
    this.refreshMiddlewareToken = refreshMiddlewareToken;
    this.timoeoutInterval = timoeoutInterval;
    this.registerActivityListeners();
    const observer = () => {
      const lastActivityHappened = this.calculateLastActivityInterval();
      if (isNaN(lastActivityHappened) || lastActivityHappened > timoeoutInterval) {
        sessionTerminator();
        if (this.activityObserver) {
          clearInterval(this.activityObserver);
        }
      }
    };
    this.activityObserver = setInterval(observer, 5000);
    observer();
  }

  static clearObserver() {
    if (this.activityObserver) {
      clearInterval(this.activityObserver);
      delete this.activityObserver;
    }
  }
}

export default ActivityWatcher;
