import getRegulations from '../api/regulationApi';

// Actions
const LOAD_CURRENT_REGULATIONS = 'api/reducer/regulations/GET_REGULATIONS';
const SET_SELECTED_EEG = 'api/reducer/regulations/SET_AVAILABLE_EEGS';
const SET_SELECTED_DATE = 'api/reducer/regulations/SET_SELECTED_DATE';
const SET_TO_LOADING = 'api/reducer/regulations/SET_TO_LOADING';
const REGUL_API_ERROR = 'api/reducer/regulations/REGUL_API_ERROR';
// Reducer
const filterRegulationsByEeg = (regulations, eegId) => {
  if (!regulations || !regulations.length || !eegId) {
    return [];
  }
  return regulations.filter((reg) => eegId === reg.eegInstallationId);
};

const filterRegulationsByDate = (regulations, from, to) => {
  if (!regulations || !regulations.length || !from || !to) {
    return [];
  }
  return regulations.filter((reg) => reg.regulationIntervalTimeStamps.start >= from && to >= reg.regulationIntervalTimeStamps.start);
};
const getMaxDate = (regulations) => {
  if (!regulations || !regulations.length) {
    return 0;
  }
  let currMax = regulations[0].regulationIntervalTimeStamps.end;
  regulations.forEach((reg) => {
    if (currMax < reg.regulationIntervalTimeStamps.end) {
      currMax = reg.regulationIntervalTimeStamps.end;
    }
  });
  return currMax;
};
const getMinDate = (regulations) => {
  if (!regulations || !regulations.length) {
    return 0;
  }
  let currMin = regulations[0].regulationIntervalTimeStamps.start;
  regulations.forEach((reg) => {
    if (currMin > reg.regulationIntervalTimeStamps.start) {
      currMin = reg.regulationIntervalTimeStamps.start;
    }
  });
  return currMin;
};

const getEegkeys = (regulations) => {
  const eegs = [];
  regulations.forEach((reg) => {
    const id = reg.eegInstallationId;
    if (!eegs.find((eeg) => eeg === id)) {
      eegs.push(id);
    }
  });
  return eegs;
};

export { filterRegulationsByEeg, filterRegulationsByDate, getMaxDate, getMinDate, getEegkeys };
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_CURRENT_REGULATIONS: {
      const eegKeys = getEegkeys(action.data.innerData);
      return {
        ...state,
        regulations: action.data.innerData.map((elem) => ({
          ...elem,
          regulationIntervalTimeStamps: {
            start: elem.regulationIntervalTimeStamps.start * 1000,
            end: elem.regulationIntervalTimeStamps.end * 1000,
          },
        })),
        errorCode: null,
        availableEegs: eegKeys,
        selectedEeg: eegKeys && eegKeys.length ? eegKeys[0] : null,
        loading: false,
      };
    }
    case SET_SELECTED_EEG:
      return {
        ...state,
        selectedEeg: action.data.eegKey,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedStartDate: action.data.from,
        selectedEndDate: action.data.to,
      };
    case SET_TO_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REGUL_API_ERROR:
      return {
        ...state,
        loading: false,
        regulations: [],
        errorCode: action.data.error,
      };
    default:
      return state;
  }
}

// Action Creators
export function loadCurrentRegulations(dispatch, contractId) {
  dispatch({
    type: SET_TO_LOADING,
  });
  const resp = getRegulations(contractId);
  return resp
    .then((reponse) => {
      dispatch({
        type: LOAD_CURRENT_REGULATIONS,
        data: {
          innerData: reponse.data ? reponse.data.content : [],
        },
      });
    })
    .catch((err) => {
      let errorCode;
      if (err.response.data.errors[0] && err.response.data.errors[0].code) {
        errorCode = err.response.data.errors[0].code.split('.').pop();
      }
      dispatch({
        type: REGUL_API_ERROR,
        data: {
          error: errorCode,
        },
      });
    });
}
export function setSelectedEegKey(dispatch, eegKey) {
  dispatch({
    type: SET_SELECTED_EEG,
    data: {
      eegKey,
    },
  });
}
export function setSelectedDate(dispatch, from, to) {
  dispatch({
    type: SET_SELECTED_DATE,
    data: {
      from,
      to,
    },
  });
}
