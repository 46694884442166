import { MeterReadingRegisterProps } from '../cic/components/MeterRead/MeterReadingTypes';

class MeterNumberValidationHelpers {
  static formattedLastReadingValue: any;

  static getValidatedData(register: MeterReadingRegisterProps, input: string): number {
    return this.getConvertedValue(register?.lastReadingResult, input, register);
  }

  static getConvertedValue(preMeterValue: number | undefined | null, newMeterValue: string, register: MeterReadingRegisterProps): number {
    return register.numberOfDecimalPlaces === 0
      ? parseFloat(newMeterValue.split('.').join(''))
      : parseFloat(newMeterValue.replace(/\./g, '').replace(/,/g, '.'));
  }

  /* Replace dot with comma and put the thousand seperator  */
  static getFormattedValue(value: number | undefined | null, fixedBy: number = 0): string | number {
    if (!value) {
      return 0;
    }
    let newVal;
    if (fixedBy > 0) {
      newVal = value.toFixed(2);
    } else {
      newVal = value;
    }
    newVal = newVal.toString().replace(/\./g, ',');
    const parts = newVal.split(',');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    this.formattedLastReadingValue = parts.join(',');
    return this.formattedLastReadingValue;
  }

  static removeRedundantZeros(value: string): string {
    if (value) {
      return value.replace(/^0+/, '');
    }
    return '';
  }
}
export default MeterNumberValidationHelpers;
