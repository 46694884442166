import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from '@eon-funke/react-shared-ui-next';
import CONFIG from '../../config/config';

const COMPONENT_ID = 'call-center-unauthorized-error';

class CallCenterUnauthorizedError extends React.Component {
  render() {
    const pageReload = () => {
      window.location.reload(false);
    };

    return (
      <div>
        <div className='error-wrapper'>
          <span>{CONFIG.AEM_CONFIG.callCenter.unauthorizedErrorMessage}</span>
          <Button type='button' onClick={pageReload}>
            {CONFIG.AEM_CONFIG.callCenter.unauthorizedButtonLabel}
          </Button>
        </div>
      </div>
    );
  }
}

export const showCCUnauthorizedError = () => {
  let errorPortal = document.getElementById(COMPONENT_ID);

  if (!errorPortal) {
    errorPortal = document.createElement('div');
    errorPortal.id = COMPONENT_ID;
    document.body.appendChild(errorPortal);
  }

  ReactDOM.render(React.createElement(CallCenterUnauthorizedError, null, null), errorPortal);

  setTimeout(() => {
    errorPortal.classList.add('show-error-box');
  }, 0);
};

export default CallCenterUnauthorizedError;
