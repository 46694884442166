import MeterNumberValidationHelpers from '../../../../../utils/MeterNumberValidationHelpers';
import { ErrorHintArgsInfoMessagesType } from '../../MeterReadingTypes';

export type MeterReadingInfoMessageTypes = {
  reason: string;
  text: string;
  weight: number;
};

const REGEX_INFO_TOKEN_MATCH = /[^%%]+(?=%%)/g;

function replaceTokensInMostRelevantInfoMessage(template, infoMessage) {
  let resultTemplate = template;
  const tokenMatches = template.match(REGEX_INFO_TOKEN_MATCH);
  tokenMatches.forEach((tokenMatch) => {
    const tokenWithBrackets = `%%${tokenMatch}%%`;
    let tokenReplacement = infoMessage[tokenMatch];
    if (!tokenReplacement) return;
    if (tokenMatch === 'date') {
      tokenReplacement = tokenReplacement.reverse().join('.');
    }
    if (tokenMatch === 'meterSerialNumber') {
      tokenReplacement = MeterNumberValidationHelpers.removeRedundantZeros(tokenReplacement);
    }
    resultTemplate = resultTemplate.replace(tokenWithBrackets, tokenReplacement);
  });
  return resultTemplate;
}

function getMeterReadingInfoMessageText(
  infoMessages: ErrorHintArgsInfoMessagesType[] = [],
  meterReadingInfoMessages: MeterReadingInfoMessageTypes[]
): string {
  const getMostRelevantReasonTextTemplate = (reason) => {
    const mostRelevantReasonText: MeterReadingInfoMessageTypes =
      meterReadingInfoMessages.find((info) => info.reason === reason) || ({} as MeterReadingInfoMessageTypes);
    return mostRelevantReasonText.text;
  };

  if (infoMessages && infoMessages.length) {
    const mostRelevantInfoMessage = infoMessages[0];
    const mostRelevantReason = mostRelevantInfoMessage.reason;

    const mostRelevantReasonTextTemplate = getMostRelevantReasonTextTemplate(mostRelevantReason) || null;

    if (mostRelevantReasonTextTemplate) {
      return replaceTokensInMostRelevantInfoMessage(mostRelevantReasonTextTemplate, mostRelevantInfoMessage);
    }
  }
  return '';
}

export default getMeterReadingInfoMessageText;
