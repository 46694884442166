import classNames from 'classnames';
import * as React from 'react';
import { InputState } from "../../constants";
import InputFeedback from '../InputFeedback/InputFeedback';
export var Checkbox = function (_a) {
    var id = _a.id, qaId = _a.qaId, label = _a.label, errorMessage = _a.errorMessage, _b = _a.inputState, inputState = _b === void 0 ? InputState.NONE : _b, name = _a.name, checked = _a.checked, disabled = _a.disabled, required = _a.required, className = _a.className, feedbackClassName = _a.feedbackClassName, readOnly = _a.readOnly, value = _a.value, handleChange = _a.onChange;
    var showError = inputState === InputState.ERROR;
    var wrapperClasses = classNames('form-checkbox', {
        'form-checkbox--error': showError,
    }, {
        'form-checkbox--active': checked,
    }, {
        'form-checkbox--disabled': disabled,
    }, className);
    var controlClasses = classNames('form-checkbox__control', {
        'form-checkbox__control--error': showError,
    }, {
        'form-checkbox__control--active': checked,
    }, {
        'form-checkbox__control--disabled': disabled,
    });
    var labelClasses = classNames('form-checkbox__label', {
        'form-checkbox__label--active': checked,
    }, {
        'form-checkbox__label--required': required,
    }, {
        'form-checkbox__label--disabled': disabled,
    });
    var inputClasses = classNames('form-checkbox__checkbox');
    var controlIcon = (React.createElement("svg", { width: "24", height: "24" },
        React.createElement("path", null),
        React.createElement("path", { id: "circlepath", d: "M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z" }),
        React.createElement("path", { id: "checkpath", d: "M10.834 17L5 10.813l.89-.94 4.946 5.246L18.512 7l.888.94L10.834 17" })));
    return (React.createElement("div", { id: "".concat(name, "__").concat(id) },
        React.createElement("div", { className: wrapperClasses },
            React.createElement("label", { className: labelClasses, htmlFor: id },
                React.createElement("input", { type: "checkbox", name: name, className: inputClasses, id: id, "data-qa": qaId, onChange: !readOnly
                        ? handleChange
                        : function (e) {
                            e.preventDefault();
                        }, disabled: disabled, checked: checked, readOnly: readOnly, value: value }),
                React.createElement("span", null, label),
                React.createElement("div", { className: controlClasses }, controlIcon)),
            errorMessage && (React.createElement(InputFeedback, { hasError: showError, errorMessage: errorMessage, feedbackClassName: feedbackClassName })))));
};
Checkbox.displayName = 'CheckboxComponent';
export default Checkbox;
