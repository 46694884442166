import { InputState } from '@eon-funke/react-shared-ui-next';

const VALIDATION_DEFAULT_CONFIG = {
  pending: false,
};

export type ValidationType = typeof VALIDATION_DEFAULT_CONFIG;

export function validatePasswordLength(value: string, min: number = 8, max: number = 20): boolean {
  if (!value) {
    return false;
  }
  return !!value && value.length >= min && value.length <= max;
}

export function validateAtLeastOneNumber(value: string): boolean {
  if (!value) {
    return false;
  }
  return !!(value && value.match(/\d/));
}

export function validateCapitalAndLowerLetter(value: string): boolean {
  if (!value) {
    return false;
  }
  const hasLower = value.toUpperCase() !== value;
  const hasUpper = value.toLowerCase() !== value;
  return hasLower && hasUpper;
}

export function validateIsRequired(value: string): boolean {
  if (!value) {
    return false;
  }
  return !!value;
}

export function validateCharacterCount(value: string): boolean {
  if (!value) return false;

  const frequencies = {};
  value.split('').forEach((char) => {
    frequencies[char] = (frequencies[char] || 0) + 1;
  });

  const maxAllowedCount = value.length / 2;
  return !Object.values(frequencies).find((count: number) => count > maxAllowedCount);
}

export function validateNameMatch(value: string, name: string): boolean {
  return !!(value && (!name || (name && value.toLowerCase().indexOf(name.toLowerCase().trim()) === -1)));
}

export function validateAsciiOnly(value: string): boolean {
  if (!value) {
    return false;
  }
  return !!(value && value.match(/^[!-~]*$/));
}

// sets the cursor/caret position
export function setCaretPosition(ctrl, pos): void {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}

export function validateVatNumber(value: string): boolean {
  return !!(value && value.match(/^[DE]+\d{0,9}$/));
}

export function validateTaxNumber(value: string): boolean {
  return !!(value && value.match(/^[0-9/]+$/));
}

export function setInputState(error: boolean, touched?: boolean, opts: ValidationType = VALIDATION_DEFAULT_CONFIG): InputState {
  if (opts.pending) {
    return InputState.PENDING;
  }
  if (touched && error) {
    return InputState.ERROR;
  }
  if (touched && !error) {
    return InputState.VALID;
  }
  return InputState.NONE;
}

/**
 * merge validation schemas from different components so that they stay by the component
 * @param schemas {object} comma separated list of schemas
 * @returns {object} merged schema to use
 */
export const mergeSchemas = (...schemas) => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
};

/**
 * helper returning inputState
 * @param field {string} name of the field to check
 * @param errors {object} error object from formik
 * @param touched {object} touched object from formik
 * @param opts {opts} option argument to use for additional config
 * @returns {number} inputState from  shared-ui
 */
export function getInputStateByField(field: string, errors: object = {}, touched: object = {}, opts?: ValidationType): InputState {
  return setInputState(errors[field], touched[field], opts);
}

/**
 * logic what message to show
 * @param fieldInputState {number} inputState from  shared-ui
 * @param error {string} error message to show
 * @param pending {string} pending message rendered as hint if input in pending state
 * @returns {string} message to display
 */
export function setValidationMessage(fieldInputState: InputState, error: string = '', pending: string = ''): string {
  let msg;
  if (fieldInputState === InputState.ERROR) {
    msg = error;
  } else if (fieldInputState === InputState.PENDING) {
    msg = pending;
  }
  return msg;
}

/**
 * find error in api error bundle by name
 * @param name
 * @param errors
 * @returns {any}
 */
export function checkApiErrors(name: string, errors: any = {}): boolean {
  return Object.entries(errors).length !== 0 ? !Object.keys(errors).find((x) => x === name) : true;
}

/**
 * restricts possible characters that can be entered in the input
 * here only numbers are possible (business partner id, contractaccount number)
 * @param event keypress event
 * @returns {boolean}
 */
export const isNumber = (event: KeyboardEvent): boolean => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode === 8 || event.metaKey || event.ctrlKey) {
    return true;
  }
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
    return false;
  }
  return true;
};
