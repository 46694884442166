import PropTypes, { InferProps, shape } from 'prop-types';

import { contractProps } from '../components/Dashboardiii/propTypes/contractPropTypes';

import { MyAppointmentLoadAction } from '../actions/MyAppointment/myAppointmentContractLoadAction';
import { MyAppointmentSelectionAction, SET_SELECTED_APPOINTMENT } from '../actions/MyAppointment/myAppointmentDetailLoadAction';
import {
  LOAD_APPOINTMENT_GAPS,
  MyAppointmentLoadGapsAction,
  SET_APPOINTMENT_LOADING,
  THROW_APPOINTMENT_ERROR,
} from '../actions/MyAppointment/myAppointmentGapLoadAction';
import { MyAppointmentSaveAction, SAVE_APPOINTMENT } from '../actions/MyAppointment/myAppointmentGapSaveAction';
import { MyAppointmentWishSaveAction, SAVED_APPOINTMENT_WISH } from '../actions/MyAppointment/myAppointmentWishSaveAction';
import { appointmentOrderPropType, myAppointmentWish } from '../types/myAppointmentTypes';

export const ATTR_ASS_ORDER_ID = 'assOrderId';

export const myAppointmentReducerState = {
  assOrderId: PropTypes.string.isRequired,
  data: shape(contractProps),
  appointmentOrder: shape(appointmentOrderPropType),
  appointmentWish: shape(myAppointmentWish),
  appointmentGapList: PropTypes.array,
  cancelledAppointment: shape(appointmentOrderPropType),
  week: PropTypes.object,
  isLoading: PropTypes.bool,
};

export const myAppointmentMapState = {
  myAppointment: shape(myAppointmentReducerState).isRequired,
};

type MyAppointmentState = InferProps<typeof myAppointmentReducerState>;
export type MyAppointmentMapState = InferProps<typeof myAppointmentMapState>;

// Reducer
export default function reducer(
  state: Partial<MyAppointmentState> = {},
  action: MyAppointmentLoadAction | MyAppointmentSelectionAction
): Partial<MyAppointmentState> {
  switch (action.type) {
    case SET_SELECTED_APPOINTMENT: {
      const appointmentSelectionAction: MyAppointmentSelectionAction = action as MyAppointmentSelectionAction;
      return {
        ...state,
        assOrderId: appointmentSelectionAction.data.assOrderId,
        appointmentOrder: appointmentSelectionAction.data.innerData,
        isLoading: false,
      };
    }
    case LOAD_APPOINTMENT_GAPS: {
      const appointmentLoadGapsAction: MyAppointmentLoadGapsAction = action as MyAppointmentLoadGapsAction;
      return {
        ...state,
        appointmentGapList: appointmentLoadGapsAction.data.innerData,
        week: appointmentLoadGapsAction.data.week,
        isLoading: false,
      };
    }
    case SAVE_APPOINTMENT: {
      const appointmentSaveAction: MyAppointmentSaveAction = action as MyAppointmentSaveAction;
      return {
        ...state,
        appointmentOrder: appointmentSaveAction.data.innerData,
        cancelledAppointment: state.appointmentOrder,
        appointmentWish: null,
        isLoading: false,
      };
    }
    case SAVED_APPOINTMENT_WISH: {
      const appointmentSaveAction: MyAppointmentWishSaveAction = action as MyAppointmentWishSaveAction;
      return {
        ...state,
        appointmentWish: appointmentSaveAction.data.innerData,
        cancelledAppointment: state.appointmentOrder,
        isLoading: false,
      };
    }
    case SET_APPOINTMENT_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case THROW_APPOINTMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
