/* eslint-disable @typescript-eslint/no-unused-vars */
import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';
import CONFIG from '../config/config';

export function getDevices() {
  return CacheHandler.requestCache(
    () =>
      RestHelper.get(
        `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/consumption/v0/locations`,
        {},
        RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
      ),
    'locations'
  );
}

export function getDeviceData(serialNumber, fromDate, toDate) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/consumption/v0/locations/${serialNumber}/data?from=${fromDate}&to=${toDate}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function getDeviceFile(locationId, fromDate, toDate) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/consumption/v0/locations/${locationId}/excel?from=${fromDate}&to=${toDate}`,
    {},
    RestHelper.setXClientHeader({ responseType: 'arraybuffer' }, CONFIG.CHANNEL_CONFIG.portal)
  );
}
