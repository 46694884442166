import React from 'react';
import { Button, Loader } from '@eon-funke/react-shared-ui-next';
import { Form, Formik } from 'formik';
import {
  DeviceDataTypes,
  MeterReadingDateInputLabels,
  MeterReadingDropdownLabels,
  MeterReadingNotesTypes,
  MeterReadingRegisterInputLabels,
  MeterRegisterLabelComponentLabels,
} from '../../MeterReadingTypes';
import { allRegistersHasPlausibilityReason, registerHasPlausibilityError, saveAndForward } from './meterReadingFormHelper';
import validate from '../../utils/meterReadingfomValidationHandler';
import { initialValues } from '../../Core/MeterReadDateInput/meterReadingDateInputHelper';
import MeterReadingDropdown from '../../Core/MeterReadingDropdown/MeterReadingDropdown';
import MeterRegisterInput from '../../Core/MeterRegisterInput/MeterRegisterInput';
import MeterRegisterLabel from '../../Core/MeterRegisterLabel/MeterRegisterLabel';
import MeterReadDateInput from '../../Core/MeterReadDateInput/MeterReadDateInput';

export type MeterReadingFormTypes = {
  loggedIn: boolean;
  deviceData: DeviceDataTypes;
  readingNotes: MeterReadingNotesTypes;
  loading: boolean;
  currentPageId: number;
  lastMeter: boolean;
  nextStep: string;
  paginate: (pageId: number) => void;
  setCurrentStep: (currentStep: string) => void;
};

export type MeterReadingFormLabels = {
  buttonLabel: string;
  versions: {
    button: string;
    a: string;
  };
};

type MeterReadingFormProps = MeterReadingFormTypes &
  MeterReadingFormLabels &
  MeterReadingDateInputLabels &
  MeterReadingRegisterInputLabels &
  MeterRegisterLabelComponentLabels &
  MeterReadingDropdownLabels;

const MeterReadingForm = (props: MeterReadingFormProps) => {
  const { deviceData } = props;

  return (
    <Formik
      initialValues={initialValues(deviceData)}
      enableReinitialize
      onSubmit={(values, formikHelpers) => {
        saveAndForward(values, formikHelpers, props.paginate, props.currentPageId, props.lastMeter, props.setCurrentStep, props.nextStep);
      }}
      validate={(values) => validate(values, { ...props })}
      validateOnMount
    >
      {(formikProps) => {
        return (
          <Form>
            <div className='container'>
              <div className='row'>
                <Loader
                  loading={props.loading || formikProps.isSubmitting}
                  fallbackGifSrc={sessionStorage.fallbackLoader}
                >
                  <div
                    className='ano-meter-form'
                    id={`meter-form-${props.deviceData?.serialNumber}`}
                  >
                    <div className='row'>
                      <div className='col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3'>
                        <MeterReadDateInput
                          {...props}
                          formikProps={formikProps}
                          name='readingDate'
                          loggedIn={false}
                        />
                      </div>
                    </div>
                    {formikProps.values.registers.map((register, index) => {
                      const classNames = registerHasPlausibilityError(register)
                        ? 'col-xs-12 col-xs-offset-0 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-0 ano-meter-form__reason-dropdown'
                        : 'col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3 ano-meter-form__reason-dropdown';
                      return (
                        <div key={`${props.deviceData.serialNumber + index} - label`}>
                          <MeterRegisterLabel
                            {...props}
                            register={register}
                            iteration={index}
                            classNames={classNames}
                          />
                          <div
                            key={`${props.deviceData.serialNumber + index} - input`}
                            className='row'
                          >
                            <div className={classNames}>
                              <MeterRegisterInput
                                {...props}
                                formikProps={formikProps}
                                formikIdentifier={`registers[${index}]`}
                                register={register}
                                index={index}
                                unit={formikProps.values.unit}
                              />
                            </div>
                            {registerHasPlausibilityError(register) && (
                              <div className={`${classNames} dropdown-area`}>
                                <MeterReadingDropdown
                                  {...props}
                                  formikProps={formikProps}
                                  formikIdentifier={`registers[${index}]`}
                                  name={`${register.obis}dropdown`}
                                  register={register}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className='ano-meter-form__submit-button text-center'>
                      <Button
                        type='submit'
                        qaId='save-meter-reading-button'
                        disabled={!formikProps.isValid || formikProps.isSubmitting || !allRegistersHasPlausibilityReason(formikProps)}
                        version={props.versions.button}
                      >
                        {props.buttonLabel}
                      </Button>
                    </div>
                  </div>
                </Loader>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MeterReadingForm;
