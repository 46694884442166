export default class EnvironmentHelper {
  static isPQAStage(): boolean {
    return (window.location.hostname || '').toLowerCase().includes('pqa');
  }

  static isProdStage(): boolean {
    return (window.location.hostname || '').toLowerCase().includes('www');
  }

  static getAemInstanceKey() {
    if (EnvironmentHelper.isPQAStage()) {
      return 'PQA';
    }
    if (EnvironmentHelper.isProdStage()) {
      return 'PROD';
    }
    return undefined;
  }
}
