import { Dispatch } from 'react';
import { PostboxApiResponseTypes, PostboxDocumentTypes } from '../types/PostBoxTypes';
import { getPostboxDocuments } from '../../../../api-factory/postboxApi';

enum PostBoxActionTypes {
  LOAD_DOCUMENTS = 'api/reducer/LOAD_DOCUMENTS',
  LOADING = 'api/reducer/LOAD_DOCUMENTS/LOADING',
  HAS_ERROR = 'api/reducer/LOAD_DOCUMENTS/HAS_ERROR',
}

enum PostboxErrorCodes {
  serverError = 'serverError',
  businessPartnerNotFoundInCrm = 'businessPartnerNotFoundInCrm',
}

type PostboxReducerActionTypes = {
  type: PostBoxActionTypes;
  documents: PostboxDocumentTypes[];
  errorStatus: number;
  errorCode: PostboxErrorCodes;
};

function loadDocuments(dispatch: Dispatch<Partial<PostboxReducerActionTypes>>) {
  dispatch({
    type: PostBoxActionTypes.LOADING,
  });
  getPostboxDocuments()
    .then((response: PostboxApiResponseTypes) => {
      dispatch({
        type: PostBoxActionTypes.LOAD_DOCUMENTS,
        documents: response.data.content,
      });
    })
    .catch((error) => {
      let errorCode = PostboxErrorCodes.serverError;
      if (error.response.status === 400) {
        const splitError: string[] = error.response.data.errors[0].code.split('.');
        if (splitError.includes(PostboxErrorCodes.businessPartnerNotFoundInCrm)) {
          errorCode = PostboxErrorCodes.businessPartnerNotFoundInCrm;
        }
      }
      dispatch({
        type: PostBoxActionTypes.HAS_ERROR,
        errorStatus: error.response.status,
        errorCode,
      });
    });
}

export { loadDocuments, PostBoxActionTypes, PostboxErrorCodes };
export type { PostboxReducerActionTypes };
