import PropTypes from 'prop-types';

const meterReadThanksLabelPropTypes = {
  metersRegisteredText: PropTypes.string.isRequired,
  fromToMetersRegisteredText: PropTypes.string.isRequired,
  revisitHint: PropTypes.string.isRequired,
  thankYouText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  emailConfirmationText: PropTypes.string.isRequired,
  okoConfirmationText1: PropTypes.string.isRequired,
  okoConfirmationText2: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  backButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  }).isRequired,
};

export default meterReadThanksLabelPropTypes;
