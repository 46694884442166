import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CONFIG from '../config/config';
import { PostboxApiResponseTypes } from '../../common/features/PostBox/types/PostBoxTypes';
import { filenameEncoding } from '../../common/features/PostBox/postboxTableBodyHelper';

function getPostboxDocuments(): Promise<PostboxApiResponseTypes> {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/documents`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

function initDownloadPostboxDocument(documentId, cb): void {
  const url = `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/partner/v0/documents/${documentId}?filenameEncoding=${filenameEncoding()}`;
  RestHelper.downloadContentWithPost(documentId, url, [], cb);
}

export default { initDownloadPostboxDocument, getPostboxDocuments };
