type ChangeTaxVatDataPageLabels = {
  backToDashboard: string;
  returnPage: string;
  expandedLabel: string;
  collapsedLabel: string;
  submitButtonLabel: string;
  cancelButtonLabel: string;
  taxLabels: {
    title: string;
    placeholder: string;
    errorMessage: string;
  };
  vatLabels: {
    title: string;
    placeholder: string;
    errorMessage: string;
  };
  buttonVersions: {
    submit: string;
    cancel: string;
  };
  successMessage: {
    save: string;
    update: string;
  };
  infoMessage: string;
};

type TaxNumberTypes = {
  taxin: string | null;
  vatin: string | null;
};

enum TaxNumberChangeEvents {
  UPDATE = 'UPDATE',
  SAVE = 'SAVE',
  NONE = 'NONE',
}

type TaxNumberChangeEventType = {
  taxin: TaxNumberChangeEvents;
  vatin: TaxNumberChangeEvents;
};

export { ChangeTaxVatDataPageLabels, TaxNumberTypes, TaxNumberChangeEvents, TaxNumberChangeEventType };
