/* eslint-disable @typescript-eslint/no-unused-vars */
import 'core-js/features/array/flat';
import { getLocations, getSubscriptions, createSubscription, editSubscription } from '../api/subscriptionsApi';

export const GET_LOCATIONS = 'api/reducer/GET_LOCATIONS';
export const GET_SUBSCRIPTION = 'api/reducer/GET_SUBSCRIPTIONS';
export const SUBSCRIPTION_REQUEST_PENDING = 'api/reducer/SUBSCRIPTION_REQUEST_PENDING';
export const CREATE_SUBSCRIPTION = 'api/reducer/CREATE_SUBSCRIPTION';
export const EDIT_SUBSCRIPTION = 'api/reducer/EDIT_SUBSCRIPTION';

/**
 * check if subscriptions has inactive locations
 * @param subscriptions {array} array of subscription for account
 * @returns {boolean} hasInactiveLocations
 */

const subscriptionsWithInactiveLocations = (subscriptions) => {
  const hasInactiveLocations = subscriptions.map((subscription) => subscription.locations.filter((location) => !location.active)).flat(Infinity);
  return hasInactiveLocations.length > 0;
};

export const loadLocations = (dispatch) => {
  getLocations()
    .then((response) => {
      return dispatch({
        type: GET_LOCATIONS,
        data: {
          innerData: response.data.content,
        },
      });
    })
    .catch((err) => {
      const error = err && err.data && err.data.errors;
      throw error;
    });
};

export const loadSubscriptions = (dispatch) => {
  getSubscriptions()
    .then((response) =>
      dispatch({
        type: GET_SUBSCRIPTION,
        data: {
          innerData: response.data.content,
        },
      })
    )
    .catch((err) => {
      const error = err && err.data && err.data.errors;
      throw error;
    });
};

export const saveSubscription = (dispatch, payload) => {
  dispatch({ type: SUBSCRIPTION_REQUEST_PENDING });
  createSubscription(payload)
    .then((response) =>
      dispatch({
        type: CREATE_SUBSCRIPTION,
        data: response,
      })
    )
    .catch((err) => {
      const error = err && err.data && err.data.errors;
      throw error;
    });
};

export const saveEditSubscription = (dispatch, subscriptionId, payload) => {
  dispatch({ type: SUBSCRIPTION_REQUEST_PENDING });
  editSubscription(subscriptionId, payload)
    .then((response) =>
      dispatch({
        type: EDIT_SUBSCRIPTION,
        data: response,
      })
    )
    .catch((err) => {
      const error = err && err.data && err.data.errors;
      throw error;
    });
};

const initialState = {
  isRequestPending: true,
  subscriptions: [],
};

const consumptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.data.innerData,
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: action.data.innerData,
        inactiveLocations: subscriptionsWithInactiveLocations(action.data.innerData),
        isRequestPending: false,
      };
    case SUBSCRIPTION_REQUEST_PENDING:
      return {
        ...state,
        isRequestPending: true,
      };
    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        isRequestPending: false,
      };
    case EDIT_SUBSCRIPTION:
      return {
        ...state,
        isRequestPending: false,
      };
    default:
      return state;
  }
};

export default consumptionsReducer;
