// import 'core-js/stable';
import * as axe from 'axe-core';
import startCicApp, { startAccountApp } from './libraryIndex';
import { isCICContentTree, isAccount } from './mainConfig';
import { initMock } from './cic/pages/DummyLogin/DummyLoginUtils';
import routes from './routes';
import 'moment/locale/de';

// eslint-disable-next-line max-len
// TODO check what's the difference to main.less under cic, maybe we can get rid of this indexAEM file
import '../styles/main.less';
// have to disable eslint because of ordering of import

const byMswIgnoredUrls = ['/src', '/node_modules/', '/browser-sync/', 'vendors-node_modules', 'cic/static', '/main.'];
if (__A11Y__) {
  initMock();
  window.myAxe = axe;
  // eslint-disable-next-line no-console
  console.log('A11Y MODE IS ACTIVATED');
}

if (isCICContentTree) {
  require('./cic/styles/main.less');
} else {
  require('./csc/styles/main.less');
}

async function deferRender() {
  if (process.env.NODE_ENV !== 'production') {
    const { handlersCollector } = await import('../tools/msw/handlersCollector');
    const { setupWorker } = await import('msw/browser');
    return setupWorker(...handlersCollector).start({
      onUnhandledRequest(request, print) {
        if (byMswIgnoredUrls.some((url) => request.url.includes(url))) {
          return;
        }
        print.warning();
      },
    });
  }
  return Promise.resolve();
}

if (process.env.NODE_ENV !== 'production' && !__A11Y__) {
  // eslint-disable-next-line global-require
  require('./cic/styles/colors.less');
}

// have to disable eslint because of ordering of import
// import startIConnectCustomerApp from 'icon-customer-web-v2';
if (isAccount) {
  deferRender().then(() => {
    startAccountApp(routes);
  });
} else {
  deferRender().then(() => {
    startCicApp(routes);
  });
}
