import React from 'react';
import MeterNumberValidationHelpers from '../../../../../utils/MeterNumberValidationHelpers';
import { DeviceDataTypes } from '../../MeterReadingTypes';

export type MeterReadGenericTypes = {
  genericTitle: string;
  contractIdLabel?: string;
  addressLabel?: string;
  deviceData?: DeviceDataTypes;
};

type MeterReadingGenericProps = MeterReadGenericTypes;

const MeterReadingGeneric = (props: MeterReadingGenericProps) => {
  const buildMeterAddress = () => {
    const address = props.deviceData && props.deviceData.address;
    if (!address || props.deviceData?.serialNumberObfuscated) return '';

    return (
      <span data-qa='address-data'>
        {address.street} {address.houseNumber} {address.houseNumberSupplement}
        <br />
        {address.zip} {address.city} {address.positionDescription ? `(${address.positionDescription})` : ''}
      </span>
    );
  };

  const dataFromParent = props.deviceData && props.deviceData.address;

  return (
    <div className='ano-meter-reading__box--top row'>
      <h2 className='text-center ano-meter-reading__headerbox__title'>{props.genericTitle}</h2>
      {dataFromParent ? (
        <div className='col-md-12 ano-meter-reading__headerbox__data-container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='ano-meter-reading__headerbox__subtitle '>{props.contractIdLabel}</h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='ano-meter-reading__headerbox__generic-data'>
                  {MeterNumberValidationHelpers.removeRedundantZeros(props.deviceData!.contractAccountId)}
                </h4>
              </div>
            </div>
          </div>
          <div className='ano-meter-reading__headerbox__divider' />
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='ano-meter-reading__headerbox__subtitle'>{props.addressLabel}</h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='ano-meter-reading__headerbox__generic-data'>{buildMeterAddress()}</h4>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MeterReadingGeneric;
