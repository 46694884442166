import RestHelper, { MWDomains } from '../../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';
import CONFIG from '../config/config';

export function getDevices() {
  return CacheHandler.requestCache(
    () =>
      RestHelper.get(
        `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/smartmeter/v0/meters`,
        {},
        RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
      ),
    'smartmeter'
  );
}

export function getHanDevices() {
  return CacheHandler.requestCache(
    () =>
      RestHelper.get(
        `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/smartmeter/v0/meters?filterBy=hanEnabledDevices`,
        {},
        RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
      ),
    'smartmeter'
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getDeviceData(serialNumber, obises, fromDate, toDate) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/smartmeter/v0/meters/${serialNumber}/obises/${obises}?from=${fromDate}&to=${toDate}`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function getDeclaration() {
  return RestHelper.get(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/smartmeter/v0/consent`,
    {},
    RestHelper.setXClientHeader({}, CONFIG.CHANNEL_CONFIG.portal)
  );
}
export function declareConsent(accepted) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/smartmeter/v0/consent`,
    {
      agreed: accepted,
    },
    RestHelper.setXClientHeader({ headers: { 'Content-Type': 'application/json' } }, CONFIG.CHANNEL_CONFIG.portal)
  );
}

export function getDeviceFile(serialNumber, obises, fromDate, toDate) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicCustomer)}/api/smartmeter/v0/meters/${serialNumber}/obises/${obises}/excel?from=${fromDate}&to=${toDate}`,
    {},
    RestHelper.setXClientHeader({ responseType: 'arraybuffer' }, CONFIG.CHANNEL_CONFIG.portal)
  );
}
