import { ContractProps } from '../components/Dashboardiii/propTypes/contractPropTypes';
import { ContractsActions, ContractsActionsTypes } from '../actions/Contracts/contractsActions';

import Router from '../../utils/Router';
import SessionHandler from '../../utils/SessionHandler';

export type ContractReducerStateTypes = {
  contractId?: string;
  data?: ContractProps[];
};

export const getPreselectedContractAccountId = (contracts: ContractProps[]) => {
  const id = Router.getParameter('contractAccId');
  if (id && contracts && contracts[0]) {
    const selectedContract = contracts.find((contract) => contract.contractAccountId === id);
    if (selectedContract) {
      SessionHandler.setPermissions(contracts, id);
      return id;
    }
    Router.setParameter({ contractAccId: '' });
  }

  if (contracts[0]) {
    SessionHandler.setPermissions(contracts, contracts[0].contractAccountId);
    return contracts[0].contractAccountId;
  }
  return null;
};

export default function reducer(state: ContractReducerStateTypes = {}, action: ContractsActionsTypes) {
  switch (action.type) {
    case ContractsActions.LOAD_CURRENT_CONTRACTS:
      return {
        ...state,
        data: action.data.innerData,
        contractId: getPreselectedContractAccountId(action.data.innerData),
      };
    case ContractsActions.SET_SELECTED_CONTRACT:
      SessionHandler.setPermissions(state.data, action.data.contractId);
      Router.setParameter({ contractAccId: action.data.contractId });
      return {
        ...state,
        contractId: action.data.contractId,
      };
    case ContractsActions.SET_CONTRACT_WITHOUT_HASH:
      SessionHandler.setPermissions(state.data, action.data.contractId);
      return {
        ...state,
        contractId: action.data.contractId,
      };
    default:
      return state;
  }
}
