import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallBackComponent from './csc/components/ErrorFallBackComponent/ErrorFallBackComponent';
import GlobalErrorHandler from './csc/utils/GlobalErrorHandler';

import { isCICContentTree } from './mainConfig';

// TODO conditional import for separate build of components in order to improve build speed
const ReactComponentsCommon = {
  ExternalRedirector: () => import('./common/features/ExternalRedirector/ExternalRedirector'),
  LoginBridge: () => import('./common/features/LoginBridge/LoginBridge'),
  RegisterPage: () => import('./common/features/Registration/Register/RegisterPage'), // TODO: Register Page and used Comoonents are completely in js.
  RegisterConfirmation: () => import('./common/features/RegisterConfirmation/RegisterConfirmation'),
  AppManagementPage: () => import('./common/features/AppManagementPage/AppManagementPage'),
  AccountData: () => import('./common/features/AccountData/components/AccountData'),
  ChangeMyDataPage: () => import('./common/features/AccountData/ChangeMyDataPage'),
  SSOLoginGeneratorPage: () => import('./common/features/SSOLoginGenerator/SSOLoginGeneratorPage'),
  DashboardNav: () => import('./cic/components/Dashboardiii/DashboardNav/DashboardNav'),
  DashboardTopPanel: () => import('./cic/components/Dashboardiii/DashboardTopPanel/DashboardTopPanel'),
  PasswordForgottenPageConfirmation: () => import('./common/features/PasswordForgotten/PasswordForgottenPageConfirmation'),
  PasswordForgottenPage: () => import('./common/features/PasswordForgotten/PasswordForgottenPage'),
  LogoutBridge: () => import('./cic/pages/LogoutBridge/LogoutBridge'),
  ChangeEmailConfirmation: () => import('./cic/pages/ChangeEmailConfirmation/ChangeEmailConfirmation'),
  SecondRegistrationPage: () => import('./cic/pages/SecondRegistration/SecondRegistrationPage'),
  PhoneNumberPage: () => import('./cic/pages/PhoneNumberPage/PhoneNumberPage'),
  MyAppointmentTile: () => import('./cic/components/Dashboard/MyAppointmentTile'),
  DashboardBody: () => import('./cic/components/Dashboardiii/DashboardBody/DashboardBody'),
  MyAppointmentPage: () => import('./cic/pages/MyAppointment/MyAppointmentPage'),
  FlyoutMenu: () => import('./csc/components/FlyoutMenu/FlyoutMenu'),
  FlyoutButton: () => import('./csc/components/FlyoutMenu/FlyoutButton'),
  SearchAutocomplete: () => import('./csc/pages/SearchAutocomplete/SearchAutocomplete'),
  PostboxPage: () => import('./common/features/PostBox/PostboxPage'),
  PostalCodeAuthentificationPage: () => import('./common/features/PostalCodeAuthentification/PostalCodeAuthentificationPage'),
  IntermediateReadingPage: () => import('./common/features/IntermediateReading/IntermediateReadingPage'),
};
const ReactComponentsCSC = {
  ...ReactComponentsCommon,
  ConsumptionAllDetailsPage: () => import('./common/features/ConsumptionDetails/ConsumptionAllDetailsPage'),
  WelcomePageCsc: () => import('./csc/pages/WelcomePage/WelcomePage'),
  ChangeBankDetailsPage: () => import('./csc/pages/ChangeBankDetailsPage/ChangeBankDetailsPage'),
  CompaniesInTroublePage: () => import('./common/features/CompaniesInTrouble/CompaniesInTrouble'),
  CompaniesInTroubleNavigation: () => import('./common/features/CompaniesInTrouble/components/CompaniesInTroubleNavigation'),
  MeterReadingPage: () => import('./csc/pages/MeterReadingPage/MeterReadingPage'),
  ConsumptionPage: () => import('./csc/pages/ConsumptionHistoryPage/ConsumptionPage'),
  ChangeEmailPage: () => import('./csc/pages/ChangeEmailPage/ChangeEmailPage'),
  ChangeNotificationPage: () => import('./csc/pages/ChangeNotificationPage/ChangeNotificationPage'),
  MyDataDashboardPage: () => import('./csc/pages/MyDataDashboard/MyDataDashboardPage'),
  ContractNavigation: () => import('./csc/components/ContractNavigation/ContractNavigation/ContractNavigation'),
  DeviceNavigation: () => import('./csc/components/DeviceNavigation/DeviceNavigation'),
  AnualReportFormPage: () => import('./csc/pages/AnualReportFormPage/AnualReportFormPage'),
  ConsumptionDetailsPage: () => import('./csc/pages/ConsumptionHistoryDetails/ConsumptionDetailsPage'),
  DoorPage: () => import('./csc/pages/DoorPage/DoorPage'),
  LoggedInMeterReadingPage: () => import('./csc/pages/LoggedInMeterReadingPage/LoggedInMeterReadingPage'),
  InvoicePage: () => import('./csc/pages/InvoicePage/InvoicePage'),
  LoginPage: () => import('./csc/pages/LoginPage/LoginPage'),
  ContractAccountManagementPage: () => import('./csc/pages/ContractAccountManagement/ContractAccountManagementPage'),
  AccessDetails: () => import('./csc/pages/AccessDetails/AccessDetails'),
  HANAccessDetails: () => import('./csc/pages/HANAccessData/HANAccessDetails'),
  FirstLoginModal: () => import('./csc/components/FirstLoginModal/FirstLoginModal'),
  TaxVatDetailsPage: () => import('./csc/pages/TaxVatDetailsPage/ChangeTaxVatDataPage'),
  ConsumptionVisualisationPage: () => import('./csc/pages/ConsumptionVisualisation/ConsumptionVisualisationPage'),
  BudgetBillingPlan: () => import('./csc/pages/BudgetBillingPlan/BudgetBillingPlan'),
  ConsumptionSubscriptionPage: () => import('./csc/pages/ConsumptionSubscription/ConsumptionSubscriptionPage'),
  AcceptThankYouPage: () => import('./csc/pages/AcceptThankYouPage/AcceptThankYouPage'),
  ConsumptionSubscriptionOverviewPage: () => import('./csc/pages/ConsumptionSubscriptionOverview/ConsumptionSubscriptionOverviewPage'),
  VisualizationWithdraval: () => import('./csc/pages/VisualizationWithdraval/VisualizationWithdraval'),
  ContactFormPage: () => import('./csc/pages/ContactForm/ContactFormPage'),
  WidgetsPage: () => import('./csc/pages/WidgetsPage/WidgetsPage'),
  HowToMarginCol: () => import('./csc/pages/HowToMarginCol/HowToMarginCol'),
  RlmVisualisationPage: () => import('./csc/pages/RlmVisualisationPage/RlmVisualisationPage'),
  PortalUnlockPage: () => import('./csc/pages/PortalUnlock/PortalUnlockPage'),
  PortalUnlockSuccessPage: () => import('./csc/pages/PortalUnlockSuccess/PortalUnlockSuccessPage'),
  MastrNavigation: () => import('./csc/components/Mastr/MastrNavigation/MastrNavigation'),
  MastrDetails: () => import('./csc/components/Mastr/MastrDetails/MastrDetails'),
  RegulatoryMeasuresPage: () => import('./csc/pages/RegulatoryMeasures/RegulatoryMeasuresPage'),
  DirectMarketingPage: () => import('./csc/pages/DirectMarketing/DirectMarketingPage'),
  XsHint: () => import('./csc/components/XsHint/XsHint'),
  AnonymousOkoPage: () => import('./csc/pages/AnonymousOko/AnonymousOkoPage'),
  SubscrSuccess: () => import('./csc/components/Communication/OptIn/SubscrSuccess/SubscrSuccess'),
  ConfirmBox: () => import('./csc/components/Communication/OptIn/ConfirmBox/ConfirmBox'),
  AnoOkoUnsubscribe: () => import('./csc/components/Communication/OptOut/Unsubscribe'),
  DoubleOptInPage: () => import('./csc/pages/DoubleOptIn/DoubleOptInPage'),
  DoubleOptInUnsubscribe: () => import('./csc/components/Communication/OptOut/Unsubscribe'),
  PrivChangeBanner: () => import('./csc/components/PrivChangeBanner/QueryClientPrivChangeBanner'),
};
const ReactComponentsCic = {
  ...ReactComponentsCommon,

  WelcomePageCic: () => import('./cic/pages/WelcomePage/WelcomePage'),
  LoginPage: () => import('./cic/pages/LoginPage/LoginPage'),
  MeterReadingPage: () => import('./common/features/OfflinePortal/OfflinePortalRouter'),
  ConsumptionPage: () => import('./cic/pages/ConsumptionHistoryPage/ConsumptionPage'),
  ChangeEmailPage: () => import('./cic/pages/ChangeEmailPage/ChangeEmailPage'),
  ChangeNotificationPage: () => import('./cic/pages/ChangeNotificationPage/ChangeNotificationPage'),
  MyDataDashboardPage: () => import('./cic/pages/MyDataDashboard/MyDataDashboardPage'),
  ContractNavigation: () => import('./cic/components/ContractNavigation/ContractNavigation'),
  ConsumptionDetailsPage: () => import('./cic/pages/ConsumptionHistoryDetails/ConsumptionDetailsPage'),
  DummyLogin: () => import('./cic/pages/DummyLogin/DummyLogin'),
  DoorPage: () => import('./csc/pages/DoorPage/DoorPage'),
  LoggedInMeterReadingPage: () => import('./cic/pages/LoggedInMeterReadingPage/LoggedInMeterReadingPage'),
  InvoicePage: () => import('./cic/pages/InvoicePage/InvoicePage'),
  ContractAccountManagementPage: () => import('./cic/pages/ContractAccountManagement/ContractAccountManagementPage'),
  AccessDetails: () => import('./cic/pages/AccessDetails/AccessDetails'),
  HANAccessDetails: () => import('./csc/pages/HANAccessData/HANAccessDetails'),
  FirstLoginModal: () => import('./cic/components/FirstLoginModal/FirstLoginModal'),
  TaxVatDetailsPage: () => import('./cic/pages/TaxVatDetailsPage/ChangeTaxVatDataPage'),
  ConsumptionVisualisationPage: () => import('./cic/pages/ConsumptionVisualisation/ConsumptionVisualisationPage'),
  ConsumptionSubscriptionPage: () => import('./cic/pages/ConsumptionSubscription/ConsumptionSubscriptionPage'),
  AcceptThankYouPage: () => import('./csc/pages/AcceptThankYouPage/AcceptThankYouPage'),
  ConsumptionSubscriptionOverviewPage: () => import('./cic/pages/ConsumptionSubscriptionOverview/ConsumptionSubscriptionOverviewPage'),
  VisualizationWithdraval: () => import('./csc/pages/VisualizationWithdraval/VisualizationWithdraval'),
  WidgetsPage: () => import('./csc/pages/WidgetsPage/WidgetsPage'),
  HowToMarginCol: () => import('./cic/pages/HowToMarginCol/HowToMarginCol'),
  RlmVisualisationPage: () => import('./cic/pages/RlmVisualisationPage/RlmVisualisationPage'),
  PortalUnlockPage: () => import('./csc/pages/PortalUnlock/PortalUnlockPage'),
  PortalUnlockSuccessPage: () => import('./csc/pages/PortalUnlockSuccess/PortalUnlockSuccessPage'),
  MastrNavigation: () => import('./csc/components/Mastr/MastrNavigation/MastrNavigation'),
  MastrDetails: () => import('./csc/components/Mastr/MastrDetails/MastrDetails'),
  RegulatoryMeasuresPage: () => import('./csc/pages/RegulatoryMeasures/RegulatoryMeasuresPage'),
  DirectMarketingPage: () => import('./csc/pages/DirectMarketing/DirectMarketingPage'),
  XsHint: () => import('./csc/components/XsHint/XsHint'),
  AnonymousOkoPage: () => import('./csc/pages/AnonymousOko/AnonymousOkoPage'),
  SubscrSuccess: () => import('./csc/components/Communication/OptIn/SubscrSuccess/SubscrSuccess'),
  ConfirmBox: () => import('./cic/components/Communication/OptIn/ConfirmBox/ConfirmBox'),
  AnoOkoUnsubscribe: () => import('./cic/components/Communication/OptOut/Unsubscribe'),
  DoubleOptInPage: () => import('./csc/pages/DoubleOptIn/DoubleOptInPage'),
  DoubleOptInUnsubscribe: () => import('./csc/components/Communication/OptOut/Unsubscribe'),
  PrivChangeBanner: () => import('./csc/components/PrivChangeBanner/QueryClientPrivChangeBanner'),
};

const relic = window.newrelic || window.newRelic;

const generateRoutes = (components) => {
  const routes = [];
  Object.keys(components).forEach((componentName) => {
    const route = {
      routeName: componentName,
      load: (container, callback) =>
        components[componentName]()
          .then((component) => {
            const cscnComponent = withErrorBoundary(component.default, {
              FallbackComponent: ErrorFallBackComponent,
              onError(error) {
                relic?.noticeError(error);
                GlobalErrorHandler.handleException(error);
              },
            });
            container.register(cscnComponent).as(componentName);
            callback();
            return true;
          })
          .catch((error) => {
            relic?.noticeError(error);
            // eslint-disable-next-line
            console.error(`Could not load component:\n${error}`);
            return false;
          }),
    };
    routes.push(route);
  });
  return routes;
};

export default generateRoutes(isCICContentTree ? ReactComponentsCic : ReactComponentsCSC);
