import { TaxNumberChangeEvents, TaxNumberChangeEventType, TaxNumberTypes } from '../pages/TaxVatDetailsPage/taxVatinPageTypes';
import { TaxNumbersActions, TaxNumbersActionsTypes } from '../actions/taxNumbers/taxNumberActions';

export type TaxNumbersReducerStateTypes = {
  taxNumbers: TaxNumberTypes;
  isLoading: boolean;
  hasApiError: boolean;
  apiErrorMessage: string;
  taxNumbersChangeEvent: TaxNumberChangeEventType;
};

const initialState: TaxNumbersReducerStateTypes = {
  taxNumbers: {
    taxin: '',
    vatin: 'DE',
  },
  taxNumbersChangeEvent: {
    taxin: TaxNumberChangeEvents.NONE,
    vatin: TaxNumberChangeEvents.NONE,
  },
  isLoading: true,
  hasApiError: false,
  apiErrorMessage: '',
};

export default function reducer(state: TaxNumbersReducerStateTypes = initialState, action: TaxNumbersActionsTypes) {
  switch (action.type) {
    case TaxNumbersActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case TaxNumbersActions.LOAD_TAX_VAT_NUMBERS:
    case TaxNumbersActions.SAVE_TAX_DATA:
      return {
        ...state,
        taxNumbers: action.taxNumbers,
        isLoading: false,
      };
    case TaxNumbersActions.SET_API_ERROR:
      return {
        ...state,
        isLoading: false,
        hasApiError: true,
        apiErrorMessage: action.apiErrorMessage,
      };
    case TaxNumbersActions.SET_TAX_VAT_NUMBERS_CHANGE_EVENTS:
      return {
        ...state,
        taxNumbersChangeEvent: action.taxNumbersChangeEvent,
      };
    default:
      return state;
  }
}
