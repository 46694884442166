import formValidation from './formValidation';
import meterReading from './meterReading';
import contracts from './contracts';
import myData from './personalData';
import loggedInMeterReading from './loggedinMeterReading';
import invoice from './invoice';
import accountData from '../../common/features/AccountData/redux/accountData';
import registration from '../../common/features/Registration/registrationReducer';
import devices from './devices';
import budgetbillingplans from './budgetBillingPlan';
import search from './search';
import meterPins from './meterPins';
import rlm from './rlm';
import contact from './contactLoggedIn';
import mastr from './mastr';
import consumptionSubscription from './consumptionSubscription';
import regulations from './regulations';
import directMark from './directMark';
import communication from './communication';
import phone from '../../cic/redux/phone';
import myAppointment from '../../cic/redux/myAppointment';
import postbox from '../../common/features/PostBox/redux/postboxReducer';
import companiesInTrouble from '../../common/features/CompaniesInTrouble/redux/companiesInTrouble';

const reducers = {
  formValidation,
  meterReading,
  myData,
  contracts,
  loggedInMeterReading,
  invoice,
  accountData,
  registration,
  devices,
  budgetbillingplans,
  meterPins,
  rlm,
  contact,
  mastr,
  companiesInTrouble,
  consumptionSubscription,
  regulations,
  directMark,
  communication,
  phone,
  myAppointment,
  search,
  postbox,
};

export default reducers;
