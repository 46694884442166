import PropTypes, { InferProps, shape } from 'prop-types';

import { getAppointmentId, getNextAppointment } from '../../../csc/api/myAppointmentApi';

export const SET_SELECTED_APPOINTMENT = 'api/reducer/SET_APPOINTMENT_CONTRACT';

const myAppointmentSelectionAction = {
  data: shape({
    assOrderId: PropTypes.string.isRequired,
    innerData: PropTypes.object.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export type MyAppointmentSelectionAction = InferProps<typeof myAppointmentSelectionAction>;

export const reloadAppointment = (dispatch: Function, contractAccountId: string, assOrderId: string) => {
  const responseNextAppointmentPromise = getNextAppointment(contractAccountId, assOrderId);
  return responseNextAppointmentPromise
    .then((responseAppointment) => {
      dispatch({
        type: SET_SELECTED_APPOINTMENT,
        data: {
          assOrderId,
          innerData: responseAppointment.data,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: SET_SELECTED_APPOINTMENT,
        data: {
          assOrderId,
          innerData: null,
        },
      });
    });
};

export const loadNextAppointment = (dispatch: Function, contractAccountId: string) => {
  if (contractAccountId) {
    getAppointmentId(contractAccountId).then((responseAppointmentIds) => {
      const selectedAssOrderIds = responseAppointmentIds.data?.assOrderIds;
      if (selectedAssOrderIds) {
        const assOrderId = selectedAssOrderIds[0];
        reloadAppointment(dispatch, contractAccountId, assOrderId);
      }
    });
  }
};
