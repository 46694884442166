import React, { useEffect } from 'react';
import { TextInput } from '@eon-funke/react-shared-ui-next';
import { FormikErrors, FormikProps } from 'formik';
import MeterNumberValidationHelpers from '../../../../../utils/MeterNumberValidationHelpers';
import { setInputState } from '../../../../../utils/InputValidationHelper';
import { DeviceDataTypes, MeterReadingRegisterInputLabels, MeterReadingRegisterTypes } from '../../MeterReadingTypes';
import { getPowerUnitLabel } from '../../../../../utils/EnergyTypeHelper';

export type MeterRegisterInputTypes = {
  formikProps: FormikProps<DeviceDataTypes>;
  formikIdentifier: string;
  register: MeterReadingRegisterTypes;
  loggedIn: boolean;
  index: number;
  unit: string;
};

type MeterRegisterInputProps = MeterRegisterInputTypes & MeterReadingRegisterInputLabels;

export const isFormikErrorMeterReadingRegisterTypes = (x: any): x is FormikErrors<MeterReadingRegisterTypes>[] => {
  return x !== undefined;
};

const MeterRegisterInput = (props: MeterRegisterInputProps) => {
  const { register, registerAPIErrors, registerPlaceholder, lastMeterValueLabel, formikProps, formikIdentifier, loggedIn, index, unit } = props;

  const { values, handleChange, errors, setFieldValue } = formikProps;

  const formErrors: FormikErrors<MeterReadingRegisterTypes>[] = isFormikErrorMeterReadingRegisterTypes(errors.registers)
    ? errors.registers
    : ({} as FormikErrors<MeterReadingRegisterTypes>[]);

  const currentRegister = values.registers[index];

  const touched = currentRegister.readingResult !== undefined;

  const errorMessage = formErrors[index]?.readingResult;

  const errorApiCode = currentRegister.errorCode?.split('.').pop();
  const errorApiMessage = errorApiCode && registerAPIErrors[errorApiCode];

  const errorOutput = (errorApiCode && errorApiMessage) || errorMessage;

  const errorState: boolean =
    touched && (errorMessage !== undefined || (errorApiMessage !== undefined && currentRegister.readingNoteNumber === undefined));

  function resetValues() {
    if (
      currentRegister.possibleReadingNotes !== undefined ||
      currentRegister.errorCode !== undefined ||
      currentRegister.readingNoteNumber !== undefined
    ) {
      setFieldValue(`${formikIdentifier}.possibleReadingNotes`, undefined);
      setFieldValue(`${formikIdentifier}.errorCode`, undefined);
      setFieldValue(`${formikIdentifier}.readingNoteNumber`, undefined);
    }
  }

  useEffect(() => resetValues(), [currentRegister.readingResult]);

  return (
    <div
      className='form-group'
      key={`form${index}`}
    >
      <div className={loggedIn ? 'loggedin-meter-form__input-group' : 'ano-meter-form__input-group'}>
        <TextInput
          name={`${formikIdentifier}.readingResult`}
          type='text'
          id={register.registerNumber}
          label={registerPlaceholder}
          qaId={register.obis}
          onChange={handleChange}
          inputState={setInputState(errorState, touched)}
          errorMessage={touched && errorOutput}
        />
        <strong className={loggedIn ? 'loggedin-meter-form__last-values' : 'ano-meter-form__last-values'}>
          {`
          ${lastMeterValueLabel || ''}
          ${MeterNumberValidationHelpers.getFormattedValue(register.lastReadingResult)}
          ${getPowerUnitLabel(unit)}
          `}
        </strong>
      </div>
    </div>
  );
};

export default MeterRegisterInput;
