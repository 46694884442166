import { PhoneNumberChangeEvent, PhoneNumberActions, PhoneNumberActionTypes } from '../actions/PhoneNumbers/phoneNumbersActions';
import { PhoneNumberType } from '../pages/PhoneNumberPage/PhoneNumberTypes';

export type PhoneNumberReducerStateTypes = {
  phoneNumbers: PhoneNumberType[];
  processedPhoneNumber: PhoneNumberType;
  changeEvent: PhoneNumberChangeEvent;
  isLoading: boolean;
  apiErrorMessage: string;
  hasApiError: boolean;
};

const initialState: PhoneNumberReducerStateTypes = {
  phoneNumbers: [],
  processedPhoneNumber: {} as PhoneNumberType,
  apiErrorMessage: '',
  changeEvent: PhoneNumberChangeEvent.NONE,
  isLoading: false,
  hasApiError: false,
};
export default function reducer(state: PhoneNumberReducerStateTypes = initialState, action: PhoneNumberActionTypes) {
  switch (action.type) {
    case PhoneNumberActions.IS_LOADING:
      return {
        ...state,
        apiErrorMessage: '',
        hasApiError: false,
        isLoading: true,
      };
    case PhoneNumberActions.IS_EDITING:
      return {
        ...state,
        changeEvent: action.changeEvent,
        apiErrorMessage: '',
        hasApiError: false,
        isLoading: true,
      };
    case PhoneNumberActions.FINISHED_EDITING:
      return {
        ...state,
        processedPhoneNumber: action.processedPhoneNumber,
        apiErrorMessage: '',
        hasApiError: false,
        isLoading: false,
      };
    case PhoneNumberActions.RESET_CHANGE_EVENT_TYPE:
      return {
        ...state,
        changeEvent: PhoneNumberChangeEvent.NONE,
        hasApiError: false,
        apiErrorMessage: '',
        processedPhoneNumber: {} as PhoneNumberType,
      };
    case PhoneNumberActions.RESET_API_ERROR:
      return {
        ...state,
        apiErrorMessage: '',
        hasApiError: false,
      };
    case PhoneNumberActions.LOAD_PHONE_NUMBERS:
      return {
        ...state,
        isLoading: false,
        phoneNumbers: action.phoneNumbers,
      };
    case PhoneNumberActions.HAS_API_ERROR:
      return {
        ...state,
        isLoading: false,
        hasApiError: true,
        apiErrorMessage: action.apiErrorMessage,
      };
    default:
      return state;
  }
}
