import RestHelper from '../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';

export function getDevices() {
  return CacheHandler.requestCache(() => RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/consumption/v0/locations`), 'locations');
}

export function getDeviceData(location, fromDate, toDate) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/consumption/v0/locations/${location.locationId}/data?from=${fromDate}&to=${toDate}&profileRole=${location.profileRole}&profileDeterminant=${location.profileDeterminant}`
  );
}

export function getDeviceFile(location, fromDate, toDate) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/consumption/v0/locations/${location.locationId}/excel?from=${fromDate}&to=${toDate}&profileRole=${location.profileRole}&profileDeterminant=${location.profileDeterminant}`,
    {},
    { responseType: 'arraybuffer' }
  );
}
