import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_NATIVE = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

class GermanDateHelpers {
  static dateToGermanString(dateObject: Date, dateFormatAsYear?: boolean) {
    const date = dateObject.getDate();
    const month = dateObject.getMonth();
    const dateProps = {
      d: date < 10 ? `0${date}` : date,
      m: month + 1 < 10 ? `0${month + 1}` : month + 1,
      Y: dateObject.getFullYear(),
    };
    if (dateFormatAsYear) {
      return `${dateProps.Y}.${dateProps.m}.${dateProps.d}`;
    }
    return `${dateProps.d}.${dateProps.m}.${dateProps.Y}`;
  }

  static isValidGermanDateFormat(date: Date | string, props?: { isMobile: boolean; isTablet: boolean }): boolean {
    return !props || (!props.isMobile && !props.isTablet)
      ? moment(date, DATE_FORMAT, true).isValid()
      : moment(date, DATE_FORMAT_NATIVE, true).isValid();
  }

  static germanStringToDate(dateString: string, now?: Date): Date | null {
    if (dateString === '30.11.0002') {
      return new Date('0002-10-30');
    }

    if (!dateString || typeof dateString !== 'string') {
      return null;
    }

    // super trim all
    let val = dateString.replace(/[\s]{1}/g, '');

    val = val.replace(/[\D]/g, '.');
    val = val.replace(/[..]+/g, '.');

    // update dotless format
    if (/^[\d]{6,8}$/.test(val)) {
      val = `${val.substr(0, 2)}.${val.substr(2, 2)}.${val.substr(4)}`;
    }

    const digits = val.split('.');
    if (digits.length === 3) {
      let y = parseInt(digits[2], 10);
      const m = parseInt(digits[1], 10);
      const d = parseInt(digits[0], 10);

      // update short year (useful only for birthdays)
      // TODO: should be updated after the year - 3000 !
      if (now) {
        const shortY = now.getFullYear() - 2000;
        if (y <= shortY) {
          y = 2000 + y;
        } else {
          y = y < 100 ? 1900 + y : y;
        }
      } else if (y < 30) {
        y += 2000;
      } else {
        y = y < 100 ? y + 1900 : y;
      }

      // invalid values
      if (!this.isValidDate(d, m, y)) {
        return null;
      }

      const date = new Date(y, m - 1, d);

      // if valid Date
      if (date && !isNaN(date.getTime())) {
        return date;
      }
    }
    return null;
  }

  static isValidDate(day: number, month: number, year: number): boolean {
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }

    if (day < 1 || year < 1) {
      return false;
    }

    if (month > 12 || month < 1) {
      return false;
    }

    if ((month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) && day > 31) {
      return false;
    }

    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
      return false;
    }

    if (month === 2) {
      if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0 && year % 100 === 0)) {
        if (day > 29) {
          return false;
        }
      } else if (day > 28) {
        return false;
      }
    }
    return true;
  }

  static convertDateArray = (date: number[]) => {
    const day = date[2];
    const month = date[1];
    const year = date[0];
    return moment(new Date(`${year}-${month}-${day}`)).format(DATE_FORMAT);
  };
}
export default GermanDateHelpers;
