import SessionHandlerEngine, { loginTypes, appTypes } from './SessionHandlerCic';

export { appTypes };
export { loginTypes };
let engine = new SessionHandlerEngine();
class SessionHandler {
  static generateSSOLoginUrl() {
    return engine.generateSSOLoginUrl();
  }

  static getCurrentCodeVerifier(...args) {
    return engine.getCurrentCodeVerifier(...args);
  }

  static removeCodeVerifier(...args) {
    return engine.removeCodeVerifier(...args);
  }

  static parseJwt(...args) {
    return engine.parseJwt(...args);
  }

  static setEngine(driver) {
    engine = driver;
    window.destroySession = () => {
      engine.destroySession();
    };
  }

  static getLogoutSuccessPage() {
    return engine.getLogoutSuccessPage();
  }

  static getRefreshToken() {
    return engine.getRefreshToken();
  }

  static setStorage(...args) {
    return engine.setStorage(...args);
  }

  static startSession(...args) {
    return engine.startSession(...args);
  }

  static attachMouseListener() {
    return engine.attachMouseListener();
  }

  static initSession(...args) {
    return engine.initSession(...args);
  }

  static getEngine() {
    return engine;
  }

  static startExpireInterval() {
    return engine.startExpireInterval();
  }

  static getLoginType() {
    return engine.getLoginType();
  }

  static setLoginType(...args) {
    return engine.setLoginType(...args);
  }

  static getRedirectUrl(...args) {
    return engine.getRedirectUrl(...args);
  }

  static destroyRemoteSso(...args) {
    return engine.destroyRemoteSso(...args);
  }

  static destroyLocalSession(...args) {
    return engine.destroyLocalSession(...args);
  }

  static destroySession(...args) {
    return engine.destroySession(...args);
  }

  static getPermissions() {
    return engine.getPermissions();
  }

  static setPermissions(...args) {
    return engine.setPermissions(...args);
  }

  static resetPermissions() {
    return engine.resetPermissions();
  }

  static checkForPermissions(...args) {
    return engine.checkForPermissions(...args);
  }

  static hasPermissionsByMwToken(...args) {
    return engine.hasPermissionsByMwToken(...args);
  }

  static hasPermissions(...args) {
    return engine.hasPermissions(...args);
  }

  static userMustBeLoggedIn(...types) {
    return engine.userMustBeLoggedIn(...types);
  }

  static isUserLoggedIn(...types): boolean {
    return engine.isUserLoggedIn(...types);
  }

  static getName() {
    return engine.getName();
  }

  static getToken(...types) {
    return engine.getToken(...types);
  }

  static setToken(...types) {
    return engine.setToken(...types);
  }

  static isUserAssignedToApp(...types) {
    return engine.isUserAssignedToApp(...types);
  }

  static setIntermediateReadingMeterNumber(meterNumber: string) {
    return engine.setIntermediateReadingMeterNumber(meterNumber);
  }

  static getIntermediateReadingMeterNumber() {
    return engine.getIntermediateReadingMeterNumber();
  }
}
SessionHandler.permissionsSet = false;
window.destroySession = () => {
  SessionHandler.destroySession();
};

export default SessionHandler;
