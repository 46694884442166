var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import classNames from 'classnames';
import * as React from 'react';
import { InputState } from '../../constants';
import { genericIcon } from '../../utils/helper';
import InputFeedback from '../InputFeedback/InputFeedback';
import Label from '../Label/Label';
var BASE_CLASS = 'form-textarea-v2';
var FIRST_ROW_HEIGHT = 30;
var OTHER_ROW_HEIGHT = 25;
var TextAreaComponent = (function (_super) {
    __extends(TextAreaComponent, _super);
    function TextAreaComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChange = function (e) {
            if (_this.props.onChange) {
                _this.props.onChange(e);
            }
            _this.setHeight(e.currentTarget);
        };
        _this.setHeight = function (element) {
            var minHeight = _this.getMinHeight();
            if (!_this.props.isFixedHeight) {
                _this.setState({ height: minHeight }, function () {
                    var offset = element.offsetHeight - element.clientHeight;
                    var newHeight = element.scrollHeight + offset;
                    if (newHeight > minHeight) {
                        _this.setState({ height: newHeight });
                    }
                });
            }
        };
        _this.state = {
            height: _this.getMinHeight(),
        };
        return _this;
    }
    TextAreaComponent.prototype.getMinHeight = function () {
        return 1 + FIRST_ROW_HEIGHT + OTHER_ROW_HEIGHT * (this.props.rows - 1);
    };
    TextAreaComponent.prototype.componentDidMount = function () {
        var id = this.props.id;
        var element = document.getElementById(id);
        if (element) {
            this.setHeight(element);
        }
    };
    TextAreaComponent.prototype.render = function () {
        var _a = this.props, id = _a.id, label = _a.label, charsLeftLabel = _a.charsLeftLabel, inputState = _a.inputState, errorMessage = _a.errorMessage, value = _a.value, required = _a.required, qaId = _a.qaId, name = _a.name, disabled = _a.disabled, iconLeft = _a.iconLeft, iconRight = _a.iconRight, maxLength = _a.maxLength, onFocus = _a.onFocus, onBlur = _a.onBlur, placeholder = _a.placeholder, inputRef = _a.inputRef, className = _a.className, labelClassName = _a.labelClassName, feedbackClassName = _a.feedbackClassName, isFixedHeight = _a.isFixedHeight;
        var height = this.state.height;
        var charLeft = maxLength - (value && value.length);
        var isValid = inputState === InputState.VALID;
        var hasError = inputState === InputState.ERROR;
        var wrapperClasses = classNames("".concat(BASE_CLASS), required && "".concat(BASE_CLASS, "--required"), hasError && "".concat(BASE_CLASS, "--error"), isValid && "".concat(BASE_CLASS, "--valid"), (value || placeholder) && "".concat(BASE_CLASS, "--filled"), disabled && "".concat(BASE_CLASS, "--disabled"), className);
        var inputClasses = classNames("".concat(BASE_CLASS, "__input"), hasError && "".concat(BASE_CLASS, "__input--error"), isValid && "".concat(BASE_CLASS, "__input--valid"), (value || placeholder) && "".concat(BASE_CLASS, "__input--filled"), disabled && "".concat(BASE_CLASS, "__input--disabled"), (iconLeft || hasError || isValid) && "".concat(BASE_CLASS, "__input--icon-left"), iconRight && "".concat(BASE_CLASS, "__input--icon-right"));
        return (React.createElement("div", { id: "__".concat(id) },
            React.createElement("div", { className: "".concat(wrapperClasses) },
                hasError && genericIcon(BASE_CLASS, id, 'error'),
                isValid && genericIcon(BASE_CLASS, id, 'valid'),
                iconLeft && (React.createElement("div", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon-left") }, iconLeft)),
                iconRight && (React.createElement("div", { className: "".concat(BASE_CLASS, "__icon ").concat(BASE_CLASS, "__icon-right") }, iconRight)),
                React.createElement("textarea", { id: id, "data-qa": qaId, className: inputClasses, value: value, name: name, disabled: disabled, maxLength: maxLength, onChange: this.handleChange, onBlur: onBlur, onFocus: onFocus, placeholder: placeholder, ref: inputRef, style: {
                        height: "".concat(height, "px"),
                        overflow: isFixedHeight ? 'auto' : 'hidden',
                    } }),
                React.createElement(Label, { htmlFor: id, required: required, labelClassName: labelClassName }, label),
                React.createElement("span", { className: "form-input__bar" }),
                React.createElement("div", { className: "".concat(BASE_CLASS, "__message") },
                    React.createElement(InputFeedback, { hasError: hasError, errorMessage: errorMessage, feedbackClassName: feedbackClassName })),
                !disabled && maxLength && (React.createElement("span", { className: "".concat(BASE_CLASS, "__message-char-left") },
                    charLeft,
                    ' ',
                    charsLeftLabel)))));
    };
    TextAreaComponent.defaultProps = {
        charsLeftLabel: 'Zeichen',
        id: 'id',
        inputState: InputState.NONE,
        label: 'InputLabel',
        name: 'InputName',
        qaId: 'qaId',
        rows: 1,
    };
    return TextAreaComponent;
}(React.Component));
TextAreaComponent.displayName = 'TextAreaComponent';
export default TextAreaComponent;
