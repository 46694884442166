import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { Dropdown } from '@eon-funke/react-shared-ui-next';
import { setInputState } from '../../../../../utils/InputValidationHelper';
import { DeviceDataTypes, MeterReadingDropdownLabels, MeterReadingRegisterTypes, PossibleReadingNotesTypes } from '../../MeterReadingTypes';
import { getDropOptions } from '../../utils/meterReadDropdownHelper';

export type MeterReadingDropdownTypes = {
  formikProps: FormikProps<DeviceDataTypes>;
  formikIdentifier: string;
  name: string;
  register: MeterReadingRegisterTypes;
};

export type MeterReadingDropdownProps = MeterReadingDropdownTypes & MeterReadingDropdownLabels;

const MeterReadingDropdown = (props: MeterReadingDropdownProps) => {
  const { readingNotes, formikProps, formikIdentifier, name, register, dropdownLabel, firstDropdownOptionText } = props;

  const { setFieldValue, touched } = formikProps;

  const reasons: PossibleReadingNotesTypes[] = register.possibleReadingNotes ?? [];

  const [isOpen, setIsOpen] = useState(false);

  const dropOptions = getDropOptions(reasons, readingNotes, firstDropdownOptionText);

  const onChange = (value) => {
    setFieldValue(`${formikIdentifier}.readingNoteNumber`, value);
    setFieldValue(`${formikIdentifier}.errorCode`, undefined);
    setIsOpen(false);
  };

  return (
    <div>
      {dropOptions.length > 1 && (
        <div className='form-group'>
          <Dropdown
            qaId='dropdown'
            name={name}
            isOpen={isOpen}
            onChange={onChange}
            onClick={() => setIsOpen(!isOpen)}
            label={dropdownLabel}
            inputState={setInputState(!register.readingNoteNumber, touched[formikIdentifier])}
            options={dropOptions}
            required
            value={register.readingNoteNumber}
          />
        </div>
      )}
    </div>
  );
};

export default MeterReadingDropdown;
