import classNames from 'classnames';
import * as React from 'react';
import icons from './svgList';
var BASE_CLASS = 'icon';
export var Icon = function (_a) {
    var id = _a.id, qaId = _a.qaId, size = _a.size, glyph = _a.glyph, style = _a.style, className = _a.className, onClick = _a.onClick;
    var wrapperClassNames = classNames("".concat(BASE_CLASS, "__wrapper"), onClick && "".concat(BASE_CLASS, "__wrapper--hasClick"), className);
    var iconClassNames = classNames("".concat(BASE_CLASS, "__default"));
    var foundIconData = icons.find(function (icon) { return icon.name === glyph; });
    var ChosenIcon = foundIconData ? foundIconData.svg : null;
    return (React.createElement("span", { className: wrapperClassNames, onClick: onClick, id: id, "data-qa": qaId }, ChosenIcon ? (React.createElement(ChosenIcon, { "data-qa": glyph, className: iconClassNames, style: style, width: size, height: size, shapeRendering: "auto" })) : (React.createElement("svg", { className: iconClassNames, style: style, width: size, height: size, shapeRendering: "auto" },
        React.createElement("use", { xlinkHref: "#".concat(glyph) })))));
};
Icon.displayName = 'Icon';
export default Icon;
