import classNames from 'classnames';
import * as React from 'react';
export var Link = function (_a) {
    var children = _a.children, id = _a.id, qaId = _a.qaId, href = _a.href, className = _a.className, iconRight = _a.iconRight, iconLeft = _a.iconLeft, title = _a.title, _b = _a.target, target = _b === void 0 ? '_self' : _b, _c = _a.size, size = _c === void 0 ? 'medium' : _c, _d = _a.version, version = _d === void 0 ? 'v0' : _d, isButton = _a.isButton, _e = _a.style, style = _e === void 0 ? undefined : _e, _f = _a.setLinkRef, setLinkRef = _f === void 0 ? function () { return (undefined); } : _f, handleClick = _a.onClick;
    var BASE_CLASS = !isButton ? 'link' : 'button-v2';
    var classes = classNames("".concat(BASE_CLASS), (iconLeft || iconRight) && "".concat(BASE_CLASS, "-v2--icon"), iconLeft && "".concat(BASE_CLASS, "-v2--icon-left"), iconRight && "".concat(BASE_CLASS, "-v2--icon-right"), version && "".concat(BASE_CLASS, "--").concat(version), size && "".concat(BASE_CLASS, "--").concat(size), className);
    var linkRef = setLinkRef();
    return (React.createElement("a", { ref: linkRef, id: id, "data-qa": qaId, title: title, className: classes, target: target, href: href, style: style, rel: target === '_blank' ? 'noopener noreferrer' : '', onClick: handleClick },
        iconLeft,
        React.createElement("span", { className: "".concat(BASE_CLASS, "__text") }, children),
        iconRight));
};
Link.displayName = 'LinkComponent';
export default Link;
