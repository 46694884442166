import RestHelper from '../utils/RestHelper';

const urlLocations = '/cscn-customer-srv/api/consumption/v0/locations';
const urlSubscriptions = '/cscn-customer-srv/api/consumption/v0/subscriptions';

export function getLocations() {
  return RestHelper.get(`${RestHelper.getBaseUrl()}${urlLocations}?activeOnly=true`);
}

/**
 * - Get a single or list of subscriptions
 * An empty list is returned if no subscriptions are found.
 */
export function getSubscriptions(subscriptionId) {
  if (subscriptionId) {
    return RestHelper.get(`${RestHelper.getBaseUrl()}${urlSubscriptions}/${subscriptionId}`);
  }

  return RestHelper.get(`${RestHelper.getBaseUrl()}${urlSubscriptions}`);
}

/**
 * - Creates a new subscription
 * ️️️️️The locations available for creating a new load shape
 * subscription can be obtained using the "getLocations()" API.
 */
export function createSubscription(data) {
  return RestHelper.post(`${RestHelper.getBaseUrl()}${urlSubscriptions}`, data);
}

/**
 * - Edit an existing subscription
 */
export function editSubscription(subscriptionId, data) {
  return RestHelper.put(`${RestHelper.getBaseUrl()}${urlSubscriptions}/${subscriptionId}`, data);
}

/**
 * - Delete an existing subscription
 * The subscription to delete is identified by the target URI (subscriptionId).
 */
export function deleteSubscription(subscriptionId) {
  return RestHelper.delete(`${RestHelper.getBaseUrl()}${urlSubscriptions}/${subscriptionId}`);
}
