import { Dispatch } from 'react';
import { getIntermediateReadingMeters } from './intermediateReadingApi';
import { DeviceDataTypes } from '../../../cic/components/MeterRead/MeterReadingTypes';
import { MeterReadingWizardStepEnum } from '../../../cic/pages/MeterReadingPage/MeterReadingPage';
import { getMeterFromSessionStorage } from './intermediateReadingFunctions';

enum IntermediateReadingActionTypes {
  SET_INTERMEDIATE_READING_DATA = 'SET_INTERMEDIATE_READING_DATA',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
}

type IntermediateReadingReducerStateTypes = {
  intermediateReadingData: DeviceDataTypes | undefined;
  currentStep: string;
  apiError: boolean;
  loading: boolean;
  errorStatus: string;
  errorCode: string;
};

type IntermediateReadingReducerActionTypes = {
  type: IntermediateReadingActionTypes;
  intermediateReadingData: DeviceDataTypes | undefined;
  currentStep: string;
  errorStatus: string;
  errorCode: string;
};

const initialState: IntermediateReadingReducerStateTypes = {
  intermediateReadingData: undefined,
  currentStep: MeterReadingWizardStepEnum.READING,
  loading: true,
  apiError: false,
  errorStatus: '',
  errorCode: '',
};

function reducer(
  state: IntermediateReadingReducerStateTypes = initialState,
  action: IntermediateReadingReducerActionTypes
): IntermediateReadingReducerStateTypes {
  switch (action.type) {
    case IntermediateReadingActionTypes.SET_INTERMEDIATE_READING_DATA:
      return {
        ...state,
        intermediateReadingData: action.intermediateReadingData,
        loading: false,
      };
    case IntermediateReadingActionTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case IntermediateReadingActionTypes.SET_ERROR:
      return {
        ...state,
        loading: false,
        apiError: true,
        errorStatus: action.errorStatus,
        errorCode: action.errorCode,
      };
    case IntermediateReadingActionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      };
    default:
      return state;
  }
}

function setCurrentStep(dispatch: Dispatch<Partial<IntermediateReadingReducerActionTypes>>, currentStep: string) {
  dispatch({
    type: IntermediateReadingActionTypes.SET_CURRENT_STEP,
    currentStep,
  });
}

function setApiError(dispatch: Dispatch<Partial<IntermediateReadingReducerActionTypes>>, errorStatus: string, errorCode: string) {
  dispatch({
    type: IntermediateReadingActionTypes.SET_ERROR,
    errorStatus,
    errorCode,
  });
}

function getIntermediateReadingDataAction(dispatch: Dispatch<Partial<IntermediateReadingReducerActionTypes>>) {
  dispatch({
    type: IntermediateReadingActionTypes.SET_LOADING,
  });
  getIntermediateReadingMeters()
    .then((response) => {
      dispatch({
        type: IntermediateReadingActionTypes.SET_INTERMEDIATE_READING_DATA,
        intermediateReadingData: getMeterFromSessionStorage(response.data.content),
      });
    })
    .catch((error) => {
      const status = error.response?.status;
      if (status === 500) {
        dispatch({
          type: IntermediateReadingActionTypes.SET_ERROR,
          errorStatus: '500',
          errorCode: 'serverError',
        });
      }
    });
}

export default reducer;
export { getIntermediateReadingDataAction, setCurrentStep, setApiError };
export type { IntermediateReadingReducerStateTypes, IntermediateReadingReducerActionTypes };
