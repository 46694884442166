import PropTypes from 'prop-types';

const stepComponentLabelPropTypes = {
  step: PropTypes.string.isRequired,
  meterValueLabel: PropTypes.string.isRequired,
  emailConfirm: PropTypes.string.isRequired,
  register: PropTypes.string.isRequired,
  done: PropTypes.string.isRequired,
};

export default stepComponentLabelPropTypes;
