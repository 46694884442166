import SessionHandler, { loginTypes } from './SessionHandler';
import CONFIG from '../mainConfig';

export const SERVER_ERROR = 'serverError';

class GlobalErrorHandler {
  errorMsgs: {};

  errorPage500Path: null;

  redirectOn500: boolean;

  representativeInfoPage: null;

  static errorMsgs: string[];

  static errorPage500Path: string;

  static redirectOn500: boolean;

  static representativeInfoPage: string;

  constructor() {
    this.errorMsgs = {};
    this.errorPage500Path = null;
    this.redirectOn500 = false;
    this.representativeInfoPage = null;
  }

  static getErrorMessage(name: string): string {
    return this.errorMsgs && this.errorMsgs[name] ? this.errorMsgs[name] : '';
  }

  static addGlobalErrorMsgs(msgs: object): void {
    this.errorMsgs = { ...this.errorMsgs, ...msgs };
  }

  static handleException(exception: any): void {
    const handleRedirect = () => {
      if (!exception.toRedirect) {
        return;
      }
      sessionStorage.cscRedirectLock = 1;
      SessionHandler.destroyLocalSession();
      if ([loginTypes.loggedIn, loginTypes.callCenter].includes(exception.loginType)) {
        SessionHandler.destroyRemoteSso(true, SessionHandler.getRedirectUrl(exception.loginType));
      } else {
        location.replace(SessionHandler.getRedirectUrl(exception.loginType));
      }
    };
    const pageName = window && window.location && window.location.pathname ? window.location.pathname.split('/').pop() : '';
    const itIsOopsPage = this.errorPage500Path && pageName?.length && this.errorPage500Path.indexOf(pageName) !== -1;
    if (exception.name === 'FunkeUnauthenticated' && !CONFIG.AEM_CONFIG.isAuthorMode) {
      handleRedirect();
    } else if (exception.name === 'FunkeUnauthorized' && !CONFIG.AEM_CONFIG.isAuthorMode) {
      handleRedirect();
    } else if (exception.name === 'FunkeResourseNotAvailabe') {
      // not implemented right now
      // const resources = RestHelper.getRootResources();
      // if (resources.meterreadingauth && !CONFIG.AEM_CONFIG.isAuthorMode) {
      //   handleRedirect();
      // }
    } else if (exception.name === 'Funke500ServerError' && this.redirectOn500 && !itIsOopsPage && !sessionStorage.cscRedirectLock) {
      location.replace(this.errorPage500Path);
    } else if (exception.name === 'MyNetworkError' && !itIsOopsPage && this.redirectOn500 && !sessionStorage.cscRedirectLock) {
      location.replace(this.errorPage500Path);
    } else if (exception.name === 'NoImplementation' && !itIsOopsPage && !sessionStorage.cscRedirectLock) {
      sessionStorage.cscRedirectLock = 1;
      location.replace(this.representativeInfoPage);
    }
  }
}

export default GlobalErrorHandler;
