var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import { InputState } from "../../constants";
import TextInput from '../TextInput/TextInput';
var MyAutosuggest = Autosuggest;
var AutosuggestInput = (function (_super) {
    __extends(AutosuggestInput, _super);
    function AutosuggestInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AutosuggestInput.getSuggestionValue = function (suggestion) {
        return suggestion.value;
    };
    AutosuggestInput.renderSuggestion = function (suggestion) {
        return React.createElement("span", null, suggestion.value);
    };
    AutosuggestInput.renderTextInput = function (inputProps) {
        var inputPassThroughProps = __assign({}, inputProps);
        delete inputPassThroughProps.disabled;
        delete inputPassThroughProps.errorMessage;
        delete inputPassThroughProps.qaId;
        delete inputPassThroughProps.label;
        delete inputPassThroughProps.inputState;
        delete inputPassThroughProps.required;
        delete inputPassThroughProps.id;
        return (React.createElement(TextInput, { id: "".concat(inputProps.id, "_text-input"), qaId: "".concat(inputProps.qaId, "_text-input"), label: inputProps.label, inputState: inputProps.inputState, errorMessage: inputProps.errorMessage, required: inputProps.required, disabled: inputProps.disabled, name: inputProps.name, type: "text", value: inputProps.value, inputPassThroughProps: inputPassThroughProps }));
    };
    AutosuggestInput.prototype.render = function () {
        var _a = this.props, id = _a.id, className = _a.className, qaId = _a.qaId, name = _a.name, required = _a.required, inputState = _a.inputState, disabled = _a.disabled, label = _a.label, errorMessage = _a.errorMessage, value = _a.value, suggestions = _a.suggestions, onBlur = _a.onBlur, onClick = _a.onClick, onFocus = _a.onFocus, onChange = _a.onChange, onSuggestionsSelected = _a.onSuggestionsSelected;
        var inputProps = {
            disabled: disabled,
            errorMessage: errorMessage,
            id: id,
            inputState: inputState,
            label: label,
            name: name,
            onBlur: onBlur,
            onChange: onChange,
            onClick: onClick,
            onFocus: onFocus,
            qaId: qaId,
            required: required,
            value: value || '',
        };
        var theme = {
            container: 'autosuggest-input__container',
            containerOpen: 'autosuggest-input__container--open',
            sectionContainer: 'autosuggest-input__section-container',
            sectionContainerFirst: 'autosuggest-input__section-container--first',
            sectionTitle: 'autosuggest-input__section-title',
            suggestion: 'autosuggest-input__suggestion',
            suggestionFirst: 'autosuggest-input__suggestion--first',
            suggestionHighlighted: 'autosuggest-input__suggestion--highlighted',
            suggestionsContainer: 'autosuggest-input__suggestions-container',
            suggestionsContainerOpen: 'autosuggest-input__suggestions-container--open',
            suggestionsList: 'autosuggest-input__suggestions-list',
        };
        return (React.createElement("div", { id: id, "data-qa": qaId, className: classNames('autosuggest-input', className, {
                'autosuggest-input--with-error-message': !!(inputState === InputState.ERROR && errorMessage),
            }) },
            React.createElement(MyAutosuggest, { suggestions: suggestions || [], onSuggestionsFetchRequested: function () { }, onSuggestionsClearRequested: function () { }, getSuggestionValue: AutosuggestInput.getSuggestionValue, renderSuggestion: AutosuggestInput.renderSuggestion, onSuggestionSelected: onSuggestionsSelected, focusInputOnSuggestionClick: false, renderInputComponent: AutosuggestInput.renderTextInput, inputProps: inputProps, theme: theme })));
    };
    return AutosuggestInput;
}(React.Component));
export { AutosuggestInput };
export default AutosuggestInput;
