import RestHelper, { MWDomains } from '../utils/RestHelper';
import CacheHandler from '../utils/CacheHandler';
import { isCICContentTree } from '../mainConfig';
import accountDataCicApi from '../cic/api/accountDataApi';
import accountDataCscApi from '../csc/api/accountDataApi';

const currentApiSet = isCICContentTree ? accountDataCicApi : accountDataCscApi;
export const { addBusinessPartner, registerUser, deleteApp, resetPasswordInitiate, saveAccountData, deactivate2FAAuthentication } = currentApiSet;

// The rest is the same for both contexts

export type AccountDataType = {
  id: string;
  firstName: string;
  mobilNumber: string;
  email: string;
  oldEmail?: string;
  salutation: string;
  lastName: string;
  emailConfirmed: string;
  apps: string[];
};

export interface deletePayload {
  applicationKeys: string[];
  aemInstanceKey?: string;
  reason?: string;
  comment?: string;
}

export const getAccountData = () =>
  new Promise((resolve) => {
    resolve({
      data: CacheHandler.getCurrentCacheByToken('accountData'),
    });
  });

export const validatePhoneNumber = (phoneNumber) => {
  const pattern = new RegExp('^(\\+[1-9]|[0-9])[\\d\\s\\-]+$');
  if (!pattern.test(phoneNumber) || phoneNumber.length > 18) {
    const data = {
      errors: [{}],
    };
    return Promise.resolve({ data });
  }
  return RestHelper.post(
    `${RestHelper.getBaseUrl(MWDomains.cicAccount)}/public-api/v1/validations/phonenumber`,
    {
      data: {
        type: 'ValidationResource',
        attributes: {
          toBeValidate: phoneNumber,
        },
      },
    },
    {},
    true,
    false
  );
};
