const NAME_REGEX: RegExp = /^[^\s][\u0020\u002D\u005F\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0100-\u017F\u0180-\u024F0-9.&]*?$/i;
const ZIP_REGEX: RegExp = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/i;
const IBAN_REGEX: RegExp = /DE\d{2}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{2}|DE\d{20}/i;
// eslint-disable-next-line max-len
const BIC_REGEX: RegExp = /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/i;
// eslint-disable-next-line max-len
const EMAIL_REGEX: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i; // eslint-disable-line no-useless-escape
const DATE_REGEX: RegExp = /^\d{2}\.\d{2}\.\d{4}$|^\d{4}-\d{2}-\d{2}$|^\d{4}\/\d{2}\/\d{2}$/i;
const ACCOUNTNUMBER_REGEX: RegExp = /[0-9]{1,20}/i;
const ONLY_POSITIVE_NUMBER_REGEX: RegExp = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/; // eslint-disable-line no-useless-escape
// eslint-disable-next-line max-len
const PHONE_REGEX: RegExp =
  /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[/]?[0-9]{2,3}[)]?[/]?[-\s\.]?[0-9]{2,3}[)]?[/]?[-\s\.]?[0-9]{2,3}[-\s\.]?[/]?[0-9]{0,8}$/im; // eslint-disable-line no-useless-escape
const TAX_REGEX = /^(?:\d{9,13}|(?:\d{5,8}[-/\\ ]\d{4,5})|(?:\d{2,4}[-/\\ ]\d{3,4}[-/\\ ]\d{4,5}))$/;
const VAT_REGEX = /^DE\d{9}|DE$/i;
const ADJUST_AMOUNT_REGEX: RegExp = /[0-9]{0,5}$/;
const ALPHA_NUMERIC_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
const ONLY_NUMBERS = /^\d+$/;

export {
  NAME_REGEX,
  ZIP_REGEX,
  IBAN_REGEX,
  BIC_REGEX,
  EMAIL_REGEX,
  DATE_REGEX,
  ACCOUNTNUMBER_REGEX,
  ONLY_POSITIVE_NUMBER_REGEX,
  PHONE_REGEX,
  TAX_REGEX,
  VAT_REGEX,
  ADJUST_AMOUNT_REGEX,
  ALPHA_NUMERIC_REGEX,
  ONLY_NUMBERS,
};
