import PropTypes, { InferProps } from 'prop-types';

const addressData = {
  premiseId: PropTypes.number.isRequired,
  street: PropTypes.string.isRequired,
  houseNumber: PropTypes.string,
  houseNumberSupplement: PropTypes.string,
  postalCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  pointOfConsumptionId: PropTypes.number.isRequired,
};

export const contractProps = {
  contractAccountId: PropTypes.string.isRequired,
  assOrderId: PropTypes.string,
  active: PropTypes.bool.isRequired,
  division: PropTypes.string.isRequired,
  customerGroup: PropTypes.string.isRequired,
  pointOfConsumptions: PropTypes.arrayOf(PropTypes.shape(addressData)),
  meterSerialNumbers: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string),
  businessPartner: PropTypes.shape({
    businessPartnerId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    name1: PropTypes.string,
    name2: PropTypes.string,
    name3: PropTypes.string,
    name4: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    salutation: PropTypes.string,
    academicTitle: PropTypes.string,
  }),
  _links: PropTypes.shape({
    self: PropTypes.shape({
      href: PropTypes.string,
    }),
    businesspartners: PropTypes.shape({
      href: PropTypes.string,
    }),
    mydata: PropTypes.shape({
      href: PropTypes.string,
    }),
    contractconsumptions: PropTypes.shape({
      href: PropTypes.string,
    }),
  }),
};

export { addressData };
export type ContractProps = InferProps<typeof contractProps>;
