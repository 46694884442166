import classNames from 'classnames';
import * as React from 'react';
import { InputState } from '../../constants/index';
import InputFeedback from '../InputFeedback/InputFeedback';
import RadioButton from '../RadioButton/RadioButton';
export var RadioGroup = function (_a) {
    var id = _a.id, label = _a.label, inputState = _a.inputState, errorMessage = _a.errorMessage, value = _a.value, disabled = _a.disabled, required = _a.required, qaId = _a.qaId, name = _a.name, options = _a.options, className = _a.className, feedbackClassName = _a.feedbackClassName, onChange = _a.onChange;
    var showError = inputState === InputState.ERROR;
    var wrapperClasses = classNames({
        'form-radio--error': showError,
    }, className);
    var labelClasses = classNames('form-radio-main__label');
    var labelContentClasses = classNames('form-radio--label--content', {
        'form-radio--label--error': showError,
    }, {
        'form-radio--label--required': required,
    });
    var radioGroupClasses = classNames('form-radio-wrapper');
    return (React.createElement("div", { className: "form-radio-group", id: "__".concat(id) },
        React.createElement("div", { className: wrapperClasses },
            React.createElement("div", { className: labelClasses },
                React.createElement("span", { className: labelContentClasses }, label)),
            React.createElement("div", { className: radioGroupClasses }, options.map(function (option, index) { return (React.createElement(RadioButton, { key: "".concat(name).concat(index), label: option.label, inputState: inputState, name: name, id: "".concat(id, "-").concat(index), qaId: "".concat(qaId, "-").concat(index), value: option.value, onChange: onChange, checked: value === option.value, disabled: disabled })); }))),
        errorMessage && (React.createElement(InputFeedback, { hasError: showError, errorMessage: errorMessage, feedbackClassName: feedbackClassName }))));
};
RadioGroup.displayName = 'RadioGroupComponent';
export default RadioGroup;
