class Unauthorized extends Error {
  loginType: string;

  constructor(message?: string) {
    super(message);
    this.name = 'FunkeUnauthorized';
    this.loginType = '';
  }
}
export default Unauthorized;
