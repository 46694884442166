import classNames from 'classnames';
import * as React from 'react';
import { InputState } from '../../constants';
var BASE_CLASS = 'form-input-v2__message';
var InputFeedback = function (_a) {
    var hasError = _a.hasError, errorMessage = _a.errorMessage, feedbackClassName = _a.feedbackClassName, inputState = _a.inputState;
    var feedbackClasses = classNames("".concat(BASE_CLASS), (hasError || inputState === InputState.ERROR) && "".concat(BASE_CLASS, "-error"), (inputState === InputState.NONE ||
        inputState === InputState.PENDING ||
        inputState === InputState.VALID) &&
        "".concat(BASE_CLASS, "-hint"), feedbackClassName);
    return errorMessage ? (React.createElement("div", { className: feedbackClasses },
        React.createElement("p", null, errorMessage))) : null;
};
InputFeedback.displayName = 'InputFeedbackComponent';
export default InputFeedback;
