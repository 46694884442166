import { registerUser, addBusinessPartner, validatePhoneNumber } from '../../../api-factory/accountDataApi';
import CacheHandler from '../../../utils/CacheHandler';
import { refreshToken } from '../../../api-factory/myDataApi';

const stages = {
  SecondRegistrationFirstStep: 'SecondRegistrationFirstStep',
  DelimitedStep: 'DelimitedStep',
  SuccessStep: 'SuccessStep',
};
// Actions
const SAVE_NEW_ACCOUNT = 'api/reducer/account/SAVE_NEW_ACCOUNT';
const ADD_NEW_PARTNER = 'api/reducer/account/ADD_NEW_PARTNER';
const RESET_TO_FIRST_STEP = 'api/reducer/account/RESET_TO_FIRST_STEP';
const SHOW_LOADER = 'api/reducer/account/SHOW_LOADER';
const API_ERROR = 'api/reducer/account/API_ERROR';
const REMOVE_API_ERROR = 'api/reducer/account/REMOVE_API_ERROR';
const SEC_DELIMITED = 'api/reducer/account/SEC_DELIMITED';
const HIDE_LOADER = 'api/reducer/account/HIDE_LOADER';
const VALIDATE_PHONENUMBER = 'api/reducer/account/CHECK_PHONENUMBER';
const VALIDATE_PHONENUMBER_PENDING = 'api/reducer/account/CHECK_PHONENUMBER_PENDING';
const VALIDATE_PHONENUMBER_RESET = 'api/reducer/account/CHECK_PHONENUMBER_RESET';
// Reducer
function isPhoneNumberValid(data) {
  return !!data?.result?.data?.data?.attributes?.formattedResult;
}

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const registrationReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_NEW_ACCOUNT:
      return {
        ...state,
        success: true,
      };
    case ADD_NEW_PARTNER:
      return {
        ...state,
        step: action.step,
        loading: false,
      };
    case RESET_TO_FIRST_STEP:
      return {
        ...state,
        step: action.step,
        loading: false,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case API_ERROR: {
      const errorBundler = {};
      const errors = action.data.innerData.response.data?.errors || [];
      if (errors.length > 0) {
        errors.forEach((error) => {
          const { code } = error;
          errorBundler[code] = true;
          if (code === 'FIELD_CONTENT') {
            if (error.source.parameter.includes('captcha')) {
              errorBundler[`${code}_captcha`] = true;
            }
          }
          if (code === 'BUSSINESSPARTNER_NUMBER_INVALID') {
            errorBundler.FIELD_CONTENT_businessNumber = true;
            errorBundler.FIELD_CONTENT_contractNumber = true;
          }
        });
      } else {
        errorBundler.FIELD_CONTENT = true;
      }
      return {
        ...state,
        loading: false,
        errorBundler,
      };
    }
    case SEC_DELIMITED: {
      const errorBundler = {};
      errorBundler[action.data.error] = true;
      return {
        ...state,
        loading: false,
        step: stages.DelimitedStep,
        isCompany: action.data.isCompany,
        errorBundler,
      };
    }
    case REMOVE_API_ERROR: {
      if (!state.errorBundler || !state.errorBundler[action.errorName]) {
        return state;
      }
      const newErrBundler = { ...state.errorBundler };
      delete newErrBundler[action.errorName];
      return {
        ...state,
        errorBundler: newErrBundler,
      };
    }
    case VALIDATE_PHONENUMBER_PENDING: {
      return {
        ...state,
        [action.data.formName]: {
          phoneNumberValidation: {
            isRequestPending: true,
            isValid: false,
          },
        },
      };
    }
    case VALIDATE_PHONENUMBER: {
      return {
        ...state,
        [action.data.formName]: {
          phoneNumberValidation: {
            isRequestPending: false,
            isValid: isPhoneNumberValid(action.data),
            apiError: action.data.error ? deepClone(action.data.error) : false,
            result: deepClone(action.data),
          },
        },
      };
    }
    case VALIDATE_PHONENUMBER_RESET: {
      return {
        ...state,
        [action.data.formName]: {},
      };
    }
    default:
      return state;
  }
};

// Action Creators
export function saveRegistration(dispatch, toSaveData, config) {
  dispatch({ type: SHOW_LOADER });
  const resp = registerUser(toSaveData, config);
  resp
    .then((response) => {
      dispatch({
        type: SAVE_NEW_ACCOUNT,
        data: {
          innerData: response,
        },
      });
      dispatch({ type: HIDE_LOADER });
    })
    .catch((response) => {
      dispatch({
        type: API_ERROR,
        data: {
          innerData: response,
        },
      });
      dispatch({ type: HIDE_LOADER });
    });
  return resp;
}

export function resetSteps(dispatch, step) {
  dispatch({
    type: RESET_TO_FIRST_STEP,
    data: {
      step: stages[step],
    },
  });
}

export function removeAPiError(dispatch, errorName) {
  dispatch({
    type: REMOVE_API_ERROR,
    errorName,
  });
}

export function addPartnerId(dispatch, toSaveData) {
  dispatch({ type: SHOW_LOADER });
  const promise = addBusinessPartner(toSaveData);
  promise
    .then((response) => {
      refreshToken().then(() => {
        CacheHandler.clearByToken('contractaccounts');
        dispatch({ type: HIDE_LOADER });
        dispatch({
          type: ADD_NEW_PARTNER,
          data: {
            innerData: response,
          },
          step: stages.SuccessStep,
        });
      });
    })
    .catch((error) => {
      const errorCode = error.response?.data?.errors[0].code;
      if (errorCode) {
        const errorMsgArr = errorCode.split('.');
        const errorMsgCustomerMW = errorMsgArr[errorMsgArr.length - 1];
        if (errorMsgCustomerMW === 'secondRegistrationMissingPersonalName' || errorMsgCustomerMW === 'secondRegistrationMissingCompanyName') {
          dispatch({
            type: SEC_DELIMITED,
            data: {
              innerData: error.response,
              isCompany: errorMsgCustomerMW === 'secondRegistrationMissingCompanyName',
              error: errorMsgCustomerMW,
            },
          });
        } else {
          dispatch({
            type: API_ERROR,
            data: {
              innerData: error,
            },
          });
        }
      }
      dispatch({ type: HIDE_LOADER });
    });
  return promise;
}

export function onPhoneNumberValidation(dispatch, number, formName) {
  if (number) {
    dispatch({
      type: VALIDATE_PHONENUMBER_PENDING,
      data: {
        formName,
      },
    });
    validatePhoneNumber(number)
      .then((result) => {
        dispatch({
          type: VALIDATE_PHONENUMBER,
          data: { formName, result },
        });
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_PHONENUMBER,
          data: { formName, error: error.response },
        });
      });
  } else {
    dispatch({
      type: VALIDATE_PHONENUMBER_RESET,
      data: { formName },
    });
  }
}

export default registrationReducer;
