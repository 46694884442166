import { FormikHelpers } from 'formik';
import { MeterReadingWizardStepEnum } from '../../../cic/pages/MeterReadingPage/MeterReadingPage';
import { MeterReadingUnprocessableEntityResponse, StepComponentLabels } from './types/intermediateReadingLabels';
import { DeviceDataTypes, MeterReadingFormValueTypes, MeterReadingRegisterTypes } from '../../../cic/components/MeterRead/MeterReadingTypes';
import SessionHandler from '../../../utils/SessionHandler';
import { prepareMeterReadingData } from './IntermediateReadingForm/meterReadingFormHelper';
import { saveMeterReadingData } from '../../../cic/api/meterReadingApi';
import {
  buildMeterDataForEmailDispatch,
  storeMeterDataForEmailDispatch,
} from '../../../cic/components/MeterRead/Anonym/MeterReadingForm/meterReadingFormHelper';

type AcknowledgeTextProps = {
  missedMeter: number;
  amount: number;
  metersRegisteredText: string;
  fromToMetersRegisteredText: string;
};

const buildAcknowledgeText = (props: AcknowledgeTextProps) => {
  const { missedMeter, amount, metersRegisteredText, fromToMetersRegisteredText } = props;
  let str = '';
  if (missedMeter === 0) {
    str = `${amount} ${metersRegisteredText}`;
  } else if (fromToMetersRegisteredText) {
    str = fromToMetersRegisteredText.replace('%%SUCCESS%%', (amount - missedMeter).toString()).replace('%%TOTAL%%', amount.toString());
  }
  return str;
};

function saveData(
  values: DeviceDataTypes,
  formikHelpers: FormikHelpers<DeviceDataTypes>,
  setApiError: (errorStatus: string, errorCode: string) => void,
  setCurrentStep: (currentStep: string) => void
) {
  const { setFieldValue, setSubmitting } = formikHelpers;
  const meterData: MeterReadingFormValueTypes = prepareMeterReadingData(values);
  saveMeterReadingData(meterData)
    .then(() => {
      storeMeterDataForEmailDispatch(buildMeterDataForEmailDispatch(values));
      setSubmitting(false);
      setCurrentStep(MeterReadingWizardStepEnum.EMAIL_FEEDBACK);
    })
    .catch(async (error) => {
      const { status, statusText } = error.response;
      if (status === 422) {
        const tempValues: MeterReadingRegisterTypes[] = [...values.registers];
        const response: MeterReadingUnprocessableEntityResponse = error.response.data;
        response.errors.forEach((errorItem) => {
          if (errorItem.code !== 'global.validation.missingValue') {
            const fieldValue = errorItem.args?.field || '';
            const index = parseInt(fieldValue.substring(fieldValue.indexOf('[') + 1, fieldValue.indexOf(']')), 10);
            if (index >= 0) {
              tempValues[index].possibleReadingNotes = errorItem.args?.readingNotes;
              tempValues[index].errorCode = errorItem.code;
            }
          }
        });
        await setFieldValue('registers', tempValues, false);
      } else {
        setApiError(status, statusText);
      }
      setSubmitting(false);
    });
}

const generateStepLabels = ({ meterValueLabel, emailConfirm, register, done }: StepComponentLabels, canSeeRegister: boolean) => {
  const stepLabelsArray = [
    {
      label: meterValueLabel,
      step: MeterReadingWizardStepEnum.READING,
    },
    {
      label: emailConfirm,
      step: MeterReadingWizardStepEnum.EMAIL_FEEDBACK,
    },
    {
      label: register,
      step: MeterReadingWizardStepEnum.REGISTER,
    },
    {
      label: done,
      step: MeterReadingWizardStepEnum.FINISHED,
    },
  ];
  if (!canSeeRegister) stepLabelsArray.splice(2, 1);
  return stepLabelsArray;
};

function getMeterFromSessionStorage(intermediateReadingData: DeviceDataTypes[]) {
  const meterNumber = SessionHandler.getIntermediateReadingMeterNumber();
  const foundEntry = intermediateReadingData.find((meter) => {
    if (meter?.obfuscationActive && meter?.serialNumberObfuscated) {
      return meterNumber?.endsWith(meter?.serialNumberObfuscated?.replace(/^[xX]*/, ''));
    }
    return meter.serialNumber === meterNumber;
  });

  return foundEntry;
}

const allRegistersHasPlausibilityReason = (registers: MeterReadingRegisterTypes[]): boolean => {
  return registers.every((register) => !(register.possibleReadingNotes && !register.readingNoteNumber));
};

export { buildAcknowledgeText, generateStepLabels, getMeterFromSessionStorage, saveData, allRegistersHasPlausibilityReason };
