import { FormikHelpers } from 'formik';
import {
  DeviceDataTypes,
  MeterReadingDataForEmailDispatchTypes,
  MeterReadingEnergyValueAndClassTypes,
  MeterReadingFormValueTypes,
  MeterReadingRegisterTypes,
  SavedMeterReadingResultTypes,
} from '../../MeterReadingTypes';
import { returnDateAsArray } from '../../Core/MeterReadDateInput/meterReadingDateInputHelper';
import { saveMeterReadingData } from '../../../../api/meterReadingApi';

export const pickRegisterFromDeviceByRegisterNumber = (registerNumber: string, registers: MeterReadingRegisterTypes[]) => {
  return registers.find((register) => register.registerNumber === registerNumber) || ({} as MeterReadingRegisterTypes);
};

export const prepareMeterReadingData = (values: DeviceDataTypes): MeterReadingFormValueTypes => {
  const meterReadingData: MeterReadingFormValueTypes = {
    serialNumber: values.serialNumber,
    readingDate: returnDateAsArray(values.readingDate?.text),
    registers: [],
  };
  values.registers.forEach((register) => {
    if (register.readingNoteNumber) {
      meterReadingData.registers.push({
        registerNumber: register.registerNumber,
        readingResult: parseFloat(register.readingResult!.replace(/\./g, '').replace(/,/g, '.')),
        readingNoteNumber: register.readingNoteNumber,
      });
    } else {
      meterReadingData.registers.push({
        registerNumber: register.registerNumber,
        readingResult: parseFloat(register.readingResult!.replace(/\./g, '').replace(/,/g, '.')),
      });
    }
  });
  return meterReadingData;
};

export const buildMeterDataForEmailDispatch = (values: DeviceDataTypes): SavedMeterReadingResultTypes => {
  const meterDataForEmailDispatch: SavedMeterReadingResultTypes = {
    serialNumber: values.serialNumber,
    registerNumbers: [],
  };
  values.registers.forEach((register) => {
    meterDataForEmailDispatch.registerNumbers.push(register.registerNumber);
  });
  return meterDataForEmailDispatch;
};

export const storeMeterDataForEmailDispatch = (savedReadingResult: SavedMeterReadingResultTypes) => {
  const newResultList = {
    serialNumber: savedReadingResult.serialNumber,
    registerNumbers: savedReadingResult.registerNumbers,
  };
  const existingEmailObjectJsonString = window.sessionStorage.getItem('emailObject');
  let emailObject: MeterReadingDataForEmailDispatchTypes;
  if (existingEmailObjectJsonString) {
    emailObject = JSON.parse(existingEmailObjectJsonString);
    emailObject.resultList.push(newResultList);
  } else {
    emailObject = {
      email: '',
      resultList: [newResultList],
    };
  }
  window.sessionStorage.setItem('emailObject', JSON.stringify(emailObject));
};

export const saveAndForward = (
  values: DeviceDataTypes,
  formikHelpers: FormikHelpers<DeviceDataTypes>,
  pagination: (pageId: number) => void,
  currentPageId: number,
  lastMeter: boolean,
  setCurrentStep: (step: string) => void,
  nextStep: string
): void => {
  const { setSubmitting, resetForm, setFieldValue } = formikHelpers;
  saveMeterReadingData(prepareMeterReadingData(values))
    .then(() => {
      storeMeterDataForEmailDispatch(buildMeterDataForEmailDispatch(values));
      if (lastMeter) {
        setCurrentStep(nextStep);
      } else {
        pagination(currentPageId + 1);
      }
      resetForm();
      setSubmitting(false);
    })
    .catch((error) => {
      const tempValues: MeterReadingRegisterTypes[] = [...values.registers];
      const { errors } = error.response.data;
      errors.forEach((errorItem) => {
        if (!(errorItem.code === 'global.validation.missingValue')) {
          const fieldValue = errorItem.args?.field ? errorItem.args?.field : '';
          const index = parseInt(fieldValue.substring(fieldValue.indexOf('[') + 1, fieldValue.indexOf(']')), 10);
          if (index >= 0) {
            tempValues[index].possibleReadingNotes = errorItem.args?.readingNotes;
            tempValues[index].errorCode = errorItem.code;
          }
        }
      });
      setFieldValue('registers', tempValues, false);
      setSubmitting(false);
    });
};

export const allRegistersHasPlausibilityReason = (formikProps): boolean => {
  let hasReason = true;
  formikProps.values.registers.forEach((register) => {
    if (register.possibleReadingNotes && register.possibleReadingNotes.length > 1) {
      if (!register.readingNoteNumber) {
        hasReason = false;
      }
    }
  });
  return hasReason;
};

export const registerHasPlausibilityError = (register: MeterReadingRegisterTypes): boolean => {
  let hasApiErrors = false;
  if (register.possibleReadingNotes?.length) {
    hasApiErrors = true;
  }
  return hasApiErrors;
};
export const someRegisterHasPlausibilityError = (registers: MeterReadingRegisterTypes[]): boolean =>
  !!registers.find((x) => x.possibleReadingNotes?.length);

export const buildButtonControl = (
  continueButtonText: string,
  submitButtonText: string,
  saveAndContinueButtonText: string,
  isLastMeter: boolean,
  isMeterLocked: boolean
) => {
  if (isMeterLocked) {
    return continueButtonText;
  }
  if (isLastMeter) {
    return submitButtonText;
  }
  return saveAndContinueButtonText;
};

export const getMeterDataForEmailDispatch = (): MeterReadingDataForEmailDispatchTypes => {
  const emailObjectJsonString = window.sessionStorage.getItem('emailObject');
  if (emailObjectJsonString) {
    return JSON.parse(emailObjectJsonString);
  }
  return { email: '', resultList: [] };
};

export const prepareEnergyTypeForDisplay = (device: DeviceDataTypes): MeterReadingEnergyValueAndClassTypes => {
  const energyTypeKey = device.division;
  let energyTypeValue: string;
  let energyTypeClass: string;
  let iconType: string;
  switch (energyTypeKey) {
    case 'GAS':
      energyTypeValue = 'Gas';
      energyTypeClass = 'ano-meter-informationbox__value__energy-type__gas';
      iconType = 'icon_gas_36x36';
      break;
    case 'POWER':
      energyTypeValue = 'Strom';
      energyTypeClass = 'ano-meter-informationbox__value__energy-type__power';
      iconType = 'icon_strom_36x36';
      break;
    default:
      energyTypeValue = 'Nicht angegeben';
      energyTypeClass = 'ano-meter-informationbox__value__energy-type__gas';
      iconType = 'icon_gas_36x36';
      break;
  }
  return {
    energyTypeValue,
    energyTypeClass,
    iconType,
  };
};

export const getObisLabel = (identifier: string, props) => {
  switch (identifier) {
    case 'LABEL_CONSUMER':
      return props.registerLabel;
    case 'LABEL_FEEDIN':
      return props.registerLabelFeedIn;
    case 'LABEL_UNDEFINED':
      return props.registerLabelUndefined;
    default:
      return props.registerLabelUndefined;
  }
};

export const formatObis = (obis) => (obis ? obis.split(':')[1] : '');

export const checkMeterLockedByImplausibleReadingResult = (device: DeviceDataTypes) => {
  if (!device.lockReasons) {
    return false;
  }
  return device.lockReasons.filter((val) => val === 'LOCKED_BY_IMPLAUSIBLE_LAST_READING_RESULT').length > 0;
};

export const isMeterLocked = (device: DeviceDataTypes): boolean => {
  return device.lockReasonWithHighestPriority !== null || checkMeterLockedByImplausibleReadingResult(device);
};

export const allMetersLockedByImplausibleReadingResults = (devices: DeviceDataTypes[]): boolean => {
  if (!devices) {
    return true;
  }
  const deviceCount = devices.length;
  const deviceCountWithImplausibleReadingResult = devices.filter((meter) => checkMeterLockedByImplausibleReadingResult(meter)).length;
  return deviceCount === deviceCountWithImplausibleReadingResult;
};
