import { DeviceDataTypes, errorHintTypes, MeterReadingErrorObjectType } from '../components/MeterRead/MeterReadingTypes';
import {
  ERROR_HINT,
  ERROR_LIST,
  LOAD_CURRENT,
  CURRENT_STEP,
  LOADING,
  MeterReadingReducerActionTypes,
  PAGINATE,
} from '../actions/meterReading/meterReadingMainActions';

export const checkMeterLockedByImplausibleReadingResult = (meter) =>
  meter.lockReasons.filter((val) => val === 'LOCKED_BY_IMPLAUSIBLE_LAST_READING_RESULT').length > 0;

export type MeterReadingReducerStateTypes = {
  devices: DeviceDataTypes[];
  currentPageId: number;
  currentStep: string;
  errorObjects: MeterReadingErrorObjectType[];
  validationState: any;
  errorHint: errorHintTypes;
  loading: boolean;
};

type MeterReadingReducerStateProps = MeterReadingReducerStateTypes;
type MeterReadingReducerActionProps = MeterReadingReducerActionTypes;

export default function reducer(
  state: MeterReadingReducerStateProps = { loading: true, currentStep: 'reading' } as MeterReadingReducerStateTypes,
  action: MeterReadingReducerActionProps
): MeterReadingReducerStateTypes {
  switch (action.type) {
    case LOAD_CURRENT:
      return {
        ...state,
        devices: action.data.devices,
        currentPageId: action.data.currentPageId,
      };
    case LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case PAGINATE:
      return {
        ...state,
        currentPageId: action.data.currentPageId,
        errorObjects: [],
      };
    case CURRENT_STEP:
      return {
        ...state,
        currentStep: action.data.currentStep,
      };
    case ERROR_LIST:
      return {
        ...state,
        errorObjects: action.data.errorObjects,
      };
    case ERROR_HINT:
      return {
        ...state,
        errorHint: {
          code: action.data.code,
          args: action.data.args,
        },
      };
    default:
      return state;
  }
}
