import RestHelper from '../utils/RestHelper';
import CacheHandler from '../../utils/CacheHandler';

export function getDevices() {
  return CacheHandler.requestCache(() => RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/meters`), 'smartmeter');
}

export function getHanDevices() {
  return CacheHandler.requestCache(
    () => RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/meters?filterBy=hanEnabledDevices`),
    'smartmeter'
  );
}

export function getDeviceData(serialNumber, obises, fromDate, toDate) {
  return RestHelper.get(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/meters/${serialNumber}/obises/${obises}?from=${fromDate}&to=${toDate}`
  );
}

export function getDeclaration() {
  return RestHelper.get(`${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/consent`);
}
export function declareConsent(accepted) {
  return RestHelper.put(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/consent`,
    {
      agreed: accepted,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export function getDeviceFile(serialNumber, obises, fromDate, toDate) {
  return RestHelper.post(
    `${RestHelper.getBaseUrl()}/cscn-customer-srv/api/smartmeter/v0/meters/${serialNumber}/obises/${obises}/excel?from=${fromDate}&to=${toDate}`,
    {},
    { responseType: 'arraybuffer' }
  );
}
