import moment from 'moment';
import getDmarketings, { postDmarketing } from '../api/directMarkApi';
import { fixMonth } from '../components/ConsumptionVisSharedComponents/VisualizationDateRange/ReadingDateRange';
// Actions
const LOAD_CURRENT_DMARKS = 'api/reducer/LOAD_CURRENT_DMARKS';
const SET_SELECTED_EEG = 'api/reducer/SET_AVAILABLE_EEGS';
const SET_SELECTED_DATE = 'api/reducer/SET_SELECTED_DATE';
const SET_TO_LOADING = 'api/reducer/SET_TO_LOADING';
const SET_TO_EMPTY = 'api/reducer/SET_TO_EMPTY';
// const DMARK_API_SAVE_SUCCESS = 'api/reducer/DMARK_API_SAVE_SUCCESS';
const DMARK_API_SAVE_FAIL = 'api/reducer/DMARK_API_SAVE_FAIL';
const DMARK_API_REMOVE_ERROR = 'api/reducer/DMARK_API_REMOVE_ERROR';
// Reducer
const filterMarketingsByEeg = (marketings, eegId) => {
  if (!marketings || !marketings.length || !eegId) {
    return [];
  }
  return marketings.find((reg) => eegId === reg.eegInstallationId);
};
const convertDate = (mwDate) => moment(fixMonth(mwDate)).startOf('day').valueOf();

const filterMarketingsByDate = (marketings, from, to) => {
  if (!marketings || !marketings.length || !from || !to) {
    return [];
  }
  return marketings.filter((reg) => {
    const cFrom = convertDate(reg.from);
    return cFrom >= from && to >= cFrom;
  });
};

const getEegkeys = (marketings) => {
  const eegs = [];
  if (!marketings || !(marketings[0] && marketings[0].eegInstallationId)) {
    return eegs;
  }
  marketings.forEach((reg) => {
    const id = reg.eegInstallationId;
    if (!eegs.find((eeg) => eeg === id)) {
      eegs.push(id);
    }
  });
  return eegs;
};

export { convertDate, filterMarketingsByEeg, filterMarketingsByDate, getEegkeys };
export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_CURRENT_DMARKS: {
      const eegKeys = getEegkeys(action.data.innerData);
      const marketings = filterMarketingsByEeg(action.data.innerData, eegKeys[0]);

      return {
        ...state,
        overallMarks: action.data.innerData,
        marketings: marketings.dateRanges.specified,
        currentRanges: marketings.dateRanges.available,
        fromDate: convertDate(marketings.dateRanges.available[0].from),
        endDate: convertDate(marketings.dateRanges.available[marketings.dateRanges.available.length - 1].to),
        errorCode: null,
        availableEegs: eegKeys,
        selectedEeg: eegKeys && eegKeys.length ? eegKeys[0] : null,
        loading: false,
      };
    }
    case SET_SELECTED_EEG: {
      const marketings = filterMarketingsByEeg(state.overallMarks, action.data.eegKey);
      return {
        ...state,
        selectedEeg: action.data.eegKey,
        marketings: marketings.dateRanges.specified,
        currentRanges: marketings.dateRanges.available,
        fromDate: convertDate(marketings.dateRanges.available[0].from),
        endDate: convertDate(marketings.dateRanges.available[marketings.dateRanges.available.length - 1].to),
      };
    }
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedStartDate: action.data.from,
        selectedEndDate: action.data.to,
      };
    case SET_TO_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_TO_EMPTY:
      return {
        ...state,
        loading: false,
        noMarketingsAvailable: true,
      };
    case DMARK_API_SAVE_FAIL: {
      return {
        ...state,
        loading: false,
        apiErrors: action.data.errorCode,
      };
    }
    case DMARK_API_REMOVE_ERROR: {
      const noErr = {};
      noErr[action.data.errorName] = false;
      return {
        ...state,
        apiErrors: {
          ...state.apiErrors,
          ...noErr,
        },
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function loadCurrentMarketings(dispatch, contractId) {
  dispatch({
    type: SET_TO_LOADING,
  });
  const resp = getDmarketings(contractId);
  return resp.then((reponse) => {
    const responseHasContent = !!(reponse.data && reponse.data.content && reponse.data.content.length);
    let action = {};

    if (responseHasContent) {
      action = {
        type: LOAD_CURRENT_DMARKS,
        data: {
          innerData: reponse.data ? reponse.data.content : [],
        },
      };
    } else {
      action = {
        type: SET_TO_EMPTY,
      };
    }

    dispatch(action);
  });
}
export function setSelectedEegKey(dispatch, eegKey) {
  dispatch({
    type: SET_SELECTED_EEG,
    data: {
      eegKey,
    },
  });
}
export function setSelectedDate(dispatch, from, to) {
  dispatch({
    type: SET_SELECTED_DATE,
    data: {
      from,
      to,
    },
  });
}

export const saveMarketing = (dispatch, payload) => {
  dispatch({
    type: SET_TO_LOADING,
  });
  const request = postDmarketing(payload);
  request
    .then(() => {
      const resp = getDmarketings(payload.contractAccountId);
      resp.then((reponse) => {
        dispatch({
          type: LOAD_CURRENT_DMARKS,
          data: {
            innerData: reponse.data.content,
          },
        });
      });
    })
    .catch((response) => {
      const errorCode = response && response.response.data.errors && response.response.data.errors[0].code.split('.').pop();
      const errs = {};
      errs[errorCode] = true;
      dispatch({
        type: DMARK_API_SAVE_FAIL,
        data: {
          errorCode: errs,
        },
      });
    });
  return request;
};
export const removeAPiError = (dispatch, errorName) => {
  dispatch({
    type: DMARK_API_REMOVE_ERROR,
    data: {
      errorName,
    },
  });
};
