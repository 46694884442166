import RestHelper from '../utils/RestHelper';

function getSearchData(endpoint) {
  return RestHelper.get(endpoint);
}
// @TODO check why it was missing
export const submit = () => {};

function parseSuggestions(searchData) {
  const getResults = (searchResults) => {
    const tempSuggestions = [];
    Object.keys(searchResults).forEach((key) => {
      return Object.keys(searchResults[key]).forEach((key2) => {
        return tempSuggestions.push(searchResults[key][key2]);
      });
    });

    return tempSuggestions;
  };
  const extractSuggestions = () => {
    const results = getResults(searchData);
    const array = results.map((item) => {
      const suggestion = item && /<h1>(.*?)<\/h1>/g.exec(item)[1].toLowerCase();

      return suggestion;
    });
    const uniqueArray = array.filter((item, pos) => {
      return item && array.indexOf(item) === pos;
    });

    return uniqueArray;
  };

  return extractSuggestions();
}

function parseResults(searchResults) {
  const parsedResults = searchResults.map((result) => {
    const { title, body, extension, url } = result;
    const parsedResult = {
      title,
      body: body && body.replace(/\s+/g, ' '),
      type: extension,
      url,
    };

    return parsedResult;
  });

  return parsedResults;
}

export const suggestionsRequestSuccess = (dispatch, response) => {
  dispatch({
    type: 'GET_SUGGESTIONS_SUCCESSFUL',
    data: {
      response: response.data,
      suggestions: parseSuggestions(response.data.highlighting),
    },
  });
};

export const suggestionsRequestError = (dispatch, error) => {
  dispatch({
    type: 'GET_SUGGESTIONS_ERROR',
    data: {
      error,
    },
  });
};

export const resultsRequestStarted = (dispatch) => {
  dispatch({
    type: 'GET_RESULTS_STARTED',
  });
};

export const resultsRequestSuccess = (dispatch, response) => {
  dispatch({
    type: 'GET_RESULTS_SUCCESSFUL',
    data: {
      response: response.data,
      results: parseResults(response.data.response.docs),
    },
  });
};

export const resultsRequestError = (dispatch, error) => {
  dispatch({
    type: 'GET_RESULTS_ERROR',
    data: {
      error,
    },
  });
};

export const getSuggestions = (dispatch, endpoint) => {
  const resp = getSearchData(endpoint);

  return resp
    .then((response) => {
      suggestionsRequestSuccess(dispatch, response);
    })
    .catch((err) => {
      suggestionsRequestError(dispatch, err);
      console.error('getSuggestions', err); // eslint-disable-line no-console
    });
};

export const getResults = (dispatch, endpoint) => {
  const resp = getSearchData(endpoint);
  resultsRequestStarted(dispatch);
  return resp
    .then((response) => {
      resultsRequestSuccess(dispatch, response);
    })
    .catch((err) => {
      resultsRequestError(dispatch, err);
      console.error('getResults', err); // eslint-disable-line no-console
    });
};

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case 'GET_SUGGESTIONS_SUCCESSFUL': {
      return { ...state, ...action.data };
    }
    case 'GET_RESULTS_STARTED': {
      return { ...state, ...action.data, isRequestPending: true };
    }
    case 'GET_RESULTS_SUCCESSFUL': {
      return { ...state, ...action.data, isRequestPending: false };
    }
    default:
      return state;
  }
}
