import invoiceCscApi from '../csc/api/invoiceApi';
import invoiceCicApi from '../cic/api/invoiceApi';
import { isCICContentTree } from '../mainConfig';

interface ApiSet {
  getInvoices;
  getInvoicePdf;
}

const currentApiSet: ApiSet = isCICContentTree ? invoiceCicApi : invoiceCscApi;

export const { getInvoices, getInvoicePdf } = currentApiSet;
