// import moment from 'moment';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import momentT from 'moment-timezone';
import Unauthenticated from '../cic/errors/Unauthenticated';
import CacheHandler from './CacheHandler';
import ActivityWatcher from './ActivityWatcher';
import CONFIG from '../cic/config/config';

momentT.tz.setDefault('Europe/Berlin');
// const loginTypes = {
//   loggedIn: 'loggedin',
//   anonym: 'anonym',
//   callCenter: 'callcenter',
//   incompleteLoggedIn: 'incompleteLoggedIn',
// };

enum storageKeys {
  intermediateReadingMeterNumber = 'intermediateReadingMeterNumber',
}

enum loginTypes {
  loggedIn = 'loggedin',
  anonym = 'anonym',
  postal = 'postal',
  callCenter = 'callcenter',
  incompleteLoggedIn = 'incompleteLoggedIn',
}
enum appTypes {
  cic = '001',
  ekuss = '002',
  ekuss2 = '003',
  cscn = '004',
}

export { appTypes };
export { loginTypes };
let storedContracts;
let permissions;

export const mwPermissions = {
  ablRead: 'ABL_READ', // read meter reading orders
  adrRead: 'ADR_READ', // check or complete address from regional structure
  anlRead: 'ANL_READ', // read installation details
  bankInsert: 'BANK_INSERT', // insert bank details/collection authorization
  bankRead: 'BANK_READ', // read bank details/collection authorization
  bankUpdate: 'BANK_UPDATE', // change bank details/collection authorization
  bbpRead: 'BBP_READ', // read budget billing plan
  bbpUpdate: 'BBP_UPDATE', // change budget billing plan
  ctcWrite: 'CTC_WRITE', // send contact form
  detailProRead: 'DETAIL_PRO_READ', // read product details
  detailVtrRead: 'DETAIL_VTR_READ', // read contract details
  digimondoRead: 'DIGIMONDO_READ', // read digimondo optIn
  digimondoUpdate: 'DIGIMONDO_UPDATE', // change digimondo optIn
  digiwerteRead: 'DIGIWERTE_READ', // read digimondo meter data
  dvsatzDelete: 'DVSATZ_DELETE', // delete direct marketing compensation rate
  dvsatzInsert: 'DVSATZ_INSERT', // add direct marketing compensation rate
  dvsatzRead: 'DVSATZ_READ', // read direct marketing compensation rates
  einmawetterUpload: 'EIMAWETTER_UPLOAD', // upload EinsMan weather data
  einsmanRead: 'EINSMAN_READ', // read EinsMan reimbursement method
  einsmanUpdate: 'EINSMAN_UPDATE', // change EinsMan reimbursement method
  faqRead: 'FAQ_READ', // show f.a.q.
  gerRead: 'GER_READ', // read contract details and/or technical details
  komDelete: 'KOM_DELETE', // delete communication data
  komInsert: 'KOM_INSERT', // add communication data
  komRead: 'KOM_READ', // read communication data
  komUpdate: 'KOM_UPDATE', // change communication data
  ksaOnlRead: 'KSA_ONL_READ', // read KSA status
  ksaOnlUpdate: 'KSA_ONL_UPDATE', // change KSA status
  korrOnlRead: 'KORR_ONL_READ', // read correspondance status
  korrOnlUpdate: 'KORR_ONL_UPDATE', // read correspondance status
  ldapInsert: 'LDAP_INSERT', // complete registration
  ldapRead: 'LDAP_READ', // read user account data
  ldapUpdate: 'LDAP_UPDATE', // change user account data
  lgangaboDelete: 'LGANGABO_DELETE', // cancel load profile subscription
  lgangaboInsert: 'LGANGABO_INSERT', // add load profile subscription
  lgangaboRead: 'LGANGABO_READ', // read load profile subscriptions
  lgangaboUpdate: 'LGANGABO_UPDATE', // change load profile subscriptions
  mtrInsert: 'MTR_INSERT', // add meter readings
  mtrRead: 'MTR_READ', // read meter readings
  orderRead: 'ORDER_READ', // read open orders (track&trace)
  pboxRead: 'PBOX_READ', // read post box
  recRead: 'REC_READ', // read bills
  regulationRead: 'REGULATION_READ', // read regulations
  rlmLastgRead: 'RLM_LASTG_READ', // read load profile
  slpLastgCompare: 'SLP_LASTG_COMPARE', // compare measured values
  slpLastgRead: 'SLP_LASTG_READ', // read measured values
  taxnumRead: 'TAXNUM_READ', // read tax numbers
  taxnumUpdate: 'TAXNUM_UPDATE', // change tax numbers
  vertreterWrite: 'VERTRETER_WRITE', // authorize proxy
  vhisRead: 'VHIS_READ', // read consumption history
  vkAdd: 'VK_ADD', // add contract account to user account
  vtrRead: 'VTR_READ', // read contract data or meter reading orders
  zdatRead: 'ZDAT_READ', // read due dates from budget billing plan
  hanInsert: 'HAN_INSERT', // read han username and password
  apptMtr: 'ASS_ORDER_CHANGE', // manage meter exchange appointment
};
let storage: Storage;
class SessionHandler {
  permissionsSet: boolean;

  setStorage(newStorage) {
    storage = newStorage;
  }

  startSession() {
    if (!storage) {
      storage = window.sessionStorage;
    }
    storage.sessStarted = true;
  }

  initSession(userName, token, type = loginTypes.loggedIn) {
    if (!storage.sessStarted) {
      throw new Error('startSession has to be called before init session');
    }
    if (!Object.values(loginTypes).includes(type)) {
      throw new Error('Proper login type is not specified!');
    }
    storage.userNamev2 = userName;
    if (token.accessToken) {
      this.setToken(token.accessToken, 'ciam');
      this.setRefreshToken(token.refreshToken);
    } else {
      this.setToken(token, 'csc');
    }
    storage.loginType2 = type;
  }

  getLoginType() {
    return storage.loginType2;
  }

  setLoginType(type) {
    storage.loginType2 = type;
  }

  getRedirectUrl(type) {
    const anonymUrl = CONFIG.AEM_CONFIG.expiredSessionURL;
    const ssoUrl = CONFIG.AEM_CONFIG.SSO.ssoSessionExpiredUrl;
    const isAnonym = typeof type === 'string' || !type ? type === loginTypes.anonym : type.includes(loginTypes.anonym);
    return isAnonym ? anonymUrl : ssoUrl;
  }

  destroyRemoteSso() {
    location.replace(CONFIG.AEM_CONFIG.SSO.ssoLogout);
  }

  generateSSOLoginUrl() {
    this.setCurrentCodeVerifier(this.generateCodeVerifier());
    const codeVerifier = this.getCurrentCodeVerifier();
    const codeChallange = `&code_challenge=${this.generateCodeChallenge(codeVerifier)}&code_challenge_method=S256`;
    return `${CONFIG.AEM_CONFIG.SSO.ssoLoginUrl}&client_id=${encodeURIComponent(CONFIG.AEM_CONFIG.SSO.clientId)}&redirect_uri=${encodeURIComponent(
      CONFIG.AEM_CONFIG.SSO.loginBridgePath
    )}${codeChallange}}`;
  }

  setCurrentCodeVerifier(code) {
    sessionStorage.setItem('code_verifier', code);
  }

  getCurrentCodeVerifier() {
    return sessionStorage.getItem('code_verifier');
  }

  removeCodeVerifier() {
    sessionStorage.removeItem('code_verifier');
  }

  parseJwt(token) {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  destroyLocalSession() {
    CacheHandler.clearAllCache(true);
    delete storage.ids;
    delete storage.token;
    delete storage.tokenv2;
    delete storage.userName;
    delete storage.userNamev2;
    delete storage.loginType;
    delete storage.refreshToken;
    delete storage.ciamtoken;
    delete storage.counterId;
    delete storage.loginType2;
    delete storage.csctoken;
    delete storage.intermediateReadingMeterNumber;
    ActivityWatcher.cleanupLocalData();
  }

  setLogoutSuccessPage(logoutType) {
    storage.logoutPage = logoutType;
  }

  getLogoutSuccessPage() {
    return storage.logoutPage;
  }

  destroySession(redirect = true, customReidrectUrl = '', callback = null) {
    if (redirect) {
      sessionStorage.cscRedirectLock = 1;
    }
    const redirectFunc = () => {
      if (redirect) {
        if (customReidrectUrl) {
          location.replace(customReidrectUrl);
        } else {
          location.replace(this.getRedirectUrl(this.getLoginType()));
        }
      }
    };
    if ([loginTypes.loggedIn, loginTypes.callCenter, loginTypes.incompleteLoggedIn].includes(this.getLoginType())) {
      this.destroyLocalSession();
      if (redirect) {
        this.setLogoutSuccessPage(customReidrectUrl || this.getRedirectUrl(this.getLoginType()));
        this.destroyRemoteSso();
      }
      if (callback) {
        callback();
      }
    } else {
      this.destroyLocalSession();
      if (callback) {
        callback();
      }
      redirectFunc();
    }
  }

  getPermissions() {
    return permissions;
  }

  setPermissions(contracts, contractId) {
    storedContracts = contracts;
    if (!contracts[0]) {
      this.resetPermissions();
      return;
    }
    const selectedContract = contracts.find((contract) => contract.contractAccountId === contractId);
    permissions = selectedContract.permissions;
    this.permissionsSet = true;
  }

  resetPermissions() {
    permissions = null;
  }

  checkForPermissions() {
    if (this.isUserLoggedIn(loginTypes.loggedIn, loginTypes.anonym, loginTypes.callCenter) && !permissions) {
      const contracts = CacheHandler.getCurrentCacheByToken('contractaccounts');
      if (contracts) {
        this.setPermissions(contracts.data.content, contracts.data.content[0].contractAccountId);
      }
    }
  }

  hasPermissionsByMwToken(requiredPermissions, allContracts = false, stringChunk = false) {
    const jsKeys = Object.keys(mwPermissions);
    const searchMethod = stringChunk
      ? (key) => !!requiredPermissions.find((rElem) => mwPermissions[key].indexOf(rElem) !== -1)
      : (key) => requiredPermissions.includes(mwPermissions[key]);
    const filteredPerms = jsKeys.filter(searchMethod);
    if (!filteredPerms.length) {
      return false;
    }
    return stringChunk
      ? !!filteredPerms.find((fPerm) => this.hasPermissions([fPerm], allContracts))
      : this.hasPermissions(filteredPerms, allContracts);
  }

  hasPermissions(requiredPermissions, allContracts = false) {
    let hasAllPermissions = true;
    let hasPermission: any;
    this.checkForPermissions();
    if (!permissions || !requiredPermissions || !requiredPermissions.length) {
      return false;
    }
    requiredPermissions.forEach((permission) => {
      if (allContracts) {
        storedContracts.forEach((contract) => {
          hasPermission = contract.permissions.includes(mwPermissions[permission]) || hasPermission;
        });
      } else {
        hasPermission = !!permissions.find((x) => x.startsWith(permission)) || permissions.includes(mwPermissions[permission]);
      }
      hasAllPermissions = hasAllPermissions && hasPermission;
    });
    return hasAllPermissions;
  }

  userMustBeLoggedIn(...types) {
    types.forEach((type) => {
      if (!Object.values(loginTypes).includes(type)) {
        throw new Error('Proper login type is not specified!');
      }
    });
    if (!this.isUserLoggedIn(...types)) {
      const newExp: any = new Unauthenticated('Not logged in');
      newExp.loginType = types;
      throw newExp;
    }
  }

  isUserLoggedIn(...types): boolean {
    types.forEach((type) => {
      if (!Object.values(loginTypes).includes(type)) {
        throw new Error('Proper login type is not specified!');
      }
    });
    return storage && this.getName() && types.includes(this.getLoginType());
  }

  getName() {
    return storage ? storage.userNamev2 : null;
  }

  getToken(type?: string) {
    if (type === 'ciam') {
      return storage ? storage.ciamtoken : null;
    }
    return storage ? storage.csctoken : null;
  }

  getRefreshToken() {
    return storage ? storage.refreshToken : null;
  }

  setToken(value, type?: string) {
    if (type === 'ciam') {
      storage.ciamtoken = value;
    } else {
      storage.csctoken = value;
    }
  }

  setRefreshToken(value) {
    storage.refreshToken = value;
  }

  generateCodeChallenge(codeVerifier) {
    return this.base64URL(sha256(codeVerifier));
  }

  base64URL(string) {
    return string.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  generateCodeVerifier() {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    // Converts it from dec to hex
    return Array.from(array, (dec) => `0${dec.toString(16)}`.substr(-2)).join('');
  }

  isUserAssignedToApp(appName: appTypes) {
    const apId = this.parseJwt(this.getToken('ciam'))?.custom_attributes?.apps;
    return apId?.indexOf(appName) !== -1;
  }

  setIntermediateReadingMeterNumber(meterNumber: string) {
    storage.setItem(storageKeys.intermediateReadingMeterNumber, meterNumber);
  }

  getIntermediateReadingMeterNumber() {
    return storage.getItem(storageKeys.intermediateReadingMeterNumber);
  }
}

export default SessionHandler;
