import { InferProps } from 'prop-types';
import React from 'react';
import stepComponentLabelPropTypes from './StepComponentPropTypes';

type stepsType = {
  step: string;
  label: string;
};

export type StepComponentTypes = {
  currentStep: string;
  stepLabels: stepsType[];
};

export type StepComponentProps = StepComponentTypes & InferProps<typeof stepComponentLabelPropTypes>;

export const THREE_STEP_COLUMN_STYLE = 'col-md-4 col-sm-4 col-xs-12';
export const FOUR_STEP_COLUMN_STYLE = 'col-md-3 col-sm-6 col-xs-6';

const stepsLimit = 4;

const buildStepLabels = (stepLabels: stepsType[]) => {
  const steps: stepsType[] = [];

  for (let i = 0; i < stepLabels.length; i += 1) {
    if (i >= stepsLimit) {
      break;
    }

    steps.push({
      step: stepLabels[i].step,
      label: stepLabels[i].label,
    });
  }

  return steps;
};

const StepComponent: React.FC<StepComponentProps> = ({ currentStep, stepLabels, ...props }) => {
  const steps = buildStepLabels(stepLabels);

  return (
    <div
      className='row'
      id='step-container'
      data-qa='step-container'
    >
      {steps.map((item, i) => {
        return (
          <div
            className={`${currentStep === item.step ? 'active__div' : 'inactive__div'}
         ${steps.length < stepsLimit ? THREE_STEP_COLUMN_STYLE : FOUR_STEP_COLUMN_STYLE}`}
            key={item.label}
          >
            <h5 className={`${currentStep === item.step ? 'active__text' : 'inactive__text'} text-center`}>{`${props.step} ${i + 1}`}</h5>
            <h5 className={`${currentStep === item.step ? 'active__text' : 'inactive__text'} text-center`}>{item.label}</h5>
          </div>
        );
      })}
    </div>
  );
};

StepComponent.propTypes = stepComponentLabelPropTypes;
export default StepComponent;
