import PropTypes, { InferProps, shape } from 'prop-types';

import { AppointmentGap, getNextAppointment, saveAppointment } from '../../../csc/api/myAppointmentApi';
import { appointmentGapPropType } from '../../types/myAppointmentTypes';
import { SET_APPOINTMENT_LOADING, THROW_APPOINTMENT_ERROR } from './myAppointmentGapLoadAction';

export const SAVE_APPOINTMENT = 'api/reducer/SAVE_APPOINTMENT';

const myAppointmentSaveAction = {
  data: shape({
    innerData: PropTypes.shape(appointmentGapPropType).isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export type MyAppointmentSaveAction = InferProps<typeof myAppointmentSaveAction>;

export const saveAppointmentGap = (dispatch: Function, contractAccountId: string, assOrderId: string, appointmentGap: AppointmentGap) => {
  return new Promise((resolve, reject) => {
    if (contractAccountId && assOrderId && appointmentGap?.gapId) {
      dispatch({ type: SET_APPOINTMENT_LOADING });
      saveAppointment(contractAccountId, assOrderId, appointmentGap?.gapId)
        .then(() => {
          const responseNextAppointmentPromise = getNextAppointment(contractAccountId, assOrderId);
          responseNextAppointmentPromise.then((responseAppointment) => {
            dispatch({
              type: SAVE_APPOINTMENT,
              data: {
                assOrderId,
                innerData: responseAppointment.data,
              },
            });
          });
          resolve(true);
        })
        .catch((error) => {
          dispatch({ type: THROW_APPOINTMENT_ERROR });
          if (error?.response?.status === 422) {
            resolve(false);
          } else {
            reject(false);
          }
        });
    } else {
      reject(false);
    }
  });
};
