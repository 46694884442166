import { resetPasswordInitiate } from '../../../api-factory/accountDataApi';
import { MyDataTypes } from '../../redux/types/personalDataPropTypes';
import { getBusinessPartner, getPersonalData, saveMyData } from '../../../api-factory/myDataApi';
import { TaxNumberChangeEventType, TaxNumberTypes } from '../../pages/TaxVatDetailsPage/taxVatinPageTypes';

export enum PersonalDataActions {
  LOAD_CURRENT_PERSONAL_DATA = 'api/reducer/GET_PERSONAL_DATA',
  SAVE_MY_DATA = 'api/reducer/SAVE_MY_DATA',
  LOAD_BUSINESS_PARTNER = 'api/reducer/LOAD_BUSINESS_PARTNER',
  IS_LOADING = 'api/reducer/IS_LOADING',
  RESET_PASSWORD_INITIATE = 'api/reducer/RESET_PASSWORD_INITIATE',
  RESET_PASSWORD_VALIDATE = 'api/reducer/RESET_PASSWORD_VALIDATE',
  RESET_PASSWORD_SET = 'api/reducer/RESET_PASSWORD_SET',
  SET_API_ERROR = 'api/reducer/SET_API_ERROR',
  CLEAR_API_ERROR = 'api/reducer/CLEAR_API_ERROR',
}

export type PersonalDataActionTypes = {
  type: PersonalDataActions;
  myData: MyDataTypes;
  isLoadingPersonalData: boolean;
  taxNumbers: TaxNumberTypes;
  taxNumbersChangeEvent: TaxNumberChangeEventType;
  businessPartner: any;
  hasApiError: boolean;
  apiErrorMessage: string;
};

export function setLoader(dispatch, isLoading: boolean = true) {
  dispatch({
    type: PersonalDataActions.IS_LOADING,
    isLoadingPersonalData: isLoading,
  });
}

export function setApiError(dispatch, hasApiError: boolean = false, error = { message: '' }) {
  dispatch({
    type: PersonalDataActions.SET_API_ERROR,
    hasApiError,
    apiErrorMessage: error.message,
  });
}

export function loadCurrentPersonalData(dispatch, contractId) {
  setLoader(dispatch);
  getPersonalData(contractId)
    .then((response) => {
      dispatch({
        type: PersonalDataActions.LOAD_CURRENT_PERSONAL_DATA,
        myData: { ...response.data },
        data: { ...response.data },
      });
    })
    .catch((error) => {
      setApiError(dispatch, true, error);
    });
}

export function resetApiError(dispatch) {
  dispatch({
    type: PersonalDataActions.CLEAR_API_ERROR,
  });
}

export function savePersonalData(dispatch, contractId, myData: MyDataTypes) {
  setLoader(dispatch);
  return saveMyData(contractId, myData)
    .then((response) => {
      dispatch({
        type: PersonalDataActions.SAVE_MY_DATA,
        myData: response.data,
        data: { ...response.data },
      });
    })
    .catch((error) => {
      setApiError(dispatch, true, error);
    });
}

export function loadBusinessPartner(dispatch) {
  setLoader(dispatch);
  getBusinessPartner()
    .then((response) => {
      dispatch({
        type: PersonalDataActions.LOAD_BUSINESS_PARTNER,
        businessPartner: response.data,
      });
    })
    .catch((error) => {
      setApiError(dispatch, true, error);
    });
}

export function passwordReset(dispatch, emailDataObj) {
  setLoader(dispatch);
  return resetPasswordInitiate(emailDataObj, null)
    .then((response) => {
      dispatch({
        type: PersonalDataActions.RESET_PASSWORD_INITIATE,
        phoneNumber: response.data.content,
      });
    })
    .catch((error) => {
      setApiError(dispatch, true, error);
    });
}
