/* eslint "@typescript-eslint/no-unused-vars": 0 */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchJsonP from 'fetch-jsonp';
import ActivityWatcher, { tokenTypes } from './ActivityWatcher';
import Unauthenticated from '../errors/Unauthenticated';
import Unauthorized from '../errors/Unauthorized';
import SessionHandler, { loginTypes, appTypes } from './SessionHandler';
import GlobalErrorHandler from './GlobalErrorHandler';
import Router from './Router';
import CONFIG from '../mainConfig';
import { showCCUnauthorizedError } from '../csc/components/CallCenterUnauthorizedError/CallCenterUnauthorizedError';
import MyNetworkError from '../errors/MyNetworkError';

export type MWDomainsTypes = {
  cicAccount: string;
  cscAccount: string;
  cicCustomer: string;
  cscCustomer: string;
};

let codeExIsInProg = false;

export const MWDomains: MWDomainsTypes = {
  cicAccount: 'account',
  cicCustomer: 'customer',
  cscCustomer: 'cscnCustomerDomain',
  cscAccount: 'cscnAccountDomain',
};

const apiTimeoutInMilliseconds = 150000;

class RestHelper {
  static baseApiURLs: any;

  static refreshTokens(response: AxiosResponse<any, any>) {
    if (response.data && response.data.token) {
      const userAndToken = response.data.token.split(' ');
      SessionHandler.initSession(userAndToken[0], userAndToken[1], SessionHandler.getLoginType());
    } else if (response.headers && response.headers['x-auth-token']) {
      const userAndToken = response.headers['x-auth-token'].split(' ');
      SessionHandler.initSession(userAndToken[0], userAndToken[1], SessionHandler.getLoginType());
    }
  }

  static isCiamLogin() {
    return Router.getUrlParameter('code');
  }

  static getRedirectUriWithoutQParams() {
    return window.location.href.split('?')[0];
  }

  static refreshCiamToken() {
    const body = new FormData();
    // grant_type=refresh_token&
    // client_id=3MVG9lKcPoNINVBIPJjdw1J9LLM82HnFVVX19KY1uA5mu0QqEWhqKpoW3svG3XHrXDiCQjK1mdgAvhCscA9GE&client_secret=1955279925675241571&
    // refresh_token=your token here
    body.append('grant_type', 'refresh_token');
    body.append('redirect_uri', RestHelper.getRedirectUriWithoutQParams());
    body.append('refresh_token', SessionHandler.getRefreshToken());
    body.append('client_id', CONFIG.AEM_CONFIG.SSO.clientId);
    const promise = axios({
      method: 'post',
      url: CONFIG.AEM_CONFIG.SSO.ssoExchangeUrl,
      data: body,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    promise.then((response) => {
      if (response.data?.id_token) {
        SessionHandler.setToken(response.data.id_token, 'ciam');
      }
    });
    return promise;
  }

  static refreshMiddlewareToken() {
    if (SessionHandler.isUserAssignedToApp(appTypes.cscn)) {
      return this.get(`${this.getBaseUrl(MWDomains.cscCustomer)}/cscn-customer-srv/api/root/v0/`);
    }
    if (SessionHandler.isUserAssignedToApp(appTypes.cic)) {
      return this.get(`${this.getBaseUrl(MWDomains.cicCustomer)}/api/root/v0`);
    }
    return Promise.resolve();
  }

  static tokenExchange(code: string, codeVerifier: string) {
    const body = new FormData();
    body.append('grant_type', 'authorization_code');
    body.append('redirect_uri', RestHelper.getRedirectUriWithoutQParams());
    body.append('code', code);
    body.append('code_verifier', codeVerifier);
    body.append('client_id', CONFIG.AEM_CONFIG.SSO.clientId);
    const promise = axios({
      method: 'post',
      url: CONFIG.AEM_CONFIG.SSO.ssoExchangeUrl,
      data: body,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return promise;
  }

  static initCiamTokenExchange() {
    if (codeExIsInProg) {
      return Promise.resolve();
    }

    const returnPromise = new Promise((resolve, reject) => {
      if (!this.isCiamLogin()) {
        reject();
      }
      SessionHandler.destroySession(false);
      const code = Router.getUrlParameter('code');
      RestHelper.tokenExchange(code, SessionHandler.getCurrentCodeVerifier() ?? '')
        .then((reponse1) => {
          codeExIsInProg = true;
          SessionHandler.removeCodeVerifier();
          if (reponse1.data && reponse1.data.id_token) {
            SessionHandler.initSession('Bearer', {
              accessToken: reponse1.data.id_token,
              refreshToken: reponse1.data.refresh_token,
            });
            ActivityWatcher.registerNewActivity();
            ActivityWatcher.registerTokenRefreshing(tokenTypes.ciam);
            ActivityWatcher.registerTokenRefreshing(tokenTypes.mw);
            ActivityWatcher.initActivityObserving(
              540,
              () => this.refreshCiamToken(),
              () => this.refreshMiddlewareToken(),
              () => SessionHandler.destroySession()
            );
          }
          resolve(reponse1);
        })
        .catch(() => {
          if (!CONFIG.AEM_CONFIG.isAuthorMode) {
            const redirectUrl = CONFIG.AEM_CONFIG.loginFailureUrl;
            location.replace(redirectUrl);
          }
          reject();
        });
    });
    return returnPromise;
  }

  static getBaseUrl(domain?: string) {
    if (!this.baseApiURLs) {
      throw Error('API URL is not set up yet');
    }
    if (!domain) {
      return this.baseApiURLs[MWDomains.cscCustomer];
    }
    return this.baseApiURLs[domain];
  }

  static initBaseURLs(urls: any) {
    this.baseApiURLs = urls;
  }

  static getAuthHeader(isCiam: boolean) {
    if (!SessionHandler.getName() && !SessionHandler.getToken()) {
      return {};
    }
    if (isCiam) {
      return { Authorization: `${SessionHandler.getName()} ${SessionHandler.getToken('ciam')}` };
    }
    return {
      'X-Auth-Token': `${SessionHandler.getName()} ${SessionHandler.getToken()}`,
    };
  }

  static setXClientHeader(config: AxiosRequestConfig<any>, header: string): AxiosRequestConfig<any> {
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Client': header,
      },
    };
  }

  static setConfig(config: any = {}, setAuth: boolean = false, url: string) {
    const isCiam =
      url?.indexOf(CONFIG.AEM_CONFIG.middlewareDomains.cscnAccountDomain) !== -1 || url?.indexOf(CONFIG.AEM_CONFIG.middlewareDomains.account) !== -1;
    const isCsc = url?.indexOf(CONFIG.AEM_CONFIG.middlewareDomains.cscnCustomerDomain) !== -1;
    const rconfig = config;
    if (!config.headers) {
      rconfig.headers = {};
    }
    if (!rconfig.headers['Content-Type']) {
      rconfig.headers['Content-Type'] = 'application/json';
    }

    if (setAuth) {
      rconfig.headers = { ...rconfig.headers, ...this.getAuthHeader(isCiam) };
    }
    if (!rconfig.headers['X-Client']) {
      rconfig.headers['X-Client'] = isCsc ? CONFIG.CHANNEL_CONFIG.loggedInCsc : CONFIG.CHANNEL_CONFIG.portal;
    }
    if (!rconfig.headers['X-Client']) {
      rconfig.headers['X-Client'] = CONFIG.CHANNEL_CONFIG.loggedInCsc;
    }
    return {
      ...rconfig,
      timeout: apiTimeoutInMilliseconds,
    };
  }

  static errorHandler(error: AxiosError<any, any>) {
    try {
      if (error.response) {
        this.refreshTokens(error.response);
        // The request was made, but the server responded with a status code
        // that falls out of the range of 2xx   );

        if (error.response.status === 401) {
          const errorCode = error.response.data && error.response.data.statusInfo && error.response.data.statusInfo.code;
          /* When user with an XID log's in to cscn portal - capture the api error
             and navigate to portal unlock page */
          if (errorCode === 'global.error.incompleteUser') {
            const locationPage = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
            const configPage = CONFIG.AEM_CONFIG.portalUnlockRedirectUrl.split('/').slice(-1)[0];
            if (locationPage !== configPage) {
              if (SessionHandler.getLoginType() !== loginTypes.callCenter) {
                SessionHandler.setLoginType(loginTypes.incompleteLoggedIn);
              }
              location.replace(CONFIG.AEM_CONFIG.portalUnlockRedirectUrl);
            }
          } else {
            // @TODO exception has to be disabled until CSC MW is accepting salesforce token
            const newExp = new Unauthenticated();
            newExp.loginType = SessionHandler.getLoginType();
            throw newExp;
          }
        } else if (error.response.status === 403) {
          const newExp = new Unauthorized();
          newExp.loginType = SessionHandler.getLoginType();
          if (newExp.loginType === loginTypes.callCenter) {
            showCCUnauthorizedError();
          }
          throw newExp;
        }
      }
    } catch (exception) {
      GlobalErrorHandler.handleException(exception);
    }
  }

  static handleAxiosError(error: AxiosError<any, any>, useErrorHandler: boolean) {
    if (error.code === AxiosError.ECONNABORTED) {
      GlobalErrorHandler.handleException(new MyNetworkError());
    } else if (useErrorHandler) {
      this.errorHandler(error);
    }
  }

  // TODO: remove params
  static get(url: string, params = {}, config: AxiosRequestConfig<any> = {}, useErrorHandler = true, auth = true): Promise<AxiosResponse> {
    const request = axios.get(url, this.setConfig(config, auth, url));

    request
      .then((response) => {
        this.refreshTokens(response);
      })
      .catch((error: AxiosError<any, any>) => {
        this.handleAxiosError(error, useErrorHandler);
      });
    return request;
  }

  static postFile(url: string, params: any, config = {}) {
    const request = axios.post(url, params, this.setConfig(config, false, url));

    request.catch((error: AxiosError<any, any>) => {
      this.handleAxiosError(error, true);
    });

    return request;
  }

  static post(url: string, params: object, config = {}, useErrorHandler = true, auth = true): Promise<AxiosResponse> {
    const request = axios.post(url, params, this.setConfig(config, auth, url));

    request
      .then((response) => {
        this.refreshTokens(response);
        return response;
      })
      .catch((error: AxiosError<any, any>) => {
        this.handleAxiosError(error, useErrorHandler);
      });

    return request;
  }

  static put(url: string, params: object, config = {}, useErrorHandler = true, auth = true): Promise<AxiosResponse> {
    const request = axios.put(url, params, this.setConfig(config, auth, url));

    request
      .then((response) => {
        this.refreshTokens(response);
        return response;
      })
      .catch((error: AxiosError<any, any>) => {
        this.handleAxiosError(error, useErrorHandler);
      });

    return request;
  }

  static patch(url: string, params: object, config = {}, useErrorHandler = true, auth = true): Promise<AxiosResponse> {
    const request = axios.patch(url, params, this.setConfig(config, auth, url));

    request
      .then((response) => {
        this.refreshTokens(response);
      })
      .catch((error: AxiosError<any, any>) => {
        this.handleAxiosError(error, useErrorHandler);
      });

    return request;
  }

  static delete(url: string, params = {}, config = {}, useErrorHandler = true, auth = true): Promise<AxiosResponse> {
    const request = axios.delete(url, {
      ...this.setConfig(config, auth, url),
      data: params,
    });

    request
      .then((response) => {
        this.refreshTokens(response);
      })
      .catch((error: AxiosError<any, any>) => {
        this.handleAxiosError(error, useErrorHandler);
      });
    return request;
  }

  static getJsonP(url: string) {
    const result = fetchJsonP(url, { timeout: 3000 });
    result
      .then((response) => response.json())
      .catch((ex) => {
        document.body.innerHTML = `failed: ${ex}`;
      });
    return result;
  }

  static downloadContentWithPost(id: string, url: string, inputs: any = [], callback = () => {}, token = SessionHandler.getToken()) {
    try {
      const pdfForm = document.createElement('form');
      pdfForm.name = 'downloadPdf';
      pdfForm.id = id;
      pdfForm.method = 'POST';
      pdfForm.action = url;
      const tokenInput = document.createElement('input');
      tokenInput.type = 'hidden';
      tokenInput.name = 'Bearer';
      tokenInput.value = token;
      pdfForm.appendChild(tokenInput);
      if (inputs.length) {
        inputs.forEach((elem: any) => {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = elem.name;
          input.value = elem.value;
          pdfForm.appendChild(input);
        });
      }
      document.body.appendChild(pdfForm);
      pdfForm.submit();
      const elementToRemove = document.getElementById(id);
      elementToRemove?.parentNode?.removeChild(elementToRemove);
      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}

export default RestHelper;
