import moment from 'moment';
import { getDevices, getDeviceData } from '../api/rlmDeviceApi';
import SessionHandler from '../utils/SessionHandler';
import Router from '../../utils/Router';

// Actions
const LOAD_CURRENT_DEVICES = 'api/reducer/RLM/GET_DEVICES';
const SET_SELECTED_DEVICE = 'api/reducer/RLM/DEVICE';
const LOAD_CURRENT_DEVICE_DATA = 'api/reducer/RLM/GET_DEVICE_DATA';
const LOAD_CURRENT_DEVICE_DATA_STARTED = 'api/reducer/RLM/LOAD_CURRENT_DEVICE_DATA_STARTED';
const LOAD_CURRENT_DEVICE_DATA_FAILURE = 'api/reducer/RLM/LOAD_CURRENT_DEVICE_DATA_FAILURE';
const SET_DEVICE_DATE = 'api/reducer/RLM/DEVICE_DATE';
const SET_START_TIME = 'api/reducer/RLM/START_TIME';

export const getRlmId = (device) => `${device.locationId}${device.profileRole}${device.profileDeterminant}`;
export const getDevice = (devices, rlmId) => (devices ? devices.find((elem) => getRlmId(elem) === rlmId) : null);
/**
 * returns type and contract specific values
 * @param measurementData {array} - data from the response
 * @param dataFilter {string} - what data type you're interested in
 * @returns {*}
 */
export const getDeviceDataByType = (measurementData, type) => measurementData.filter((x) => x.type === type);
/**
 * creates contractspecific value series to display in the graph
 * @param deviceData {array} - array of available data per contract account
 * @returns {*[]}
 */

export const getContractSpecificItems = (deviceData, type) =>
  Array.from(
    deviceData.map((elem) => ({
      contractAccountId: type === 'rlm' && elem.contractAccountId,
      time: moment(elem.startTime * 1000),
      standardTime: type === 'smartmeter' && moment(elem.startTime * 1000),
      values: type === 'rlm' ? elem.values : elem.measurements,
      standardMeasurements: elem.standardMeasurements && elem.standardMeasurements,
    }))
  );

// Reducer
export default function reducer(state = {}, action) {
  const getPreselectedDevice = (devices) => {
    const id = Router.getParameter('rlmId');
    if (id) {
      return id;
    }
    if (devices[0]) {
      Router.setParameter({ rlmId: getRlmId(devices[0]) });
      return getRlmId(devices[0]);
    }
    return null;
  };
  switch (action.type) {
    case LOAD_CURRENT_DEVICES:
      return {
        ...state,
        devicesList: action.data.innerData,
        rlmId: getPreselectedDevice(action.data.innerData),
      };
    case SET_SELECTED_DEVICE:
      Router.setParameter({ rlmId: action.data.rlmId });
      return {
        ...state,
        rlmId: action.data.rlmId,
      };
    case LOAD_CURRENT_DEVICE_DATA:
      return {
        ...state,
        deviceData: getDeviceDataByType(action.data.innerData.measurements, 'METERED'),
      };
    case SET_DEVICE_DATE:
      return {
        ...state,
        selectedStartDate: action.data.dateFrom,
        selectedEndDate: action.data.dateTo,
      };
    case SET_START_TIME:
      return {
        ...state,
        measurementData: {
          interval: state.deviceData[0].intervalSec * 1000,
          unit: state.deviceData[0].unit,
          contractSpecificValues: getContractSpecificItems(state.deviceData, 'rlm'),
        },
        deviceDataProcceding: false,
        apiErrorCode: null,
      };
    case LOAD_CURRENT_DEVICE_DATA_FAILURE:
      return {
        ...state,
        deviceDataProcceding: false,
        apiErrorCode: action.data.error,
      };
    case LOAD_CURRENT_DEVICE_DATA_STARTED:
      return {
        ...state,
        deviceDataProcceding: true,
      };
    default:
      return state;
  }
}

// Action Creators
export function loadCurrent(dispatch) {
  const resp = getDevices();
  return resp.then((reponse) => {
    dispatch({
      type: LOAD_CURRENT_DEVICES,
      data: {
        innerData: reponse.data.content,
      },
    });
  });
}

export function setSelectedDevice(dispatch, rlmId) {
  SessionHandler.resetPermissions();
  dispatch({
    type: SET_SELECTED_DEVICE,
    data: {
      rlmId,
    },
  });
}

export function loadDeviceData(dispatch, location, dateFrom, dateTo) {
  const resp = getDeviceData(location, dateFrom, dateTo);
  dispatch({
    type: LOAD_CURRENT_DEVICE_DATA_STARTED,
  });
  return resp
    .then((response) => {
      dispatch({
        type: LOAD_CURRENT_DEVICE_DATA,
        data: {
          innerData: response.data,
        },
      });
    })
    .catch((err) => {
      let errorCode = 'dataNotFound';
      if (err.response?.data?.errors?.[0]) {
        errorCode = err.response.data.errors[0].code.split('.').pop();
      }
      dispatch({
        type: LOAD_CURRENT_DEVICE_DATA_FAILURE,
        data: {
          error: errorCode,
        },
      });
    });
}

export function setDeviceDate(dispatch, dateFrom, dateTo) {
  dispatch({
    type: SET_DEVICE_DATE,
    data: {
      dateTo,
      dateFrom,
    },
  });
}

export function setMeasurementTimeList(dispatch, time) {
  dispatch({
    type: SET_START_TIME,
    data: {
      time,
    },
  });
}
