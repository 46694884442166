const storeName = 'cache';
class CacheHandler {
  static getCurrentCache(): any {
    return sessionStorage[storeName] ? JSON.parse(sessionStorage[storeName]) : {};
  }

  static getCurrentCacheByToken(tokenName: string): any {
    return this.getCurrentCache()[tokenName];
  }

  static saveCache(newCache): void {
    sessionStorage[storeName] = JSON.stringify({ ...this.getCurrentCache(), ...newCache });
  }

  static clearAllCache(all: boolean = false): void {
    if (all) {
      sessionStorage.cicCache = '';
      sessionStorage.cscCache = '';
      sessionStorage.cache = '';
    } else {
      sessionStorage[storeName] = '';
    }
  }

  static clearByToken(tokenName: string): void {
    const currCache = this.getCurrentCache();
    currCache[tokenName] = '';
    this.saveCache(currCache);
  }

  static requestCache(promiseCallback: any, tokenName: string): Promise<any> {
    if (!this.getCurrentCacheByToken(tokenName)) {
      const promise = promiseCallback();
      promise.then((response) => {
        const toCache = {};
        toCache[tokenName] = response;
        this.saveCache(toCache);
      });
      return promise;
    }
    return new Promise((resolve) => {
      resolve(this.getCurrentCacheByToken(tokenName));
    });
  }
}
export default CacheHandler;
