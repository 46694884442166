import React from 'react';
import { ErrorHintArgsTypes } from '../../MeterReadingTypes';
import MeterReadingGeneric from '../../Anonym/MeterReadingGeneric/MeterReadingGeneric';
import NoRegisterError, { NoRegisterErrorLabels } from '../../Anonym/NoRegisterError/NoRegisterError';
import getMeterReadingInfoMessageText, { MeterReadingInfoMessageTypes } from './meterReadingInfoMessageDisplayHelper';

export type MeterReadingInfoMessageDisplayLabels = {
  genericTitle: string;
  anonymousMeterReadingLoginPage: string;
  logoutButtonTxt: string;
  noRegisterError: string;
  allMetersLockedText: string;
};

export type MeterReadingInfoMessageDisplayTypes = {
  meterReadingInfoMessages: MeterReadingInfoMessageTypes[];
  errorArgs?: ErrorHintArgsTypes;
  allMetersLocked?: boolean;
};

export type MeterReadingInfoMessageDisplayProps = MeterReadingInfoMessageDisplayLabels & MeterReadingInfoMessageDisplayTypes & NoRegisterErrorLabels;

const MeterReadingInfoMessageDisplay = (props: MeterReadingInfoMessageDisplayProps) => {
  const infoMessageText: string = props.allMetersLocked
    ? props.allMetersLockedText
    : getMeterReadingInfoMessageText(props.errorArgs?.infoMessages, props.meterReadingInfoMessages) || props.noRegisterError;

  return (
    <div className='col-md-12 form-wrapper form-wrapper--has-shadow ano-meter-reading'>
      <MeterReadingGeneric genericTitle={props.genericTitle} />
      <NoRegisterError
        {...props}
        isSmallText
        noRegisterError={infoMessageText}
      />
    </div>
  );
};

export default MeterReadingInfoMessageDisplay;
