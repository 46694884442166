import { Dispatch } from 'react';
import { InvoiceTypes } from '../../components/Invoice/InvoiceTypes';
import { getInvoices } from '../../../api-factory/invoiceApi';

export enum InvoiceActionType {
  LOAD_ALL_INVOICES = 'api/reducer/ALL_INVOICES',
  LOADING = 'api/reducer/LOADING',
  HAS_API_ERROR = 'api/reducer/HAS_API_ERROR',
}

export type InvoiceActionTypes = {
  type: InvoiceActionType;
  invoices: InvoiceTypes[];
  apiErrorMessage: string;
};

const loadAllInvoices = (dispatch: Dispatch<Partial<InvoiceActionTypes>>, contractId: string) => {
  dispatch({
    type: InvoiceActionType.LOADING,
  });
  getInvoices(contractId)
    .then((response) => {
      dispatch({
        type: InvoiceActionType.LOAD_ALL_INVOICES,
        invoices: response.data.content,
      });
    })
    .catch((error) => {
      dispatch({
        type: InvoiceActionType.HAS_API_ERROR,
        apiErrorMessage: error.message,
      });
    });
};

export { loadAllInvoices };
