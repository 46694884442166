import PropTypes from 'prop-types';

export const meterReadingYourBenefitsPropTypes = {
  paperlessCommunication: PropTypes.string.isRequired,
  meterShow: PropTypes.string.isRequired,
  meterRemind: PropTypes.string.isRequired,
  consumptionHistory: PropTypes.string.isRequired,
  addressChangeOnline: PropTypes.string.isRequired,
};

export const meterReadingRegisterLabelPropTypes = {
  onlyGuestPortal: PropTypes.bool.isRequired,
  withoutRegisterText: PropTypes.string.isRequired,
  withoutRegisterButton: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  readingCardHeadline: PropTypes.string.isRequired,
  readingCardSubline: PropTypes.string.isRequired,
  registerForPortalHeadline: PropTypes.string.isRequired,
  registerForPortalSubline: PropTypes.string.isRequired,
  yourBenefitsText: PropTypes.string.isRequired,
  yourBenefits: PropTypes.shape(meterReadingYourBenefitsPropTypes).isRequired,
  activateButton: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  registerButton: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  dataProtectionPageUrl: PropTypes.string.isRequired,
  dataProtectionLabel: PropTypes.string.isRequired,
  dataProtectionText1: PropTypes.string.isRequired,
  dataProtectionText2: PropTypes.string.isRequired,
};
