import { getInstallationsInfo, getInstallationDetails } from '../api/mastrApi';
import SessionHandler from '../utils/SessionHandler';
import Router from '../../utils/Router';

// Actions
const LOAD_CURRENT_MASTR = 'api/reducer/GET_MASTR';
export const SET_SELECTED_MASTR = 'api/reducer/SET_SELECTED_MASTR';
const LOAD_CURRENT_MASTR_DATA = 'api/reducer/GET_DEVICE_DATA';
const LOAD_CURRENT_MASTR_DATA_STARTED = 'api/reducer/LOAD_CURRENT_MASTR_DATA_STARTED';
const LOAD_CURRENT_MASTR_DATA_FAILURE = 'api/reducer/LOAD_CURRENT_MASTR_DATA_FAILURE';
const LOAD_CURRENT_MASTR_DETAILS = 'api/reducer/LOAD_CURRENT_MASTR_DETAILS';
const STOP_MASTR_DET_LOADING = 'api/reducer/STOP_MASTR_DET_LOADING';

export const getHarnesId = (contract) => contract.harnesId;
export const getContract = (contracts, harnesId) => (contracts && contracts.find ? contracts.find((elem) => getHarnesId(elem) === harnesId) : null);
// Reducer
export default function reducer(state = {}, action) {
  const getPreselectedContract = (devices) => {
    const id = Router.getParameter('harnesId');
    if (id) {
      return id;
    }
    if (devices[0]) {
      const hId = getHarnesId(devices[0]) || 'none__0';
      Router.setParameter({ harnesId: hId, pos: 0 });
      return hId;
    }
    return null;
  };
  const getElemPosition = () => {
    const id = Router.getParameter('pos');
    if (id) {
      return id;
    }
    Router.setParameter({ pos: 0 });
    return 0;
  };
  switch (action.type) {
    case LOAD_CURRENT_MASTR:
      return {
        ...state,
        contractsList: action.data.innerData,
        harnesId: getPreselectedContract(action.data.innerData),
        elemPos: getElemPosition(),
      };
    case SET_SELECTED_MASTR:
      Router.setParameter({
        harnesId: action.data.harnesId,
        pos: action.data.pos,
      });
      Router.setParameter({ pos: action.data.pos });
      return {
        ...state,
        harnesId: action.data.harnesId,
        elemPos: action.data.pos,
        error: null,
        mastrDataProcceding: true,
      };
    case LOAD_CURRENT_MASTR_DATA:
      return {
        ...state,
        mastrData: [action.data.innerData],
      };
    case LOAD_CURRENT_MASTR_DATA_STARTED:
      return {
        ...state,
        mastrDataProcceding: true,
      };
    case LOAD_CURRENT_MASTR_DATA_FAILURE:
      return {
        ...state,
        mastrDataProcceding: false,
        error: action.data.error,
      };
    case LOAD_CURRENT_MASTR_DETAILS:
      return {
        ...state,
        error: null,
        mastrDetails: action.data.innerData.details,
        mastrDataProcceding: false,
      };
    case STOP_MASTR_DET_LOADING:
      return {
        ...state,
        mastrDataProcceding: false,
      };

    default:
      return state;
  }
}

// Action Creators
export function loadCurrentEegs(dispatch) {
  const resp = getInstallationsInfo();
  return resp.then((reponse) => {
    dispatch({
      type: LOAD_CURRENT_MASTR,
      data: {
        innerData: reponse.data ? reponse.data.content : {},
      },
    });
  });
}

export function setSelectedEeg(dispatch, harnesId, pos) {
  SessionHandler.resetPermissions();
  dispatch({
    type: SET_SELECTED_MASTR,
    data: {
      harnesId,
      pos,
    },
  });
}
export function stopLoading(dispatch) {
  dispatch({
    type: STOP_MASTR_DET_LOADING,
  });
}

export function loadInstallationDetails(dispatch, selected) {
  dispatch({
    type: LOAD_CURRENT_MASTR_DATA_STARTED,
  });
  const resp = getInstallationDetails(selected);
  return resp
    .then((reponse) => {
      dispatch({
        type: LOAD_CURRENT_MASTR_DETAILS,
        data: {
          innerData: reponse.data ? reponse.data : {},
        },
      });
    })
    .catch((err) => {
      let errorCode;
      if (err.response.data.errors[0] && err.response.data.errors[0].code) {
        errorCode = err.response.data.errors[0].code.split('.').pop();
      }
      dispatch({
        type: LOAD_CURRENT_MASTR_DATA_FAILURE,
        data: {
          error: errorCode,
        },
      });
    });
}
// export function loadDeviceData(dispatch, serialNumber, obises, dateFrom, dateTo) {
//   const resp = getDeviceData(serialNumber, obises, dateFrom, dateTo);
//   dispatch({
//     type: LOAD_CURRENT_DEVICE_DATA_STARTED,
//   });
//   return resp
//     .then((response) => {
//       dispatch({
//         type: LOAD_CURRENT_DEVICE_DATA,
//         data: {
//           innerData: response.data,
//         },
//       });
//     }).catch((err) => {
//       let errorCode;
//       if (err.response.data.errors[0] && err.response.data.errors[0].code) {
//         errorCode = err.response.data.errors[0].code.split('.').pop();
//       }
//       dispatch({
//         type: LOAD_CURRENT_DEVICE_DATA_FAILURE,
//         data: {
//           error: errorCode,
//         },
//       });
//     });
// }
