import { loggedinMeterReadingReducerActionTypes, MeterReadingActions } from '../actions/meterReading/loggedinMeterReadingActions';
import { DeviceDataTypes } from '../components/MeterRead/MeterReadingTypes';

export type LoggedinMeterReadingReducerStateTypes = {
  loading: boolean;
  apiError: boolean;
  noMeters: boolean;
  openMeter: string;
  metersWithCategory: DeviceDataTypes[];
  metersWithoutCategory: DeviceDataTypes[];
  metersWithLockedCategory: DeviceDataTypes[];
  savedMeters: string[];
};

const initialState: LoggedinMeterReadingReducerStateTypes = {
  loading: true,
  apiError: false,
  noMeters: false,
  openMeter: '',
  metersWithCategory: [],
  metersWithoutCategory: [],
  metersWithLockedCategory: [],
  savedMeters: [],
};

export default function reducer(state: LoggedinMeterReadingReducerStateTypes = initialState, action: loggedinMeterReadingReducerActionTypes) {
  switch (action.type) {
    case MeterReadingActions.LOAD_ALL_METERS: {
      const metersWithCategory = action.data.devices.filter((device) => device.readingStatus === 'WITH_OPEN_READING_ORDERS');
      const metersWithoutCategory = action.data.devices.filter((device) => device.readingStatus === 'WITHOUT_OPEN_READING_ORDERS');
      const metersWithLockedCategory = action.data.devices.filter((device) => device.readingStatus === 'LOCKED');
      const selectedMeterSerialNumber = metersWithCategory?.length > 0 ? metersWithCategory[0].serialNumber : '';
      return {
        ...state,
        noMeters: action.data.devices.length === 0,
        loading: false,
        metersWithCategory,
        metersWithoutCategory,
        metersWithLockedCategory,
        openMeter: selectedMeterSerialNumber,
        savedMeters: [],
      };
    }
    case MeterReadingActions.LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case MeterReadingActions.MARK_METER_SAVED: {
      return {
        ...state,
        savedMeters: [...state.savedMeters, action.serialNumber],
      };
    }
    case MeterReadingActions.OPEN_METER: {
      return {
        ...state,
        openMeter: action.serialNumber,
      };
    }
    case MeterReadingActions.SET_API_ERROR: {
      return {
        ...state,
        apiError: action.apiError,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
}
