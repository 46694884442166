import React from 'react';
import { DeviceDataTypes, MeterReadingRegisterTypes, MeterRegisterLabelComponentLabels } from '../../MeterReadingTypes';
import { formatObis, getObisLabel } from '../../Anonym/MeterReadingForm/meterReadingFormHelper';

type MeterRegisterLabelComponentTypes = {
  deviceData: DeviceDataTypes;
  register: MeterReadingRegisterTypes;
  iteration: number;
  classNames: string;
  loggedIn: boolean;
};

export type MeterRegisterLabelProps = MeterRegisterLabelComponentTypes & MeterRegisterLabelComponentLabels;

const MeterRegisterLabel = (props: MeterRegisterLabelProps) => {
  const { deviceData, register, iteration, classNames, loggedIn } = props;

  const registerType = register.registerType && register.registerType !== '' ? ` (${register.registerType})` : '';

  return (
    <div
      className='row'
      key={iteration}
    >
      <div className={classNames || ''}>
        <h5
          className={
            loggedIn
              ? 'loggedin-meter-form__header loggedin-meter-form__header--additional-padding'
              : 'ano-meter-form__header ano-meter-form__header--additional-padding'
          }
        >
          {deviceData.division !== 'GAS' && (
            <span data-qa='checkSpan'>{`${getObisLabel(register.registerTypeLabel, props)} ${formatObis(register.obis)}${registerType}`}</span>
          )}
        </h5>
      </div>
    </div>
  );
};

export default MeterRegisterLabel;
