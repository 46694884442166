import moment from 'moment';
import PropTypes, { InferProps, shape } from 'prop-types';
import { DATE_FORMAT_NATIVE } from '../../../utils/GermanDateHelpers';

import { saveAppointmentWish } from '../../../csc/api/myAppointmentApi';
import { reloadAppointment } from './myAppointmentDetailLoadAction';
import { IMyAppointmentWishFormValues, IProposalFormValue } from '../../components/MyAppointment/MyAppointmentWishForm/MyAppointmentWishFormUtil';
import { MyAppointmentProposalData, MyAppointmentWish, myAppointmentWish } from '../../types/myAppointmentTypes';
import { SET_APPOINTMENT_LOADING, THROW_APPOINTMENT_ERROR } from './myAppointmentGapLoadAction';

export const SAVED_APPOINTMENT_WISH = 'api/reducer/SAVE_APPOINTMENT_WISH';

const myAppointmentWishSaveAction = {
  data: shape({
    innerData: PropTypes.shape(myAppointmentWish).isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export type MyAppointmentWishSaveAction = InferProps<typeof myAppointmentWishSaveAction>;

const addZeros = (str: number) => {
  return str.toString().padStart(2, '0');
};

export const saveAppointmentWishAction = (
  dispatch: Function,
  contractAccountId: string,
  assOrderId: string,
  formValues: IMyAppointmentWishFormValues
) => {
  return new Promise((resolve) => {
    if (contractAccountId && assOrderId) {
      dispatch({ type: SET_APPOINTMENT_LOADING });
      const proposals: MyAppointmentProposalData[] = formValues.proposals.map((nextProsal: IProposalFormValue) => ({
        weekday: nextProsal.weekday,
        from: `${addZeros(nextProsal.timeslotFromHour)}:00:00`,
        to: `${addZeros(nextProsal.timeslotToHour)}:00:00`,
      }));
      const wishData: MyAppointmentWish = {
        earliestDate: moment(formValues.whenDate.start).format(DATE_FORMAT_NATIVE),
        proposals,
      };
      return saveAppointmentWish(contractAccountId, assOrderId, wishData)
        .then(() => {
          dispatch({
            type: SAVED_APPOINTMENT_WISH,
            data: {
              assOrderId,
              innerData: wishData,
            },
          });
          reloadAppointment(dispatch, contractAccountId, assOrderId);
          return resolve(true);
        })
        .catch(() => {
          dispatch({ type: THROW_APPOINTMENT_ERROR });
          return resolve(false);
        });
    }
    return resolve(false);
  });
};
